import React, { useState } from 'react';
import {
    Dialog,
    Typography,
    Button,
    IconButton,
    DialogTitle,
    DialogContent,
    DialogActions,
    Box,
    Tooltip
} from '@mui/material';
import { useNavigate, useLocation } from 'react-router-dom';
import MoveIcon from '@mui/icons-material/DriveFileMove';
import TransferWithinAStationIcon from '@mui/icons-material/TransferWithinAStation'; // Add this import
import styled from '@mui/system/styled';
import DocumentService from '../../Services/documents.service';
import { toast } from "react-toastify";
import CircularProgress, {
    circularProgressClasses,
} from '@mui/material/CircularProgress';
import axios from 'axios';

const StyledDialog = styled(Dialog)({
    '& .MuiPaper-root': {
        width: '20%',
        maxWidth: '25%',
    },
});


const StyledDialogTitle = styled(DialogTitle)({
    fontSize: '1.8rem',
    fontWeight: 'bold',
    textAlign: 'center',
    color: '#025366',
});

const StyledDialogContent = styled(DialogContent)({
    textAlign: 'center',
    padding: '20px 30px',
});

const StyledDialogActions = styled(DialogActions)({
    justifyContent: 'center',
    paddingBottom: '20px',
});

const StyledButton = styled(Button)({
    margin: '0 8px',
    padding: '10px 20px',
    fontSize: '1.1rem',
    textTransform: 'none',
    color: '#fff',
    backgroundColor: '#E57C22',
    '&:hover': {
        backgroundColor: '#d9731a',
    },
});

const ConfirmButton = styled(StyledButton)({
    backgroundColor: '#4caf50', // Green for confirm
    color: '#fff',
    '&:hover': {
        backgroundColor: '#388e3c',
    },
});

const OptionButton = styled(Button)({
    margin: '10px 0',
    padding: '12px 25px',
    fontSize: '1.1rem',
    textTransform: 'none',
    color: '#025366',
    backgroundColor: '#f5f5f5',
    border: '1px solid #E57C22',
    width: '100%',  // Make buttons take the full width
    maxWidth: '200px',  // Set a maximum width to ensure consistency
    '&:hover': {
        backgroundColor: '#E57C22',
        color: '#fff',
    },
});

const StyledIconButton = styled(IconButton)({
    color: '#E57C22',
    '&:hover': {
        color: '#ff9800',
    },
    transition: 'color 0.3s ease',
    width: '50px',
    height: '50px',
});

const DocumentTransferDialog = ({
    editedItem,
    token,
    setLoading,
    RefreshPage,
    t,
    client
}) => {
    const [isTypeDialogOpen, setIsTypeDialogOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isConfirmDialogOpen, setIsConfirmDialogOpen] = useState(false);
    const [selectedType, setSelectedType] = useState('');
    const navigate = useNavigate();
    const handleMoveClick = () => {
        setIsTypeDialogOpen(true);
    };

    const handleTypeSelection = (type) => {
        setSelectedType(type);
        setIsTypeDialogOpen(false);
        setIsConfirmDialogOpen(true);
    };

    const handleIncomesExpensesOCRSave = async (dto, documents, token, t) => {
        // Create FormData to send with the request
        const formData = new FormData();
        formData.append('CaseId', dto.caseId);
        formData.append('AccountantId', dto.accountantId);
        formData.append('ReportingMonthDate', new Date(dto.reportingMonthDate).toISOString());
        formData.append('DocumentFileType', dto.toType);
        formData.append('VAT', dto.vat);
        documents.forEach(document => {
            formData.append("documents", document, document.name);
        });

        try {
            // Upload the document using OCR
            const response = await DocumentService.UploadOCRDocument(dto.toType, formData, token);

            if (response.ok) {
                // If upload is successful, delete the original document
                await DocumentService.DeleteDocument(dto.fromType, dto.id, token);
                toast.success(t('documentUploadSuccess'));
            } else {
                const errorText = await response.text();
                toast.error(t('documentUploadError', { errorText }));
            }
        } catch (error) {
            toast.error(t('documentUploadError', { errorText: 'Error uploading document' }));
        }
    };

    const handleConfirm = async () => {
        try {
            setLoading(true);
            setIsLoading(true);
            setIsConfirmDialogOpen(false);
            // Fetch the document from the URL and convert it into a Blob
            // Initialize an empty array to hold files
            const files = [];

            // Check if documentUrl is an array or a single URL
            const documentUrls = Array.isArray(editedItem.documentUrl) ? editedItem.documentUrl : [editedItem.documentUrl];

            // Fetch each document from the URL(s) and convert it into a Blob

            for (const url of documentUrls) {
                const response = await axios.get(url, {
                    responseType: 'blob',
                    headers: {
                        'Accept': 'application/pdf',
                    },
                    withCredentials: false,
                    mode: 'cors',
                });
                const blob = response.data;
                const file = new File([blob], "downloaded_file.pdf", { type: blob.type });
                files.push(file);
            }




            const dto = {
                id: editedItem.id,
                caseId: editedItem.caseId,
                accountantId: client.accountantId,
                reportingMonthDate: editedItem.reportingMonthDate || new Date(),
                fromType: editedItem.docType,
                toType: selectedType,
                vat: editedItem.vat || 100
            };


            // If the selected type is 'Incomes' or 'Expenses', handle the OCR save process
            if (selectedType === 'Incomes' || selectedType === 'Expenses') {
                await handleIncomesExpensesOCRSave(dto, files, token, t);
                await RefreshPage();  // Refresh the page after the operation is complete
            } else if (selectedType === 'PaymentAcceptances') {
                // Reset sessionStorage flag
                sessionStorage.removeItem('hasTransferred');

                // Navigate to PaymentAcceptances with state
                navigate('/PaymentAcceptance', { state: { type: "PaymentAcceptances", client, fileTransported: files[0], dto } });
            }
            else if (selectedType === 'OtherDocuments') {
                // Reset sessionStorage flag
                sessionStorage.removeItem('hasTransferredOtherDocuments');

                // Navigate to PaymentAcceptances with state
                navigate('/OtherDocument', { state: { type: "OtherDocuments", client, fileTransported: files, dto } });
            }
        } catch (error) {
            console.error('Error during document transfer:', error);
        } finally {
            setLoading(false);
            setIsLoading(false);
        }
    };

    return (
        <>
            <Tooltip title={t('TransferDocument')}>
                <IconButton onClick={handleMoveClick}>

                    <MoveIcon style={{ color: '#304FFF' }} />
                </IconButton>

            </Tooltip>

            {/* Document Type Selection Dialog */}
            <StyledDialog open={isTypeDialogOpen} onClose={() => setIsTypeDialogOpen(false)} >
                <StyledDialogTitle>{t("SelectDocumentType")}</StyledDialogTitle>
                <StyledDialogContent>
                    <Typography variant="body2" color="#025366">{t("ChooseTypeToTransferDocument")}:</Typography>
                    <Box display="flex" flexDirection="column" alignItems="center" mt={2}>
                        {editedItem.docType === 'Incomes' && (
                            <>
                                <OptionButton onClick={() => handleTypeSelection('Expenses')}>
                                    {t("Expenses")}
                                </OptionButton>
                                <OptionButton onClick={() => handleTypeSelection('PaymentAcceptances')}>
                                    {t("PaymentAcceptances")}
                                </OptionButton>
                                <OptionButton onClick={() => handleTypeSelection('OtherDocuments')}>
                                    {t("OtherDocuments")}
                                </OptionButton>
                            </>
                        )}
                        {editedItem.docType === 'Expenses' && (
                            <>
                                <OptionButton onClick={() => handleTypeSelection('Incomes')}>
                                    {t("Incomes")}
                                </OptionButton>
                                <OptionButton onClick={() => handleTypeSelection('PaymentAcceptances')}>
                                    {t("PaymentAcceptances")}
                                </OptionButton>
                                <OptionButton onClick={() => handleTypeSelection('OtherDocuments')}>
                                    {t("OtherDocuments")}
                                </OptionButton>
                            </>
                        )}
                        {editedItem.docType === 'PaymentAcceptances' && (
                            <>
                                <OptionButton onClick={() => handleTypeSelection('Incomes')}>
                                    {t("Incomes")}
                                </OptionButton>
                                <OptionButton onClick={() => handleTypeSelection('Expenses')}>
                                    {t("Expenses")}
                                </OptionButton>
                                <OptionButton onClick={() => handleTypeSelection('OtherDocuments')}>
                                    {t("OtherDocuments")}
                                </OptionButton>
                            </>
                        )}
                        {editedItem.docType === 'OtherDocuments' && (
                            <>
                                <OptionButton onClick={() => handleTypeSelection('Incomes')}>
                                    {t("Incomes")}
                                </OptionButton>
                                <OptionButton onClick={() => handleTypeSelection('Expenses')}>
                                    {t("Expenses")}
                                </OptionButton>
                                <OptionButton onClick={() => handleTypeSelection('PaymentAcceptances')}>
                                    {t("PaymentAcceptances")}
                                </OptionButton>
                            </>
                        )}
                    </Box>

                </StyledDialogContent>
            </StyledDialog>

            {/* Confirmation Dialog */}
            <Dialog open={isConfirmDialogOpen} onClose={() => setIsConfirmDialogOpen(false)}>
                <StyledDialogTitle>{t("ConfirmTransfer")}</StyledDialogTitle>
                <StyledDialogContent>
                    <Typography variant="body1" color="#025366">
                        {t("AreYouSureYouWantToTransferDocument")} {t(selectedType)}?
                    </Typography>
                </StyledDialogContent>
                <StyledDialogActions>
                    <ConfirmButton onClick={handleConfirm} startIcon={<TransferWithinAStationIcon style={{ marginRight: '10px' }} />}>
                        {t("Confirm")}
                    </ConfirmButton>
                </StyledDialogActions>
            </Dialog>

            {isLoading &&

                <div className="loader-container">
                    <svg width="0" height="0" style={{ position: 'absolute' }}>
                        <defs>
                            <linearGradient id="gradient" x1="0%" y1="0%" x2="100%" y2="100%">
                                <stop offset="0%" stopColor="#E57C22" />
                                <stop offset="100%" stopColor="#025366" />
                            </linearGradient>
                        </defs>
                    </svg>
                    <CircularProgress
                        size={200}
                        thickness={4}
                        sx={{
                            animation: 'progress 1.4s linear infinite',
                            '@keyframes progress': {
                                '0%': {
                                    transform: 'rotate(0deg)',
                                },
                                '100%': {
                                    transform: 'rotate(360deg)',
                                },
                            },
                            '& .MuiCircularProgress-circle': {
                                stroke: 'url(#gradient)',
                                strokeLinecap: 'round',
                            },
                        }}
                    />
                </div>
            }
        </>
    );
};

export default DocumentTransferDialog;
