import React, { useRef, useState, useEffect } from 'react';
import { Modal, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import Iframe from 'react-iframe'; // Assuming you're using the react-iframe library
import './DraggableModal.css'; // Ensure you add the necessary styles

const DraggableModal = ({ bigIframeModalOpen, handleClose, openedIframeUrl, handleCloseIframeModal }) => {
    const modalRef = useRef(null);
    const [pointerEvents, setPointerEvents] = useState('auto');
    const offsetX = useRef(0);
    const offsetY = useRef(0);
    const resizingRef = useRef(false);
    const resizeDirRef = useRef(null);
    const initialSize = useRef({ width: 0, height: 0 });
    const initialMousePos = useRef({ x: 0, y: 0 });

    const MIN_WIDTH = 200;
    const MIN_HEIGHT = 200;

    const handleMouseDown = (e) => {
        if (resizingRef.current) return; // If resizing, do not handle dragging

        const modal = modalRef.current;
        if (!modal) return;
        const rect = modal.getBoundingClientRect();
        offsetX.current = e.clientX - rect.left;
        offsetY.current = e.clientY - rect.top;

        document.addEventListener('mousemove', handleMouseMove);
        document.addEventListener('mouseup', handleMouseUp);
    };

    const handleMouseMove = (e) => {
        const modal = modalRef.current;
        if (!modal) return;
        if (resizingRef.current) {
            let newWidth = initialSize.current.width;
            let newHeight = initialSize.current.height;


            if (resizeDirRef.current.includes('right')) {
                newWidth += e.clientX - initialMousePos.current.x;
            } else if (resizeDirRef.current.includes('left')) {
                newWidth -= e.clientX - initialMousePos.current.x;
                modal.style.left = `${e.clientX}px`;
            }

            if (resizeDirRef.current.includes('bottom')) {
                newHeight += e.clientY - initialMousePos.current.y;
            } else if (resizeDirRef.current.includes('top')) {
                newHeight -= e.clientY - initialMousePos.current.y;
                modal.style.top = `${e.clientY}px`;
            }

            // Ensure minimum width and height
            if (newWidth < MIN_WIDTH) {
                newWidth = MIN_WIDTH;
            }
            if (newHeight < MIN_HEIGHT) {
                newHeight = MIN_HEIGHT;
            }

            modal.style.width = `${newWidth}px`;
            modal.style.height = `${newHeight}px`;
        } else {
            modal.style.left = `${e.clientX - offsetX.current}px`;
            modal.style.top = `${e.clientY - offsetY.current}px`;
            modal.style.transform = `translate(0, 0)`; // Reset transform when moving
        }
    };

    const handleMouseUp = () => {
        document.removeEventListener('mousemove', handleMouseMove);
        document.removeEventListener('mouseup', handleMouseUp);
        resizingRef.current = false;
        resizeDirRef.current = null;
    };

    const handleResizeMouseDown = (e, direction) => {
        
        e.stopPropagation(); // Prevent triggering drag logic
        resizingRef.current = true;
        resizeDirRef.current = direction;
        const modal = modalRef.current;
        if (!modal) return;
        initialSize.current = { width: modal.offsetWidth, height: modal.offsetHeight };
        initialMousePos.current = { x: e.clientX, y: e.clientY };

        document.addEventListener('mousemove', handleMouseMove);
        document.addEventListener('mouseup', handleMouseUp);
    };



    useEffect(() => {
        const checkMouseOverModal = (e) => {
            const modal = modalRef.current;
            if (!modal) return;
            const rect = modal.getBoundingClientRect();
            if (
                e.clientX >= rect.left &&
                e.clientX <= rect.right &&
                e.clientY >= rect.top &&
                e.clientY <= rect.bottom
            ) {
                setPointerEvents('auto');
            } else {
                setPointerEvents('none');
            }
        };

        document.addEventListener('mousemove', checkMouseOverModal);
        return () => {
            document.removeEventListener('mousemove', checkMouseOverModal);
        };
    }, []);



    // useEffect(() => {
    //     const updateNoSelectClass = () => {
    //         if (resizingRef.current) {
    //             document.body.classList.remove('no-select');

    //         } else {
    //             document.body.classList.add('no-select');

    //         }
    //     };
    
    //     updateNoSelectClass();
    
    //     return () => {
    //         document.body.classList.remove('no-select'); // Ensure cleanup
    //     };
    // }, [resizingRef.current]);

    return (
        <Modal
            open={bigIframeModalOpen}
            onClose={handleClose}
            aria-labelledby="pdf-modal-title"
            aria-describedby="pdf-modal-description"
            hideBackdrop={true}
            style={{ pointerEvents: 'none' }}
            disableEnforceFocus // Let the user focus on elements outside the dialog
            //style={{ position: 'initial' }} // This was the key point, reset the position of the dialog, so the user can interact with other elements
        >
            <div
                className="edit-modal-moveable"
                ref={modalRef}
                onMouseDown={handleMouseDown}
                style={{
                    pointerEvents: pointerEvents,
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)', // Center the modal
                }}
            >
                <div className="upper_nav_bar">
                    <IconButton
                        aria-label="close"
                        onClick={handleCloseIframeModal}
                        sx={{
                            height: '50px',
                            width: '50px',
                            marginRight: '10px',
                            top: 8,
                            color: 'black',
                            backgroundColor: 'transparent',
                            '&:hover': {
                                backgroundColor: 'gray',
                            },
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                </div>
                <Iframe
                    url={`${openedIframeUrl}#page=1&view=FitH,FitV`}
                    width="100%"
                    height="100%"
                    style={{ border: 'none' }}
                    title="PDF Viewer"
                />
                <div className="lower_nav_bar">


                </div>
                <div className="resize-handle top-left" onMouseDown={(e) => handleResizeMouseDown(e, 'top-left')}></div>
                <div className="resize-handle top-right" onMouseDown={(e) => handleResizeMouseDown(e, 'top-right')}></div>
                <div className="resize-handle bottom-left" onMouseDown={(e) => handleResizeMouseDown(e, 'bottom-left')}></div>
                <div className="resize-handle bottom-right" onMouseDown={(e) => handleResizeMouseDown(e, 'bottom-right')}></div>
                <div className="resize-handle left" onMouseDown={(e) => handleResizeMouseDown(e, 'left')}></div>
                <div className="resize-handle right" onMouseDown={(e) => handleResizeMouseDown(e, 'right')}></div>
                <div className="resize-handle top" onMouseDown={(e) => handleResizeMouseDown(e, 'top')}></div>
                <div className="resize-handle bottom" onMouseDown={(e) => handleResizeMouseDown(e, 'bottom')}></div>
            </div>
        </Modal>
    );
};

export default DraggableModal;
