import React, { useState, useRef, useEffect } from 'react';
import Checkbox from '@mui/material/Checkbox';
import './FilterOptionMenu.css';
import { useTranslation } from 'react-i18next';

const FilterOptionMenu = ({ optionGroups = [], selectedOptions, setSelectedOptions }) => {
  const [isMenuVisible, setMenuVisible] = useState(false);
  const [isClosing, setIsClosing] = useState(false);
  const menuRef = useRef(null);
  const [direction, setDirection] = useState('rtl'); // Default to 'rtl'
  const { i18n, t } = useTranslation();

  const [localSelectedOptions, setLocalSelectedOptions] = useState(selectedOptions);

  useEffect(() => {
    // Sync local selection with parent selection when `selectedOptions` changes in the parent
    setLocalSelectedOptions(selectedOptions);
  }, [selectedOptions]);

  useEffect(() => {
    if (i18n.language === 'he' || i18n.language === 'ar') {
      setDirection('rtl');
    } else {
      setDirection('ltr');
    }
  }, [i18n.language]);

  const toggleMenu = () => {
    if (isMenuVisible) {
      setIsClosing(true);
      setTimeout(() => {
        setMenuVisible(false);
        setIsClosing(false);
      }, 300); // match duration with CSS animation time
    } else {
      setMenuVisible(true);
    }
  };

  const closeToggleMenu = () => {
    setIsClosing(true);
    setTimeout(() => {
      setMenuVisible(false);
      setIsClosing(false);
    }, 300);
  };

  const handleOptionChange = (optionId, group) => {
    // Update local selected options based on the selection type of the group
    setLocalSelectedOptions((prevSelected) => {
      let updatedSelectedOptions;

      if (group.selectionType === 'single') {
        const otherOptionsInGroup = group.options.map(option => option.id);
        updatedSelectedOptions = prevSelected
          .filter(id => !otherOptionsInGroup.includes(id)) // Clear existing selections in this group
          .concat(optionId); // Add the new selection
      } else {
        // Toggle for multi-select groups
        updatedSelectedOptions = prevSelected.includes(optionId)
          ? prevSelected.filter(id => id !== optionId)
          : [...prevSelected, optionId];
      }

      // Update the parent’s selected options
      setSelectedOptions(updatedSelectedOptions);
      return updatedSelectedOptions;
    });
  };


  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        closeToggleMenu();
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isMenuVisible]);

  return (
    <div className="filterMenuWrapper" ref={menuRef}>
      <div className="filterOptionContainer" onClick={toggleMenu}>
        <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
          <path d="M5.67325 7.48001C8.36658 10.9333 13.3399 17.3333 13.3399 17.3333V25.3333C13.3399 26.0667 13.9399 26.6667 14.6733 26.6667H17.3399C18.0733 26.6667 18.6733 26.0667 18.6733 25.3333V17.3333C18.6733 17.3333 23.6333 10.9333 26.3266 7.48001C27.0066 6.60001 26.3799 5.33334 25.2733 5.33334H6.72659C5.61992 5.33334 4.99325 6.60001 5.67325 7.48001Z" fill="black" fillOpacity="0.8" />
        </svg>
      </div>

      {isMenuVisible && (
        <div className={`menu ${isClosing ? 'menu--close' : 'menu--open'} `}
          style={{
            [direction === 'rtl' ? 'right' : 'left']: 0,
          }}
        >
          {optionGroups.map((group, index) => (
            <div key={index} className="option-group">
              <div className="group-title">{group.title}</div>
              {group.options.map((option) => (
                <div
                  key={option.id}
                  className={`menu-item ${selectedOptions.includes(option.id) ? 'selected' : 'unselected'}`}
                  onClick={() => handleOptionChange(option.id, group)}
                >
                  <Checkbox
                    checked={selectedOptions.includes(option.id)}
                    onClick={(e) => e.stopPropagation()}
                    onChange={() => handleOptionChange(option.id, group)}
                    inputProps={{ 'aria-label': option.label }}
                  />
                  {option.label}
                </div>
              ))}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default FilterOptionMenu;
