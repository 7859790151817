import config from '../config';
import selectedEnvironment from '../env';
const API_URL = `${config[selectedEnvironment].API_URL}`;

const CreateMatch = async (matchDTO, auth) => {
    const response = await fetch(`${API_URL}/CardMatches/Create`, {
        method: 'POST',
        headers: {
            "Authorization": 'Bearer ' + auth,
            "Content-Type": "application/json"
        },
        body: JSON.stringify(matchDTO)
    });
    return response;
};

const CreateMultipleMatches = async (matchDTOs, auth) => {
    const response = await fetch(`${API_URL}/CardMatches/CreateMultiple`, {
        method: 'POST',
        headers: {
            "Authorization": 'Bearer ' + auth,
            "Content-Type": "application/json"
        },
        body: JSON.stringify(matchDTOs)
    });
    if (!response.ok) {
        const errorText = await response.text();
        throw new Error(errorText || 'Failed to create multiple matches');
    }
    return await response.json();
};

const UpdateMatch = async (editDTO, auth) => {
    const response = await fetch(`${API_URL}/CardMatches/Update`, {
        method: 'PUT',
        headers: {
            "Authorization": 'Bearer ' + auth,
            "Content-Type": "application/json"
        },
        body: JSON.stringify(editDTO)
    });
    return response;
};

const DeleteMatch = async (deleteDTO, auth) => {
    const response = await fetch(`${API_URL}/CardMatches/Delete`, {
        method: 'DELETE',
        headers: {
            "Authorization": 'Bearer ' + auth,
            "Content-Type": "application/json"
        },
        body: JSON.stringify(deleteDTO)
    });
    if (!response.ok) {
        const errorText = await response.text();
        throw new Error(errorText || 'Failed to delete the match');
    }
    return response;
};

const GetById = async (id, auth) => {
    const response = await fetch(`${API_URL}/CardMatches/GetById/${id}`, {
        method: 'GET',
        headers: {
            "Authorization": 'Bearer ' + auth,
            "Content-Type": "application/json"
        }
    });
    return response;
};

const GetByCaseAndAccount = async (caseId, accountCodeId, auth) => {
    const response = await fetch(`${API_URL}/CardMatches/GetByCaseAndAccount?CaseId=${caseId}&AccountCodeId=${accountCodeId}`, {
        method: 'GET',
        headers: {
            "Authorization": 'Bearer ' + auth,
            "Content-Type": "application/json"
        }
    });
    return response;
};

const GetByCaseId = async (caseId, auth) => {
    const response = await fetch(`${API_URL}/CardMatches/GetByCaseId/${caseId}`, {
        method: 'GET',
        headers: {
            "Authorization": 'Bearer ' + auth,
            "Content-Type": "application/json"
        }
    });
    return response;
};

const GetByTransactionDateRange = async (dto, auth) => {
    const response = await fetch(`${API_URL}/CardMatches/GetByTransactionDateRange`, {
        method: 'POST',
        headers: {
            "Authorization": 'Bearer ' + auth,
            "Content-Type": "application/json"
        },
        body: JSON.stringify(dto)
    });
    return response;
};

const GetByMatchDateRange = async (dto, auth) => {
    const response = await fetch(`${API_URL}/CardMatches/GetByMatchDateRange`, {
        method: 'POST',
        headers: {
            "Authorization": 'Bearer ' + auth,
            "Content-Type": "application/json"
        },
        body: JSON.stringify(dto)
    });
    return response;
};

const GetMatchedByDateRange = async (CaseId, StartDate, EndDate, accountCodeId, IsMatchDate, auth) => {
    const response = await fetch(`${API_URL}/CardMatches/GetByMatchDateRange`, {
        method: 'POST',
        headers: {
            "Authorization": 'Bearer ' + auth,
            "Content-Type": "application/json"
        },
        body: JSON.stringify({ CaseId, StartDate, EndDate, accountCodeId, IsMatchDate })
    });
    return response;
};

const CardMatchService = {
    CreateMatch,
    CreateMultipleMatches,
    UpdateMatch,
    DeleteMatch,
    GetById,
    GetByCaseAndAccount,
    GetByCaseId,
    GetByTransactionDateRange,
    GetByMatchDateRange,
    GetMatchedByDateRange
};

export default CardMatchService;
