import React, { forwardRef } from 'react';
import ReactDatePicker from 'react-datepicker';
import { TextField } from '@mui/material';
import 'react-datepicker/dist/react-datepicker.css';
import { useTranslation } from 'react-i18next';

const DateInput = forwardRef(({ value, onClick, label }, ref) => {
    const { t } = useTranslation();

    return (
        <TextField
            fullWidth
            value={value}
            onClick={onClick}
            inputRef={ref}
            label={t(label)}
            InputProps={{
                className: 'custom-input-box-sizing',
            }}
            style={{
                display: 'flex',
                justifyContent: 'center',
                alignSelf: 'center',
            }}
        />
    );
});

const CustomMonthDatePickerMui = ({ Date, setDate, label }) => {
    
    
    return (
        <ReactDatePicker
            selected={Date}
            onChange={(date) => setDate(date)} // Call the passed setDate function
            dateFormat="MM-yyyy"
            showMonthYearPicker
            customInput={<DateInput label={label} />}
        />
    );
};

export default CustomMonthDatePickerMui;
