import React, { useState, useRef } from 'react';
import Lottie from 'lottie-react';
import lockAnimation from '../../../assests/images/Animations/Lock.json'; // Path to your Lottie animation
import TextField from '@mui/material/TextField'; // MUI TextField for the OTP input
import '../Login.css';

const VerifyOtpComponent = ({ t, isRTL, handleVerifyOtp, handleSendOtp,setOtp }) => {
  const [otpDigit, setOtpDigit] = useState(Array(6).fill(''));
  const [hasError, setHasError] = useState(false);
  const inputs = useRef([]);

  const handleChangeText = (text, index) => {
    if (text.length > 1) {
      return; // Prevent more than one character in the input
    }

    const newOtpDigit = [...otpDigit];
    newOtpDigit[index] = text;
    setOtpDigit(newOtpDigit);
    setOtp(newOtpDigit.join(''));
    setHasError(false); // Reset error state when the user changes the text

    // Automatically focus on the next input if a digit is entered
    if (text && index < 5) {
      inputs.current[index + 1].focus();
    }
  };

  const handleKeyPress = (event, index) => {
    if (event.key === 'Backspace') {
      if (otpDigit[index]) {
        // If the current input has a value, clear it
        const newOtpDigit = [...otpDigit];
        newOtpDigit[index] = '';
        setOtpDigit(newOtpDigit);
        setOtp(newOtpDigit.join(''));
      } else if (index > 0) {
        // If the current input is empty, move to the previous input
        inputs.current[index - 1].focus();
      }
    }
  };



  return (
    <div className="verify-otp-container">
      <div className="animations-container">
        <Lottie
          animationData={lockAnimation}
          loop
          style={{ height: 240, width: 240 }}
        />
      </div>

      <div className="otp-details-container">
        <p className="receive-6-digit-code">

          {isRTL ? (
            <>
              <span className="code-text"> {t('ReceivedA')}{' '} {t('Code')}</span>{' '}
              <span className="highlight-text">{t('SixDigit')}</span>
            </>
          ) : (
            <>
              <span className="highlight-text">{t('SixDigit')}</span>{' '}
              <span className="code-text">{t('Code')}</span>
            </>
          )}
        </p>
        <p className="password-details">{t('EnterCodeToResetPassword')}</p>
      </div>

      {/* Updated Container for OTP Inputs */}
      <div className="otp-inputs-container" style={{ display: 'flex', justifyContent: 'center', direction: 'ltr', gap: '20px' }}>
        {otpDigit.map((digit, index) => (
          <TextField
            key={index}
            inputRef={(el) => (inputs.current[index] = el)}
            variant="standard"
            value={digit}
            onChange={(e) => handleChangeText(e.target.value, index)}
            InputProps={{
              disableUnderline: true,
              inputProps: {
                maxLength: 1, // Restrict to 1 character only
                style: {
                  textAlign: 'center', // Horizontally center the text
                  fontSize: '20px',
                  height: '56px',
                  padding: 0, // Remove extra padding for vertical centering
                },
              },
            }}
            onKeyDown={(e) => handleKeyPress(e, index)}
            sx={{
              width: '56px',
              height: '56px',
              borderRadius: '8px',
              backgroundColor: digit ? 'rgba(249, 170, 42, 1)' : 'rgba(254, 247, 234, 1)',
              '& .MuiInputBase-input': {
                textAlign: 'center', // Ensure text is centered
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              },
            }}
            error={hasError}
          />
        ))}
      </div>
      <button className='login-button' onClick={handleVerifyOtp}>{t('verify code')}</button>
      <div className="resend-otp-container">
        {isRTL ? (
          <>
            <p>{t('DidNotReceiveCode')}</p>
            <button className='DidNotReceiveCode-button' onClick={handleSendOtp}>{t('SendAgainIn5Seconds')}</button>
          </>
        ) : (
          <>
            <p>{t('DidNotReceiveCode')}</p>
            <button className='DidNotReceiveCode-button' onClick={handleSendOtp}>{t('SendAgainIn5Seconds')}</button>
          </>
        )}
      </div>


    </div>
  );
};

export default VerifyOtpComponent;
