import React from 'react';
import { Autocomplete, Checkbox, TextField, Chip, Tooltip } from '@mui/material';
import { maxHeight, styled } from '@mui/system';
import Popper from '@mui/material/Popper';
import { useTranslation } from 'react-i18next';

const StyledAutocomplete = styled(Autocomplete)(({ theme }) => ({
  '& .MuiOutlinedInput-root': {
    borderRadius: 'var(--Spacing-md, 8px)',
    background: 'var(--bg-input, #F5F5F6)',
    '& .MuiAutocomplete-tag': {
      maxHeight: '32px',
    },
  },
  '& .MuiOutlinedInput-notchedOutline': {
    borderColor: 'transparent',
  },
  '&.MuiAutocomplete-hasPopupIcon .MuiOutlinedInput-root, &.MuiAutocomplete-hasClearIcon .MuiOutlinedInput-root': {
    background: 'var(--bg-input, #F5F5F6)',
    borderRadius: 'var(--Spacing-md, 8px)',

  },
  '& .MuiAutocomplete-inputRoot[class*="MuiInput-root"]': {
    flexWrap: 'nowrap',
  },
  '& .MuiInputBase-root': {
    boxSizing: 'border-box !important',
    
  },
}));

const StyledPopper = styled(Popper)(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: 'var(--Spacing-md, 8px)',
    background: 'var(--white, #FFF)',
    boxShadow: '0px 4px 8px 0px rgba(0, 0, 0, 0.32)',
  },
  '& .MuiAutocomplete-listbox': {
    padding: theme.spacing(1),
  },
  '& .MuiAutocomplete-option': {
    borderRadius: 'var(--Spacing-sm, 4px)',
    '&[aria-selected="true"], &.Mui-focused, &:hover': {
      background: 'var(--orange, #F9AA2A) !important',
    },
  },
}));

const AutocompleteSelect = ({
  options,
  selectedValues,
  onChange,
  label = '',
  placeholder = 'Type to search',
  isMultiple = true,
  width = '165px', // Default width
  isDisabled = false
}) => {
  const { i18n, t } = useTranslation();
  const isRtl = ['he', 'ar'].includes(i18n.language);

  
  const isAllSelected = isMultiple
    ? options.length > 0 && selectedValues.length === options.length
    : false;

  const handleOnChange = (event, newValue) => {
    if (isMultiple) {
      if (newValue.some((option) => option.value === 'select_all')) {
        if (isAllSelected) {
          onChange(event, []);
        } else {
          onChange(event, options);
        }
      } else {
        const filteredValues = newValue.filter(
          (option) => option.value !== 'select_all'
        );
        onChange(event, filteredValues);
      }
    } else {
      onChange(event, newValue);
    }
  };

  const renderTags = (value, getTagProps) => {
    if (value.length === 0) {
      return null;
    }

    const displayedValues = value.slice(0, 1);
    const remainingCount = value.length - displayedValues.length;

    return (
      <>
        {displayedValues.map((option, index) => (
          <Chip
            key={option.value}
            label={option.label}
            {...getTagProps({ index })}
          />
        ))}
        {remainingCount > 0 && (
          <Chip label={`+${remainingCount} ${t('More')}`} />
        )}
      </>
    );
  };

  const allOptions = React.useMemo(() => {
    if (isMultiple) {
      return [{ label: t('Select all'), value: 'select_all' }, ...options];
    } else {
      return options;
    }
  }, [options, t, isMultiple]);

  const hasValue = isMultiple
    ? selectedValues.length > 0
    : selectedValues !== null && selectedValues !== undefined;

  const inputPlaceholder = hasValue ? '' : placeholder;

  const renderOption = (props, option, state) => {
    const { selected } = state;
    let isChecked = selected;
    if (isMultiple && option.value === 'select_all') {
      isChecked = isAllSelected;
    }

    return (
      <li
        {...props}
        style={{
          display: 'flex',
          flexDirection: isRtl ? 'row-reverse' : 'row',
          alignItems: 'center',
          justifyContent: 'flex-start',
        }}
      >
        {isMultiple && (
          <Checkbox
            checked={isChecked}
            style={{
              marginLeft: isRtl ? 8 : 0,
              marginRight: !isRtl ? 8 : 0,
            }}
          />
        )}
        {option.label}
      </li>
    );
  };

  const renderInput = (params) => {
    const MAX_PLACEHOLDER_LENGTH = 10;
    const isPlaceholderLong = placeholder.length > MAX_PLACEHOLDER_LENGTH;

    const hasValue = isMultiple
      ? selectedValues.length > 0
      : selectedValues !== null && selectedValues !== undefined;

    const inputPlaceholder = hasValue ? '' : placeholder;

    const textField = (
      <TextField
        {...params}
        variant="outlined"
        label={label}
        placeholder={inputPlaceholder}
      />
    );

    return isPlaceholderLong ? (
      <Tooltip title={placeholder}>{textField}</Tooltip>
    ) : (
      textField
    );
  };

  return (
    <StyledAutocomplete
      multiple={isMultiple}
      options={allOptions}
      value={selectedValues}
      onChange={handleOnChange}
      getOptionLabel={(option) => option.label}
      isOptionEqualToValue={(option, value) => option.value === value.value}
      // disableCloseOnSelect
      PopperComponent={StyledPopper}
      PopperProps={{
        sx: {
          '& .MuiPaper-root': {
            width: width,
          },
        },
      }}
      limitTags={1}
      renderTags={renderTags}
      renderOption={renderOption}
      renderInput={renderInput}
      sx={{
        width: width,
        minWidth: width, // Ensure minimum width
      }}
      disabled ={isDisabled}
    />
  );
};

export default AutocompleteSelect;
