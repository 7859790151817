import React, { useState, useRef } from 'react';
import Lottie from "lottie-react";
import passwordLockAnimation from '../../../assests/images/Animations/passwordLock.json'; // Path to your Lottie animation
import { TextField, IconButton, InputAdornment } from '@mui/material';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import '../Login.css';

const ResetPassword = ({ t, isRTL, password, setPassword, reEnterPassword,setReEnterPassword,handleResetPassword,handleInputChange }) => {
  const [hasError, setHasError] = useState(false);
  const inputs = useRef([]);
  const [showPassword, setShowPassword] = useState(false);
  const [showRePassword, setShowRePassword] = useState(false);



  return (
    <div className="verify-otp-container">
      <div className="animations-container">
        <Lottie
          animationData={passwordLockAnimation}
          loop
          style={{ height: 300, width: 300 }}
        />
      </div>
      <div className="login-input-container" style={{ width:'100%' }}>
        <div className="login-input-label" style={{ textAlign: !isRTL ? 'left' : 'right' }}>
          {t('enterNewPassword')}
        </div>
        <TextField
          fullWidth
          placeholder={t('enterNewPassword')}
          type={showPassword ? 'text' : 'password'}
          value={password}
          onChange={handleInputChange(setPassword)}
          className="style-input password-input"
          variant="standard"
          InputProps={{
            disableUnderline: true,
            className: `custom-input-box-sizing ${!isRTL ? 'ltr-input' : 'rtl-input'}`,

            style: {
              height: '64px',
              padding: '0 10px 0 10px'
            },
            endAdornment: (
              <InputAdornment style={{ boxSizing: 'border-box !important' }} position={!isRTL ? 'end' : 'start'}>
                <IconButton
                  onClick={() => setShowPassword((prev) => !prev)}
                  edge={!isRTL ? 'end' : 'start'}
                  style={{
                    ...(!isRTL
                      ? { marginRight: '10px' }
                      : { marginLeft: '10px' }),
                  }}
                >
                  {showPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </div>
      <div className="login-input-container" style={{ width:'100%' }}>
        <div className="login-input-label" style={{ textAlign: !isRTL ? 'left' : 'right' }}>
          {t('reEnterPassword')}
        </div>
        <TextField
          fullWidth
          placeholder={t('reEnterPassword')}
          type={showPassword ? 'text' : 'password'}
          value={reEnterPassword}
          onChange={handleInputChange(setReEnterPassword)}
          className="style-input password-input"
          variant="standard"
          InputProps={{
            disableUnderline: true,
            className: `custom-input-box-sizing ${!isRTL ? 'ltr-input' : 'rtl-input'}`,

            style: {
              height: '64px',
              padding: '0 10px 0 10px'
            },
            endAdornment: (
              <InputAdornment style={{ boxSizing: 'border-box !important' }} position={!isRTL ? 'end' : 'start'}>
                <IconButton
                  onClick={() => setShowRePassword((prev) => !prev)}
                  edge={!isRTL ? 'end' : 'start'}
                  style={{
                    ...(!isRTL
                      ? { marginRight: '10px' }
                      : { marginLeft: '10px' }),
                  }}
                >
                  {showRePassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </div>


      <button className='login-button' onClick={handleResetPassword}>{t('verify code')}</button>



    </div>
  );
};

export default ResetPassword;
