// App.js
import React, { useEffect, useState, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

// Context Providers
import { SignalRProvider } from './Contexts/signalR.js';
import { FileProvider } from './Contexts/FileContext.js';
import { PermissionsProvider } from './Contexts/Permissions.js';

// Components
import Login from './Components/Login/Login.jsx';
import Main from './Components/Main/Main.jsx';
import ClientDetails from './Components/ClientDetails/ClientDetails.jsx';
import AccountantDetails from './Components/AccountantDetails/AccountantDetails.jsx';
import Document from './Components/Document/Document.jsx';
import TransactionDocuments from './Components/TransactionDocuments/TransactionDocuments.jsx';
import OtherDocument from './Components/OtherDocuments/OtherDocument.jsx';
import Checks from './Components/Checks/Check.js';
import PaymentAcceptance from './Components/PaymentAcceptance/PaymentAcceptance.jsx';
import SubAccountant from './Components/SubAccountants/SubAccountants.jsx';
import ErrorPage from './Components/ErrorPage/ErrorPage.js';
import ProfitAndLossReport from './Components/ProfitAndLossReport/ProfitAndLossReport.jsx';
import SortCodeReport from './Components/SortCodeReport/SortCodeReport.jsx';
import CollectionPage from './Components/CollectionPage/CollectionPage.jsx';
import ReportTracking from './Components/ReportTracking/ReportTracking.jsx';
import LedgerReport from './Components/LedgerReport/LedgerReport.jsx';
import AccountCardDashboard from './Components/AccountCardDashboard/AccountCardDashboard.jsx';
import AccountCardsTable from './Components/AccountCardsTable/AccountCardsTable.jsx';
import JournalEntry from './Components/JournalEntryies/JournalEntry.jsx';
import AddClient from './Components/Widgets/AddClient/AddClient.jsx';
import CreateInvoice from './Components/Widgets/CreateInvoice/CreateInvoice.jsx';
import CreateReceipt from './Components/Widgets/CreateInvoice/CreateReceipt.jsx';
import BankMatching from './Components/BankMatching/BankMatching.jsx';
import CardMatching from './Components/CardMatching/CardMatching.jsx';
import ProtectedRoute from './Utils/ProtectedRoute.jsx';
import Unauthorized from './Components/Unauthorized/Unauthorized.jsx';
import BankPages from './Components/BankPages/BankPages.jsx';
// Styles and Themes
import './Services/translation.service';
import './App.css';
import 'react-toastify/dist/ReactToastify.css';
import 'primeicons/primeicons.css';
import 'primereact/resources/primereact.css';
import 'primereact/resources/themes/lara-light-indigo/theme.css';
import 'primeflex/primeflex.css';

import { StyledEngineProvider } from '@mui/material/styles';
import { PrimeReactProvider } from 'primereact/api';
import { createTheme, ThemeProvider, useTheme } from '@mui/material/styles';
import { CacheProvider } from '@emotion/react';
import { arSD, heIL, enUS } from '@mui/x-data-grid/locales';
import createCache from '@emotion/cache';
import { prefixer } from 'stylis';
import rtlPlugin from 'stylis-plugin-rtl';
function App() {
  const { i18n } = useTranslation();
  const [selectedLanguage, setSelectedLanguage] = useState(localStorage.getItem('language') || 'he');

  // const initChatConf = async () => {
  //   await ChatService.InitChat();

  // }
  useEffect(() => {
    // Check for saved language in localStorage
    const currentLanguage = localStorage.getItem('language') || 'he'; // Default to 'en' if no language is stored

    i18n.changeLanguage(currentLanguage);
    // initChatConf();
  }, []);

  useEffect(() => {
    const currentLanguage = localStorage.getItem('language') || 'he';
    setSelectedLanguage(currentLanguage);
  }, [i18n.language]);

  const existingTheme = useTheme();

  const theme = useMemo(() => {
    let locale;
    let direction = 'ltr'; // Default direction

    switch (selectedLanguage) {
      case 'ar':
        locale = arSD;
        direction = 'rtl';
        break;
      case 'he':
        locale = heIL;
        direction = 'rtl';
        break;
      case 'en':
      default:
        locale = enUS;
        direction = 'ltr';
        break;
    }

    return createTheme({}, locale, existingTheme, { direction });
  }, [selectedLanguage, existingTheme]);

  const cacheRtl = createCache({
    key: 'muirtl',
    stylisPlugins: [prefixer, rtlPlugin],
  });

  const cacheLtr = createCache({
    key: 'muiltr',
    stylisPlugins: [prefixer] // Removed rtlPlugin for strict LTR styling
  });
  const cache = useMemo(
    () =>
      createCache({
        key: selectedLanguage === 'en' ? 'muiltr' : 'muirtl',
        stylisPlugins:
          selectedLanguage === 'en' ? [prefixer] : [prefixer, rtlPlugin],
      }),
    [selectedLanguage]
  );




  return (
    <SignalRProvider>
      <FileProvider>
        <PrimeReactProvider>
          <StyledEngineProvider injectFirst>
            <CacheProvider value={cache}>
              <ThemeProvider theme={theme}>
                <Router>
                  <PermissionsProvider>

                    <div className="App">
                      <Routes>
                        <Route path="/login" element={<Login />} />
                        <Route path="/Document" element={<Document />} />
                        <Route path="/Checks" element={<Checks />} />
                        <Route path="/OtherDocument" element={<OtherDocument />} />
                        <Route path="/client-details" element={<ClientDetails />} />
                        {/* <Route path="/Accountant-details" element={<AccountantDetails />} /> */}
                        <Route
                          path="/Accountant-details"
                          element={
                            <ProtectedRoute unauthorizedPermission="ViewSubAccountantClients">
                              <AccountantDetails />
                            </ProtectedRoute>
                          }
                        />
                        <Route path="/Main" element={<Main />} />
                        <Route
                          path="/SubAccountants"
                          element={
                            <ProtectedRoute requireAccountant>
                              <SubAccountant />
                            </ProtectedRoute>
                          }
                        />
                        <Route path="/ProfitAndLossReport" element={<ProfitAndLossReport />} />
                        <Route path="/SortCodeReport" element={<SortCodeReport />} />
                        <Route path="/TransactionDocuments" element={<TransactionDocuments />} />
                        <Route path="/PaymentAcceptance" element={<PaymentAcceptance />} />
                        {/* <Route path="/ReportTracking" element={<ReportTracking />} /> */}
                        <Route
                          path="/ReportTracking"
                          element={
                            <ProtectedRoute unauthorizedPermission="ViewSubAccountantClients">
                              <ReportTracking />
                            </ProtectedRoute>
                          }
                        />
                        <Route path="/LedgerReport" element={<LedgerReport />} />
                        <Route path="/AccountCardDashboard" element={<AccountCardDashboard />} />
                        <Route path="/AccountCardsTable" element={<AccountCardsTable />} />
                        <Route path="/JournalEntry" element={<JournalEntry />} />
                        <Route path="/BankTransactions" element={<BankMatching />} />
                        <Route path="/BankPages" element={<BankPages />} />
                        <Route
                          path="/AddClient"
                          element={
                            <ProtectedRoute unauthorizedPermission="CannotAddClient">
                              <AddClient />
                            </ProtectedRoute>
                          }
                        />

                        <Route path="/CreateInvoice" element={<CreateInvoice />} />
                        <Route path="/ReceiptTax" element={<CreateReceipt />} />
                        <Route
                          path="/CollectionPage"
                          element={
                            <ProtectedRoute unauthorizedPermission="ViewSubAccountantClients">
                              <CollectionPage />
                            </ProtectedRoute>
                          }
                        />
                        <Route path="/CardMatching" element={<CardMatching />} />

                        {/* <Route path="/Chat" element={<Chat />} /> */}
                        <Route path="/" element={<Login />} />
                        <Route path="*" element={<ErrorPage />} />
                        <Route path="/unauthorized" element={<Unauthorized />} />

                      </Routes>
                    </div>
                  </PermissionsProvider>
                </Router>
              </ThemeProvider>
            </CacheProvider>
          </StyledEngineProvider>
        </PrimeReactProvider>
      </FileProvider>
    </SignalRProvider>
  );
}

export default App;
