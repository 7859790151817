import React, { useRef, useMemo, useEffect, useState } from 'react';
import { Dropdown } from 'primereact/dropdown';
import { Button } from 'primereact/button';
import { FloatLabel } from 'primereact/floatlabel';
import { Paper } from '@mui/material';
import { dir, t } from 'i18next'; // Assuming you're using i18next for translations
import './CustomDropdown.css'
import { Directions, Height } from '@mui/icons-material';
const OptionTemplate = React.memo(({ option, index, handleEdit, handleDelete}) => {

    return (
        <div key={option?.cardId} className="option-container">
            {index !== 0 && (
                <div className="table-header-dropdown">
                    <div className="table-cell-dropdown header-cell-dropdown">{t('Card ID')}</div>
                    <div className="table-cell-dropdown header-cell-dropdown">{t('Card Name')}</div>
                    <div className="table-cell-dropdown header-cell-dropdown">{t('CompanyId')}</div>
                    <div className="table-cell-dropdown header-cell-dropdown">{t('PalCode')}</div>
                    {
                        option.isEditable &&
                        <div className="table-cell-dropdown header-cell-dropdown">{t('Actions')}</div>
                    }
                </div>
            )}
            <div className="table-row-dropdown">
                {index === 0 ? (
                    <div className="table-cell-dropdown" style={{ textAlign: 'center', width: '100%' }}>
                        {option.accountCaseId ? `${option.name} - ${option.accountCaseId}` : `${option.name}`}
                    </div>
                ) : (
                    <>
                        <div className="table-cell-dropdown">{option.cardId}</div>
                        <div className="table-cell-dropdown item-text-dropdown">{option.name || ''}</div>
                        <div className="table-cell-dropdown">{option.accountCaseId || ''}</div>
                        <div className="table-cell-dropdown">{option.palCode || ''}</div>
                        {
                            option.isEditable &&
                            <div className="table-cell-dropdown action-buttons-dropdown">
                                <Button icon="pi pi-pencil" className="p-button-text" style={{ color: '#E57C22' }} onClick={() => handleEdit(option)} />
                                <Button icon="pi pi-trash" className="p-button-text" style={{ color: '#E57C22' }} onClick={() => handleDelete(option)} />
                            </div>
                        }

                    </>
                )}
            </div>
        </div>
    );
});

const CustomDropdown = ({ accountCardOptions, selectedAccount, handleMuiChange, handleEdit, handleDelete, isRange, isClosedReportingMonth, editedItem, type, clientDetailsFlag = false, withLabel = true  }) => {
    const dropdownRef = useRef(null);
    const [panelVisible, setPanelVisible] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');

    const handleInputChange = (event) => {
        setSearchTerm(event.filter);
    };
    const filteredOptionsWithSearch = useMemo(() => {
        return accountCardOptions.map((option, index) => ({
            ...option,
            value: option.cardId,
            label: option.accountCaseId
                ? `${option.accountCaseId} -${option.name} - ${option.cardId}`
                : option.cardId ? `${option.name} - ${option.cardId}` : `${option.name}`,
            index,
        }));
    }, [accountCardOptions, selectedAccount]);

    const selectedValue = useMemo(() => {
        return selectedAccount ? filteredOptionsWithSearch.find(option => option.cardId === selectedAccount.cardId) : null;
    }, [selectedAccount, filteredOptionsWithSearch]);
    // const onDropdownShow = () => {
    //     document.body.style.overflow = 'hidden';
    // };

    // const onDropdownHide = () => {
    //     document.body.style.overflow = '';
    // };

    // useEffect(() => {
    //     return () => {
    //         document.body.style.overflow = ''; // Ensure overflow is reset on component unmount
    //     };
    // }, []);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (dropdownRef.current && dropdownRef.current.container && !dropdownRef.current.container.contains(event.target)) {
                setPanelVisible(false);
            }
        };

        if (panelVisible) {
            document.addEventListener('click', handleClickOutside, true);
        } else {
            document.removeEventListener('click', handleClickOutside, true);
        }

        return () => {
            document.removeEventListener('click', handleClickOutside, true);
        };
    }, [panelVisible]);

    const onDropdownShow = () => {
        setPanelVisible(true);
    };

    const onDropdownHide = () => {
        setPanelVisible(false);
    };
    const renderSelectedItem = (selectedValue) => {
        if (!selectedValue) return '';
        return (
            <span dir="auto">
                {selectedValue.label}
            </span>
        );
    };

    const emptyFilterMessage = () => {
        if (accountCardOptions.length > 0) {
            let option = accountCardOptions[0];
            option.searchTerm = searchTerm
            return (
                <div onClick={() => handleMuiChange({ value: option })} style={{ cursor: 'pointer', margin: '0 auto', display: 'flex' }}>
                    {` ${option.name} ( ${searchTerm} )`}
                </div>
            );
        }
        return t('No available options');
    };

    return (

        <FloatLabel style={{ width: '100%', Height: '100%', }}>
            <Dropdown
                ref={dropdownRef}
                value={selectedValue}
                options={filteredOptionsWithSearch}
                onChange={handleMuiChange}
                filter
                filterBy="name,cardId,accountCaseId,palCode"
                placeholder={type === "Incomes" ? t('SelectClientName') : type === "Expenses" ? t('SelectProviderName') : t('Select AccountCard')}
                disabled={clientDetailsFlag ? false : (!isRange ? isClosedReportingMonth : editedItem.isClosedReportingMonth)}
                style={{ width: '100%', minHeight: '45px' }}
                itemTemplate={(option) => <OptionTemplate option={option} index={option.index} handleEdit={handleEdit} handleDelete={handleDelete} />}
                virtualScrollerOptions={{ itemSize: 100, scrollHeight: '500px', dir: 'rtl' }}
                optionLabel="label"
                onShow={onDropdownShow}
                onHide={onDropdownHide}
                valueTemplate={(option) => renderSelectedItem(option)}
                panelClassName="custom-dropdown-panel"
                hideOverlaysOnDocumentScrolling={false}
                emptyFilterMessage={emptyFilterMessage}
                onFilter={handleInputChange}
                className="centered-dropdown" // Custom class to center the text
            />
            {withLabel && (
                <label>{type === "Incomes" ? t('SelectClientName') : type === "Expenses" ? t('SelectProviderName') : t('Select AccountCard')}</label>

            )}
        </FloatLabel>
    );
};

export default CustomDropdown;
