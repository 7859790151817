import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './LanguageSelector.module.css';
import classNames from 'classnames';
export function LanguageSelector() {
    const { i18n, t } = useTranslation();
    const [selectedLanguage, setSelectedLanguage] = useState(localStorage.getItem('language') || 'he');
    const [menuOpen, setMenuOpen] = useState(false);

    useEffect(() => {
        const currentLanguage = localStorage.getItem('language') || 'he';
        i18n.changeLanguage(currentLanguage);
    }, [i18n]);

    const changeLanguage = (language) => {
        i18n.changeLanguage(language);
        setSelectedLanguage(language);
        localStorage.setItem('language', language);
        setMenuOpen(false); // Close the menu after selecting a language
    };

    const toggleMenu = () => {
        setMenuOpen((prev) => !prev);
    };

    return (
        <div className={styles.languageSelector}>
            <div className={styles.frame33952} onClick={toggleMenu}>
                <div className={styles.english}> {selectedLanguage === 'en' ? 'English' : selectedLanguage === 'he' ? 'עברית' : 'العربية'}</div>
                <svg
                    className={styles.keyboardArrowDown}
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        d="M8.12498 9.00002L12.005 12.88L15.885 9.00002C16.275 8.61002 16.905 8.61002 17.295 9.00002C17.685 9.39002 17.685 10.02 17.295 10.41L12.705 15C12.315 15.39 11.685 15.39 11.295 15L6.70498 10.41C6.31498 10.02 6.31498 9.39002 6.70498 9.00002C7.09498 8.62002 7.73498 8.61002 8.12498 9.00002Z"
                        fill="black"
                        fillOpacity="0.8"
                    />
                </svg>
            </div>
            {menuOpen && (
                <div className={styles.menu}>
                    {['en', 'ar', 'he'].map((language) => (
                        <div
                            key={language}
                            onClick={() => changeLanguage(language)}
                            className={classNames(styles.menuItem, {
                                [styles.selectedItem]: selectedLanguage === language,
                            })}
                        >
                            {language === 'en' ? 'English' : language === 'he' ? 'עברית' : 'العربية'}
                        </div>
                    ))}
                </div>
            )}
        </div>
    );
}