import React from 'react';
import { Navigate } from 'react-router-dom';
import { useContext } from 'react';
import { PermissionsContext } from '../Contexts/Permissions';
import PuffLoader from "react-spinners/PuffLoader";

function ProtectedRoute({ children, requiredPermission, unauthorizedPermission, requireAccountant }) {
    const { permissionsState, loading, isAccountant } = useContext(PermissionsContext);

    // Handle the case where permissions are not yet loaded
    if (loading) {
        return (
            <div className="loader-container">
                <PuffLoader
                    size={100}        // Larger size for better visibility
                    loading={true}
                />
            </div>
        );
    }

    let isAllowed = true;

    // Check if the route requires the user to be an accountant
    if (requireAccountant && !isAccountant) {
        isAllowed = false;
    }

    // Check permissions if requiredPermission is provided
    if (requiredPermission) {

        
        isAllowed = isAllowed && permissionsState[requiredPermission];
    }

    // Check if the user has an unauthorized permission
    if (unauthorizedPermission) {

        
        isAllowed = isAllowed && !permissionsState[unauthorizedPermission];
    }

    // Render children if the user is allowed, otherwise navigate to unauthorized page
    if (isAllowed) {
        return children;
    } else {
        return <Navigate to="/unauthorized" />;
    }
}

export default ProtectedRoute;
