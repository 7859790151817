import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import translationEN from '../assests/translation/translationEN.json';
import translationHE from '../assests/translation/translationHE.json';
import translationAR from '../assests/translation/translationAR.json';

const resources = {
  en: { translation: translationEN },
  he: { translation: translationHE },
  ar: { translation: translationAR },
};

i18n
  .use(initReactI18next)
  .init({
    resources,
    lng: 'he',
    keySeparator: false,
    interpolation: { escapeValue: false },
  });

export default i18n;
