import React, { useState, useEffect, useRef } from 'react';
import { Modal, Box, Button } from '@mui/material';
import { t } from 'i18next';
import DownloadIcon from '@mui/icons-material/Download';
import PrintIcon from '@mui/icons-material/Print';
import PythonService from '../../../Services/pythonScripts.service';
import { beautifyNumber } from '../../../Utils/FormatNumber';
import TocIcon from '@mui/icons-material/Toc';
import { FormControlLabel, Checkbox } from '@mui/material';
import * as XLSX from 'xlsx';
import JSON5 from 'json5';

const modalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '70%',
    height: '90%',
    bgcolor: 'background.paper',
    boxShadow: 24,
    borderRadius: '10px',
    overflowY: 'auto',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
};
const transactionsPerPage = 13;

const TrialBalanceReportHtml = ({ open, handleClose, data, handleSelect, token, client }) => {

    class PalCodeAccountGroupDetails {
        constructor(name, count = 0, appeared = 0, totalInitDebit = 0, totalInitCredit = 0, totalDebit = 0, totalCredit = 0, totalBalance = 0) {
            this.name = name;
            this.count = count;
            this.appeared = appeared;
            this.totalInitDebit = totalInitDebit;
            this.totalInitCredit = totalInitCredit;
            this.totalDebit = totalDebit;
            this.totalCredit = totalCredit;
            this.totalBalance = totalBalance;
        }

        // Method to retrieve the object as JSON
        toJSON() {
            return {
                name: this.name,
                totalInitDebit: this.totalInitDebit,
                totalInitCredit: this.totalInitCredit,
                totalDebit: this.totalDebit,
                totalCredit: this.totalCredit,
                totalBalance: this.totalBalance
            };
        }
    }

    const [reportHtmlContent, setReportHtmlContent] = useState('');
    const [clientData, setClientData] = useState('');
    // Declare the total accumulators at the component level
    let totalInitDebitAccountReport = 0;
    let totalInitCreditAccountReport = 0;
    let totalDebitAccountReport = 0;
    let totalCreditAccountReport = 0;
    let totalBalanceReport = 0;
    const [showWithZeros, setShowWithZeros] = useState(true);
    const [showReport, setShowReport] = useState(false);
    const [profitAndLossNames, setProfitAndLossNames] = useState([]);

    const [accountingGroups, setAccountingGroups] = useState(null);
    const reportGenerated = useRef(false); // Ref to ensure generateReportHtml is called once
    let totalInitDebit = 0;
    let totalInitCredit = 0;
    let totalDebit = 0;
    let totalCredit = 0;
    let totalBalance1 = 0

    let profitAndLossTotalInitDebit = 0;
    let profitAndLossTotalInitCredit = 0;
    let profitAndLossTotalDebit = 0;
    let profitAndLossTotalCredit = 0;
    let profitAndLossTotalBalance1 = 0

    const initTotalValues = () => {
        totalInitDebitAccountReport = 0;
        totalInitCreditAccountReport = 0;
        totalDebitAccountReport = 0;
        totalCreditAccountReport = 0;
        totalBalanceReport = 0;

        totalInitDebit = 0;
        totalInitCredit = 0;
        totalDebit = 0;
        totalCredit = 0;
        totalBalance1 = 0;

        profitAndLossTotalInitDebit = 0;
        profitAndLossTotalInitCredit = 0;
        profitAndLossTotalDebit = 0;
        profitAndLossTotalCredit = 0;
        profitAndLossTotalBalance1 = 0
    }

    useEffect(() => {
        ;

        if (data) {
            const palCodeAccountGroupDict = {};
            let reachedProfitAndLoss = false;
            const newProfitAndLossNames = [];

            data.transaction.forEach(item => {
                const accountGroupName = item.palCodeAccountGroup;
                if (accountGroupName === 'הכנסות') {
                    reachedProfitAndLoss = true;
                    if (!newProfitAndLossNames.includes(accountGroupName)) {
                        newProfitAndLossNames.push(accountGroupName);
                    }
                } else if (reachedProfitAndLoss && accountGroupName !== 'NonPalCodeAccountGroup') {
                    if (!newProfitAndLossNames.includes(accountGroupName)) {
                        newProfitAndLossNames.push(accountGroupName);
                    }
                }

                if (!palCodeAccountGroupDict[accountGroupName]) {
                    palCodeAccountGroupDict[accountGroupName] = {
                        name: accountGroupName,
                        count: 0,
                        appeared: 0,
                        totalInitDebit: 0,
                        totalInitCredit: 0,
                        totalDebit: 0,
                        totalCredit: 0,
                        totalBalance: 0
                    };
                }
            });

            // Only update state if the profitAndLossNames array has changed
            if (JSON.stringify(newProfitAndLossNames) !== JSON.stringify(profitAndLossNames)) {
                setProfitAndLossNames(newProfitAndLossNames);
            }

            // Set the accounting groups
            setAccountingGroups(palCodeAccountGroupDict);
            setShowReport(true);
        }
    }, [data, showWithZeros]);

    // Call generateReportHtml once after accountingGroups is set
    useEffect(() => {
        if (showReport) {
            initTotalValues();
            generateReportHtml();
            setShowReport(false);
        }
    }, [showReport]);




    useEffect(() => {
        setClientData(client);
    }, [client]);

  

    const handleRowClick = (event) => {
        try {

            // Decode the encoded JSON string
            const jsonString = decodeURIComponent(event.detail);


            // Parse the JSON string
            const transaction = JSON.parse(jsonString);


            // Proceed with your logic
            const accountCard = {
                cardId: transaction.accountCardId,
                name: transaction.accountCardName,
            };

            const palCode = {
                palCodeId: transaction.palCode,
            };

            handleSelect(accountCard, palCode);
        } catch (error) {
            // Handle any JSON parsing errors
            console.error("Failed to parse JSON from event.detail:", error, event.detail);
        }
    };


    useEffect(() => {


        // Attach event listener for the custom event
        window.addEventListener('rowClick', handleRowClick);

        // Cleanup the event listener on component unmount
        return () => {
            window.removeEventListener('rowClick', handleRowClick);
        };
    }, []);


    const generateReportHtml = () => {
        let filteredTransactions = data.transaction;


        if (!showWithZeros) {
            filteredTransactions = data.transaction.filter(tr => (tr.initCreditAccount !== 0 || tr.initDebitAccount !== 0));
        } else {
            filteredTransactions = data.transaction;
        }




        const uniquePalCodeCategories = [...new Set(filteredTransactions
            .map(tr => tr.palCodeCategory)
            .filter(category => category !== "NonPalCodeCategory"))];

        const uniquePalCodes = [...new Set(filteredTransactions
            .map(tr => tr.palCode)
            .filter(code => code !== "NonPalCode"))];

        // Pass the filtered transactions to generate the content
        let content = generateAccountingGroupClassificationSections(filteredTransactions, uniquePalCodeCategories, uniquePalCodes);
        // Define the final summary row to inject
        const finalSummaryRow = `
        <tr class="tr-final-row-palcode-Report">
                <td class="total-palcode">סה''כ רווח והפסד בדוח: </td>
                <td class="td-last-sum">${beautifyNumber(profitAndLossTotalInitDebit)}</td>
                <td class="td-last-sum">${beautifyNumber(profitAndLossTotalInitCredit)}</td>
                <td class="td-last-sum">${beautifyNumber(profitAndLossTotalDebit)}</td>
                <td class="td-last-sum">${beautifyNumber(profitAndLossTotalCredit)}</td>
                <td class="td-last-sum">${beautifyNumber(profitAndLossTotalBalance1)}</td>
            </tr>
            <tr class="tr-final-row-palcode-Report">
                <td class="total-palcode">סה''כ בדוח: </td>
                <td class="td-last-sum">${beautifyNumber(totalInitDebitAccountReport)}</td>
                <td class="td-last-sum">${beautifyNumber(totalInitCreditAccountReport)}</td>
                <td class="td-last-sum">${beautifyNumber(totalDebitAccountReport)}</td>
                <td class="td-last-sum">${beautifyNumber(totalCreditAccountReport)}</td>
                <td class="td-last-sum">${beautifyNumber(totalBalanceReport)}</td>
            </tr>
        `;

        // Split the content by 'pageStyle' to target the last one
        let parts = content.split(/(<div class="pageStyle">[\s\S]*?<\/div>\s*)/);


        if (parts.length > 1) {
            // The last part of the array should be the last 'pageStyle' container
            let lastPageStyleContent = parts.pop();

            // Inject the final summary row before closing the last table in this container
            lastPageStyleContent = lastPageStyleContent.replace(
                /<\/tbody>\s*<\/table>/, // Regex to match the closing tags of the last table within this container
                `${finalSummaryRow}</tbody></table>` // Append the final summary row before the closing tags
            );

            // Add the modified last part back to the content
            parts.push(lastPageStyleContent);
        }

        // Reconstruct the content with the modified last part
        content = parts.join('');
        const reportHtml = `
            <!DOCTYPE html>
            <html lang="he">
            <head>
                <meta charset="UTF-8">
                <meta name="viewport" content="width=device-width, initial-scale=1.0">
                <style>
                html {
                         height: 0;
                         }
                    .accountingGroupClassification_accountingGroup_container {
                        display: -webkit-box;               /* Old syntax for older WebKit browsers */
                        display: -moz-box;                  /* Old syntax for Firefox */
                        display: -ms-flexbox;               /* Old syntax for IE10 */
                        display: -webkit-flex;              /* Newer WebKit syntax */
                        display: flex;                      /* Standard syntax */

                        -webkit-box-pack: justify;          /* Justify content for older WebKit browsers */
                        -moz-box-pack: justify;             /* Justify content for older Firefox */
                        -ms-flex-pack: justify;             /* Justify content for IE10 */
                        -webkit-justify-content: space-between; /* Newer WebKit syntax for justifying content */
                        justify-content: space-between;     /* Standard syntax */

                        width: 100%;
                        margin-top: 20px;
                    }

                    .titles-details {
                            display: -webkit-box;               /* Old syntax for older WebKit browsers */
                            display: -moz-box;                  /* Old syntax for Firefox */
                            display: -ms-flexbox;               /* Old syntax for IE10 */
                            display: -webkit-flex;              /* Newer WebKit syntax */
                            display: flex;                      /* Standard syntax */
                            
                            -webkit-box-align: center;          /* Align items for older WebKit browsers */
                            -moz-box-align: center;             /* Align items for older Firefox */
                            -ms-flex-align: center;             /* Align items for IE10 */
                            -webkit-align-items: center;        /* Align items for newer WebKit */
                            align-items: center;                /* Standard syntax */

                            -webkit-box-pack: justify;          /* Justify content for older WebKit browsers */
                            -moz-box-pack: justify;             /* Justify content for older Firefox */
                            -ms-flex-pack: justify;             /* Justify content for IE10 */
                            -webkit-justify-content: space-between; /* Justify content for newer WebKit */
                            justify-content: space-between;     /* Standard syntax */
                        }

                    h1 {
                        color: navy;
                        text-align: center;
                    }

                    p {
                        color: #333;
                        padding: 20px;
                        text-align: center;
                    }

                    .upper-name-div {
                        display: -webkit-box;              /* Old syntax for older WebKit browsers */
                        display: -moz-box;                 /* Old syntax for Firefox */
                        display: -ms-flexbox;              /* Old syntax for IE10 */
                        display: -webkit-flex;             /* Newer WebKit syntax */
                        display: flex;                     /* Standard syntax */
                        
                        -webkit-box-orient: horizontal;    /* Ensures horizontal layout in older WebKit */
                        -moz-box-orient: horizontal;       /* Ensures horizontal layout in older Firefox */
                        -webkit-box-direction: reverse;    /* Reverses direction for older WebKit */
                        -moz-box-direction: reverse;       /* Reverses direction for older Firefox */
                        -webkit-flex-direction: row-reverse; /* Flexbox reverse direction for WebKit */
                        -ms-flex-direction: row-reverse;   /* Flexbox reverse direction for IE10 */
                        flex-direction: row-reverse;       /* Standard syntax */

                        padding: 10px;
                        margin-right: 50px;
                        justify-content: flex-end;         /* Align items to the right */
                        text-align: right;                 /* Ensure text aligns to the right */
                        direction: rtl;                    /* Set text direction to right-to-left */
                    }


                    .upper-name-font {
                        font-weight: 800;
                    }


                    .middle-details {
                    display: -webkit-box;              /* Old syntax for older WebKit browsers */
                    display: -moz-box;                 /* Old syntax for Firefox */
                    display: -ms-flexbox;              /* Old syntax for IE10 */
                    display: -webkit-flex;             /* Newer WebKit syntax */
                    display: flex;                     /* Standard syntax */

                    -webkit-box-pack: center;          /* Center content for older WebKit browsers */
                    -moz-box-pack: center;             /* Center content for older Firefox */
                    -ms-flex-pack: center;             /* Center content for IE10 */
                    -webkit-justify-content: center;   /* Justify content to center for newer WebKit */
                    justify-content: center;           /* Standard syntax */

                    -webkit-box-align: center;         /* Align items for older WebKit browsers */
                    -moz-box-align: center;            /* Align items for older Firefox */
                    -ms-flex-align: center;            /* Align items for IE10 */
                    -webkit-align-items: center;       /* Align items to center for newer WebKit */
                    align-items: center;               /* Standard syntax */

                    height: 120px;
                    padding-right: 30px;
                    padding-left: 30px;
                }


                    .middle-under-details {
                            display: -webkit-box;              /* Old syntax for older WebKit browsers */
                            display: -moz-box;                 /* Old syntax for Firefox */
                            display: -ms-flexbox;              /* Old syntax for IE10 */
                            display: -webkit-flex;             /* Newer WebKit syntax */
                            display: flex;                     /* Standard syntax */

                            -webkit-box-align: center;         /* Align items for older WebKit browsers */
                            -moz-box-align: center;            /* Align items for older Firefox */
                            -ms-flex-align: center;            /* Align items for IE10 */
                            -webkit-align-items: center;       /* Align items to center for newer WebKit */
                            align-items: center;               /* Standard syntax */

                            height: 80px;
                            padding-right: 30px;
                        }

                    .rt1 {
                        font-size: 32px;
                        font-style: normal;
                        font-weight: 800;
                        line-height: normal;
                        direction: rtl;
                        text-decoration: underline;
                    }

                    .rt2 {
                        margin: 0;
                        padding: 0;
                        padding-bottom: 10px;
                        font-size: 23px;
                        font-style: normal;
                        font-weight: 800;
                        line-height: normal;
                        text-decoration: underline;
                    }

                    .num-total {
                        margin: 0;
                        padding: 0;
                        padding-bottom: 10px;
                        font-size: 23px;
                        font-style: normal;
                        font-weight: 800;
                        line-height: normal;
                        position: relative;
                        display: inline-block;
                    }

                    .num-total::before {
                        content: '';
                        position: absolute;
                        left: 0;
                        right: 0;
                        height: 2px;
                        background: black;
                        top: -1px;
                    }

                    .num-total::after {
                        content: '';
                        position: absolute;
                        left: 0;
                        right: 0;
                        height: 2px;
                        background: repeating-linear-gradient(to right,
                                rgb(0, 0, 0),
                                rgb(0, 0, 0) 2px,
                                transparent 2px,
                                transparent 4px);
                        bottom: 10px;
                    }

                    .normal-num {
                        margin: 0;
                        padding: 0;
                        padding-bottom: 10px;
                        font-size: 23px;
                        font-style: normal;
                        line-height: normal;
                        position: relative;
                        direction: ltr;
                        display: inline-block;
                    }

                    .underlined {
                        margin: 0;
                        padding: 0;
                        padding-bottom: 10px;
                        font-size: 23px;
                        font-style: normal;
                        line-height: normal;
                        position: relative;
                        direction: ltr;
                        display: inline-block;
                    }

                    .underlined::after {
                        content: '';
                        position: absolute;
                        left: 0;
                        right: 0;
                        height: 1px;
                        background: rgb(0, 0, 0);
                        bottom: 10px;
                    }

                    .first {
                        margin: 0;
                        padding: 0;
                        padding-bottom: 10px;
                        font-size: 23px;
                        font-style: normal;
                        font-weight: 800;
                        line-height: normal;
                    }

                    .det {
                        margin: 0;
                        padding: 2px;
                        font-size: 23px;
                        font-style: normal;
                    }

                    .det-small {
                        margin: 0;
                        padding: 2px;
                        font-size: 18px;
                        font-style: normal;
                    }

                    .num {
                        margin: 0;
                        padding: 2px;
                        font-size: 23px;
                        font-style: normal;
                    }

                    .details-div {
                        width: 200px;
                        margin-right: 90px;
                        display: flex;
                        flex-direction: column;
                        align-items: flex-end;
                    }

                    .details-line {
                        width: 100%;
                        height: 0px;
                        border: 1px solid black;
                        margin-left: auto;
                        margin-right: auto;
                    }

                    .period-div {
                        display: flex;
                        justify-content: flex-end;
                        align-items: start;
                        width: 95%;
                        margin-left: auto;
                        margin-right: auto;
                        padding: 10px;
                    }

                    .column_div {
                        width: 200px;
                        display: flex;
                        flex-direction: column;
                        align-items: flex-end;
                    }

                    .table {
                        width: 100%;
                        page-break-inside: avoid;
                    }

                    .tr-underline {
                        direction: rtl;
                        border-bottom: 1px;
                        border-color: #000000;
                    }

                    td {
                        font-size: 14px;
                    }

                    .td-last {
                        border-bottom: 1px;
                        border-color: #000000;
                    }

                    .td-last:hover {
                        background-color: #000000;
                    }

                    .td-last-sum {
                        position: relative;
                        text-align: center;
                        font-weight: 1000;
                        padding-bottom: 8px;
                    }

                    .td-last-sum::before, 
                    .td-last-sum::after {
                        content: "";
                        position: absolute;
                        left: 0;
                        right: 0;
                        border-bottom: 1px solid black;
                    }

                    .td-last-sum::before {
                        bottom: -2px;
                    }

                    .td-last-sum::after {
                        bottom: -8px;
                    }

                    .td-header {
                        position: relative;
                        border-bottom: 1px solid black;
                    }

                    .td-last-sum2 {
                        position: relative;
                    }

                    .td-last-sum2::after {
                        content: "";
                        position: absolute;
                        left: 0;
                        right: 0;
                        bottom: 0;
                        height: 2px;
                        border-bottom: 2px dashed #ccc;
                        z-index: 1;
                    }

                    .tr {
                        border-bottom: 1px solid #b2b2b2;
                        font-size: 16px;
                    }

                    .tr-final-row {
                        border-bottom: 1px solid #000000;
                        font-size: 16px;
                    }

                    .tr:hover {
                        background-color: #ddd;
                    }

                    .th,
                    td {
                        border: 0px solid black;
                        padding: 8px ;
                        text-align: center;
                    }

                    .overflowed {
                        text-align: right;
                        padding-right: 20px !important;
                    }

                    .td {
                        display: flex;
                        flex-direction: column;
                        align-items: flex-end;
                        border: 1px solid black;
                        padding: 8px;
                        text-align: center;
                    }

                    .number-of-transactions {
                        font-size: 18px;
                        font-weight: 1000;
                        text-decoration: underline;
                    }

                    .sums-of-transactions {
                        font-size: 20px;
                        font-weight: 1000;
                        text-align: right;
                    }

                    .number-total {
                        font-size: 16px;
                        font-weight: 1000;
                    }

                    .underline {
                        width: 100%;
                        border: 1px solid black;
                    }

                    .sum-details {
                        width: 100%;
                        display: -webkit-box;              /* Old syntax for older WebKit browsers */
                        display: -moz-box;                 /* Old syntax for Firefox */
                        display: -ms-flexbox;              /* Old syntax for IE10 */
                        display: -webkit-flex;             /* Newer WebKit syntax */
                        display: flex;                     /* Standard syntax */
                        
                        -webkit-box-orient: vertical;      /* Ensures column layout in older WebKit */
                        -moz-box-orient: vertical;         /* Ensures column layout in older Firefox */
                        -webkit-box-direction: normal;     /* Normal flow direction for older WebKit */
                        -moz-box-direction: normal;        /* Normal flow direction for older Firefox */
                        -webkit-flex-direction: column;    /* Flexbox column direction for WebKit */
                        -ms-flex-direction: column;        /* Flexbox column direction for IE10 */
                        flex-direction: column;            /* Standard syntax */
                        
                        -webkit-box-pack: start;           /* Align items to start for older WebKit */
                        -moz-box-pack: start;              /* Align items to start for older Firefox */
                        -ms-flex-pack: start;              /* Align items to start for IE10 */
                        -webkit-justify-content: flex-start; /* Justify content at the start for WebKit */
                        justify-content: flex-start;       /* Standard syntax */

                        -webkit-box-align: end;            /* Align items to end for older WebKit */
                        -moz-box-align: end;               /* Align items to end for older Firefox */
                        -ms-flex-align: end;               /* Align items to end for IE10 */
                        -webkit-align-items: flex-end;     /* Align items at the end for WebKit */
                        align-items: flex-end;             /* Standard syntax */
                        
                        margin-top: 40px;
                        margin-bottom: 20px;
                    }


                    .titles-details {
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                    }


                    .palcodes-details {
                        font-size: 14px;
                    }

                    .total-palcode {
                        font-weight: bold;
                        font-size: 18px;
                    }
                   

                   .pageStyle {
                            margin-bottom: 20px;
                            background:red;
                            display: -webkit-box;               /* Old syntax for older WebKit browsers */
                            display: -moz-box;                  /* Old syntax for Firefox */
                            display: -ms-flexbox;               /* Old syntax for IE10 */
                            display: -webkit-flex;              /* Newer WebKit syntax */
                            display: flex;                      /* Standard syntax */
                            
                            -webkit-box-orient: vertical;       /* Ensures column layout in older WebKit */
                            -moz-box-orient: vertical;          /* Ensures column layout in older Firefox */
                            -webkit-box-direction: normal;      /* Normal flow direction for older WebKit */
                            -moz-box-direction: normal;         /* Normal flow direction for older Firefox */
                            -webkit-flex-direction: column;     /* Flexbox column direction for WebKit */
                            -ms-flex-direction: column;         /* Flexbox column direction for IE10 */
                            flex-direction: column;             /* Standard syntax */
                            
                            -webkit-box-pack: justify;          /* Justify content for older WebKit browsers */
                            -moz-box-pack: justify;             /* Justify content for older Firefox */
                            -ms-flex-pack: justify;             /* Justify content for IE10 */
                            -webkit-justify-content: space-between; /* Justify content for newer WebKit */
                            justify-content: space-between;     /* Standard syntax */

                            background-color: white;
                            padding: 20px;

                            page-break-before: auto;
                            page-break-after: auto;
                            page-break-inside: avoid;
                            width:100%;
                            border: 1px solid #ccc;
                            box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
                        }

                       
                    .page-break {
                        margin-top: 50px;
                        page-break-before: always;
                        page-break-after: auto; /* Avoid unnecessary page breaks */
                        page-break-inside: avoid;
                    }
                    tr {                                            
                        page-break-inside: avoid;                      
                        page-break-after: avoid;                       
                        page-break-before: avoid;                      
                    } 

                    .hoverTr {
                        transition: transform 0.2s ease, box-shadow 0.1s ease;
                    }

                    .hoverTr:hover {
                        transform: translateY(-5px); /* Moves the row up by 5px */
                        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15); /* Adds a shadow for the 3D effect */
                    }
                </style>
            </head>
            <body>
                    ${content}
            </body>
            </html>
        `;

        setReportHtmlContent(reportHtml);
    };

    const updateNonPalCodeValues = (transactions) => {
        return transactions.map(transaction => {
            return {
                ...transaction,
                palCodeType: transaction.palCodeType === 'NonPalCodeType' ? t('NonPalCodeType') : transaction.palCodeType,
                palCodeCategory: transaction.palCodeCategory === 'NonPalCodeCategory' ? t('NonPalCodeCategory') : transaction.palCodeCategory,
                palCodeAccountGroup: transaction.palCodeAccountGroup === 'NonPalCodeAccountGroup' ? t('NonPalCodeAccountGroup') : transaction.palCodeAccountGroup,
                accountingGroupClassification: transaction.accountingGroupClassification === 'NonAccountingGroupClassifaction' ? t('NonAccountingGroupClassifaction') : transaction.accountingGroupClassification
            };
        });
    };

    const generateAccountingGroupClassificationSections = (transactions, uniquePalCodeCategories, uniquePalCodes) => {


        let content = '';
        const groupedByAccountingGroupClassification = groupBy(transactions, 'accountingGroupClassification');


        Object.keys(groupedByAccountingGroupClassification).forEach(accountingGroupClassification => {


            const transactionsForAccountingGroupClassification = groupedByAccountingGroupClassification[accountingGroupClassification];



            const groupedByPalCodeCategory = groupBy(transactionsForAccountingGroupClassification, 'palCodeCategory');



            const palCodeAccountGroup = transactionsForAccountingGroupClassification[0]?.palCodeAccountGroup || '';

            // Process each transaction and replace "Non" values with translations



            let headerHtml = `
               
               
            `;
            const categories = Object.keys(groupedByPalCodeCategory);
            Object.keys(groupedByPalCodeCategory).forEach((palCodeCategory, index) => {
                //    if (accountingGroupClassification === 'נכסים') {

                const currentCategory = groupedByPalCodeCategory[palCodeCategory];

                const nextCategoryKey = categories[index + 1];
                const nextCategory = groupedByPalCodeCategory[nextCategoryKey];

                // 
                // 
                // 
                // 
                // }

                let printAccountingGroup = false;
                printAccountingGroup = !nextCategory || (currentCategory[0]?.palCodeAccountGroup !== nextCategory[0]?.palCodeAccountGroup);

                const transactionsForPalCodeCategory = groupedByPalCodeCategory[palCodeCategory];

                headerHtml = `
                <div class="upper-name-div">
                    <p class="first" style="font-size: 20px; margin-left:20px;">${data.clientName}</p>
                    <p class="first" style="font-size: 20px; margin-left:20px;">ח.פ. / מ.ע: ${data.clientCase}</p>
                </div>
                <div class="middle-details">
                    <div>
                        <p class="rt1" style="font-size: 20px; margin: 0;">דוח מאזן בוחן יתרות</p>
                        <div style="display: flex; gap: 20px;">
                            <p class="det-small" style="font-size: 20px; text-decoration: underline; font-weight: 900;">
                                מחודש ${data.startDate} עד חודש ${data.endDate}
                            </p>
                        </div>
                    </div>
                </div>
                <div class="sum-details">
                    <div class="palcodes-details" style="margin-right:40px;">
                        מסעיף: ${uniquePalCodeCategories[0]} - עד סעיף: ${uniquePalCodeCategories[uniquePalCodeCategories.length - 1]}
                    </div>
                    <div class="palcodes-details" style="margin-right:40px;">
                        מסיווג: ${uniquePalCodes[0]} - עד סיווג: ${uniquePalCodes[uniquePalCodes.length - 1]}
                    </div>
                </div>
                 <div class="accountingGroupClassification_accountingGroup_container">
                    <div class="sums-of-transactions" style="text-decoration: underline; margin-left:40px">
                        ${accountingGroupClassification === 'NonAccountingGroupClassifaction'
                        ? t('NonAccountingGroupClassifaction')
                        : accountingGroupClassification}
                    </div>
                    <div class="sums-of-transactions" style="text-decoration: underline; margin-right:40px">
                        ${palCodeAccountGroup === 'NonPalCodeAccountGroup'
                        ? t('NonPalCodeAccountGroup')
                        : transactionsForPalCodeCategory[0]?.palCodeAccountGroup || ''}
                    </div>
                </div>`;

                // ${headerHtml}
                // <div style="margin-right: 40px;">
                //     <div class="sums-of-transactions" style="margin-bottom: 10px; text-decoration: underline;">${palCodeCategory}</div>
                // </div>

                content += `
                        ${generatePalCodeTables(transactionsForPalCodeCategory, palCodeCategory, headerHtml, printAccountingGroup, transactionsForPalCodeCategory[0]?.palCodeAccountGroup || '')}
                `;
            });
        });

        return content;
    };

    const generatePalCodeTables = (transactionsForPalCodeCategory, palCodeCategory, headerHtml, printAccountingGroup, accountingGroupName) => {
        let content = '';
        const groupedByPalCode = groupBy(transactionsForPalCodeCategory, 'palCode');



        let transactionsProcessed = 0;
        let headerDisplayedForCurrentPage = false;

        // Start the table once at the beginning
        content += `
            <div class="pageStyle">
                ${headerHtml}
                <div style="margin-right: 40px;">
                    <div class="sums-of-transactions" style="margin-bottom: 10px; text-decoration: underline;">${palCodeCategory === 'NonPalCodeCategory'
                ? t('NonPalCodeCategory')
                : palCodeCategory}</div>
                </div>
                <table class="table" style="margin-right: 40px; margin-top: 20px; padding: 0px 20px 0 20px;" dir="rtl">
                    <thead>
                        <tr class="tr">
                            <th class="td-header">כרטיס חשבון</th>
                            <th class="td-header">י. פ. חובה</th>
                            <th class="td-header">י. פ. זכות</th>
                            <th class="td-header">חובה</th>
                            <th class="td-header">זכות</th>
                            <th class="td-header">יתרה</th>
                        </tr>
                    </thead>
                    <tbody>
        `;

        const palCodes = Object.keys(groupedByPalCode);
        palCodes.forEach((palCode, index) => {
            const transactionsForPalCode = groupedByPalCode[palCode];

            // Add a row to divide different palCodes
            content += `
            <tr>
                <td class="overflowed" colspan="6">${palCode === 'NonPalCode'
                    ? t('NonPalCode')
                    : palCode}</td>
            </tr>
        `;

            transactionsForPalCode.forEach(tr => {
                const transactionDetail = encodeURIComponent(JSON.stringify(tr)); // Escape quotes for embedding JSON in HTML

                content += `
                 <tr class ="hoverTr" onclick="window.parent.dispatchEvent(new CustomEvent('rowClick', { detail: '${transactionDetail}' }))" style="cursor:pointer">
                    <td class="td-last-sum2">${tr.accountCardId} ${tr.accountCardName}</td>
                    <td class="td-last-sum2">${beautifyNumber(tr.initDebitAccount)}</td>
                    <td class="td-last-sum2">${beautifyNumber(tr.initCreditAccount)}</td>
                    <td class="td-last-sum2">${beautifyNumber(tr.debitAccount)}</td>
                    <td class="td-last-sum2">${beautifyNumber(tr.creditAccount)}</td>
                    <td class="td-last-sum2">${beautifyNumber(tr.balance)}</td>
                </tr>
            `;

                transactionsProcessed++;

                // Check if we need to start a new page
                if (transactionsProcessed % transactionsPerPage === 0) {
                    content += `
                    </tbody>
                </table>
                </div>
               
                <div class="pageStyle">
                    ${headerHtml}
                    <div style="margin-right: 40px;">
                        <div class="sums-of-transactions" style="margin-bottom: 10px; text-decoration: underline;">${palCodeCategory}</div>
                    </div>
                    <table class="table" style="margin-right: 40px; margin-top: 20px; padding: 0px 20px 0 20px;" dir="rtl">
                        <thead>
                            <tr class="tr">
                                <th class="td-header">כרטיס חשבון</th>
                                <th class="td-header">י. פ. חובה</th>
                                <th class="td-header">י. פ. זכות</th>
                                <th class="td-header">חובה</th>
                                <th class="td-header">זכות</th>
                                <th class="td-header">יתרה</th>
                            </tr>
                        </thead>
                        <tbody>
                `;
                }
            });

            // Add totals for the current PalCode
            content += generatePalCodeTotals(transactionsForPalCode, palCode);

            // If this is the last palCode in the current palCodeCategory, add the category totals
            if (index === palCodes.length - 1) {
                content += generatePalCodeCategoryTotals(transactionsForPalCodeCategory, palCodeCategory);


                if (printAccountingGroup) {
                    // 







                    content += `
                    <tr class="tr-final-row-palcode-category">
                        <td class="total-palcode">סה''כ:  ${accountingGroupName === 'NonPalCodeAccountGroup'
                            ? t('NonPalCodeAccountGroup')
                            : accountingGroupName}</td>
                        <td class="td-last-sum">${beautifyNumber(totalInitDebit)}</td>
                        <td class="td-last-sum">${beautifyNumber(totalInitCredit)}</td>
                        <td class="td-last-sum">${beautifyNumber(totalDebit)}</td>
                        <td class="td-last-sum">${beautifyNumber(totalCredit)}</td>
                        <td class="td-last-sum">${beautifyNumber(totalBalance1)}</td>

                    </tr>`;
                    if (profitAndLossNames.includes(accountingGroupName)) {
                        profitAndLossTotalInitDebit += totalInitDebit;
                        profitAndLossTotalInitCredit += totalInitCredit;
                        profitAndLossTotalDebit += totalDebit;
                        profitAndLossTotalCredit += totalCredit;
                        profitAndLossTotalBalance1 += totalBalance1;
                    }
                    totalInitDebit = 0;
                    totalInitCredit = 0;
                    totalDebit = 0;
                    totalCredit = 0;
                    totalBalance1 = 0
                }
            }
        });

        // Close the final table and pageStyle div
        content += `
                    </tbody>
                </table>
                </div>
                `;

        // Add totals for the PalCodeCategory
        // content += `
        //     <div class="pageStyle">
        //     </div>
        // `;

        return content;
    };







    const generatePalCodeTotals = (transactions, palCode) => {
        const totalInitDebitAccount = transactions.reduce((acc, tr) => acc + tr.initDebitAccount, 0);
        const totalInitCreditAccount = transactions.reduce((acc, tr) => acc + tr.initCreditAccount, 0);
        const totalDebitAccount = transactions.reduce((acc, tr) => acc + tr.debitAccount, 0);
        const totalCreditAccount = transactions.reduce((acc, tr) => acc + tr.creditAccount, 0);
        const totalBalance = transactions.reduce((acc, tr) => acc + tr.balance, 0);

        return `
            <tr class="tr-final-row-palcode">
                <td class="total-palcode">סה''כ: ${palCode === 'NonPalCode'
                ? t('NonPalCode')
                : palCode}</td>
                <td class="td-last-sum">${beautifyNumber(totalInitDebitAccount)}</td>
                <td class="td-last-sum">${beautifyNumber(totalInitCreditAccount)}</td>
                <td class="td-last-sum">${beautifyNumber(totalDebitAccount)}</td>
                <td class="td-last-sum">${beautifyNumber(totalCreditAccount)}</td>
                <td class="td-last-sum">${beautifyNumber(totalBalance)}</td>
            </tr>
        `;
    };

    const generatePalCodeCategoryTotals = (transactions, palCodeCategory) => {
        const totalInitDebitAccount = transactions.reduce((acc, tr) => acc + tr.initDebitAccount, 0);
        const totalInitCreditAccount = transactions.reduce((acc, tr) => acc + tr.initCreditAccount, 0);
        const totalDebitAccount = transactions.reduce((acc, tr) => acc + tr.debitAccount, 0);
        const totalCreditAccount = transactions.reduce((acc, tr) => acc + tr.creditAccount, 0);
        const totalBalance = transactions.reduce((acc, tr) => acc + tr.balance, 0);

        if (transactions[0].palCodeAccountGroup === 'רכוש קבוע') {


        }

        const group = transactions[0].palCodeAccountGroup;
        // transactions.forEach(transaction => {
        //     setAccountingGroups(prevGroups => {
        //         const updatedGroup = { ...prevGroups[transaction.palCodeAccountGroup] };

        //         // Increment the 'appeared' count
        //         updatedGroup.appeared += 1;

        //         return {
        //             ...prevGroups,
        //             [group]: updatedGroup
        //         };
        //     });
        // });

        // setAccountingGroups(prevGroups => {
        //     const updatedGroup = { ...prevGroups[group] }; // Clone the current group's data
        //     
        //     if (group === 'רכוש קבוע') {
        //         

        //     }
        //     // Update the totals for the existing group
        //     updatedGroup.totalInitDebit += totalInitDebitAccount;
        //     updatedGroup.totalInitCredit += totalInitCreditAccount;
        //     updatedGroup.totalDebit += totalDebitAccount;
        //     updatedGroup.totalCredit += totalCreditAccount;
        //     updatedGroup.totalBalance += totalBalance;
        //     return {
        //         ...prevGroups,
        //         [group]: updatedGroup // Update the specific group in the state
        //     };
        // });
        totalInitDebit += totalInitDebitAccount;
        totalInitCredit += totalInitCreditAccount;
        totalDebit += totalDebitAccount;
        totalCredit += totalCreditAccount;
        totalBalance1 += totalBalance;
        // Accumulate to report totals
        totalInitDebitAccountReport += totalInitDebitAccount;
        totalInitCreditAccountReport += totalInitCreditAccount;
        totalDebitAccountReport += totalDebitAccount;
        totalCreditAccountReport += totalCreditAccount;
        totalBalanceReport += totalBalance;

        return `
            <tr class="tr-final-row-palcode-category">
                <td class="total-palcode">סה''כ: ${palCodeCategory === 'NonPalCodeCategory'
                ? t('NonPalCodeCategory')
                : palCodeCategory}</td>
                <td class="td-last-sum">${beautifyNumber(totalInitDebitAccount)}</td>
                <td class="td-last-sum">${beautifyNumber(totalInitCreditAccount)}</td>
                <td class="td-last-sum">${beautifyNumber(totalDebitAccount)}</td>
                <td class="td-last-sum">${beautifyNumber(totalCreditAccount)}</td>
                <td class="td-last-sum">${beautifyNumber(totalBalance)}</td>
            </tr>
        `;
    };

    const handleDownloadExcel = () => {
        if (!data || !data.transaction) return;

        // Define the header translations manually
        const headers = {
            accountCardId: t("AccountCard ID"),
            accountCardName: t("AccountCard Name"),
            palCode: t('BalanceClassificationMazni'),
            palCodeType: t("BalanceClassificationType"),
            palCodeCategory: t("BalanceClassificationCategory"),
            palCodeAccountGroup: t("AccountingGroup"),
            accountingGroupClassification: t("BalanceClassification"),
            initCreditAccount: t("OpeningBalanceCreditAccount"),
            initDebitAccount: t("OpeningBalanceDebitAccount"),
            creditAccount: t("Credit Account"),
            debitAccount: t("Debit Account"),
            balance: t("Balance")
        };

        // Transform the data, translating the headers
        const transformedData = data.transaction.map(transaction => {
            const transformedTransaction = {};
            for (const key in transaction) {
                if (headers[key]) {
                    transformedTransaction[headers[key]] = transaction[key];
                }
            }
            return transformedTransaction;
        });

        // Convert the transformed data to a worksheet
        const ws = XLSX.utils.json_to_sheet(transformedData);

        // Create a new workbook and append the worksheet
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, 'Transactions');

        // Write the workbook and download it
        XLSX.writeFile(wb, `${data.clientName}_TransactionReport.xlsx`);
    };


    const handleDownloadPdf = async () => {
        const pageElements = document.querySelectorAll('.pageStyle');
        try {
            // Get all elements with the .pageStyle class

            // Store original styles and remove border and shadow for PDF generation
            let pdfContent = reportHtmlContent;

            // Remove the border and box-shadow styles directly
            pdfContent = pdfContent.replace(/border: 1px solid #ccc;/g, '');
            pdfContent = pdfContent.replace(/box-shadow: 0px 0px 10px rgba\(0, 0, 0, 0.1\);/g, '');


            const response = await PythonService.convertHtmlToPdf(pdfContent, token);
            if (response.ok) {
                const blob = await response.blob();
                const url = window.URL.createObjectURL(new Blob([blob]));
                const link = document.createElement('a');
                link.href = url;
                // Set the filename using clientName and clientCase
                const fileName = `${data.clientName}-${data.clientCase}_דוח מאזן בוחן.pdf`;
                link.setAttribute('download', fileName);
                document.body.appendChild(link);
                link.click();
                link.remove();
            } else {
                console.error('Failed to convert HTML to PDF:', response.status);
            }
        } catch (error) {
            console.error('Error converting HTML to PDF:', error);
        }
    };

    const handlePrint = () => {
        const printWindow = window.open('', '_blank');
        printWindow.document.write(reportHtmlContent);
        printWindow.document.close();
        printWindow.print();
    };

    return (
        <Modal open={open} onClose={handleClose} aria-labelledby="account-card-report-modal-title" aria-describedby="account-card-report-modal-description">
            <Box sx={modalStyle}>

                {/* <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%' }}>
                    <Button variant="contained" onClick={handleDownloadPdf} startIcon={<DownloadIcon sx={{ ml: 1 }} />} sx={{ mt: 1, backgroundColor: '#025366', color: '#fff', '&:hover': { backgroundColor: '#1565c0' } }}>
                        {t('DownloadPDFFile')}
                    </Button>
                    <Button variant="contained" onClick={handlePrint} startIcon={<PrintIcon sx={{ ml: 1 }} />} sx={{ mt: 1, backgroundColor: '#025366', color: '#fff', '&:hover': { backgroundColor: '#1565c0' } }}>
                        {t('PrintReport')}
                    </Button>
                </div> */}
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%' }}>
                    <div
                        className="pdf-view-upper"
                        style={{
                            width: '65%',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            marginBottom: '10px',
                            borderRadius: '0 0 10px 10px'
                        }}
                    >
                        <div style={{ margin: '20px', textAlign: 'center' }}>
                            <Button
                                variant="contained"
                                onClick={handleDownloadPdf}
                                startIcon={<DownloadIcon sx={{ ml: 1 }} />}
                                sx={{ mt: 1, backgroundColor: '#304FFF', height: '35px', color: '#fff', '&:hover': { backgroundColor: '#F9AA2A' } }}
                            >
                                {t('DownloadPDFFile')}
                            </Button>
                        </div>

                        <div style={{ margin: '20px', textAlign: 'center' }}>
                            <Button
                                variant="contained"
                                onClick={handleDownloadExcel}
                                startIcon={<TocIcon sx={{ ml: 1 }} />}
                                sx={{ mt: 1, backgroundColor: '#304FFF', height: '35px', color: '#fff', '&:hover': { backgroundColor: '#F9AA2A' } }}
                            >
                                {t('DownloadExcelFile')}
                            </Button>
                        </div>

                        <div style={{ margin: '20px', textAlign: 'center' }}>
                            {/* <Button
                                variant="contained"
                                onClick={handlePrint}
                                height='50px'
                                startIcon={<PrintIcon sx={{ ml: 1 }} />}
                                sx={{ mt: 1, backgroundColor: '#025366', height: '35px', color: '#fff', '&:hover': { backgroundColor: '#1565c0' } }}
                            >
                                {t('PrintReport')}
                            </Button> */}


                            {/* <ReactToPrint
                                    trigger={() => (
                                        <Button
                                            variant="contained"
                                            startIcon={<PrintIcon sx={{ ml: 1 }} />}
                                            sx={{ mt: 1, backgroundColor: '#025366', color: '#fff', '&:hover': { backgroundColor: '#1565c0' } }}
                                        >
                                            {t('PrintReport')}
                                        </Button>
                                    )}
                                    content={() => reportContentRef.current}
                                /> */}
                        </div>

                        {/* <FormControlLabel
                            control={<Checkbox />}
                            label={t('ShowWithZeros')}
                            checked={showWithZeros}
                            onChange={() => setShowWithZeros(prev => !prev)}
                            labelPlacement="right"
                        /> */}

                        {/* <FormControlLabel
                            control={<Checkbox />}
                            label={t('ShowWithZeros')}
                            labelPlacement="top"
                        /> */}

                    </div>
                </div>
                <div dangerouslySetInnerHTML={{ __html: reportHtmlContent }} />
            </Box>
        </Modal>
    );
};

const groupBy = (arr, key) => {
    return arr.reduce((acc, item) => {
        if (!acc[item[key]]) {
            acc[item[key]] = [];
        }
        acc[item[key]].push(item);
        return acc;
    }, {});
};

export default TrialBalanceReportHtml;
