import React, { useState } from 'react';
import {
    Box,
    Checkbox,
    Button,
    Typography,
    FormControl,
    Select,
    MenuItem,
    InputLabel,
    ToggleButtonGroup,
    ToggleButton,
} from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import { beautifyNumber } from '../../Utils/FormatNumber';
import { el } from 'date-fns/locale';
import DeleteIcon from '@mui/icons-material/Delete';

const StyledToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
    backgroundColor: '#e0e0e0',
    borderRadius: '30px',
    padding: '4px',
    '& .MuiToggleButton-root': {
        backgroundColor: '#ffffff',
        color: theme.palette.text.primary,
        transition: 'background-color 0.3s, transform 0.3s',
        borderRadius: '30px',
        padding: '8px 16px',
        minWidth: '120px',
        fontWeight: 'bold',
        '&:hover': {
            backgroundColor: '#e0e0e0',
            transform: 'scale(1.05)',
        },
        '&.Mui-selected': {
            backgroundColor: '#007bff',
            color: '#fff',
            '&:hover': {
                backgroundColor: '#0069d9',
            },
        },
    },
}));

const BottomDockBar = ({
    columns,
    handleJournalEntries,
    handleMatchEntries,
    handleAutoMatch,
    handleMatch,
    handleClearMatches,
    externalSum,
    internalSum,
    matches,
    selectedExternalRows,
    selectedInternalRows,
    handleDeleteExternalRows
}) => {
    const theme = useTheme();
    const { t } = useTranslation();
    const [selectedColumns, setSelectedColumns] = useState(['amount']);
    const [createJournalEntries, setCreateJournalEntries] = useState(false);
    const [matchType, setMatchType] = useState('Manual'); // Toggle between "Manual" and "Automatic"
    const [isMatchingApplied, setIsMatchingApplied] = useState(false); // State to control Apply/Match/Clear buttons

    const handleCreateJournalEntries = () => {
        
        
        if (selectedInternalRows.length !== 0 && selectedExternalRows.length > 0 && createJournalEntries === true) {
           
            handleJournalEntries(false);
        }
        else {
            handleJournalEntries(createJournalEntries);
        }
    }
    // Handle multi-select for columns
    const handleColumnSelect = (event) => {
        

        setSelectedColumns(event.target.value);
    };

    // Handle toggle between matching types
    const handleMatchTypeChange = (event, newMatchType) => {
        if (newMatchType !== null) {
            setMatchType(newMatchType);
            setIsMatchingApplied(false); // Reset when switching modes
        }
    };

    // Handle Apply click in Automatic Matching
    const handleAutomaticApply = () => {
        if (selectedColumns.length === 0 || !selectedColumns.includes('amount')) {
            alert('Please select at least the Amount column for matching.');
            return;
        }
        handleAutoMatch(selectedColumns);
        setIsMatchingApplied(true); // Show Match and Clear buttons
    };

    // Handle Clear action
    const handleClear = () => {
        setIsMatchingApplied(false);
        handleClearMatches();
        setSelectedColumns(['amount']);
    };
    const Difference = (externalSum || 0) - (internalSum || 0)
    return (
        <Box
            sx={{
                position: 'fixed',
                bottom: 10, // Smaller padding from the bottom
                left: '50%',
                transform: 'translateX(-50%)',
                backgroundColor: '#ffffff',
                boxShadow: '0px -2px 10px rgba(0, 0, 0, 0.2)', // Lighter shadow for dock effect
                borderRadius: '20px',
                p: 1, // Smaller padding
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                width: '85%', // Slightly smaller width
                maxWidth: '500px', // Limit the max width
                gap: 1,
                zIndex: 1111,
            }}
        >
            <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                {/* Toggle between Manual and Automatic */}
                <StyledToggleButtonGroup
                    color="primary"
                    value={matchType}
                    exclusive
                    onChange={handleMatchTypeChange}
                    aria-label="Matching Type"
                >
                    <ToggleButton value="Manual">{t('ManualMatching')}</ToggleButton>
                    <ToggleButton value="Automatic">{t('AutomaticMatching')}</ToggleButton>
                </StyledToggleButtonGroup>

                {
                    (selectedExternalRows.length > 0) &&

                    <Button
                        variant="contained"
                        color="secondary"
                        onClick={handleDeleteExternalRows}
                        sx={{
                            backgroundColor: '#d32f2f',  // Red color for delete
                            color: '#fff',
                            '&:hover': { backgroundColor: '#c62828' }, // Darker red on hover
                            fontSize: '0.9rem',
                        }}
                        startIcon={<DeleteIcon />}  // Add the Delete icon
                    >
                        {t('Delete')}
                    </Button>

                }

               
            </div>
            {/* If Manual Matching, show Difference and Checkbox */}
            {matchType === 'Manual' && (
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        width: '100%',
                        alignItems: 'center',
                    }}
                >
                    {
                        !createJournalEntries &&
                        <Typography
                            variant="h6"
                            sx={{
                                flexGrow: 1,
                                textAlign: 'center',
                                fontSize: '0.9rem',
                                color: theme.palette.text.primary,
                            }}
                        >
                            {`${t('Difference')}: ${beautifyNumber(Difference)}`}
                        </Typography>
                    }

                    {/* Text left of the checkbox */}
                    {
                        (selectedInternalRows.length === 0 && selectedExternalRows.length > 0 && Difference != 0) &&
                        <Box
                            sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                        >
                            <Box sx={{ display: 'flex', alignItems: 'center', mr: 1 }}>
                                {t('CreateJournalEntriesForEachTransaction')}
                            </Box>
                            <Checkbox
                                checked={createJournalEntries}
                                onChange={(e) => setCreateJournalEntries(e.target.checked)}
                                color="primary"
                            />
                        </Box>
                    }

                    {/* Apply Button */}
                    {
                        !(Difference === 0 && selectedInternalRows.length === 0 && selectedExternalRows.length === 0) && (
                            (Difference === 0  && (selectedExternalRows.length > 0 || selectedInternalRows.length>0)) ? (
                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={() => handleMatch(selectedInternalRows)}
                                    sx={{
                                        ml: 2,
                                        backgroundColor: '#0288d1',
                                        color: '#fff',
                                        '&:hover': { backgroundColor: '#0277bd' },
                                        fontSize: '0.9rem', // Slightly smaller font size
                                    }}
                                >
                                    {t('Match')}
                                </Button>
                            ) : (
                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={handleCreateJournalEntries}
                                    sx={{
                                        ml: 2,
                                        backgroundColor: '#F9AA2A',
                                        color: '#fff',
                                        '&:hover': { backgroundColor: '#0277bd' },
                                        fontSize: '0.9rem', // Slightly smaller font size
                                    }}
                                >
                                    {t('CreateJournalEntry')}
                                </Button>
                            )
                        )
                    }

                </Box>
            )}

            {/* If Automatic Matching, show Select and Apply or Match/Clear Buttons */}
            {matchType === 'Automatic' && (
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'space-around',
                        width: '100%',
                        alignItems: 'center',
                    }}
                >
                    {matches.length <= 0 ? (
                        <>
                            <FormControl fullWidth sx={{ maxWidth: 300 }}>
                                <InputLabel>{t('SelectHeaders')}</InputLabel>
                                <Select
                                    multiple
                                    value={selectedColumns}
                                    onChange={handleColumnSelect}
                                    label={t('SelectHeaders')}
                                    renderValue={(selected) =>
                                        selected.map(item => t(item)).join(', ')
                                    }
                                    sx={{ minWidth: 100, backgroundColor: '#f5f5f5' }}
                                >
                                    {columns.map((col) => (
                                        <MenuItem key={col.field} value={col.field}>
                                            {col.headerName}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>

                            {/* Apply Button for Automatic Matching */}
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={handleAutomaticApply}
                                sx={{
                                    ml: 2,
                                    backgroundColor: '#0288d1',
                                    color: '#fff',
                                    '&:hover': { backgroundColor: '#0277bd' },
                                    fontSize: '0.9rem',
                                }}
                            >
                                {t('Match')}
                            </Button>
                        </>
                    ) : (
                        <>
                            {/* Match and Clear Buttons */}
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={handleMatchEntries}
                                sx={{
                                    ml: 2,
                                    backgroundColor: '#4caf50',
                                    color: '#fff',
                                    '&:hover': { backgroundColor: '#45a049' },
                                    fontSize: '0.9rem',
                                }}
                            >
                                {t('Match')}
                            </Button>
                            <Button
                                variant="outlined"
                                color="secondary"
                                onClick={handleClear}
                                sx={{
                                    ml: 2,
                                    fontSize: '0.9rem',
                                }}
                            >
                                {t('Clear')}
                            </Button>
                        </>
                    )}
                </Box>
            )}
        </Box>
    );
};

export default BottomDockBar;
