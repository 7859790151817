import React, { useRef, useState, useMemo, useEffect } from 'react';
import { DataGrid, GridToolbar, useGridApiRef } from '@mui/x-data-grid';
import { Box, Button } from '@mui/material';
import { styled } from '@mui/material/styles';

import { useTranslation } from 'react-i18next';
import { beautifyNumber } from '../../../Utils/FormatNumber';



// Styled DataGrid component
const StyledDataGrid = styled(DataGrid)(({ theme }) => ({
    width: '100%',
    '& .MuiDataGrid-columnHeaders': {
        opacity: 0.7,
        backgroundColor: '#BFC8FF !important',
    },
    '& .MuiDataGrid-columnHeader': {
        backgroundColor: '#BFC8FF !important',
    },
    '& .MuiDataGrid-columnHeaderTitle': {
        color: 'var(--Foundation-Blue-Normal, #304FFF)',
        textAlign: 'center',
        fontFamily: 'Montserrat',
        fontSize: '14px',
        fontWeight: 600,
        lineHeight: 'normal',
    },
    '& .MuiDataGrid-row': {
        cursor: 'pointer',
        borderTop: '1px solid rgba(191, 191, 193, 0.56)',
        backgroundColor: 'rgba(234, 237, 255, 0.32)',
    },
    '& .MuiDataGrid-cell': {
        whiteSpace: 'normal',
        wordWrap: 'break-word',
    },
    '& .MuiDataGrid-cell:focus': {
        outline: 'none',
    },
    '& .disableUser': {
        pointerEvents: 'none',
        opacity: 0.5,
    },
}));

const ClientsDataGrid = ({
    filteredClients,
    viewClientDetails,
    subAccountants,
    activeUsers,
    ViewSubAccountantClients
}) => {

    const { i18n, t } = useTranslation();
    const [selectedLanguage, setSelectedLanguage] = useState(localStorage.getItem('language') || 'he');

    // const initChatConf = async () => {
    //   await ChatService.InitChat();

    // }


    useEffect(() => {
        // Check for saved language in localStorage
        const currentLanguage = localStorage.getItem('language') || 'he'; // Default to 'en' if no language is stored

        i18n.changeLanguage(currentLanguage);
        // initChatConf();
    }, []);

    useEffect(() => {
        const currentLanguage = localStorage.getItem('language') || 'he';
        setSelectedLanguage(currentLanguage);
    }, [i18n.language]);

    const columns = useMemo(() => {
        const baseColumns = [
            {
                field: 'name',
                headerName: t('clientName'),
                headerAlign: 'center',
                align: 'center',
                flex: 1,
            },
            {
                field: 'caseId',
                headerName: t('clientCase'),
                headerAlign: 'center',
                align: 'center',
                flex: 1,
            },
            {
                field: 'subAccountantId',
                headerName: t('SubAccountantName'),
                headerAlign: 'center',
                align: 'center',
                flex: 1,
                renderCell: (params) => {
                    const subAccountant = subAccountants.find(
                        (accountant) => accountant.value === params.value
                    );
                    return <span>{subAccountant ? subAccountant.label : ''}</span>;
                },
            },
            {
                field: 'reportingType',
                headerName: t('MonthlyBiMonthly'),
                headerAlign: 'center',
                align: 'center',
                flex: 1,
            },
            {
                field: 'totalCounts',
                headerName: t('unprocessed'),
                headerAlign: 'center',
                align: 'center',
                flex: 1,
                renderCell: (params) => (
                    <span style={{ color: params.value > 0 ? 'red' : 'inherit' }}>
                        {params.value}
                    </span>
                ),
            },
            {
                field: 'accountManagement',
                headerName: t('AccountManagement'),
                headerAlign: 'center',
                align: 'center',
                flex: 2,
            },
        ];

        // Add the balance column conditionally
        if (!ViewSubAccountantClients) {
            baseColumns.push({
                field: 'balance',
                headerName: t('Balance'),
                headerAlign: 'center',
                align: 'center',
                flex: 3,
                valueFormatter: (params) =>
                    params.value !== '-' ? beautifyNumber(params.value) : params.value,
            });
        }

        return baseColumns;
    }, [t, subAccountants, ViewSubAccountantClients]);


    const apiRef = useGridApiRef();
    const [selectedRows, setSelectedRows] = useState([]);

    // Handlers for sorting and filtering
    const handleSortModelChange = (model) => {
        // Implement your sort logic here
    };

    const handleFilterModelChange = (model) => {
        // Implement your filter logic here
    };

    const handleEdit = (params) => {
        // Implement your edit logic here
    };

    return (
        <Box
            sx={{
                height: '50vh',
                width: '100%',
                boxSizing: 'border-box',
                backgroundColor: '#fff',
                direction: 'ltr',
            }}
        >

            <StyledDataGrid
                apiRef={apiRef}
                rows={filteredClients}
                columns={columns}
                pageSize={25} // Max rows set to 25
                rowsPerPageOptions={[10, 25, 50]}
                disableRowSelectionOnClick
                onSelectionModelChange={(ids) => setSelectedRows(ids)}
                getRowClassName={(params) => params.row.rowClassName || ''}
                getRowId={(row) => row.caseId}
                onCellEditCommit={handleEdit}
                selectionModel={selectedRows}
                components={{ Toolbar: GridToolbar }}
                componentsProps={{
                    toolbar: {
                        showQuickFilter: true,
                    },
                    row: {
                        className: !activeUsers ? 'disableUser' : '',
                    },
                }}
                pagination
                onSortModelChange={handleSortModelChange}
                onFilterModelChange={handleFilterModelChange}
                onRowClick={(params) => viewClientDetails(params.row)}
            />

        </Box>
    );
};

export default ClientsDataGrid;
