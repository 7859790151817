import config from '../config';
import selectedEnvironment from '../env';
const API_URL = `${config[selectedEnvironment].API_URL}/Reports`;


const getReferenceSequenceReport = async (caseId, startDate, endDate, accountCardId = null, token) => {
    const response = await fetch(`${API_URL}/ReferenceSequenceReport`, {
        method: 'POST',
        headers: {
            "Authorization": 'Bearer ' + token,
            "Content-Type": "application/json"
        },
        body: JSON.stringify({
            CaseId: caseId,
            StartDate: startDate,
            EndDate: endDate,
            AccountCardCode: accountCardId
        })
    });

    if (!response.ok) {
        const errorText = await response.text();
        console.error(errorText);
        throw new Error(errorText);
    }

    const blob = await response.blob();

    // Create a URL for the file
    const fileUrl = window.URL.createObjectURL(blob);

    // Create a temporary link and trigger the download
    const link = document.createElement('a');
    link.href = fileUrl;
    link.setAttribute('download', `ReferenceSequenceReport-${caseId}.pdf`); // Use a suitable filename
    document.body.appendChild(link);
    link.click();

    // Clean up by removing the link and revoking the object URL
    document.body.removeChild(link);
    window.URL.revokeObjectURL(fileUrl);
};

const getSupplierWithholdingTaxSummary = async (caseId, startDate, endDate, accountantId, token) => {
    const response = await fetch(`${API_URL}/SupplierWithholdingTaxSummaryMonthly`, {
        method: 'POST',
        headers: {
            "Authorization": 'Bearer ' + token,
            "Content-Type": "application/json"
        },
        body: JSON.stringify({
            CaseId: caseId,
            AccountantId: accountantId,
            StartDate: startDate,
            EndDate: endDate
        })
    });

    if (!response.ok) {
        const errorText = await response.text();
        console.error(errorText);
        throw new Error(errorText);
    }

    const blob = await response.blob();

    const fileUrl = window.URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = fileUrl;
    link.setAttribute('download', `ניכוי-במקור-תקופתי-${caseId}.pdf`);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    window.URL.revokeObjectURL(fileUrl);
};

const getSupplierWithholdingTaxSummaryYearly = async (caseId, startDate, accountantId, token) => {
    const response = await fetch(`${API_URL}/SupplierWithholdingTaxSummaryYearly`, {
        method: 'POST',
        headers: {
            "Authorization": 'Bearer ' + token,
            "Content-Type": "application/json"
        },
        body: JSON.stringify({
            CaseId: caseId,
            AccountantId: accountantId,
            StartDate: startDate
        })
    });

    if (!response.ok) {
        const errorText = await response.text();
        console.error(errorText);
        throw new Error(errorText);
    }

    const blob = await response.blob();

    const fileUrl = window.URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = fileUrl;
    link.setAttribute('download', `ניכוי-במקור-שנתי-${caseId}.pdf`);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    window.URL.revokeObjectURL(fileUrl);
};


const getSupplierWithholdingTaxCertificate = async (caseId, startDate, accountantId, cardId = null, token) => {
    const response = await fetch(`${API_URL}/SupplierWithholdingTaxCertificate`, {
        method: 'POST',
        headers: {
            "Authorization": 'Bearer ' + token,
            "Content-Type": "application/json"
        },
        body: JSON.stringify({
            CaseId: caseId,
            AccountantId: accountantId,
            StartDate: startDate,
            CardId: cardId
        })
    });

    if (!response.ok) {
        const errorText = await response.text();
        console.error(errorText);
        throw new Error(errorText);
    }

    const contentType = response.headers.get("Content-Type");

    if (contentType === "application/zip") {
        const blob = await response.blob();
        const fileUrl = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = fileUrl;
        link.setAttribute('download', `ניכוי-במקור-אישור-${caseId}.zip`);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        window.URL.revokeObjectURL(fileUrl);
    } else if (contentType === "application/pdf") {
        const blob = await response.blob();
        const fileUrl = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = fileUrl;
        link.setAttribute('download', `ניכוי-במקור-אישור-${caseId}.pdf`);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        window.URL.revokeObjectURL(fileUrl);
    }
};



const ReportsService = {
    getReferenceSequenceReport,
    getSupplierWithholdingTaxSummary,
    getSupplierWithholdingTaxSummaryYearly,
    getSupplierWithholdingTaxCertificate
};

export default ReportsService;