import * as React from 'react';
import PropTypes from 'prop-types';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { beautifyNumber } from '../../Utils/FormatNumber';

const defaultHeaderStyle = {
  backgroundColor: '#ebebeb',
  color: 'black',
  fontWeight: 'bold',
};

const defaultRowStyle = {};

export default function DenseTable({
  headers,
  rows,
  styleFlag = false,
  width = '70%',
  numericrows = true,
  reverse = false,
  align = 'center',
  headerStyle = {},
  rowStyle = {},
  oneRow = false
}) {
  const processedHeaders = reverse ? [...headers].reverse() : headers;
  let processedRows = [];
  if (!oneRow) {
    processedRows = reverse
      ? rows.map(row => Object.values(row).reverse())
      : rows.map(row => Object.values(row));
  } else {
    processedRows = reverse
    ? rows.reverse()
    : rows;
  }
  const combinedHeaderStyle = { ...defaultHeaderStyle, ...headerStyle };
  const combinedRowStyle = { ...defaultRowStyle, ...rowStyle };
  return (
    <TableContainer
      component={Paper}
      sx={{
        width: width,
        height: 'fit-content',
        overflow: 'hidden',
        borderRadius: '10px',
      }}
    >
      <Table size="small" aria-label="a dense table">
        <TableHead>
          <TableRow sx={combinedHeaderStyle}>
            {processedHeaders.map((header) => (
              <TableCell key={header} align={align} sx={{height:'30px'}}>
                {header}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {(numericrows && !oneRow) ? (
            processedRows.map((row, rowIndex) => (
              <TableRow key={rowIndex} sx={combinedRowStyle}>
                {row.map((cell, cellIndex) => (
                  <TableCell
                    key={cellIndex}
                    align="center"
                    sx={{
                      height: '30px',
                      ...(styleFlag &&
                        cellIndex === 0 &&
                        cell.trim() !== '0' &&
                        cell.trim() !== '0.00' && {
                        backgroundColor: '#f0675d',
                      }),
                    }}
                  >
                    {cell}
                  </TableCell>
                ))}
              </TableRow>
            ))
          ) : (
            <TableRow sx={combinedRowStyle}>
              {rows.map((row, cellIndex) => (
                <TableCell
                  key={cellIndex}
                  align="center"
                  sx={{
                    height: '30px',
                    ...(styleFlag && { backgroundColor: '#f0675d' }),
                  }}
                >
                  {numericrows ? beautifyNumber(row) : row}
                </TableCell>
              ))}
            </TableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

DenseTable.propTypes = {
  headers: PropTypes.arrayOf(PropTypes.string).isRequired,
  rows: PropTypes.arrayOf(PropTypes.object).isRequired,
  styleFlag: PropTypes.bool,
  width: PropTypes.string,
  numericrows: PropTypes.bool,
  reverse: PropTypes.bool,
  align: PropTypes.string,
  headerStyle: PropTypes.object,
  rowStyle: PropTypes.object,
};
