// AccountAutocomplete.js
import React from 'react';
import {
  Autocomplete,
  TextField,
  Button,
  Tooltip,
  Popper,
} from '@mui/material';
import { styled } from '@mui/system';

// Styled components for custom styles
const StyledListItem = styled('li')({
  '&:hover': {
    backgroundColor: '#F5F5F6', // Hover color
    cursor: 'pointer',
  },
});

const StyledPopper = styled(Popper)(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: '8px',
    background: '#FFF',
    boxShadow: '0px 4px 8px 0px rgba(0, 0, 0, 0.32)',
  },
  '& .MuiAutocomplete-listbox': {
    padding: theme.spacing(1),
  },
  '& .MuiAutocomplete-option': {
    borderRadius: '4px',
    '&[aria-selected="true"], &.Mui-focused, &:hover': {
      background: '#F9AA2A !important',
    },
  },
}));

const AccountAutocomplete = ({
  type, // 'creditAccount', 'debitAccount', or 'accountCard'
  options,
  formValues,
  handleSelectChange,
  setCreditDebitSearchTerm,
  handleNoOptionCreditDebitClick,
  creditDebitSearchTerm,
  editedItem,
  t,
  direction = 'ltr',
  useCustomStyle = false,
  inputPropsStyle = {},
  inputStyle = {},
  updateAccountFields,
  handleCreditDebitForJournalEntry,
  EditAccountCard,
  formattedAccountCardOptions,
  width = '30%'
}) => {
  // Determine the label for options
  const getLabel = (option) => {
    if (useCustomStyle) {
      return `${option.cardId} - ${option.name}`;
    }
    return option.label || `${option.cardId} - ${option.name}`;
  };

  // Determine the value of the autocomplete
  const getValue = () => {
    if (useCustomStyle) {
      return (
        formattedAccountCardOptions.find(
          (option) => option.cardId === editedItem[type]
        ) || null
      );
    } else if (type === 'accountCard') {
      return (
        options.find(
          (option) => option.value === formValues.accountCard
        ) || null
      );
    } else {
      return (
        options.find((option) => option.value === formValues[type]) || null
      );
    }
  };

  // Handle change events
  const handleChange = (event, newValue) => {
    if (useCustomStyle) {
      if (editedItem.docType === 'JournalEntries') {
        handleCreditDebitForJournalEntry(newValue, type);
      } else {
        updateAccountFields(newValue, type);
      }
    } else {
      handleSelectChange(type, newValue);
    }
  };

  // Render options with or without custom styles
  const renderOption = (props, option) => {
    return (
      <Tooltip
        title={
          option.isEditable ? t('RightClickToEdit') : t('NotEditable')
        }
        placement="top"
      >
        <StyledListItem
          {...props}
          onContextMenu={(event) => {
            event.preventDefault();
            EditAccountCard(option, type);
          }}
        >
          {`${option.cardId || ''} - ${option.name}`}
        </StyledListItem>
      </Tooltip>
    );

  };

  // Use custom Popper if needed
  const CustomPopper = (props) => {
    if (useCustomStyle || type === 'accountCard') {
      return (
        <StyledPopper
          {...props}
          style={{
            ...props.style,
            direction: direction,
          }}
        />
      );
    } else {
      return <Popper {...props} />;
    }
  };

  return (
    <Autocomplete
      options={
        useCustomStyle || type === 'accountCard'
          ? formattedAccountCardOptions || options
          : options
      }
      getOptionLabel={(option) => getLabel(option)}
      value={getValue()}
      onChange={handleChange}
      onInputChange={(event, inputValue) => {
        if (setCreditDebitSearchTerm) {
          setCreditDebitSearchTerm(inputValue);
        }
      }}
      noOptionsText={
        handleNoOptionCreditDebitClick ? (
          <Button
            onClick={() =>
              handleNoOptionCreditDebitClick(
                creditDebitSearchTerm,
                type,
                editedItem.docType
              )
            }
            variant="contained"
            color="primary"
          >
            {t('AddAccountCard')} "{creditDebitSearchTerm}"
          </Button>
        ) : null
      }
      renderInput={(params) => (
        <TextField
          {...params}
          label={
            !useCustomStyle &&
            t(
              type === 'debitAccount'
                ? 'Debit Account'
                : type === 'creditAccount'
                ? 'Credit Account'
                : 'AccountCard'
            )
          }
          placeholder={
            useCustomStyle &&
            t(
              type === 'debitAccount'
                ? 'Debit Account'
                : type === 'creditAccount'
                ? 'Credit Account'
                : 'AccountCard'
            )
          }
          variant={useCustomStyle ? 'standard' : 'outlined'}
          fullWidth={!useCustomStyle}
          required
          sx={
            !useCustomStyle
              ? {
                  marginRight: '16px',
                  '.MuiInputBase-root': {
                    boxSizing: 'border-box !important',
                  },
                }
              : undefined
          }
          InputProps={
            useCustomStyle
              ? {
                  ...params.InputProps,
                  ...inputPropsStyle,
                }
              : {
                  ...params.InputProps,
                  className: 'custom-input-box-sizing',
                }
          }
          inputProps={
            useCustomStyle
              ? {
                  ...params.inputProps,
                  ...inputStyle,
                }
              : params.inputProps
          }
        />
      )}
      renderOption={renderOption}
      PopperComponent={CustomPopper}
      ListboxProps={{ dir: 'rtl' }}
      style={{
        direction: 'rtl',
        boxSizing: 'border-box !important',
      }}
      sx={{ width: `${width} !important` }}
    />
  );
};

export default AccountAutocomplete;
