import axios from 'axios';
import config from '../config';
import selectedEnvironment from '../env';
const API_URL = `${config[selectedEnvironment].API_URL}/UserTypePermissions`;

const checkPermission = async (userId,userType ,PermissionName,token) => {
    try {
        const response = await fetch(`${API_URL}/CheckPermission`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify({ userId,userType ,PermissionName }),
        });

        if (!response.ok) {
            throw new Error("Permission check failed");
        }

        const { hasPermission } = await response.json();
        return hasPermission;
    } catch (error) {
        console.error("Error checking permission:", error);
        return false;
    }
};


const GetPermissions = async (userId,userType ,token) => {
    try {
        const response = await fetch(`${API_URL}/GetPermissionsByUserId`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify({ userId,userType  }),
        });

        if (!response.ok) {
            throw new Error("Permission check failed");
        }

        const data = await response.json();
        return data;
    } catch (error) {
        console.error("Error checking permission:", error);
        return false;
    }
};

const PermissionService = {
    checkPermission,
    GetPermissions
};

export default PermissionService;
