import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
// import ChatService from './Services/chat.service';
// import UserService from './Services/user.service';
// Initialize CometChat and persist user data
// const initializeCometChat = async () => {
//   try {
//     const User = await UserService.getCurrentUser();
//     const Token = await UserService.getCurrentToken();
//     const userType = await UserService.getCurrentUserType();
//     if (User && Token && userType) {
//       // Initialize CometChat with stored user data
//       const uid = userType === 2 ? User.accountantId : User.subAccountantId;
//       await ChatService.InitChat();
//       await ChatService.Chatlogin(uid);
//       // Continue fetching data or rendering your component
//     } else {
//       // Redirect to login or handle the case where user data is not available
//     }
//   } catch (error) {
//     console.error('CometChat initialization error:', error);
//     // Handle the error gracefully
//   }
// };

// // Call the initialization function when the application loads
// initializeCometChat();

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
