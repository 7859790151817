// OtherDocumentDraggableDialog.js

import React, { useState, useEffect, useRef } from 'react';
import {
    TextField,
    Autocomplete,
    Portal,
    Popper,
} from '@mui/material';
import Draggable from 'react-draggable';
import { useTranslation } from 'react-i18next';
import CustomDatePicker from '../../CustomDatePicker/CustomDatePicker';

function OtherDocumentDraggableDialog(props) {
    const { t, i18n } = useTranslation();
    const {
        open,
        onClose,
        data,
        onSave,
        selectOtherDocumentsOptions,
        direction,
    } = props;

    const [formData, setFormData] = useState(data);
    const [container] = useState(() => document.createElement('div'));
    const nodeRef = useRef(null);

    useEffect(() => {
        setFormData(data);
    }, [data]);

    useEffect(() => {
        if (open) {
            document.body.appendChild(container);

            // Center the modal
            const updateModalPosition = () => {
                const modalWidth = 700;
                const modalHeight = nodeRef.current ? nodeRef.current.offsetHeight : 0;
                const screenWidth = window.innerWidth;
                const screenHeight = window.innerHeight;

                const x = (screenWidth - modalWidth) / 2;
                const y = (screenHeight - modalHeight) / 2;

                nodeRef.current.style.left = `${x}px`;
                nodeRef.current.style.top = `${y}px`;
            };

            setTimeout(updateModalPosition, 0);

            window.addEventListener('resize', updateModalPosition);

            return () => {
                try {
                    document.body.removeChild(container);
                } catch (e) {
                    // Ignore
                }
                window.removeEventListener('resize', updateModalPosition);
            };
        } else {
            try {
                document.body.removeChild(container);
            } catch (e) {
                // Ignore
            }
        }
    }, [open, container]);

    const handleChange = (field, value) => {
        const updatedData = { ...formData, [field]: value };
        setFormData(updatedData);
    };

    const handleSave = () => {
        onSave(formData);
        onClose();
    };

    const inputPropsStyle = {
        disableUnderline: true,
        className: `custom-input-box-sizing ${direction === 'ltr' ? 'ltr-input' : 'rtl-input'}`,
        sx: {
            height: '48px',
            padding: '0 10px',
            borderRadius: '8px',
            background: '#F5F5F6',
            border: '1px solid transparent',
            '&:focus-within': {
                border: '2px solid var(--Foundation-Blue-Normal, #304FFF)',
            },
        },
    };

    const inputStyle = {
        style: {
            textAlign: direction === 'ltr' ? 'left' : 'right',
        },
    };

    if (!open) return null;

    return (
        <Portal container={container}>
            <Draggable handle=".handle" nodeRef={nodeRef}>
                <div
                    ref={nodeRef}
                    style={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        backgroundColor: 'white',
                        borderRadius: '8px',
                        padding: '16px',
                        maxHeight: '90vh',
                        overflowY: 'auto',
                        minWidth: '700px',
                        zIndex: 1300,
                    }}
                >
                    <div
                        className="handle"
                        style={{
                            cursor: 'move',
                            fontSize: '20px',
                            fontWeight: 'bold',
                            marginBottom: '16px',
                            width: '100%',
                        }}
                    >
                        <div className="edit-title">{t('EditRow')}</div>
                    </div>
                    {/* Modal Content */}
                    <div className={`form-container ${direction}`}>
                        {/* Row 1 */}
                        <div className="form-row">
                            {/* Title */}
                            <div className="edit-form-row-item">
                                <div className="edit-form-row-item-title">
                                    {t('Title')}<span className="required-asterisk">*</span>
                                </div>
                                <TextField
                                    fullWidth
                                    placeholder={t('Title')}
                                    value={formData.title || ''}
                                    onChange={(e) => handleChange('title', e.target.value)}
                                    variant="standard"
                                    InputProps={inputPropsStyle}
                                    inputProps={inputStyle}
                                    required
                                />
                            </div>
                            {/* Document Type */}
                            <div className="edit-form-row-item">
                                <div className="edit-form-row-item-title">
                                    {t('Document Type')}<span className="required-asterisk">*</span>
                                </div>
                                <Autocomplete
                                    options={selectOtherDocumentsOptions}
                                    getOptionLabel={(option) => option.name}
                                    value={
                                        selectOtherDocumentsOptions.find(
                                            (option) => option.name === formData.documentType
                                        ) || null
                                    }
                                    onChange={(event, newValue) => {
                                        const newDocumentType = newValue ? newValue.name : '';
                                        handleChange('documentType', newDocumentType);
                                    }}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            placeholder={t('Document Type')}
                                            variant="standard"
                                            InputProps={{
                                                ...params.InputProps,
                                                ...inputPropsStyle,
                                            }}
                                            inputProps={{
                                                ...params.inputProps,
                                                ...inputStyle,
                                            }}
                                            required
                                        />
                                    )}
                                    PopperComponent={(props) => (
                                        <Popper
                                            {...props}
                                            style={{
                                                ...props.style,
                                                direction: direction,
                                            }}
                                        />
                                    )}
                                />
                            </div>
                        </div>
                
                        {/* Save and Cancel Buttons */}
                        <div className="button-container">
                            <div onClick={onClose} className="edit-cancel-button">
                                {t('Cancel')}
                            </div>
                            <div onClick={handleSave} className="edit-save-button">
                                {t('Save')}
                            </div>
                        </div>
                    </div>
                </div>
            </Draggable>
        </Portal>
    );
}

export default OtherDocumentDraggableDialog;
