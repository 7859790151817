// VirtualizedListboxComponent.jsx

import React from 'react';
import { VariableSizeList } from 'react-window';

const LISTBOX_PADDING = 8; // px

function renderRow(props) {
  const { data, index, style } = props;
  const option = data[index];

  return React.cloneElement(option, {
    style: {
      ...style,
      top: style.top + LISTBOX_PADDING,
    },
  });
}

const OuterElementContext = React.createContext({});

const OuterElementType = React.forwardRef((props, ref) => {
  const outerProps = React.useContext(OuterElementContext);
  return <div ref={ref} {...props} {...outerProps} />;
});

const VirtualizedListboxComponent = React.forwardRef(function ListboxComponent(
  { children, itemSizes, ...other },
  ref
) {
  const itemData = React.Children.toArray(children);
  const itemCount = itemData.length;
    
  // Compute the total height for the visible items
  const maxItemsToShow = Math.min(8, itemCount);
  const height =
    itemSizes.slice(0, maxItemsToShow).reduce((a, b) => a + b, 0) +
    2 * LISTBOX_PADDING;

  const getItemSize = (index) => itemSizes[index];

  return (
    <div ref={ref}>
      <OuterElementContext.Provider value={other}>
        <VariableSizeList
          itemData={itemData}
          height={height}
          outerElementType={OuterElementType}
        //   innerElementType="ul"
          itemSize={getItemSize}
          overscanCount={5}
          itemCount={itemCount}
        >
          {renderRow}
        </VariableSizeList>
      </OuterElementContext.Provider>
    </div>
  );
});

export default VirtualizedListboxComponent;
