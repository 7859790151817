export function formatDate_MMYYYY(date) {
    const d = new Date(date);
    const month = String(d.getMonth() + 1).padStart(2, '0'); // Add leading zero for month
    const year = d.getFullYear();
    return `${month}${year}`;
};

export function formatDate_MM_YYYY(date) {
    const d = new Date(date);
    const month = String(d.getMonth() + 1).padStart(2, '0'); // Add leading zero for month
    const year = d.getFullYear();
    return `${month}/${year}`;
};

export function formatDate_DDMMYYYY(date) {
    const d = new Date(date);
    const day = String(d.getDate()).padStart(2, '0'); // Add leading zero for day
    const month = String(d.getMonth() + 1).padStart(2, '0'); // Add leading zero for month
    const year = d.getFullYear();
    return `${day}${month}${year}`;
};

export function formatDate_YYYYMMDD(date) {
    const d = new Date(date);
    const year = d.getFullYear();
    const month = String(d.getMonth() + 1).padStart(2, '0'); // Add leading zero for month
    const day = String(d.getDate()).padStart(2, '0'); // Add leading zero for day
    return `${year}${month}${day}`;
};

export function formatDate_DD_MM_YYYY(date) {
    const d = new Date(date);
    const day = String(d.getDate()).padStart(2, '0'); // Add leading zero for day
    const month = String(d.getMonth() + 1).padStart(2, '0'); // Add leading zero for month
    const year = d.getFullYear();
    return `${day}-${month}-${year}`;
};

export function formatDate_MM_DD_YYYY(date) {
    const d = new Date(date);
    const month = String(d.getMonth() + 1).padStart(2, '0'); // Add leading zero for month
    const day = String(d.getDate()).padStart(2, '0'); // Add leading zero for day
    const year = d.getFullYear();
    return `${month}-${day}-${year}`;
};

export function formatDate_HHMMSS(date) {
    const d = new Date(date);
    const hours = String(d.getHours()).padStart(2, '0'); // Add leading zero for hours
    const minutes = String(d.getMinutes()).padStart(2, '0'); // Add leading zero for minutes
    const seconds = String(d.getSeconds()).padStart(2, '0'); // Add leading zero for seconds
    return `${hours}:${minutes}:${seconds}`;
};

export function formatDate_YYYY_MM_DD_HHMMSS(date) {
    const d = new Date(date);
    const year = d.getFullYear();
    const month = String(d.getMonth() + 1).padStart(2, '0'); // Add leading zero for month
    const day = String(d.getDate()).padStart(2, '0'); // Add leading zero for day
    const hours = String(d.getHours()).padStart(2, '0'); // Add leading zero for hours
    const minutes = String(d.getMinutes()).padStart(2, '0'); // Add leading zero for minutes
    const seconds = String(d.getSeconds()).padStart(2, '0'); // Add leading zero for seconds
    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
};


export function formatDateRangeFromString  (dateRangeString) {
    const [startDateStr, endDateStr] = dateRangeString.split(' $ ');

    const formatSingleDate = (dateStr) => {
        const date = new Date(dateStr);
        const month = ('0' + (date.getMonth() + 1)).slice(-2); // Ensure month is in MM format
        const year = date.getFullYear();
        return `${month}/${year}`;
    };

    const startFormatted = formatSingleDate(startDateStr);
    const endFormatted = formatSingleDate(endDateStr);

    return `${startFormatted} - ${endFormatted}`;
};