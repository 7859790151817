import React, { useState, useRef, useEffect } from 'react';
import HamburgerMenu from '../HamburgerMenu/HamburgerMenu.jsx'; // Adjust the import path as necessary
import './SubAccountants.css';
import employess from '../../assests/images/office.png'; // Update with the correct path
import UserService from '../../Services/user.service.js';
import CustomDeleteConfirmationModal from '../CustomDeleteConfirmationModal/CustomDeleteConfirmationModal.js';
import SubAccountantService from '../../Services/subAccountant.service.js';
import { useNavigate } from 'react-router-dom';
// import ChatService from '../../Services/chat.service';
import employesGroupIcon from "../../assests/images/employesGroup.png";
import { useTranslation } from 'react-i18next';
import { HashLoader } from "react-spinners";
import { ToastContainer, toast } from "react-toastify";
import Header from '../Header/Header.js';
import editIcon from "../../assests/images/edit.png";
import cancelIcon from "../../assests/images/cancel.png";
import loadingAnimation from "../../assests/images/Animation.gif";
import deleteIcon from "../../assests/images/deleteicon.png";
import PuffLoader from "react-spinners/PuffLoader";
import CircularProgress, {
  circularProgressClasses,
} from '@mui/material/CircularProgress';
import UpperPanelDetails from '../Widgets/UpperPanelDetails/UpperPanelDetails.jsx';
import { beautifyNumber } from '../../Utils/FormatNumber.js';
import { TextField, IconButton, InputAdornment } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import AutocompleteSelect from '../Widgets/AutocompleteSelect/AutocompleteSelect.jsx';
import SubAccountantsDataGrid from './SubAccountantsDataGrid.jsx';
import subAccountantsIcon from "../../assests/images/Main/subAccountants.svg";

const SubAccountant = () => {
  const navigate = useNavigate();
  const { i18n, t } = useTranslation();
  const [user, setUser] = useState();
  const [token, setToken] = useState();
  const [isUserDataFetched, setIsUserDataFetched] = useState(false);
  const [subAccountants, setSubAccountants] = useState([]);
  const [subAccountantsCount, setSubAccountantsCount] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [sortOrder, setSortOrder] = useState('asc'); // 'asc' or 'desc'
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [isNewSubAccountant, setIsNewSubAccountant] = useState(false);
  const [selectedSubAccountant, setSelectedSubAccountant] = useState(null);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const popupContentRef = useRef(null);
  const [direction, setDirection] = useState('rtl'); // Default to 'rtl'


  const allSubAccountantFieldsFilled = !isNewSubAccountant || (isNewSubAccountant && selectedSubAccountant && selectedSubAccountant.subAccountantId && selectedSubAccountant.name && selectedSubAccountant.password && selectedSubAccountant.email);
  useEffect(() => {
    // Update direction based on the current language
    if (i18n.language === 'he' || i18n.language === 'ar') {
      setDirection('rtl');
    } else {
      setDirection('ltr');
    }
  }, [i18n.language]);

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const handleSort = () => {
    setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
  };

  const handleViewButtonClick = (subAccountant) => {
    setSelectedSubAccountant(subAccountant);
    setIsPopupOpen(true);
  };

  const handleAddClick = () => {
    setSelectedSubAccountant({});
    setIsNewSubAccountant(true);
    setIsPopupOpen(true);
  };

  const handleEdit = (e) => {
    e.stopPropagation();
    setIsEditMode(!isEditMode);
  };

  const handleDelete = async () => {
    // Perform delete operation
    setLoading(true);
    try {
      // Call the DeleteDocument function from DocumentService with the item's id
      const response = await SubAccountantService.DeleteSubAccountant(token, selectedSubAccountant.subAccountantId);
      // const response1 = await ChatService.DeleteChatUser(selectedSubAccountant.subAccountantId);
      if (response.ok) {
        // Handle successful deletion, e.g., by updating your UI
        toast.success(t('deleteSubAccountantSuccess'));
      } else {
        // Handle any errors or display a message
        const errorResponse = await response.json();
        console.error('Error deleting sub-accountant:', errorResponse);
        toast.error(t('deleteSubAccountantError'));
      }
    } catch (error) {
      console.error('Error deleting sub-accountant:', error);
      toast.error(t('deleteSubAccountantError'));
    }
    fetchSubAccountantsData(user.accountantId, token, true);
    setIsModalVisible(false);
    setLoading(false);
  };

  const prepareDelete = () => {
    // Logic for deleting the selected sub-accountant
    setIsPopupOpen(false);
    setIsModalVisible(true)
  };
  const handleClose = () => {
    setIsEditMode(false)
    setIsPopupOpen(false) // Prevent click event from propagating to parent elements
  };

  const handleSaveChanges = async () => {
    try {
      setLoading(true);
      const editedItem = {
        SubAccountantId: selectedSubAccountant.subAccountantId,
        AccountantId: selectedSubAccountant.accountantId,
        PhoneNumber: selectedSubAccountant.phoneNumber,
        Email: selectedSubAccountant.email,
        Name: selectedSubAccountant.name
      }
      const response = await SubAccountantService.UpdateSubAccountant(token, editedItem);
      if (response.ok) {
        fetchSubAccountantsData(user.accountantId, token, true);
        toast.success(t('updateSubAccountantSuccess'));
      } else {
        const errorText = await response.text();
        console.error(errorText);
        toast.error(t('updateSubAccountantError'));
      }
    } catch (error) {
      console.error(error);
      toast.error(t('updateSubAccountantError'));
    }
    setIsEditMode(false);
    setIsPopupOpen(false);
    setLoading(false);
  };


  const handleAddSubAccountant = async () => {
    try {
      setLoading(true);
      const subData = {
        SubAccountantId: selectedSubAccountant.subAccountantId,
        AccountantId: user.accountantId,
        PhoneNumber: selectedSubAccountant.phoneNumber,
        Email: selectedSubAccountant.email,
        Name: selectedSubAccountant.name,
        Password: selectedSubAccountant.password,
      }
      const response = await UserService.addSubAccountant(subData, token);
      fetchSubAccountantsData(user.accountantId, token, true);
      toast.success(t('updateSubAccountantSuccess'));
    } catch (error) {
      console.error(error);
      toast.error(t('updateSubAccountantError'));
    }
    setIsEditMode(false);
    setIsPopupOpen(false);
    setIsNewSubAccountant(false);
    setSelectedSubAccountant({});
    setLoading(false);
  };

  const [isEditMode, setIsEditMode] = useState(false);


  const isRtl = ['he', 'ar'].includes(i18n.language);


  const fetchSubAccountantsData = async (accountantId, token, isFirstFetch) => {
    try {
      setLoading(true);
      const data = await SubAccountantService.GetSubAccountantByAccountantId(token, accountantId);
      if (isFirstFetch) {
        setSubAccountants(data);
        setSubAccountantsCount(data.length);

        // toast.success(t('fetchSubAccountantsDataSuccess'));
      } else {
        setSubAccountants([...subAccountants, ...data]);
      }
    } catch (error) {
      console.error(error);
      toast.error(t('fetchSubAccountantsDataError'));
    }
    setLoading(false);
  };

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        setLoading(true);
        const user = await UserService.getCurrentUser();
        const token = await UserService.getCurrentToken();
        if (user && token) {
          setUser(user);
          setToken(token);
          setIsUserDataFetched(true); // Set true when user data is successfully fetched
          fetchSubAccountantsData(user.accountantId, token, true); // Fetch subAccountants after setting user data
        } else {
          navigate("/login");
        }
      } catch (error) {
        setIsUserDataFetched(false);
        navigate("/login");
      }
    };
    setLoading(false);
    fetchUserData();
  }, []);

  const handleOverlayClick = () => {
    setIsPopupOpen(false);
  };

  const handleContainerClick = (e) => {
    e.stopPropagation(); // Prevents the overlay's click event from firing when clicking inside the container
  };


  // Filter subAccountants based on search query
  const filteredSubAccountants = subAccountants
    .filter(subAccountant =>
      subAccountant.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
      subAccountant.subAccountantId.toLowerCase().includes(searchQuery.toLowerCase())
    )
    .sort((a, b) => {
      if (sortOrder === 'asc') {
        return a.name.localeCompare(b.name);
      } else {
        return b.name.localeCompare(a.name);
      }
    });


  return (
    <>
      <HamburgerMenu />

      <div className="template-page">
        <div className="template-container">
          <UpperPanelDetails />
          <div className={`main-title ${direction}`}>{t('SubAccountants')}</div>
          <div className={`main-cards ${direction}`}>
            <div style={{ display: 'flex', gap: "20px" }}>


              <div className={`main-card-container ${direction}`}>
                <div className={`main-card-icon ${direction}`}>
                  <img
                    src={subAccountantsIcon}
                    alt="home"
                  />
                </div>
                <div className={`main-card-details ${direction}`}>
                  <div className={`main-card-details1 ${direction}`}>
                    {t('SubAccountants')}
                  </div>
                  <div className={`main-card-details2 ${direction}`}>
                    {beautifyNumber(subAccountantsCount)}
                  </div>
                </div>
              </div>
            </div>
            <div className='addClient-button' onClick={handleAddClick}>
              <span>{t("addSubAccountant")}</span>
            </div>
          </div>
          <div className={`filtersBar ${direction}`} >
            <TextField
              fullWidth
              placeholder={t('searchClient')}
              value={searchQuery}
              onChange={handleSearchChange}
              className="search-bar"
              variant="standard"
              InputProps={{
                disableUnderline: true,
                className: `custom-input-box-sizing ${direction === 'ltr' ? 'ltr-input' : 'rtl-input'}`,
                sx: {
                  height: '48px',
                  padding: '0 10px',
                  borderRadius: '8px',
                  background: '#F5F5F6',
                  border: '1px solid transparent',
                  '&:focus-within': {
                    border: '2px solid var(--Foundation-Blue-Normal, #304FFF)',
                  },
                },
                ...(direction === 'ltr'
                  ? {
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton edge="end" style={{ marginRight: '10px' }}>
                          <SearchIcon />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }
                  : {
                    startAdornment: (
                      <InputAdornment position="start">
                        <IconButton edge="start" style={{ marginLeft: '10px' }}>
                          <SearchIcon />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }),
              }}
              inputProps={{
                style: {
                  textAlign: direction === 'ltr' ? 'left' : 'right',
                },
              }}
            />

          </div>
          <SubAccountantsDataGrid
            filteredSubAccountants={filteredSubAccountants}
            handleViewButtonClick={handleViewButtonClick}
          />


        </div>

        {isPopupOpen && selectedSubAccountant && (
          <div className="sub-popup-hamburger-overlay" onClick={handleOverlayClick}>
            <div className="sub-add-client__container" onClick={handleContainerClick}>
              <div className={`sub-add-client-content ${direction}`}>
                <div className="title-and-buttons">
                  <h2 className='centered-heading'>{t('EditSubAccountant')}</h2>
                  <div className="edit-delete-buttons">
                    <button className='row-buttons subEditDeleteButton' onClick={() => prepareDelete()}>
                      <svg xmlns="http://www.w3.org/2000/svg" width="19" height="23" viewBox="0 0 19 23" fill="none">
                        <path d="M13.7143 12.7139C16.3179 12.7139 18.4286 14.8245 18.4286 17.4282C18.4286 20.0318 16.3179 22.1424 13.7143 22.1424C11.1107 22.1424 9.00002 20.0318 9.00002 17.4282C9.00002 14.8245 11.1107 12.7139 13.7143 12.7139ZM9.00002 0.713867C10.8362 0.713867 12.3352 2.15727 12.4244 3.97132L12.4286 4.14244H16.7143C17.1877 4.14244 17.5714 4.52619 17.5714 4.99958C17.5714 5.43916 17.2406 5.80145 16.8143 5.85096L16.7143 5.85672H15.9866L15.4772 12.1414C14.9232 11.9567 14.3304 11.8567 13.7143 11.8567C10.6373 11.8567 8.14287 14.3511 8.14287 17.4282C8.14287 18.9255 8.73355 20.2849 9.69461 21.286L6.22853 21.2853C4.55406 21.2853 3.16009 19.9998 3.02476 18.3308L2.01259 5.85672H1.28573C0.846158 5.85672 0.483868 5.52583 0.434355 5.09954L0.428589 4.99958C0.428589 4.56001 0.759481 4.19772 1.18577 4.14821L1.28573 4.14244H5.57145C5.57145 2.24889 7.10647 0.713867 9.00002 0.713867ZM12.1979 15.306L12.1386 15.2564C11.9924 15.1551 11.7974 15.1551 11.6512 15.2564L11.5918 15.306L11.5423 15.3653C11.441 15.5115 11.441 15.7066 11.5423 15.8527L11.5918 15.9121L13.1086 17.4287L11.5941 18.9431L11.5445 19.0025C11.4432 19.1487 11.4432 19.3437 11.5445 19.4899L11.5941 19.5492L11.6534 19.5988C11.7996 19.7001 11.9946 19.7001 12.1408 19.5988L12.2002 19.5492L13.7146 18.0347L15.2309 19.5512L15.2903 19.6007C15.4364 19.702 15.6315 19.702 15.7777 19.6007L15.837 19.5512L15.8866 19.4918C15.9879 19.3457 15.9879 19.1506 15.8866 19.0044L15.837 18.9451L14.3206 17.4287L15.8394 15.9121L15.889 15.8528C15.9902 15.7066 15.9902 15.5115 15.889 15.3654L15.8394 15.306L15.78 15.2564C15.6339 15.1552 15.4388 15.1552 15.2927 15.2564L15.2333 15.306L13.7146 16.8227L12.1979 15.306ZM9.00002 2.42815C8.09628 2.42815 7.35588 3.12748 7.29043 4.0145L7.28573 4.14244H10.7143L10.7096 4.0145C10.6442 3.12748 9.90376 2.42815 9.00002 2.42815Z" fill="#FC2807" />
                      </svg>
                    </button>
                  </div>
                </div>

                {/* Name field */}
                <div className="form-row-item subAcc-full-width">
                  <div className="form-row-item-title">
                    {t('Name')}<span className="required-asterisk">*</span>
                  </div>
                  <TextField
                    fullWidth
                    placeholder={t('enterName')}
                    value={selectedSubAccountant.name}
                    onChange={(e) => setSelectedSubAccountant({ ...selectedSubAccountant, name: e.target.value })}
                    variant="standard"

                    InputProps={{
                      disableUnderline: true,
                      sx: {
                        height: '48px',
                        padding: '0 10px',
                        borderRadius: '8px',
                        background: '#F5F5F6',
                        border: '1px solid transparent',
                        '&:focus-within': {
                          border: '2px solid var(--Foundation-Blue-Normal, #304FFF)',
                        },
                      },
                    }}
                    inputProps={{
                      style: {
                        textAlign: direction === 'ltr' ? 'left' : 'right',
                      },
                    }}
                  />
                </div>

                {/* SubAccountantId field */}
                <div className="form-row-item subAcc-full-width">
                  <div className="form-row-item-title">
                    {t('SubAccountantId')}<span className="required-asterisk">*</span>
                  </div>
                  <TextField
                    fullWidth
                    placeholder={t('enterSubAccountantId')}
                    value={selectedSubAccountant.subAccountantId}
                    onChange={(e) => setSelectedSubAccountant({ ...selectedSubAccountant, subAccountantId: e.target.value })}
                    variant="standard"
                    disabled={!isNewSubAccountant}
                    InputProps={{
                      disableUnderline: true,
                      sx: {
                        height: '48px',
                        padding: '0 10px',
                        borderRadius: '8px',
                        background: '#F5F5F6',
                        border: '1px solid transparent',
                        '&:focus-within': {
                          border: '2px solid var(--Foundation-Blue-Normal, #304FFF)',
                        },
                      },
                    }}
                    inputProps={{
                      style: {
                        textAlign: direction === 'ltr' ? 'left' : 'right',
                      },
                    }}
                  />
                </div>

                {/* PhoneNumber field */}
                <div className="form-row-item subAcc-full-width">
                  <div className="form-row-item-title">
                    {t('phoneNumber')}
                  </div>
                  <TextField
                    fullWidth
                    placeholder={t('enterPhoneNumber')}
                    value={selectedSubAccountant.phoneNumber}
                    onChange={(e) => setSelectedSubAccountant({ ...selectedSubAccountant, phoneNumber: e.target.value })}
                    variant="standard"

                    InputProps={{
                      disableUnderline: true,
                      sx: {
                        height: '48px',
                        padding: '0 10px',
                        borderRadius: '8px',
                        background: '#F5F5F6',
                        border: '1px solid transparent',
                        '&:focus-within': {
                          border: '2px solid var(--Foundation-Blue-Normal, #304FFF)',
                        },
                      },
                    }}
                    inputProps={{
                      style: {
                        textAlign: direction === 'ltr' ? 'left' : 'right',
                      },
                    }}
                  />
                </div>

                {/* Email field */}
                <div className="form-row-item subAcc-full-width">
                  <div className="form-row-item-title">
                    {t('email')}<span className="required-asterisk">*</span>
                  </div>
                  <TextField
                    fullWidth
                    placeholder={t('enterEmail')}
                    value={selectedSubAccountant.email}
                    onChange={(e) => setSelectedSubAccountant({ ...selectedSubAccountant, email: e.target.value })}
                    variant="standard"

                    InputProps={{
                      disableUnderline: true,
                      sx: {
                        height: '48px',
                        padding: '0 10px',
                        borderRadius: '8px',
                        background: '#F5F5F6',
                        border: '1px solid transparent',
                        '&:focus-within': {
                          border: '2px solid var(--Foundation-Blue-Normal, #304FFF)',
                        },
                      },
                    }}
                    inputProps={{
                      style: {
                        textAlign: direction === 'ltr' ? 'left' : 'right',
                      },
                    }}
                  />
                </div>

                {
                  isNewSubAccountant &&
                  <div className="form-row-item subAcc-full-width">
                    <div className="form-row-item-title">
                      {t('enterPassword')}<span className="required-asterisk">*</span>
                    </div>
                    <TextField
                      fullWidth
                      placeholder={t('enterPassword')}
                      value={selectedSubAccountant.password}
                      onChange={(e) => setSelectedSubAccountant({ ...selectedSubAccountant, password: e.target.value })}
                      variant="standard"
                      type="password"
                      InputProps={{
                        disableUnderline: true,
                        sx: {
                          height: '48px',
                          padding: '0 10px',
                          borderRadius: '8px',
                          background: '#F5F5F6',
                          border: '1px solid transparent',
                          '&:focus-within': {
                            border: '2px solid var(--Foundation-Blue-Normal, #304FFF)',
                          },
                        },
                      }}
                      inputProps={{
                        style: {
                          textAlign: direction === 'ltr' ? 'left' : 'right',
                        },
                      }}
                    />
                  </div>
                }

                {/* Buttons */}
                <div className="sub-button-container">
                  <button className="sub-close-subAcc-content-button" onClick={handleClose}>
                    {t('close')}
                  </button>
                  <button className={`sub-add-subAcc-content-button ${!allSubAccountantFieldsFilled ? "button-disabled" : ""}`} disabled={!allSubAccountantFieldsFilled} onClick={isNewSubAccountant ? handleAddSubAccountant : handleSaveChanges}>
                    {t('save')}
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}

        <CustomDeleteConfirmationModal
          isVisible={isModalVisible}
          onClose={() => setIsModalVisible(false)}
          onDelete={() => handleDelete(selectedSubAccountant.subAccountantId)}
        />
        <ToastContainer />
        {loading && (
          <div className="loader-container">
            <PuffLoader
              size={100}        // Larger size for better visibility
              loading={loading}
            />
          </div>
        )}
      </div>
    </>
  );
};

export default SubAccountant;