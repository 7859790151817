import config from '../config';
import selectedEnvironment from '../env';
const API_URL = `${config[selectedEnvironment].API_URL}/ClientDocumentCounts`;
const DeleteClientDocumentCountByCaseId = async (caseId, token) => {
    try {
        const response = await fetch(`${API_URL}/Delete/${caseId}`, {
            method: 'DELETE',
            headers: {
                "Authorization": 'Bearer ' + token,
                "Content-Type": "application/json",
            },
        });

        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }
        return response;
    } catch (error) {
    }
};

const getClientDocumentCountByCaseId = async (caseId, token) => {
    try {
        const response = await fetch(`${API_URL}/GetByCaseId/${caseId}`, {
            method: 'GET',
            headers: {
                "Authorization": 'Bearer ' + token,
            },
        });

        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }
        return await response.json();
    } catch (error) {
        console.error("Error retrieving client document count by case ID:", error);
    }
};

const getClientDocumentCountsByAccountantId = async (accountantId, token, page, pageSize,isActive = true) => {
    try {
        const response = await fetch(`${API_URL}/GetByAccountantId`, {
            method: 'POST',
            headers: {
                "Authorization": 'Bearer ' + token,
                "Content-Type": "application/json",
            },
            body: JSON.stringify({ accountantId, page, pageSize,isActive })
        });

        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }
        return await response.json();
    } catch (error) {
        console.error("Error retrieving client document counts by accountant ID:", error);
    }
};

const getAllClientDocumentCounts = async (token) => {
    try {
        const response = await fetch(`${API_URL}/GetAll`, {
            method: 'GET',
            headers: {
                "Authorization": 'Bearer ' + token,
            },
        });

        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }
        return await response.json();
    } catch (error) {
        console.error("Error retrieving all client document counts:", error);
    }
};

const getClientDocumentCountByDocumentType = async (caseId, documentType, token) => {
    try {
        const response = await fetch(`${API_URL}/GetByDocumentType/${caseId}/${documentType}`, {
            method: 'GET',
            headers: {
                "Authorization": 'Bearer ' + token,
            },
        });

        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }
        return await response.json();
    } catch (error) {
        console.error(`Error retrieving client document count by document type: ${documentType}`, error);
    }
};

const removeDocumentByTypeAndId = async (caseId, accountantId, documentType, documentId, token) => {
    try {
        const response = await fetch(`${API_URL}/RemoveDocumentByTypeAndId`, {
            method: 'POST',
            headers: {
                "Authorization": 'Bearer ' + token,
                "Content-Type": "application/json",
            },
            body: JSON.stringify({ CaseId: caseId, DocumentType: documentType, DocumentId: documentId,  AccountantId: accountantId}),
        });

        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }
        return response;
    } catch (error) {
        console.error("Error removing document by type and ID:", error);
    }
};

const addDocumentByTypeAndId = async (caseId, accountantId, documentType, documentId, token) => {
    try {
        const response = await fetch(`${API_URL}/AddDocumentByTypeAndId`, {
            method: 'POST',
            headers: {
                "Authorization": 'Bearer ' + token,
                "Content-Type": "application/json",
            },
            body: JSON.stringify({ CaseId: caseId, DocumentType: documentType, DocumentId: documentId,  AccountantId: accountantId}),
        });

        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }
        return response;
    } catch (error) {
        console.error("Error removing document by type and ID:", error);
    }
};

const removeDocumentsByTypeAndIds = async (caseId, accountantId, documentType, documentIds, token) => {
    try {
        const response = await fetch(`${API_URL}/RemoveDocumentsByTypeAndIds`, {
            method: 'POST',
            headers: {
                "Authorization": 'Bearer ' + token,
                "Content-Type": "application/json",
            },
            body: JSON.stringify({ CaseId: caseId, DocumentType: documentType, DocumentIds: documentIds, AccountantId: accountantId }),
        });

        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }
        return response;
    } catch (error) {
        console.error("Error removing documents by type and IDs:", error);
    }
};

const addDocumentsByTypeAndIds = async (caseId, accountantId, documentType, documentIds, token) => {
    try {
        const response = await fetch(`${API_URL}/AddDocumentsByTypeAndIds`, {
            method: 'POST',
            headers: {
                "Authorization": 'Bearer ' + token,
                "Content-Type": "application/json",
            },
            body: JSON.stringify({ CaseId: caseId, DocumentType: documentType, DocumentIds: documentIds, AccountantId: accountantId }),
        });

        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }
        return response;
    } catch (error) {
        console.error("Error adding documents by type and IDs:", error);
    }
};

const getClientDocumentCountsBySubAccountantId = async (accountantId,subAccountantId ,token, page, pageSize,isActive = true) => {
    try {
        const response = await fetch(`${API_URL}/GetBySubAccountantId`, {
            method: 'POST',
            headers: {
                "Authorization": 'Bearer ' + token,
                "Content-Type": "application/json",
            },
            body: JSON.stringify({ subAccountantId, accountantId,page, pageSize,isActive })
        });

        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }
        return await response.json();
    } catch (error) {
        console.error("Error retrieving client document counts by accountant ID:", error);
    }
};

const ClientDocumentCountService = {
    DeleteClientDocumentCountByCaseId,
    getClientDocumentCountByCaseId,
    getClientDocumentCountsByAccountantId,
    getAllClientDocumentCounts,
    getClientDocumentCountByDocumentType,
    removeDocumentByTypeAndId,
    addDocumentByTypeAndId,
    removeDocumentsByTypeAndIds,
    addDocumentsByTypeAndIds,
    getClientDocumentCountsBySubAccountantId
};

export default ClientDocumentCountService;
