import React, { useState ,useEffect} from 'react';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import { debounce } from 'lodash';
import {
    TextField
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import updateLocale from 'dayjs/plugin/updateLocale';
// Import Day.js locales
import 'dayjs/locale/en';
import 'dayjs/locale/ar';
import 'dayjs/locale/he';

// Add necessary plugins for formatting and localization
dayjs.extend(customParseFormat);
dayjs.extend(localizedFormat);
dayjs.extend(updateLocale);
function CustomYearDatePicker({ date, onDateChange, label,height = '48px' }) {
    const [open, setOpen] = useState(false);
    const [views, setViews] = useState(['year']); // Focus on the year view
    const [inputValue, setInputValue] = useState(date ? dayjs(date).format('MM/YYYY') : '');
    const { i18n } = useTranslation();
    const [direction, setDirection] = useState('ltr'); // Default to 'ltr'

    useEffect(() => {
        // Update Day.js locale based on i18n.language
        dayjs.locale(i18n.language);

        // Set text direction based on language
        if (i18n.language === 'he' || i18n.language === 'ar') {
            setDirection('rtl');
        } else {
            setDirection('ltr');
        }
    }, [i18n.language]);
    const handleOpen = () => {
        setOpen(true);
        setViews(['year']); // Open with the year view
    };

    const handleClose = () => setOpen(false);

    const handleYearDateChange = (newValue) => {
        setOpen(false); // Close the picker after year selection
        onDateChange(newValue.toDate());
    };
    const handleInputChange = debounce((e) => {
        const newValue = e.target.value;
        setInputValue(newValue);
        const cleanValue = newValue.replace(/[^\d]/g, '');
        if (dayjs(cleanValue, 'YYYY', true).isValid()) {
            onDateChange(dayjs(cleanValue, 'YYYY').toDate());
        }
    }, 800);
    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
                views={views}
                open={open}
                onOpen={handleOpen}
                onClose={handleClose}
                label={label}
                value={date}
                onYearChange={handleYearDateChange}
                format="YYYY"
                placeholder="YYYY"
                className='custom-input-box-sizing'
                // slotProps={{
                //     textField: {
                //         onKeyDown: (event) => {
                //             handleInputChange(event);  // Call handleInputChange directly
                //         },
                //     },
                // }}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        value={inputValue}
                        onChange={handleInputChange} // Ensure this handles input change directly
                    />
                )}
                slotProps={{
                    textField: {
                        InputProps: {
                            disableUnderline: true,
                            className: `custom-input-box-sizing ${direction === 'ltr' ? 'ltr-input' : 'rtl-input'}`,
                            sx: {
                                height: height,
                                padding: '0 10px',
                                borderRadius: 'var(--Spacing-md, 8px)',
                                background: 'var(--bg-input, #F5F5F6)',
                                border: '1px solid transparent',
                            },
                        },
                        inputProps: {
                            style: {
                                textAlign: direction === 'ltr' ? 'left' : 'right',
                            },
                        },
                        onKeyDown: (event) => {
                            handleInputChange(event);  // Call handleInputChange directly
                        },
                    },
                }}
            />
        </LocalizationProvider>
    );
}

export default CustomYearDatePicker;
