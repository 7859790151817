import React from 'react';
import Lottie from "lottie-react";
import passwordResetAnimation from '../../../assests/images/Animations/PasswordReset.json';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import Button from '@mui/material/Button';
import styles from './PasswordChanged.module.css';

export default function PasswordChanged({ visible, onClose, t }) {
    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: passwordResetAnimation,
    };

    return (
        <Dialog open={visible} onClose={onClose} maxWidth="sm" fullWidth classes={{ paper: styles.dialog }}>
            <DialogContent className={styles.dialogContent}>
                <div className={styles.frame2393}>
                    <div className={styles.animationsContainer}>
                        <Lottie animationData={passwordResetAnimation} loop style={{ height: 300, width: 300 }} />
                    </div>
                    <div className={styles.frame2392}>
                        <div className={styles.frame46}>
                            <p className={styles.passwordChangedSuccessfully}>
                                {t("PasswordChanged")} <span className={styles.successfully}>{t("Successfully")}</span>
                            </p>
                            <p className={styles.usePasswordSuccessfully}>
                                {t("UsePasswordSuccessfully")}
                            </p>
                        </div>
                    </div>
                </div>
                <Button 
                    variant="contained" 
                    fullWidth 
                    onClick={onClose} 
                    className={styles.backToLoginButton}
                >
                    {t("BackToLogin")}
                </Button>
            </DialogContent>
        </Dialog>
    );
}
