// import ChatService from './chat.service';
//http://192.168.1.32
//http://localhost
//https://accounting-manager.com
import { PermissionsContext } from '../Contexts/Permissions';
import axios from 'axios';
import config from '../config';
import selectedEnvironment from '../env';
const API_URL = `${config[selectedEnvironment].API_URL}/Auth`;



// Log in a user
const login = async (accountantId, password) => {
  return axios.post(API_URL + '/login', {
    username: accountantId,
    password,
    userType: 2,
    IsFromApplication:false
  }).then( async (response) => {
    const userType = JSON.stringify(response.data.userType);
    const user = JSON.stringify(response.data.user);
    const token = JSON.stringify(response.data.token);
    localStorage.setItem('user', user);
    localStorage.setItem('token', token);
    localStorage.setItem('userType', userType);
      // if(response.data.userType === 8){
      //    await updateFriendsList(response.data.user.accountantId,response.data.user.subAccountantId)
      // }
      // const UID =  response.data.userType === 2  ? response.data.user.accountantId : response.data.user.subAccountantId;
      // await ChatService.InitChat();
      // await ChatService.Chatlogin(UID);
    return response.data;
  })
    .catch(error => {
      const errorMessage = error.response && error.response.data ? error.response.data : 'Login failed';
      throw errorMessage;
    });
};

// Log out the current user
const logout = () => {
  try {
    localStorage.clear();
  } catch (error) {
    // Error clearing the localStorage
  }
};

const sendOtp = async (Username, UserType) => {
  const response = await fetch(`${API_URL}/forgot-password`, {
      method: 'POST',
      headers: {
          "Content-Type": "application/json"
      },
      body: JSON.stringify({ Username, UserType })
  });

  return response;
};

const verifyOtp = async (Username, otp,UserType) => {
  const response = await fetch(`${API_URL}/verify-otp`, {
      method: 'POST',
      headers: {
          "Content-Type": "application/json"
      },
      body: JSON.stringify({ Username, UserType,otp })
  });

  return response;
};

const resetPassword = async (UserId, NewPassword,UserType) => {
  const response = await fetch(`${API_URL}/ResetPassword`, {
      method: 'PUT',
      headers: {
          "Content-Type": "application/json"
      },
      body: JSON.stringify({ UserId, UserType,NewPassword })
  });

  return response;
};

const CheckTokenValidation = async (token) => {
  const response = await fetch(`${API_URL}/CheckTokenValidation`, {
      method: 'POST',
      headers: {
          "Content-Type": "application/json"
      },
      body: JSON.stringify({ Token: token })
  });

  if (response.ok) {
      const data = await response.json();
      return data;
  } else {
      throw new Error('Failed to validate token');
  }
};

const AuthService = {
  login,
  logout,
  sendOtp,
  verifyOtp,
  resetPassword,
  CheckTokenValidation
};

export default AuthService;
