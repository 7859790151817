const API_URL = 'https://www.accounting-manager.com:5001/run-script';

const generateFooterHtml = (backgroundColor = '#f8f8f8') => {
    return `
<html>
<head>
<meta charset="UTF-8">
<style>
    footer {
        width: 100%;
        background-color: ${backgroundColor};
        text-align: center;
        box-shadow: 0 -1px 10px 0 rgba(0, 0, 0, 0.1);
        font-family: 'Noto Sans Hebrew', Arial, sans-serif;
    }

    .footer-details {
        font-family: 'Inter', sans-serif;
        font-size: 16px;
        font-style: normal;
        font-weight: 300;
        line-height: normal;
        text-align: center;
    }

    .footer-Xtram {
        margin-right: 10px;
        margin-left: 10px;
        font-weight: bold;
        font-family: 'Inter', sans-serif;
        font-size: 16px;
        font-style: normal;
        line-height: normal;
        text-align: center;
    }

    .footer {
        background-color: #c5c7c9;
        text-align: left;
        font-size: 18px;
        color: #333;

        display: -webkit-box;            /* Old syntax for older WebKit browsers */
        display: -moz-box;               /* Old syntax for Firefox */
        display: -ms-flexbox;            /* Old syntax for IE10 */
        display: -webkit-flex;           /* Newer WebKit syntax */
        display: flex;                   /* Standard syntax */

        -webkit-box-align: center;       /* Align items vertically for WebKit */
        -moz-box-align: center;          /* Align items vertically for older Firefox */
        -ms-flex-align: center;          /* Align items vertically for IE10 */
        align-items: center;             /* Standard syntax for modern browsers */

        height: 50px;
        line-height: 50px;               /* Vertically center text as a fallback */
    }
</style>
</head>
<body>
    <footer class='footer'>
        <p class='footer-Xtram'>Xtram</p>
        <p class='footer-details'>מסמך זה הופק במערכת</p>
    </footer>
</body>
</html>
    `;
};

const convertHtmlToPdf = async (htmlContent,token) => {
    const footer_html = generateFooterHtml("#f8f8f8");
    const formData = new URLSearchParams();
    formData.append('html_content', htmlContent);
    formData.append('footer_html', footer_html);
    const response = await fetch(`${API_URL}/convert_html_to_pdf`, {
        method: 'POST',
        headers: {
            "Content-Type": "application/x-www-form-urlencoded",
            "Authorization": 'Bearer ' + token,
        },
        body: formData.toString()
    });

    return response;
};


const PythonService = {
    convertHtmlToPdf,
    generateFooterHtml
};

export default PythonService;
