import React, { useRef, useState, useMemo, useEffect } from 'react';
import { DataGrid, GridToolbar, useGridApiRef } from '@mui/x-data-grid';
import { Box, Button } from '@mui/material';
import { styled } from '@mui/material/styles';

import { useTranslation } from 'react-i18next';



// Styled DataGrid component
const StyledDataGrid = styled(DataGrid)(({ theme }) => ({
    width: '100%',
    '& .MuiDataGrid-columnHeaders': {
        opacity: 0.7,
        backgroundColor: '#BFC8FF !important',
    },
    '& .MuiDataGrid-columnHeader': {
        backgroundColor: '#BFC8FF !important',
    },
    '& .MuiDataGrid-columnHeaderTitle': {
        color: 'var(--Foundation-Blue-Normal, #304FFF)',
        textAlign: 'center',
        fontFamily: 'Montserrat',
        fontSize: '14px',
        fontWeight: 600,
        lineHeight: 'normal',
    },
    '& .MuiDataGrid-row': {
        borderTop: '1px solid rgba(191, 191, 193, 0.56)',
        backgroundColor: 'rgba(234, 237, 255, 0.32)',
    },
    '& .MuiDataGrid-cell': {
        whiteSpace: 'normal',
        wordWrap: 'break-word',
    },
    '& .MuiDataGrid-cell:focus': {
        outline: 'none',
    },
    '& .disableUser': {
        pointerEvents: 'none',
        opacity: 0.5,
    },
}));

const SubAccountantsDataGrid = ({
    filteredSubAccountants,
    handleViewButtonClick,
}) => {

    const { i18n, t } = useTranslation();
    const [selectedLanguage, setSelectedLanguage] = useState(localStorage.getItem('language') || 'he');

    // const initChatConf = async () => {
    //   await ChatService.InitChat();

    // }
    useEffect(() => {
        // Check for saved language in localStorage
        const currentLanguage = localStorage.getItem('language') || 'he'; // Default to 'en' if no language is stored

        i18n.changeLanguage(currentLanguage);
        // initChatConf();
    }, []);

    useEffect(() => {
        const currentLanguage = localStorage.getItem('language') || 'he';
        setSelectedLanguage(currentLanguage);
    }, [i18n.language]);
  
    const columns = [
        {
            field: 'subAccountantId',
            headerName: t('SubAccountantId'),
            headerAlign: 'center',
            align: 'center',
            flex: 1,
        },
        {
            field: 'name',
            headerName: t('name'),
            headerAlign: 'center',
            align: 'center',
            flex: 1,
        },
        {
            field: 'phoneNumber',
            headerName: t('phoneNumber'),
            headerAlign: 'center',
            align: 'center',
            flex: 1,
            
        },
        {
            field: 'email',
            headerName: t('email'),
            headerAlign: 'center',
            align: 'center',
            flex: 1,
        },
        {
            field: 'view',
            headerName: t('view'),
            headerAlign: 'center',
            align: 'center',
            width: 80, // Fixed width for the 'View' column
            flex: 2,
            sortable: false,
            filterable: false,
            renderCell: (params) => (
                <Button
                    variant="contained"
                    color="primary"
                    size="small"
                    onClick={() => handleViewButtonClick(params.row)}
                    style={{ minWidth: '60px', padding: '4px 8px' }}
                >
                    {t('view')}
                </Button>
            ),
        },
    ];


    const apiRef = useGridApiRef();
    const [selectedRows, setSelectedRows] = useState([]);

    // Handlers for sorting and filtering
    const handleSortModelChange = (model) => {
        // Implement your sort logic here
    };

    const handleFilterModelChange = (model) => {
        // Implement your filter logic here
    };

    const handleEdit = (params) => {
        // Implement your edit logic here
    };

    return (
        <Box
            sx={{
                height: '50vh',
                width: '100%',
                boxSizing: 'border-box',
                backgroundColor: '#fff',
                direction: 'ltr',
            }}
        >

            <StyledDataGrid
                apiRef={apiRef}
                rows={filteredSubAccountants}
                columns={columns}
                pageSize={25} // Max rows set to 25
                rowsPerPageOptions={[10, 25, 50]}
                disableRowSelectionOnClick
                onSelectionModelChange={(ids) => setSelectedRows(ids)}
                getRowClassName={(params) => params.row.rowClassName || ''}
                getRowId={(row) => row.subAccountantId}
                onCellEditCommit={handleEdit}
                selectionModel={selectedRows}
                components={{ Toolbar: GridToolbar }}
                componentsProps={{
                    toolbar: {
                        showQuickFilter: true,
                    },
                  
                }}
                pagination
                onSortModelChange={handleSortModelChange}
                onFilterModelChange={handleFilterModelChange}
            />

        </Box>
    );
};

export default SubAccountantsDataGrid;
