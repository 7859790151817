import React, { forwardRef, useState, useEffect } from 'react';
import debounce from 'lodash.debounce';

const DateInput = forwardRef(({ value, onClick, onChange }, ref) => {
  const [inputValue, setInputValue] = useState(value);

  useEffect(() => {
    setInputValue(value);
  }, [value]);

  const debouncedOnChange = debounce((e) => {
    onChange(e);
  }, 500);

  const handleInputChange = (e) => {
    setInputValue(e.target.value);
    debouncedOnChange(e);
  };

  return (
    <input
      type="text"
      ref={ref}
      value={inputValue}
      onClick={onClick}
      onChange={handleInputChange}
      onKeyDown={(e) => e.stopPropagation()} // Prevents keyboard event propagation to the date picker
      className="edit-custom-input"
      placeholder="dd-MM-yyyy"
    />
  );
});

export default DateInput;
