// OtherDocumentDownloadModal.js

import React, { useState } from 'react';
import { Modal, TextField } from '@mui/material';
import CustomDatePicker from '../../CustomDatePicker/CustomDatePicker';
import { useTranslation } from 'react-i18next';
import AutocompleteSelect from '../../Widgets/AutocompleteSelect/AutocompleteSelect';
const OtherDocumentDownloadModal = ({
    modalVisible,
    closeModal,
    t,
    direction,
    selectOtherDocumentsOptions,
    handleDownloadDocuments,
}) => {
    const [downloadAmount, setDownloadAmount] = useState('');
    const [selectedDocumentTypes, setSelectedDocumentTypes] = useState([]);
    const [downloadStartDate, setDownloadStartDate] = useState(null);
    const [downloadEndDate, setDownloadEndDate] = useState(null);

    const handleDownloadStartDateChange = (date) => {
        setDownloadStartDate(date);
    };

    const handleDownloadEndDateChange = (date) => {
        setDownloadEndDate(date);
    };

    const handleDownload = () => {
        // Prepare data for downloading documents
        const downloadData = {
            documentTypes: selectedDocumentTypes,
            startDate: downloadStartDate,
            endDate: downloadEndDate,
        };
        handleDownloadDocuments(downloadData);
        
        closeModal();
    };

    const inputPropsStyle = {
        disableUnderline: true,
        className: `custom-box-sizing-border-box ${direction}`,
        sx: {
            height: '48px',
            padding: '0 10px',
            borderRadius: '8px',
            background: '#F5F5F6',
            border: '1px solid transparent',
            '&:focus-within': {
                border: '2px solid var(--Foundation-Blue-Normal, #304FFF)',
            },
        },
    };

    const inputStyle = {
        style: {
            textAlign: direction === 'ltr' ? 'left' : 'right',
        },
    };

    const formattedDocumentTypeOptions = selectOtherDocumentsOptions.map((option) => ({
        value: option.name,
        label: option.name,
    }));

    const allFieldsFilled =
        downloadAmount &&
        selectedDocumentTypes.length > 0 &&
        downloadStartDate &&
        downloadEndDate;

    return (
        modalVisible && (
            <div className="document-download-modal-overlay" onClick={closeModal}>
                <div
                    className="document-download-modal"
                    onClick={(e) => e.stopPropagation()}
                >
                    <h4 className="download-report-title">{t('DownloadDocuments')}</h4>
                    <div className={`document-download-modal-content ${direction}`}>
                        <div className="form-row" style={{ width: '100%', gap: '50px' }}>


                            {/* Document Type Select */}
                            <div className="form-row-item">
                                <div className="form-row-item-title">
                                    {t('Document Type')}
                                    <span className="required-asterisk">*</span>
                                </div>
                                <AutocompleteSelect
                                    options={formattedDocumentTypeOptions}
                                    selectedValues={formattedDocumentTypeOptions.filter((option) =>
                                        selectedDocumentTypes.includes(option.value)
                                    )}
                                    onChange={(e, newValue) => {
                                        const selectedValues = newValue
                                            ? newValue.map((option) => option.value)
                                            : [];
                                        setSelectedDocumentTypes(selectedValues);
                                    }}
                                    placeholder={t('Document Type')}
                                    isMultiple={true}
                                    width="100%"
                                />
                            </div>
                        </div>
                        <div className="form-row" style={{ width: '100%', gap: '50px' }}>

                            {/* Start Date Picker */}
                            <div className="form-row-item">
                                <div className="form-row-item-title">
                                    {t('From')}
                                    <span className="required-asterisk">*</span>
                                </div>
                                <CustomDatePicker
                                    date={downloadStartDate}
                                    onDateChange={handleDownloadStartDateChange}
                                    height="48px"
                                />
                            </div>
                            {/* End Date Picker */}
                            <div className="form-row-item">
                                <div className="form-row-item-title">
                                    {t('To')}
                                    <span className="required-asterisk">*</span>
                                </div>
                                <CustomDatePicker
                                    date={downloadEndDate}
                                    onDateChange={handleDownloadEndDateChange}
                                    height="48px"
                                />
                            </div>


                        </div>
                    </div>
                    <div className="download-button-row">
                        <div
                            className={`document-download-buttons`}
                            onClick={handleDownload}
                        >
                            {t('Download')}
                        </div>
                        <div
                            className="document-download-cancel-buttons"
                            onClick={closeModal}
                        >
                            {t('close')}
                        </div>
                    </div>
                </div>
            </div>
        )
    );
};

export default OtherDocumentDownloadModal;
