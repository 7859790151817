// DocumentDraggableDialog.js
import React, { useState, useEffect, useRef } from 'react';
import {
    Button,
    TextField,
    Autocomplete,
    FormControl,
    Select,
    MenuItem,
    Portal,
    Popper
} from '@mui/material';
import Draggable from 'react-draggable';
import { useTranslation } from 'react-i18next';
import { enUS, arSA, he } from 'date-fns/locale'; // You can remove this if not used elsewhere
import CustomDatePicker from '../../CustomDatePicker/CustomDatePicker';
function DocumentDraggableDialog(props) {
    const { t, i18n } = useTranslation();
    const {
        open,
        onClose,
        data,
        onSave,
        accountCardOptions,
        creditAccountOptions,
        debitAccountOptions,
        sortCodeIdOptions,
        Constants,
        type,
        direction,
    } = props;
    const [formData, setFormData] = useState(data);
    const [container] = useState(() => document.createElement('div'));
    const nodeRef = useRef(null);
    // const [boxSize, setBoxSize] = useState({ w: 0, h: 0 });

    // useEffect(() => {

    //     setBoxSize({
    //         w: document.querySelector('.container').clientWidth / -2,
    //         h: document.querySelector('.container').clientHeight / -2
    //     });

    // }, []);
    // **Calculate the center position**
    const [modalPosition, setModalPosition] = useState({ x: '50%', y: '50%' });
    const formattedAccountCardOptions = accountCardOptions.map((option) => ({
        value: option.cardId,
        label: option.accountCaseId
            ? `${option.name} - ${option.accountCaseId}`
            : `${option.name}`,
        name: option.name,
        accountCaseId: option.accountCaseId || '',
        cardId: option.cardId,
    }));
    useEffect(() => {
        setFormData(data);
    }, [data]);

    useEffect(() => {
        if (open) {
            document.body.appendChild(container);

            // Calculate center position when the modal is opened
            const updateModalPosition = () => {
                const modalWidth = 700; // Adjust to match minWidth or actual width
                const modalHeight = nodeRef.current ? nodeRef.current.offsetHeight : 0;
                const screenWidth = window.innerWidth;
                const screenHeight = window.innerHeight;

                const x = (screenWidth - modalWidth) / 2;
                const y = (screenHeight - modalHeight) / 2;

                setModalPosition({ x, y });
            };

            // Update position after the modal has rendered
            setTimeout(updateModalPosition, 0);

            // Update position on window resize
            window.addEventListener('resize', updateModalPosition);

            return () => {
                try {
                    document.body.removeChild(container);
                } catch (e) {
                    // Ignore
                }
                window.removeEventListener('resize', updateModalPosition);
            };
        } else {
            try {
                document.body.removeChild(container);
            } catch (e) {
                // Ignore
            }
        }
    }, [open, container]);

    const handleChange = (field, value) => {
        const updatedData = { ...formData, [field]: value };
        

        // Replicate logic from processRowUpdate
        if (field === 'accountCodeId') {
            
            
            

            const accountCard = accountCardOptions.find(
                (option) => option.cardId === value
            );
            updatedData.accountCodeName = accountCard ? accountCard.name : '';
            updatedData.accountCaseId = accountCard ? accountCard.accountCaseId : '';

            if (type === 'Incomes') {
                

                updatedData.debitAccount = value;
                

            } else {
                updatedData.creditAccount = value;
            }
        }

        if (field === 'sortCodeId') {
            const selectedOption = sortCodeIdOptions.find(
                (option) => option.sortCodeId === value
            );

            if (selectedOption) {
                const nameWithoutParentheses = selectedOption.sortCodeName
                    .replace(/\s*\(.*?\)\s*/, '')
                    .trim();

                const match = selectedOption.sortCodeName.match(/\((\d+)%?/);
                let customVat = match ? parseInt(match[1], 10) : null;

                const sortCode = selectedOption;
                

                if (Constants.SortCodesWith66Vat.includes(nameWithoutParentheses)) {
                    updatedData.vat = 66;
                } else if (
                    Constants.SortCodesWithZeroVat.includes(nameWithoutParentheses) ||
                    sortCode.sortCodeVatType === 0
                ) {
                    updatedData.vat = 0;
                } else if (Constants.SortCodesWith16Vat.includes(nameWithoutParentheses)) {
                    updatedData.vat = 16;
                } else if (customVat !== null) {
                    updatedData.vat = customVat === 66 ? 66 : customVat;
                } else {
                    updatedData.vat = 100;
                }

                // Update creditAccount and debitAccount only if there are values in sortCode
                if (sortCode.creditAccount) {
                    updatedData.creditAccount = sortCode.creditAccount;
                }
                if (sortCode.debitAccount) {
                    updatedData.debitAccount = sortCode.debitAccount;
                }
                const accountCard = accountCardOptions.find(
                    (item) => item.cardId === (type === 'Incomes' ? updatedData.debitAccount : updatedData.creditAccount)
                );

                
                

                // Set account code details only if accountCard is found
                if (accountCard) {
                    updatedData.accountCodeName = accountCard.name;
                    updatedData.accountCodeId = accountCard.cardId;
                    updatedData.accountCaseId = accountCard.accountCaseId;
                } else {
                    // Optional: set to empty strings if accountCard is not found
                    updatedData.accountCodeName = '';
                    updatedData.accountCodeId = '';
                    updatedData.accountCaseId = '';
                }

            }
        }

        if (field === 'creditAccount' || field === 'debitAccount') {
            

            const accountCard = accountCardOptions.find(
                (option) => option.cardId === value
            );

            updatedData.accountCodeName = accountCard ? accountCard.name : '';
            updatedData.accountCodeId = accountCard ? accountCard.cardId : '';
            updatedData.accountCaseId = accountCard ? accountCard.accountCaseId : '';
        }
        

        setFormData(updatedData);
    };

    const handleSave = () => {
        onSave(formData);
        onClose();
    };

    // Input style as per your code snippet
    const inputPropsStyle = {
        disableUnderline: true,
        className: `custom-input-box-sizing ${direction === 'ltr' ? 'ltr-input' : 'rtl-input'}`,
        sx: {
            height: '48px',
            padding: '0 10px',
            borderRadius: '8px',
            background: '#F5F5F6',
            border: '1px solid transparent',
            '&:focus-within': {
                border: '2px solid var(--Foundation-Blue-Normal, #304FFF)',
            },
        },
    };

    const inputStyle = {
        style: {
            textAlign: direction === 'ltr' ? 'left' : 'right',
        },
    };

    if (!open) return null;

    return (
        <Portal container={container}>
            <Draggable handle=".handle" nodeRef={nodeRef} >

                <div
                    ref={nodeRef}
                    style={{
                        position: 'absolute',
                        top: '20%',
                        left: '30%',
                        transform: 'translate(-50%, -50%)',

                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center',
                        backgroundColor: 'white',
                        borderRadius: '8px',
                        padding: '16px',
                        maxHeight: '90vh',
                        overflowY: 'auto',
                        minWidth: '700px',
                        zIndex: 1300, // Ensure it's above other elements
                    }}
                >
                    <div className="handle" style={{ cursor: 'move', fontSize: '20px', fontWeight: 'bold', marginBottom: '16px', width: '100%' }}>
                        <div className="edit-title">
                            {t('EditRow')}

                        </div>
                    </div>
                    {/* Modal Content */}
                    <div className={`form-container ${direction}`}>
                        {/* Row 1 */}
                        <div className="form-row">
                            {/* Account Code Name */}
                            <div className="edit-form-row-item">
                                <div className="edit-form-row-item-title">
                                    {type === 'Incomes' ? t('Client Name') : t('Provider Name')}
                                    <span className="required-asterisk">*</span>
                                </div>
                                <Autocomplete
                                    options={formattedAccountCardOptions}
                                    getOptionLabel={(option) => option.label}
                                    value={
                                        formattedAccountCardOptions.find(
                                            (option) => option.value === formData.accountCodeId
                                        ) || null
                                    }
                                    onChange={(event, newValue) => {
                                        const newAccountCodeId = newValue ? newValue.cardId : null;
                                        handleChange('accountCodeId', newAccountCodeId);
                                        // handleChange('accountCodeName', newValue ? newValue.name : '');
                                        // handleChange('accountCaseId', newValue ? newValue.accountCaseId : '');
                                    }}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            placeholder={type === 'Incomes' ? t('Client Name') : t('Provider Name')}
                                            variant="standard"
                                            InputProps={{
                                                ...params.InputProps,
                                                ...inputPropsStyle,
                                            }}
                                            inputProps={{
                                                ...params.inputProps,
                                                ...inputStyle,
                                            }}
                                            required
                                        />
                                    )}
                                    PopperComponent={(props) => (
                                        <Popper
                                            {...props}
                                            style={{
                                                ...props.style,
                                                direction: direction,
                                            }}
                                        />
                                    )}
                                />
                            </div>
                            {/* Account Case ID (read-only) */}
                            <div className="edit-form-row-item">
                                <div className="edit-form-row-item-title">
                                    {t('CompanyId')}
                                </div>
                                <TextField
                                    fullWidth
                                    placeholder={t('CompanyId')}
                                    value={formData.accountCaseId || ''}
                                    variant="standard"
                                    InputProps={{
                                        ...inputPropsStyle,
                                        readOnly: true,
                                    }}
                                    inputProps={inputStyle}
                                />
                            </div>
                        </div>
                        {/* Row 2 */}
                        <div className="form-row">
                            {/* Reference */}
                            <div className="edit-form-row-item">
                                <div className="edit-form-row-item-title">
                                    {t('Reference')}<span className="required-asterisk">*</span>
                                </div>
                                <TextField
                                    fullWidth
                                    placeholder={t('Reference')}
                                    value={formData.reference || ''}
                                    onChange={(e) => handleChange('reference', e.target.value)}
                                    variant="standard"
                                    InputProps={inputPropsStyle}
                                    inputProps={inputStyle}
                                    required
                                />
                            </div>
                            {/* Confirmation Number */}
                            <div className="edit-form-row-item">
                                <div className="edit-form-row-item-title">
                                    {t('ConfirmationNumber')}<span className="required-asterisk">*</span>
                                </div>
                                <TextField
                                    fullWidth
                                    placeholder={t('ConfirmationNumber')}
                                    value={formData.confirmationNumber || ''}
                                    onChange={(e) => handleChange('confirmationNumber', e.target.value)}
                                    variant="standard"
                                    InputProps={inputPropsStyle}
                                    inputProps={inputStyle}
                                    required
                                />
                            </div>
                        </div>
                        {/* Row 3 */}
                        <div className="form-row">
                            {/* Upload Date */}
                            <div className="edit-form-row-item">
                                <div className="edit-form-row-item-title">
                                    {t('Upload Date')}<span className="required-asterisk">*</span>
                                </div>
                                {/* Use CustomDatePicker here */}
                                <CustomDatePicker
                                    date={formData.uploadDate ? new Date(formData.uploadDate) : null}
                                    onDateChange={(date) => {
                                        if (date) {
                                            handleChange('uploadDate', date.toISOString());
                                        }
                                    }}
                                    // label={t('Upload Date')}
                                    height="48px"
                                />
                            </div>
                            {/* Document Date */}
                            <div className="edit-form-row-item">
                                <div className="edit-form-row-item-title">
                                    {t('Document Date')}<span className="required-asterisk">*</span>
                                </div>
                                {/* Use CustomDatePicker here */}
                                <CustomDatePicker
                                    date={formData.documentDate ? new Date(formData.documentDate) : null}
                                    onDateChange={(date) => {
                                        if (date) {
                                            handleChange('documentDate', date.toISOString());
                                        }
                                    }}
                                    // label={t('Select Document Date')}
                                    height="48px"
                                />
                            </div>
                        </div>
                        {/* Row 4 */}
                        <div className="form-row">

                            {/* Description */}
                            <div className="edit-form-row-item">
                                <div className="edit-form-row-item-title">
                                    {t('Description')}<span className="required-asterisk">*</span>
                                </div>
                                <TextField
                                    fullWidth
                                    placeholder={t('Description')}
                                    value={formData.description || ''}
                                    onChange={(e) => handleChange('description', e.target.value)}
                                    variant="standard"
                                    InputProps={inputPropsStyle}
                                    inputProps={inputStyle}
                                    required
                                />
                            </div>
                            {/* Amount */}
                            <div className="edit-form-row-item">
                                <div className="edit-form-row-item-title">
                                    {t('Amount')}<span className="required-asterisk">*</span>
                                </div>
                                <TextField
                                    fullWidth
                                    placeholder={t('Amount')}
                                    value={formData.amount || ''}
                                    onChange={(e) => handleChange('amount', e.target.value)}
                                    variant="standard"
                                    type="number"
                                    InputProps={inputPropsStyle}
                                    inputProps={inputStyle}
                                    required
                                />
                            </div>
                        </div>
                        {/* Row 5 */}
                        <div className="form-row">
                            {/* VAT */}
                            <div className="edit-form-row-item">
                                <div className="edit-form-row-item-title">
                                    {t('VATPercentage')}<span className="required-asterisk">*</span>
                                </div>
                                <FormControl fullWidth variant="standard">
                                    <Select
                                        fullWidth
                                        value={formData.vat || ''}
                                        onChange={(e) => handleChange('vat', e.target.value)}
                                        disableUnderline
                                        sx={{
                                            height: '48px',
                                            padding: '0 10px',
                                            borderRadius: '8px',
                                            background: '#F5F5F6',
                                            border: '1px solid transparent',
                                            '&:focus-within': {
                                                border: '2px solid var(--Foundation-Blue-Normal, #304FFF)',
                                            },
                                            textAlign: direction === 'ltr' ? 'right' : 'left',
                                        }}
                                        inputProps={{
                                            style: {
                                                textAlign: direction === 'ltr' ? 'right' : 'left',
                                            },
                                        }}
                                        MenuProps={{
                                            PaperProps: {
                                                style: {
                                                    direction: direction,
                                                },
                                            },
                                        }}
                                        required
                                    >
                                        {[16, 100, 66, 0].map((option) => (
                                            <MenuItem key={option} value={option}>
                                                {option === 16 ? t('AccountingTax') : `${option}%`}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </div>
                            {/* Sort Code ID */}
                            <div className="edit-form-row-item">
                                <div className="edit-form-row-item-title">
                                    {t('Code ID')}<span className="required-asterisk">*</span>
                                </div>
                                <Autocomplete
                                    options={sortCodeIdOptions}
                                    getOptionLabel={(option) =>
                                        `${option.sortCodeId} - ${option.sortCodeName}`
                                    }
                                    value={
                                        sortCodeIdOptions.find(
                                            (option) => option.sortCodeId === formData.sortCodeId
                                        ) || null
                                    }
                                    onChange={(event, newValue) => {
                                        const newSortCodeId = newValue ? newValue.sortCodeId : '';
                                        

                                        handleChange('sortCodeId', newSortCodeId);
                                        // Handle VAT and accounts based on sort code
                                        // handleChange('vat', newValue ? newValue.vat : '');
                                        // handleChange('creditAccount', newValue ? newValue.creditAccount : formData.creditAccount);
                                        // handleChange('debitAccount', newValue ? newValue.debitAccount : formData.debitAccount);
                                    }}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            placeholder={t('Code ID')}
                                            variant="standard"
                                            InputProps={{
                                                ...params.InputProps,
                                                ...inputPropsStyle,
                                            }}
                                            inputProps={{
                                                ...params.inputProps,
                                                ...inputStyle,
                                            }}
                                            required
                                        />
                                    )}
                                    PopperComponent={(props) => (
                                        <Popper
                                            {...props}
                                            style={{
                                                ...props.style,
                                                direction: direction,
                                            }}
                                        />
                                    )}
                                />
                            </div>
                        </div>
                        {/* Row 6 */}
                        <div className="form-row">
                            {/* Credit Account */}
                            <div className="edit-form-row-item">
                                <div className="edit-form-row-item-title">
                                    {t('Credit Account')}<span className="required-asterisk">*</span>
                                </div>
                                <Autocomplete
                                    options={creditAccountOptions}
                                    getOptionLabel={(option) => `${option.cardId} - ${option.name}`}
                                    value={
                                        creditAccountOptions.find(
                                            (option) => option.cardId === formData.creditAccount
                                        ) || null
                                    }
                                    onChange={(event, newValue) => {
                                        const newCreditAccount = newValue ? newValue.cardId : null;
                                        handleChange('creditAccount', newCreditAccount);
                                    }}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            placeholder={t('Credit Account')}
                                            variant="standard"
                                            InputProps={{
                                                ...params.InputProps,
                                                ...inputPropsStyle,
                                            }}
                                            inputProps={{
                                                ...params.inputProps,
                                                ...inputStyle,
                                            }}
                                            required
                                        />
                                    )}
                                    PopperComponent={(props) => (
                                        <Popper
                                            {...props}
                                            style={{
                                                ...props.style,
                                                direction: direction,
                                            }}
                                        />
                                    )}
                                />
                            </div>
                            {/* Debit Account */}
                            <div className="edit-form-row-item">
                                <div className="edit-form-row-item-title">
                                    {t('Debit Account')}<span className="required-asterisk">*</span>
                                </div>
                                <Autocomplete
                                    options={debitAccountOptions}
                                    getOptionLabel={(option) => `${option.cardId} - ${option.name}`}
                                    value={
                                        debitAccountOptions.find(
                                            (option) => option.cardId === formData.debitAccount
                                        ) || null
                                    }
                                    onChange={(event, newValue) => {
                                        const newDebitAccount = newValue ? newValue.cardId : null;
                                        handleChange('debitAccount', newDebitAccount);
                                    }}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            placeholder={t('Debit Account')}
                                            variant="standard"
                                            InputProps={{
                                                ...params.InputProps,
                                                ...inputPropsStyle,
                                            }}
                                            inputProps={{
                                                ...params.inputProps,
                                                ...inputStyle,
                                            }}
                                            required
                                        />
                                    )}
                                    PopperComponent={(props) => (
                                        <Popper
                                            {...props}
                                            style={{
                                                ...props.style,
                                                direction: direction,
                                            }}
                                        />
                                    )}
                                />
                            </div>
                        </div>
                        {/* Save and Cancel Buttons */}
                        <div className="button-container">
                            <div onClick={onClose} className="edit-cancel-button">
                                {t('Cancel')}
                            </div>
                            <div onClick={handleSave} className="edit-save-button">
                                {t('Save')}
                            </div>
                        </div>
                    </div>
                </div>
            </Draggable>
        </Portal>
    );
}

export default DocumentDraggableDialog;
