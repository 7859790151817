import config from '../config';
import selectedEnvironment from '../env';
const API_SubAccountant_URL = `${config[selectedEnvironment].API_URL}/SubAccountants`;

const GetSubAccountantCounts = async ( token, accountantId) => {
    try {
        const response = await fetch(`${API_SubAccountant_URL}/SumByAccountant/${accountantId}`, {
            method: 'GET',
            headers: {
                "Authorization": 'Bearer ' + token,
                "Content-Type": "application/json",
            },
        });

        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }

        const data = await response.json();
        return data;
    } catch (error) {
    }
};

const GetSubAccountantByAccountantId = async ( token, accountantId) => {
    try {
        const response = await fetch(`${API_SubAccountant_URL}/ByAccountant/${accountantId}`, {
            method: 'GET',
            headers: {
                "Authorization": 'Bearer ' + token,
                "Content-Type": "application/json",
            },
        });

        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }

        const data = await response.json();
        return data;
    } catch (error) {
    }
};

const UpdateSubAccountant = async (token, subAccountant) => {
    try {
        const response = await fetch(`${API_SubAccountant_URL}/Update`, {
            method: 'PUT',
            headers: {
                "Authorization": 'Bearer ' + token,
                "Content-Type": "application/json",
            },
            body: JSON.stringify({ ...subAccountant })
        });

        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }

        return response;
    } catch (error) {
    }
};

const DeleteSubAccountant = async (token, subAccountant) => {
    try {
        const response = await fetch(`${API_SubAccountant_URL}/Delete/${subAccountant}`, {
            method: 'DELETE',
            headers: {
                "Authorization": 'Bearer ' + token,
                "Content-Type": "application/json",
            },
        });

        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }
        // const data = await response.json();
        return response;
    } catch (error) {
    }
};

const SubAccountantService = {
    GetSubAccountantCounts,
    GetSubAccountantByAccountantId,
    UpdateSubAccountant,
    DeleteSubAccountant
};

export default SubAccountantService;