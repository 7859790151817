import React, { useState, useEffect, useImperativeHandle, forwardRef } from 'react';
import {
    DataGrid,
    GridToolbar,
    GridRowModes,
    GridActionsCellItem,
} from '@mui/x-data-grid';
import { styled } from '@mui/material/styles';
import {
    IconButton,
    RadioGroup,
    FormControlLabel as MuiFormControlLabel,
    InputLabel,
    Autocomplete,
    TextField,
    FormControl,
    Select,
    MenuItem,
} from '@mui/material';
import ReactDatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Close';
import NoDocumentsPdf from '../../../assests/pdf/NoDocument.pdf'; // Adjust the path accordingly
import FormControlLabel from '@mui/material/FormControlLabel';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { enUS, arSA, he } from 'date-fns/locale'; // Import desired locales
import { useTranslation } from 'react-i18next';
import editIcon from '../../../assests/images/Icons/editIcon.svg';
import deleteIcon from '../../../assests/images/Icons/deleteIcon.svg';
import saveIcon from '../../../assests/images/Icons/saveIcon.svg';
import cancelIcon from '../../../assests/images/Icons/cancelIcon.svg';
import DocumentDraggableDialog from './DocumentDraggableDialog';
// Styled DataGrid component
const StyledDataGrid = styled(DataGrid)(({ theme }) => ({
    width: '100%',
    '& .MuiAutocomplete-root': {
        display: 'flex',
        justifyContent: 'center',
        alignItems: "center"

    },
    '& *': {
        boxSizing: 'border-box !important',

    },
    '& .MuiDataGrid-columnHeaders': {
        // opacity: 0.7,
        backgroundColor: '#BFC8FF',
    },
    '& .MuiDataGrid-columnHeader': {
        backgroundColor: '#BFC8FF',
        whiteSpace: 'normal', // Allow text wrapping
        wordWrap: 'break-word', // Enable breaking long words
        textAlign: 'center', // Center align the header text
        display: 'flex',
        alignItems: 'center', // Vertically center the text
        justifyContent: 'center', // Horizontally center the text
        padding: '8px', // Add some padding for better spacing
    },
    '& .MuiDataGrid-columnHeaderTitle': {
        color: '#304FFF',
        fontFamily: 'Montserrat',
        fontSize: '14px',
        fontWeight: 600,
        lineHeight: 'normal',
    },
    '& .MuiDataGrid-cell': {
        borderTop: '1px solid rgba(191, 191, 193, 0.56)',
        backgroundColor: 'rgba(234, 237, 255, 0.32)',
        whiteSpace: 'normal',
        wordWrap: 'break-word',
    },
    '& .MuiDataGrid-row:hover': {
        backgroundColor: 'rgba(191, 200, 255, 0.3)',
    },
    '& .MuiDataGrid-cell:focus': {
        outline: 'none',
    },
    '& .MuiDataGrid-cell': {
        display: 'flex',
        // justifyContent: 'center',
        alignItems: "center"
    },
    '& .disableUser': {
        pointerEvents: 'none',
        opacity: 0.5,
    },
    // New styles for edit mode
    '& .MuiDataGrid-cell--editing': {
        paddingTop: '8px',
        paddingBottom: '8px',
        paddingLeft: '8px',
        paddingRight: '8px',
    },
    '& .MuiDataGrid-row--editing .MuiDataGrid-cell': {
        borderRight: 'none',
    },
    '& .MuiDataGrid-row--editing .MuiDataGrid-cell:not(:last-child)': {
        borderRight: '8px solid transparent',
    },
    '& .MuiDataGrid-cell--editing': {
        padding: 0,
        backgroundColor: 'inherit',
    },
}));


const fullCellTextFieldStyle = {
    width: '100%',
    height: '100%',
    '& .MuiInputBase-root': {
        height: '100%',
        padding: 0,
        backgroundColor: 'transparent',
    },
    '& .MuiInputBase-input': {
        height: '100%',
        padding: '0 8px',
        boxSizing: 'border-box',
        backgroundColor: 'transparent',
    },
};


const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = ('0' + date.getDate()).slice(-2);
    const month = ('0' + (date.getMonth() + 1)).slice(-2);
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
};

const DocumentDataGrid = forwardRef((props, ref) => {
    const {
        t, // Translation function
        type, // 'Incomes' or 'Expenses'
        client, // Client data
        data, // Array of documents to display
        isRange, // Boolean indicating if date range is selected
        isClosedReportingMonth, // Boolean indicating if the reporting month is closed
        accountCardOptions, // Options for account cards
        creditAccountOptions, // Options for credit accounts
        debitAccountOptions, // Options for debit accounts
        sortCodeIdOptions, // Options for sort codes
        openPdfInNewTab, // Function to open PDF in a new tab
        formatDateNew, // Function to format dates
        formatMonthYearDate, // Function to format month and year dates
        beautifyNumber, // Function to format numbers
        Constants, // Constants used in the application
        handleDeleteClick, // Function to handle deleting a row
        handleSave,
        handleCancel,
        direction,
        setEditedItem,
        editedItem,
        setEditedPopupItem,
        handleEdit,
        setCurrentRowData,
        setOpenInlineEditModal,
        handleSelectRows,
        handleRightClickAction
    } = props;

    const { i18n } = useTranslation();
    const [selectedRows, setSelectedRows] = useState([]);
    const [originalItem, setOriginalItem] = useState(null);
    const [rows, setRows] = useState(data); // Initialize with data prop
    const [rowModesModel, setRowModesModel] = useState({});
    const [openDialog, setOpenDialog] = useState(false);

    // Define the deselectRow function to remove an id from selectedRows
    const deselectRows = (ids) => {


        setSelectedRows((prevSelectedRows) => prevSelectedRows.filter(rowId => !ids.includes(rowId)));
    };

    const getAlignment = () => {
        return i18n.language === 'en' ? 'left' : 'left';
    };

    // Expose the deselectRow function to the parent via ref
    useImperativeHandle(ref, () => ({
        deselectRows
    }));

    const getLocale = () => {
        switch (i18n.language) {
            case 'ar':
                return arSA; // Arabic locale
            case 'he':
                return he; // Hebrew locale
            default:
                return enUS; // Default to English locale
        }
    };

    // Function to handle double-click to enter edit mode and set editedItem
    const handleCellDoubleClick = (params) => {
        const row = params?.row || {};
        const rowIndex = data.findIndex((r) => r.id === row.id); // Find the index in the original data array
        handleEdit(row, rowIndex)
    };

    const handleRowModesModelChange = (newRowModesModel) => {
        
        setRowModesModel(newRowModesModel);
    };

    // Function to save changes and exit edit mode
    const handleSaveClick = (id) => () => {


        setRowModesModel((prevModel) => ({
            ...prevModel,
            [id]: { mode: GridRowModes.View },
        }));
    };

    // Function to cancel edit mode
    const handleCancelClick = (id) => () => {
        setRowModesModel((prevModel) => ({
            ...prevModel,
            [id]: { mode: GridRowModes.View, ignoreModifications: true },
        }));
        if (originalItem) {
            setRows((prevRows) =>
                prevRows.map((row) => (row.id === id ? originalItem : row))
            );
        }
        setEditedItem(null);
        setOriginalItem(null);
        handleCancel(); // Call the cancel handler if needed
    };

    const processRowUpdate = (newRow, oldRow) => {
        // Handle accountCodeId changes



        if (newRow.accountCodeId !== oldRow.accountCodeId) {
            const accountCard = accountCardOptions.find(
                (option) => option.cardId === newRow.accountCodeId
            );


            newRow.accountCodeName = accountCard ? accountCard.name : '';
            newRow.accountCaseId = accountCard ? accountCard.accountCaseId : '';

            if (type === 'Incomes') {
                newRow.debitAccount = newRow.accountCodeId;
            } else {
                newRow.creditAccount = newRow.accountCodeId;
            }
        }

        // Handle sortCodeId changes
        if (newRow.sortCodeId !== oldRow.sortCodeId) {


            const selectedOption = sortCodeIdOptions.find(
                (option) => option.sortCodeId === newRow.sortCodeId
            );

            if (selectedOption) {
                const nameWithoutParentheses = selectedOption.sortCodeName
                    .replace(/\s*\(.*?\)\s*/, '')
                    .trim();

                const match = selectedOption.sortCodeName.match(/\((\d+)\)/);
                let customVat = match ? parseInt(match[1], 10) : null;

                const sortCode = selectedOption;

                if (Constants.SortCodesWith66Vat.includes(nameWithoutParentheses)) {
                    newRow.vat = 66;
                } else if (
                    Constants.SortCodesWithZeroVat.includes(nameWithoutParentheses) ||
                    sortCode.sortCodeVatType === 0
                ) {
                    newRow.vat = 0;
                } else if (Constants.SortCodesWith16Vat.includes(nameWithoutParentheses)) {
                    newRow.vat = 16;
                } else if (customVat !== null) {
                    newRow.vat = customVat === 66 ? 66 : customVat;
                } else {
                    newRow.vat = 100;
                }



                newRow.creditAccount = sortCode.creditAccount || newRow.creditAccount;
                newRow.debitAccount = sortCode.debitAccount || newRow.debitAccount;
            }
        }


        // Handle creditAccount changes
        if (newRow.creditAccount !== oldRow.creditAccount) {
            const accountCard = accountCardOptions.find(
                (option) => option.cardId === newRow.creditAccount
            );

            newRow.accountCodeName = accountCard ? accountCard.name : '';
            newRow.accountCodeId = accountCard ? accountCard.cardId : '';
            newRow.accountCaseId = accountCard ? accountCard.accountCaseId : '';
        }

        // Handle debitAccount changes
        if (newRow.debitAccount !== oldRow.debitAccount) {


            const accountCard = accountCardOptions.find(
                (option) => option.cardId === newRow.debitAccount
            );

            newRow.accountCodeName = accountCard ? accountCard.name : '';
            newRow.accountCodeId = accountCard ? accountCard.cardId : '';
            newRow.accountCaseId = accountCard ? accountCard.accountCaseId : '';
        }

        // Update the rows state
        setRows((prevRows) =>
            prevRows.map((row) => (row.id === newRow.id ? newRow : row))
        );



        // handleSave(newRow); // Call your save handler if needed

        return newRow;
    };



    // Handle selection model change
    const handleSelectionModelChange = (newSelection) => {

        
        setSelectedRows(newSelection);
        handleSelectRows(newSelection)
    };

    // Determine if a row is selectable
    const isRowSelectable = (params) => {
        const { row } = params;
        return client.accountManagement === Constants.AccountManagementOptions[0].name
            ? row.sortCodeId && row.creditAccount && row.debitAccount
            : row.sortCodeId;
    };

    useEffect(() => {
        if (data) {
            setRows(data);
        }
    }, [data]);

    const formattedAccountCardOptions = accountCardOptions.map((option) => ({
        value: option.cardId,
        label: option.accountCaseId
            ? `${option.name} - ${option.accountCaseId}`
            : `${option.name}`,
        name: option.name,
        accountCaseId: option.accountCaseId || '',
        cardId: option.cardId,
    }));

    // Define columns with explicit widths
    const columns = [
        // Document Column
        {
            field: 'document',
            headerName: t('Document'),
            headerAlign: getAlignment(),
            align: getAlignment(),
            flex: 1,
            renderCell: (params) => {
                const documentUrl = params.row.documentUrl || NoDocumentsPdf;

                return (
                    <div
                        className="iframe-container"
                        onClick={() => params.row.documentUrl && openPdfInNewTab(params.row.documentUrl)}
                        style={{ cursor: params.row.documentUrl ? 'pointer' : 'default' }}
                    >
                        {documentUrl && (
                            <iframe
                                className="zoom-iframe"
                                src={`${documentUrl}#page=1`}
                                width="100px"
                                height="50px"
                                title={params.row.title || 'No Title'}
                                style={{
                                    pointerEvents: 'none',
                                    border: 'none', // Remove any default iframe border
                                }}
                            />
                        )}
                    </div>
                );
            },
        },
        // Document Processed Column
        {
            field: 'processed',
            headerName: t('DocumentProcessed'),
            headerAlign: getAlignment(),
            align: getAlignment(),
            flex: 1,
            renderCell: (params) => {
                const isIdInChecks =
                    type === 'Incomes'
                        ? client.documentIds.incomes.includes(params.row.id)
                        : client.documentIds.expenses.includes(params.row.id);

                const handleRightClick = (event) => {
                    event.preventDefault(); // Prevent the default context menu
                    const status = isIdInChecks ? 'done' : 'notDone'; // Determine the opposite status
                    const id = params.row.id; // Get the ID of the row

                    // Call your function with Id and status
                    handleRightClickAction(id, status,params.row);
                };

                return (
                    <p
                        onContextMenu={handleRightClick} // Attach the right-click handler
                        className={isIdInChecks ? 'unprocessed' : 'processed'}
                        style={{
                            cursor: 'context-menu', // Indicate a context menu is available
                        }}
                    >
                        {isIdInChecks ? t('unprocessed') : t('processed')}
                    </p>
                );
            },
            sortComparator: (v1, v2, params1, params2) => {
                // Determine if each row is unprocessed based on the type
                const isIdInChecks1 =
                    type === 'Incomes'
                        ? client.documentIds.incomes.includes(params1.id)
                        : client.documentIds.expenses.includes(params1.id);

                const isIdInChecks2 =
                    type === 'Incomes'
                        ? client.documentIds.incomes.includes(params2.id)
                        : client.documentIds.expenses.includes(params2.id);

                if (isIdInChecks1 === isIdInChecks2) {
                    return 0; // Both are either processed or unprocessed
                }
                return isIdInChecks1 ? 1 : -1; // Sort 'unprocessed' after 'processed'
            },
        },
        {
            field: 'accountCodeId',
            headerName: 'Account Code ID',
            hide: true, // Hide the column from the UI
            editable: true, // Ensure it's editable
            hideable: false, // Prevent users from showing it via the UI

        },
        // Account Code Name Column
        // {
        //     field: 'accountCodeName',
        //     headerName: type === 'Incomes' ? t('Client Name') : t('Provider Name'),
        //     headerAlign: getAlignment(),
        //     align: getAlignment(),
        //     editable: false,
        //     width: 150,

        // },
        // CompanyId Column
        {
            field: 'accountCaseId',
            headerName: t('CompanyId'),
            headerAlign: getAlignment(),
            align: getAlignment(),
            editable: false,
            flex: 1,

        },
        // Reference Column
        {
            field: 'reference',
            headerName: t('Reference'),
            headerAlign: getAlignment(),
            align: getAlignment(),
            editable: false,
            flex: 1,

            sortComparator: (v1, v2) => {
                const num1 = parseFloat(v1) || 0; // Convert to number, fallback to 0 if NaN
                const num2 = parseFloat(v2) || 0;
                return num1 - num2;
            },
        },
        // ConfirmationNumber Column
        {
            field: 'confirmationNumber',
            headerName: t('ConfirmationNumber'),
            headerAlign: getAlignment(),
            align: getAlignment(),
            editable: false,
            flex: 1,


        },
        // Description Column
        {
            field: 'description',
            headerName: t('Description'),
            headerAlign: getAlignment(),
            align: getAlignment(),
            editable: false,
            flex: 1,


        },
        // MonthReportDate Column (conditional on isRange)
        ...(isRange
            ? [
                {
                    field: 'reportingMonthDate',
                    headerName: t('MonthReportDate'),
                    headerAlign: getAlignment(),
                    align: getAlignment(),
                    editable: false,
                    sortComparator: (v1, v2) => {
                        const date1 = new Date(v1);
                        const date2 = new Date(v2);
                        return date1 - date2;
                    },
                    valueFormatter: (params) => formatMonthYearDate(params),
                },
            ]
            : []),
        // Upload Date Column
        {
            field: 'uploadDate',
            headerName: t('Upload Date'),
            headerAlign: getAlignment(),
            align: getAlignment(),
            editable: false,
            flex: 1,

            sortComparator: (v1, v2) => {
                const date1 = new Date(v1);
                const date2 = new Date(v2);
                return date1 - date2;
            },

            valueFormatter: (params) => formatDate(params),
        },
        // Document Date Column
        {
            field: 'documentDate',
            headerName: t('Document Date'),
            headerAlign: getAlignment(),
            align: getAlignment(),
            editable: false,
            flex: 1,

            sortComparator: (v1, v2) => {
                const date1 = new Date(v1);
                const date2 = new Date(v2);
                return date1 - date2;
            },

            valueFormatter: (params) => formatDate(params),
        },
        // Amount Column
        {
            field: 'amount',
            headerName: t('Amount'),
            headerAlign: getAlignment(),
            align: getAlignment(),
            editable: false,
            flex: 1,

            renderCell: (params) => (
                <p
                    style={{
                        color: 'green',
                        fontSize: '16px',
                        fontWeight: 'bold',
                    }}
                >
                    {params.value !== null ? beautifyNumber(params.value) : '0.00'}
                </p>
            ),

        },
        // VATPercentage Column
        {
            field: 'vat',
            headerName: t('VATPercentage'),
            headerAlign: getAlignment(),
            align: getAlignment(),
            editable: false,
            flex: 1,

        },
        // VATAmount Column
        {
            field: 'vatAmount',
            headerName: t('VATAmount'),
            headerAlign: getAlignment(),
            align: getAlignment(),
            flex: 1,

            valueFormatter: (params) => beautifyNumber(params || 0),
        },
        // NetAmount Column
        {
            field: 'netAmount',
            headerName: t('NetAmount'),
            headerAlign: getAlignment(),
            align: getAlignment(),
            flex: 1,

            valueFormatter: (params) => beautifyNumber(params || 0),
        },
        // Credit Account Column
        {
            field: 'creditAccount',
            headerName: t('Credit Account'),
            headerAlign: getAlignment(),
            align: getAlignment(),
            editable: true,
            flex: 1,

            renderCell: (params) => {
                const option = creditAccountOptions.find(
                    (option) => option.cardId === params.value
                );
                return (
                    <span>{option ? `${option.cardId} - ${option.name}` : ''}</span>
                );
            },
        },
        // Debit Account Column
        {
            field: 'debitAccount',
            headerName: t('Debit Account'),
            headerAlign: getAlignment(),
            align: getAlignment(),
            editable: true,
            flex: 1,

            renderCell: (params) => {
                const option = debitAccountOptions.find(
                    (option) => option.cardId === params.value
                );
                return (
                    <span>{option ? `${option.cardId} - ${option.name}` : ''}</span>
                );
            },
        },
        // Code ID Column
        {
            field: 'sortCodeId',
            headerName: t('Code ID'),
            editable: true,
            headerAlign: getAlignment(),
            align: getAlignment(),
            flex: 1,

            renderCell: (params) => {
                const value = params.value || '';
                const option = sortCodeIdOptions.find(
                    (option) => option.sortCodeId === value
                );
                const name = option ? option.sortCodeName : '';
                return (
                    <span>
                        {value} {name && `- ${name}`}
                    </span>
                );
            },
        },

        // UploadNumber Column
        {
            field: 'uploadNumber',
            headerName: t('UploadNumber'),
            headerAlign: getAlignment(),
            align: getAlignment(),
            flex: 1,

            renderCell: (params) => (
                <span>{params.value !== undefined ? params.value : 'N/A'}</span>
            ),
        },
        // Actions Column
        // {
        //     field: 'actions',
        //     type: 'actions',
        //     headerName: t('Actions'),
        //     headerAlign: getAlignment(),
        //     align: getAlignment(),
        //     width: 180,
        //     getActions: (params) => {
        //         const row = params?.row || {};
        //         const rowIndex = data.findIndex((r) => r.id === row.id); // Find the index in the original data array

        //         return [
        //             <GridActionsCellItem
        //                 icon={<img src={editIcon} alt="Edit" />}
        //                 label="Edit"
        //                 onClick={() => handleEdit(row, rowIndex)} // Pass both row and rowIndex
        //                 showInMenu={false} // Optional
        //             />,
        //             (isRange ? !row.isClosedReportingMonth : !isClosedReportingMonth) && (
        //                 <GridActionsCellItem
        //                     icon={<img src={deleteIcon} alt="Delete" />}
        //                     label="Delete"
        //                     onClick={() => handleDeleteClick(row.id)}
        //                     showInMenu={false} // Optional
        //                 />
        //             ),
        //         ].filter(Boolean); // Filter out falsey values in case the condition is false
        //     },

        // },
    ].reverse();
    const handleRowEditStop = (params, event) => {
        event.defaultMuiPrevented = true;
    };

    const handleCellEditCommit = (params) => {
        const { id, field, value } = params;

        setRows((prevRows) =>
            prevRows.map((row) => {
                if (row.id === id) {
                    const updatedRow = { ...row, [field]: value };

                    // Update related fields if necessary
                    if (field === 'accountCodeId') {
                        const accountCard = accountCardOptions.find(
                            (option) => option.cardId === value
                        );
                        updatedRow.accountCodeName = accountCard ? accountCard.name : '';
                        updatedRow.accountCaseId = accountCard ? accountCard.accountCaseId : '';

                        if (type === 'Incomes') {
                            updatedRow.debitAccount = value;
                        } else {
                            updatedRow.creditAccount = value;
                        }
                    }

                    return updatedRow;
                }
                return row;
            })
        );
    };


    return (
        <div className={`${direction}`} style={{ width: '100%', height: '60vh' }}>
            <LocalizationProvider
                dateAdapter={AdapterDateFns}
                adapterLocale={getLocale()}
            >
                <StyledDataGrid
                    rows={rows}
                    columns={columns}
                    checkboxSelection
                    disableSelectionOnClick
                    rowSelectionModel={selectedRows}
                    onRowSelectionModelChange={(ids) => handleSelectionModelChange(ids)}
                    getRowId={(row) => row.id}
                    components={{ Toolbar: GridToolbar }}
                    componentsProps={{
                        toolbar: {
                            showQuickFilter: true,
                        },
                    }}
                    pagination
                    sortingOrder={['asc', 'desc']}
                    isRowSelectable={isRowSelectable}
                    onCellDoubleClick={handleCellDoubleClick}
                    processRowUpdate={processRowUpdate}
                    experimentalFeatures={{ newEditingApi: true }}
                    editMode="cell"
                    rowModesModel={rowModesModel}
                    onRowModesModelChange={handleRowModesModelChange}
                    columnVisibilityModel={{
                        accountCodeId: false,
                    }}
                    onCellEditCommit={handleCellEditCommit}
                    onRowEditStop={handleRowEditStop}
                />
            </LocalizationProvider>

        </div>
    );
});

export default DocumentDataGrid;
