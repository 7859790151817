import React, { useState, useEffect } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Typography, Box, Chip, Stack } from '@mui/material';
import MergeIcon from '@mui/icons-material/Merge';
import ClearIcon from '@mui/icons-material/Clear';
import AdvancedDropdown from '../../AdvancedDropdown/AdvancedDropdown'; // Import your AdvancedDropdown component
import ArrowBackIcon from '@mui/icons-material/ArrowBack'; // Import the arrow icon
import { useTranslation } from 'react-i18next';
import ListIcon from '@mui/icons-material/List';
import { ToastContainer, toast } from "react-toastify";
import SwapVertIcon from '@mui/icons-material/SwapVert';
import AutocompleteSelect from '../../Widgets/AutocompleteSelect/AutocompleteSelect';

// Function to calculate the Levenshtein distance between two strings
const levenshteinDistance = (a, b) => {
  const matrix = Array.from({ length: a.length + 1 }, () => []);
  for (let i = 0; i <= a.length; i++) matrix[i][0] = i;
  for (let j = 0; j <= b.length; j++) matrix[0][j] = j;

  for (let i = 1; i <= a.length; i++) {
    for (let j = 1; j <= b.length; j++) {
      const cost = a[i - 1] === b[j - 1] ? 0 : 1;
      matrix[i][j] = Math.min(
        matrix[i - 1][j] + 1,
        matrix[i][j - 1] + 1,
        matrix[i - 1][j - 1] + cost
      );
    }
  }
  return matrix[a.length][b.length];
};

// Function to calculate similarity score between two strings (lower score = more similar)
const getSimilarityScore = (name1, name2) => {
  const distance = levenshteinDistance(name1.toLowerCase(), name2.toLowerCase());
  const maxLength = Math.max(name1.length, name2.length);

  // If the entire name would need to be changed, don't include it
  if (distance === maxLength) {
    return -1; // This score indicates that the strings are completely different
  }

  return 1 - distance / maxLength; // Normalize the distance by string length (0 = no similarity, 1 = identical)
};

const MergeAccountCards = ({ isVisible, onClose, accountCardsData, handleAccountMerge }) => {
  const { t } = useTranslation();
  const [mainCard, setMainCard] = useState({});
  const [neglectedCard, setNeglectedCard] = useState({});
  const [processedCardsData, setProcessedCardsData] = useState([]);
  const [showMiniModal, setShowMiniModal] = useState(false);
  const [areYouSureModal, setAreYouSureModal] = useState(false);
  const [similarCards, setSimilarCards] = useState([]); // State to store similar cards
  const [mergedCard, setMergedCard] = useState({
    cardId: '',
    caseId: '',
    phoneNumber: '',
    location: '',
    email: '',
    sourceDeduction: '',
    palCode: '',
    name: '',
  });

  // Process accountCardsData to add value and label
  useEffect(() => {
    if (accountCardsData && accountCardsData.length > 0) {
      const transformedData = accountCardsData.map(card => ({
        ...card,
        value: card.cardId, // Set value to cardId
        label: card.name,   // Set label to name
      }));
      setProcessedCardsData(transformedData);
    }
  }, [accountCardsData]);

  const setSecondCardByChip = (chipCardId) => {


    // Find the index of the card in processedCardsData
    const cardIndex = processedCardsData.findIndex(item => item.cardId === chipCardId);

    if (cardIndex !== -1) {

      const selectedCard = { ...processedCardsData[cardIndex], index: cardIndex }; // Add the index to the card
      if (selectedCard) {
        setNeglectedCard(selectedCard);
      }
    } else {

    }
  };

  const handleCancel = () => {
    setMainCard('');
    setNeglectedCard('');
    setAreYouSureModal(false);
    onClose();
  };



  // When the first card is selected, find the most similar names using Levenshtein distance
  useEffect(() => {
    if (mainCard) {
      const selectedCard = processedCardsData.find(card => card.cardId === mainCard.cardId);
      if (selectedCard) {
        const scoredCards = processedCardsData
          .filter(card => card.cardId !== mainCard) // Exclude the selected card itself
          .map(card => ({
            ...card,
            similarityScore: getSimilarityScore(selectedCard.name, card.name),
          }))
          .filter(card => card.similarityScore > 0.35) // Exclude completely different names
          .sort((a, b) => b.similarityScore - a.similarityScore) // Sort by similarity score in descending order
          .slice(1, 11); // from 1 to not show the first result

        setSimilarCards(scoredCards);
      }
    } else {
      setSimilarCards([]);
    }
  }, [mainCard, processedCardsData]);

  const closeMiniModal = () => {
    setShowMiniModal(false);
  };
  const closeAreYouSureModal = () => {
    setAreYouSureModal(false);
  };

  const handleMerge = () => {
    handleAccountMerge(mainCard.cardId, neglectedCard.cardId);
    handleCancel();
  };

  const openMiniModal = () => {
    setShowMiniModal(true);
  };

  const openAreYouSureModal = () => {
    
    if (mainCard !== '' && neglectedCard !== '' && neglectedCard.isEditable) {
      setAreYouSureModal(true);
    }
    if (!neglectedCard.isEditable) {
      toast.info(t('CardToMergeCannotBeLocked'), { position: "top-center" });
    }
  };

  const selectedACardFromSimilarList = (cardId) => {
    closeMiniModal();
    setFirstCardByChip(cardId)
  };

  const setFirstCardByChip = (chipCardId) => {
    const cardIndex = processedCardsData.findIndex(item => item.cardId === chipCardId);
    if (cardIndex !== -1) {
      const selectedCard = { ...processedCardsData[cardIndex], index: cardIndex }; // Add the index to the card
      if (selectedCard) {
        
        
        setMainCard(selectedCard);
      }
    } else {

    }
  };
  



  return (
    <>
      <Dialog
        open={isVisible}
        onClose={onClose}
        sx={{
          '& .MuiDialog-paper': {
            width: '600px',
            direction: 'ltr', // Set the direction for the dialog to LTR
            textAlign: 'center', fontWeight: 'bold'
          }
        }}>
        <DialogTitle sx={{ direction: 'ltr' }}>{t('MergeAccountCards')}</DialogTitle>
        <DialogContent sx={{ direction: 'ltr' }}>
          <Box mb={2}>
            <Button onClick={openMiniModal} variant="outlined" endIcon={<ListIcon sx={{ ml: 1 }} />}>
              {t('ViewCardsWithSimilarCards')}
            </Button>
          </Box>
          <Typography sx={{ direction: 'ltr', fontWeight: 'bold' }}>{t('SelectTwoAccountCardsToMerge')}:</Typography>

          {/* AutocompleteSelect for selecting Card 1 */}
          <div style={{ marginTop: '30px' }}>
            <AutocompleteSelect
              options={processedCardsData}
              selectedValues={
                processedCardsData.find((option) => option?.value === mainCard.cardId) || null
              }
              onChange={(event, newValue) => setMainCard(newValue )}
              placeholder={t('TargetCard')}
              isMultiple={false}
              width="100%" // Custom width for compact display
            />
          </div>

          <div style={{ marginTop: '30px' }}>
            <Button
              onClick={() => {
                // Swap the values of mainCard and neglectedCard
                const temp = mainCard;
                setMainCard(neglectedCard);
                setNeglectedCard(temp);
              }}
              startIcon={<SwapVertIcon />} // Add the vertical switch icon
              sx={{
                color: 'black',        // Set text color to black
                backgroundColor: 'transparent',  // Remove background color
                boxShadow: 'none',      // Remove box shadow
                fontSize: '1rem',
                padding: '5px 10px',
                '&:hover': {
                  backgroundColor: 'transparent', // Keep transparent on hover
                  color: 'gray',       // Optional: make text gray on hover for a subtle effect
                },
              }}
            >
              {t('SwitchCards')}
            </Button>
          </div>

          {/* AutocompleteSelect for selecting Card 2 */}
          <div style={{ marginTop: '30px' }}>
            <AutocompleteSelect
              options={processedCardsData}
              selectedValues={
                processedCardsData.find((option) => option.value === neglectedCard?.cardId) || null
              }
              onChange={(event, newValue) => setNeglectedCard(newValue)}
              placeholder={t('TheNeglected')}
              isMultiple={false}
              width="100%" // Custom width for compact display
            />
          </div>

          {/* Display the similar name account cards as Chips */}
          {similarCards.length > 0 && (
            <Box mt={3}>
              <Typography sx={{ direction: 'ltr', margin: '20px 0 20px 0', fontWeight: 'bold' }}>{t('MostSimilarAccountCardNames')}:</Typography>
              <Stack direction="row" spacing={1} flexWrap="wrap" sx={{ direction: 'ltr' }}>
                {similarCards.map(card => (
                  <Chip
                    key={card.cardId}
                    label={card.name + ' - ' + card.cardId}
                    onClick={() => setSecondCardByChip(card.cardId)} // Optionally select the second card when clicked
                    variant="outlined"
                    style={{ marginBottom: '8px' }}
                  />
                ))}
              </Stack>
            </Box>
          )}

          <Box mt={1}>
            <Typography sx={{ direction: 'ltr', fontWeight: 'bold', margin: '20px 0 20px 0' }}>{t('PreviewOfMerge')}:</Typography>
            {/* <Typography sx={{ direction: 'ltr' }}>Name: {mergedCard.name || 'N/A'}</Typography>
          <Typography sx={{ direction: 'ltr' }}>Phone Number: {mergedCard.phoneNumber || 'N/A'}</Typography>
          <Typography sx={{ direction: 'ltr' }}>Location: {mergedCard.location || 'N/A'}</Typography> */}
          </Box>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mt: 2 }}>

            {/* Left section for Card 1 details */}
            <Box sx={{ flex: 1, p: 2, border: '1px solid #ccc', borderRadius: '8px' }}>
              <Typography sx={{ direction: 'ltr', fontWeight: 'bold', textAlign: 'center' }}>
                {t('DetailsOf')} {t('TheNeglected')}
              </Typography>
              <Typography sx={{ direction: 'ltr' }}>{t('name')}: {neglectedCard?.name || '-'}</Typography>
              <Typography sx={{ direction: 'ltr' }}>{t('Card ID')}: {neglectedCard?.cardId || '-'}</Typography>
            </Box>

            {/* Middle section for the arrow */}
            <Box sx={{ mx: 2 }}>
              <ArrowBackIcon fontSize="large" /> {/* Left arrow */}
            </Box>

            {/* Right section for Card 2 details */}


            <Box sx={{ flex: 1, p: 2, border: '1px solid #ccc', borderRadius: '8px' }}>
              <Typography sx={{ direction: 'ltr', fontWeight: 'bold', textAlign: 'center' }}>
                {t('DetailsOf')} {t('TargetCard')}
              </Typography>
              <Typography sx={{ direction: 'ltr' }}>{t('name')}: {mainCard?.name || '-'}</Typography>
              <Typography sx={{ direction: 'ltr' }}>{t('Card ID')} {mainCard?.cardId || '-'}</Typography>
            </Box>
          </Box>
        </DialogContent>
        <DialogActions sx={{ direction: 'ltr', justifyContent: 'center' }}>
          <Button
            onClick={openAreYouSureModal}
            startIcon={<MergeIcon />}
            color="primary"
            sx={{ fontSize: '1.1rem', padding: '10px 20px' }} // Increase font size and padding
          >
            {t('Merge')}
          </Button>

          <Button
            onClick={() => handleCancel()}
            startIcon={<ClearIcon />}
            sx={{ fontSize: '1.1rem', padding: '10px 20px' }} // Increase font size and padding
          >
            {t('cancelButton')}
          </Button>
        </DialogActions>
      </Dialog>


      {/* Mini modal */}
      {showMiniModal && (
        <Dialog
          open={showMiniModal}
          onClose={closeMiniModal}
          sx={{
            '& .MuiDialog-paper': {
              width: '500px',
              height: '400px',
            }
          }}
        >
          <DialogContent>
            {processedCardsData
              .map((card) => {
                // Find the 5 most similar cards to the current card
                const similarCards = processedCardsData
                  .filter(otherCard => otherCard.cardId !== card.cardId) // Exclude the card itself
                  .map(otherCard => ({
                    ...otherCard,
                    similarityScore: getSimilarityScore(card.name, otherCard.name),
                  }))
                  .filter(otherCard => otherCard.similarityScore > 0.75) // Only include cards with high similarity (> 0.75)
                  .sort((a, b) => b.similarityScore - a.similarityScore) // Sort by similarity score descending
                  .slice(0, 5); // Get the top 5 most similar cards

                // Calculate the highest similarity score for sorting
                const highestSimilarityScore = similarCards.length > 0 ? similarCards[0].similarityScore : 0;

                return { card, similarCards, highestSimilarityScore };
              })
              .filter(item => item.similarCards.length > 0) // Filter out cards with no similar cards
              .sort((a, b) => b.highestSimilarityScore - a.highestSimilarityScore) // Sort by highest similarity score
              .map(({ card, similarCards }) => (
                <Box
                  key={card.cardId}
                  onClick={() => selectedACardFromSimilarList(card.cardId)} // Set the main card when the box is clicked
                  sx={{
                    width: '90%',
                    height: '100px',
                    border: '1px solid #ccc',
                    borderRadius: '8px',
                    position: 'relative',
                    marginBottom: '10px',
                    padding: '10px',
                    display: 'flex',
                    flexDirection: 'column', // Stack items vertically
                    justifyContent: 'flex-start',
                    alignItems: 'flex-end',
                    textAlign: 'right',
                    mx: 'auto', // Center the Box horizontally
                    cursor: 'pointer', // Change the cursor to indicate clickability
                    '&:hover': {
                      backgroundColor: '#f0f0f0', // Add hover effect for better UX
                    },
                  }}
                >
                  {/* Card Name and ID */}
                  <Typography sx={{ position: 'absolute', top: '10px', right: '10px' }}>
                    {card.cardId}
                  </Typography>

                  <Typography sx={{ position: 'absolute', top: '10px', right: '%90' }}>
                    {card.name}
                  </Typography>

                  {/* Similar Cards displayed as Chips */}
                  <Box sx={{ marginTop: '40px', display: 'flex', flexWrap: 'wrap', justifyContent: 'flex-end' }}>
                    {similarCards.map(similarCard => (
                      <Chip
                        key={similarCard.cardId}
                        label={similarCard.name}
                        sx={{ marginLeft: '5px', marginBottom: '5px' }}
                      />
                    ))}
                  </Box>
                </Box>
              ))}
          </DialogContent>




        </Dialog>
      )}

      {areYouSureModal && (
        <Dialog
          open={areYouSureModal}
          onClose={closeAreYouSureModal}
          sx={{
            '& .MuiDialog-paper': {
              width: '550px',
              height: '300px',
            }
          }}
        >
          <DialogContent>
            <Typography sx={{ direction: 'ltr', fontWeight: 'bold', color: '#00008B' }}>{t('AreYouSureYouWantToMergeTheseCards')}</Typography>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mt: 2 }}>
              {/* Left section for Card 1 details */}
              <Box sx={{ flex: 1, p: 2, border: '1px solid #ccc', borderRadius: '8px' }}>
                <Typography sx={{ direction: 'ltr', fontWeight: 'bold', textAlign: 'center' }}>
                  {t('DetailsOf')} {t('TargetCard')}
                </Typography>
                <Typography sx={{ direction: 'ltr' }}>{t('name')}: {mainCard?.name || '-'}</Typography>
                <Typography sx={{ direction: 'ltr' }}>{t('Card ID')} {mainCard?.cardId || '-'}</Typography>
              </Box>

              {/* Middle section for the arrow */}
              <Box sx={{ mx: 2 }}>
                <ArrowBackIcon fontSize="large" /> {/* Left arrow */}
              </Box>

              {/* Right section for Card 2 details */}
              <Box sx={{ flex: 1, p: 2, border: '1px solid #ccc', borderRadius: '8px' }}>
                <Typography sx={{ direction: 'ltr', fontWeight: 'bold', textAlign: 'center' }}>
                  {t('DetailsOf')} {t('TheNeglected')}
                </Typography>
                <Typography sx={{ direction: 'ltr' }}>{t('name')}: {neglectedCard?.name || '-'}</Typography>
                <Typography sx={{ direction: 'ltr' }}>{t('Card ID')}: {neglectedCard?.cardId || '-'}</Typography>
              </Box>
            </Box>
            <Typography sx={{ direction: 'ltr', fontWeight: 'bold', margin: '10px 0 0 0', color: '#00008B' }}>{t('NeglectedCardChangeToGoalCard')}</Typography>
            <DialogActions sx={{ direction: 'ltr', justifyContent: 'center', margin: '10px 0 0 0' }}>
              <Button
                onClick={handleMerge}
                sx={{
                  fontSize: '1.1rem',
                  padding: '10px 20px',
                  backgroundColor: 'green', // Make the button green
                  color: 'white',           // Ensure the text is white for visibility
                  '&:hover': {
                    backgroundColor: 'darkgreen', // Darker green on hover
                  },
                }}
              >
                {t('Yes')} {t('YouMerge')}
              </Button>

              <Button
                onClick={() => closeAreYouSureModal()}
                sx={{
                  fontSize: '1.1rem',
                  padding: '10px 20px',
                  backgroundColor: '#c22d2d', // Softer red
                  color: 'white',             // Ensure the text is white for visibility
                  '&:hover': {
                    backgroundColor: '#992323', // Slightly darker but still soft red on hover
                  },
                }}
              >
                {t('cancelButton')}
              </Button>
            </DialogActions>
          </DialogContent>
        </Dialog>
      )}
    </>

  );
};

export default MergeAccountCards;
