import React, { useState, useRef, useEffect } from 'react';
import HamburgerMenu from '../../HamburgerMenu/HamburgerMenu.jsx';
import UpperPanelDetails from '../UpperPanelDetails/UpperPanelDetails.jsx';
import SignaturePad from 'react-signature-canvas';
import ReactDatePicker from 'react-datepicker';
import Select from 'react-select';
import { ToastContainer, toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import './CreateInvoice.css';
import {
    Modal,
    TextField,
    CircularProgress,
    RadioGroup,
    FormControlLabel,
    Radio,
    Popper,
    Button,
} from '@mui/material';
import EnumsService from '../../../Services/enums.service.js';
import UserService from '../../../Services/user.service.js';
import AutocompleteSelect from '../AutocompleteSelect/AutocompleteSelect.jsx';
import cancelIcon from '../../../assests/images/Icons/cancelIcon.svg';
import SignatureReferenceModal from '../SignatureReferenceModal/SignatureReferenceModal.jsx';
import TaxAuthorityService from '../../../Services/taxAuthority.service.js';
import DocumentService from '../../../Services/documents.service.js';
import 'react-toastify/dist/ReactToastify.css'; // Import Toastify CSS
import Constants from '../../../assests/Constants/constants.js';
import CustomDatePicker from '../../CustomDatePicker/CustomDatePicker.js';
import { beautifyNumber } from '../../../Utils/FormatNumber.js';
import ClientService from '../../../Services/client.service.js';
const InvoiceModalTypes = [
    {
        controller: 'Incomes',
        endPoint: 'GenerateIncomePdf',
        draftEndPoint: 'GenerateIncomeDraftPdf', // Assuming you have a draft endpoint
        type: '',
        TransactionDocumentType: '',
        TransactionDocumentTitle: '',
        title: 'generateInvoice',
        screen: '/Document',
        type: 'Incomes',
    },
    {
        controller: 'Incomes',
        endPoint: 'GenerateRefundIncomePdf',
        draftEndPoint: 'GenerateRefundIncomeDraftPdf',
        type: '',
        TransactionDocumentType: '',
        TransactionDocumentTitle: '',
        title: 'generateCreditNote',
        screen: '/Document',
        type: 'Incomes',
    },
    {
        controller: "TransactionDocuments",
        endPoint: "GenerateTransactionDocumentPdf",
        draftEndPoint: "GenerateTransactionDocumentDraftPdf",
        type: "quotation", TransactionDocumentType: "Quotation",
        TransactionDocumentTitle: "הצעת מחיר",
        title: "generateQuotation",
        screen: '/TransactionDocuments',
        type: 'TransactionDocuments',
    },
    {
        controller: "TransactionDocuments",
        endPoint: "GenerateTransactionDocumentPdf",
        draftEndPoint: "GenerateTransactionDocumentDraftPdf",
        type: "order",
        TransactionDocumentType: "Order",
        TransactionDocumentTitle: "הזמנה",
        title: "generateOrder",
        screen: '/TransactionDocuments',
        type: 'TransactionDocuments',
    },
    {
        controller: "TransactionDocuments",
        endPoint: "GenerateTransactionDocumentPdf",
        draftEndPoint: "GenerateTransactionDocumentDraftPdf",
        type: "transaction",
        TransactionDocumentType: "Transaction",
        TransactionDocumentTitle: "חשבון עסקה",
        title: "generateTransactionInvoice",
        screen: '/TransactionDocuments',
        type: 'TransactionDocuments',

    },
];



const CreateInvoice = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const { t, i18n } = useTranslation();
    const [accountCardOptions, setAccountCardOptions] = useState([]);
    const [sortCodeIdOptions, setSortCodeIdOptions] = useState([]);
    const [loading, setLoading] = useState(false);
    const [creditAccountOptions, setCreditAccountOptions] = useState([]);
    const [debitAccountOptions, setDebitAccountOptions] = useState([]);
    const [addNewAccountCardDocument, setAddNewAccountCardDocument] = useState(false);
    const [accountCardEditDownloadOptions, setAccountCardEditDownloadOptions] = useState([]);
    const [direction, setDirection] = useState('rtl'); // Default to 'rtl'
    const [clientItems, setClientItems] = useState([]);
    const [isFirstUserFetched, setIsFirstUserFetched] = useState(false);
    const [editedItem, setEditedItem] = useState({});
    const [token, setToken] = useState('');
    const [data, setData] = useState([]);
    const [client, setClient] = useState(null);
    const [datePickerVisibility, setDatePickerVisibility] = useState(null);
    const [type, setType] = useState(null);
    const [user, setUser] = useState(null);
    const [cardIdOption, setCardIdOption] = useState(-1);
    const [newItems, setNewItems] = useState([]); // To store new items
    const [addItemFlags, setAddItemFlags] = useState([]); // Array of booleans to track add new item mode per index
    const [invoiceSignature, setInvoiceSignature] = useState('');
    const [invoiceReference, setInvoiceReference] = useState('');
    const [signatureReferenceFilled, setSignatureReferenceFilled] = useState(false);
    const [authUrl, setAuthUrl] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [resumeInvoiceCreation, setResumeInvoiceCreation] = useState(false);
    const [draftPdfModalVisible, setDraftPdfModalVisible] = useState(false);
    const [PdfModalVisible, setPdfModalVisible] = useState(false);
    const [draftPdfPath, setDraftPdfPath] = useState('');
    const [draftPdfBlob, setDraftPdfBlob] = useState(null);
    const [PdfBlob, setPdfBlob] = useState(null);
    const [isTaxAuthorityAuthModalVisible, setIsTaxAuthorityAuthModalVisible] = useState(false);
    const [invoiceModalType, setInvoiceModalType] = useState(0); // Default to 0 for TaxInvoice
    const sigPad = useRef(null);
    const [signatureReferenceInvoiceModalVisible, setSignatureReferenceInvoiceModalVisible] = useState(false);
    const [authModalVisible, setAuthModalVisible] = useState(false);
    const [authWindow, setAuthWindow] = useState(null);
    const [needSignature, setNeedSignature] = useState(false);
    const [needReference, setNeedReference] = useState(false);

    const handleTaxAuthorityAuth = (authUrl) => {
        // Open the authentication URL in a new tab

        const authWindow = window.open(authUrl, '_blank');

        // Store the authWindow in state
        setAuthWindow(authWindow);

        // Start checking for authentication completion
        checkAuthCompletion(authWindow);
    };
    const [formData, setFormData] = useState({
        invoiceClientName: '',
        invoiceClientCaseId: '',
        invoiceAccountCodeId: '',
        invoiceClientEmail: '',
        invoiceClientPhoneNumber: '',
        invoiceClientLocation: '',
        invoiceDescription: '',
        priceIncludingVAT: true,
        invoiceDate: new Date(),
        reportingMonthDate: new Date(),
        invoiceItems: [],
    });
    const computeTotalInvoiceAmount = () => {
        const VAT_RATE = 0.17;
        return formData.invoiceItems.reduce((sum, item) => {
            let price = parseFloat(item.price) || 0;
            price *= item.quantity || 1;
            const discountPercent = parseFloat(item.discount) || 0;
            const discountedPrice = price - (price * discountPercent) / 100;
            const priceWithVAT = formData.priceIncludingVAT ? discountedPrice : discountedPrice * (1 + VAT_RATE);
            return sum + priceWithVAT;
        }, 0);
    };

    const totalInvoiceAmount = computeTotalInvoiceAmount();
    const isAmountAboveLimit = totalInvoiceAmount >= Constants.ISRAEL_INVOICE_AMOUNT_LIMIT;

    const allClientItemFieldsFilled =
        formData.invoiceClientName &&
        formData.invoiceClientPhoneNumber &&
        formData.reportingMonthDate &&
        formData.invoiceDate &&
        formData.invoiceItems.length > 0 &&
        (!isAmountAboveLimit || formData.invoiceClientCaseId) &&  // Modified condition
        formData.invoiceItems.every((item) => {
            const hasRequiredFields = item.name && item.makat && item.price && item.discount >= 0;
            return hasRequiredFields;
        });



    const handleSignatureChange = () => {
        if (sigPad.current) {
            setInvoiceSignature(sigPad.current.getTrimmedCanvas().toDataURL('image/png'));
            setSignatureReferenceFilled(invoiceSignature && invoiceReference);
        }
    };

    const handleSignatureReferenceSave = async () => {
        if (needSignature && !invoiceSignature) {
            toast.error(t('ProvideSignature'));
            return;
        }
        if (needReference && !invoiceReference) {
            toast.error(t('ProvideSignature'));
            return;
        }
        setSignatureReferenceInvoiceModalVisible(false);
        // Proceed with next steps
    };

    const handleSignatureReferenceClose = async () => {
        setInvoiceSignature('');
        setInvoiceReference('');
        setSignatureReferenceInvoiceModalVisible(false);
    };

    const clearSignature = () => {
        sigPad.current.clear();
        setInvoiceSignature('');
        setSignatureReferenceFilled(false);
    };

    useEffect(() => {
        const fetchUserData = async () => {
            setLoading(true);
            try {
                const currentUser = await UserService.getCurrentUser();
                const currentToken = await UserService.getCurrentToken();
                if (currentUser && currentToken) {
                    setUser(currentUser);
                    setToken(currentToken);

                    if (location.state && location.state.client && location.state.type) {
                        setClient(location.state.client);
                        setType(location.state.type);

                        // Map the location.state.type to the correct invoiceModalType index
                        const typeMapping = {
                            'TaxInvoice': 0,
                            'CreditNote': 1,
                            'Quotation': 2,
                            'Order': 3,
                            'Transaction': 4,
                        };

                        const modalTypeIndex = typeMapping[location.state.type];

                        if (modalTypeIndex !== undefined) {
                            setInvoiceModalType(modalTypeIndex);
                        } else {
                            // Handle unknown type, maybe set a default or show an error
                            console.error('Unknown invoice type:', location.state.type);
                            // You can set a default or handle it as needed
                            setInvoiceModalType(0); // Default to 'taxInvoice' if unknown
                        }

                        await fetchEnums(currentUser, currentToken, location.state.client);
                    }
                } else {
                    navigate('/login');
                }
            } catch (error) {
                // Handle error - maybe navigate to login or show a message
                console.error('Error fetching user data:', error);
            }
            setLoading(false);
        };

        fetchUserData();
        // Include dependencies in the dependency array
    }, [location.state.client, location.state.type]);

    useEffect(() => {
        // Update direction based on the current language
        if (i18n.language === 'he' || i18n.language === 'ar') {
            setDirection('rtl');
        } else {
            setDirection('ltr');
        }
    }, [i18n.language]);

    const fetchEnums = async (user, token, client) => {
        setLoading(true);
        try {
            const [codeIdResponse, accountsResponse, clientItemsResponse] = await Promise.all([
                EnumsService.GetSortCodesByAccountantId(client.accountantId, token),
                EnumsService.GetAccountCardsByCaseId(client.caseId, token),
                EnumsService.GetClientItemsByCaseId(client.caseId, token),
            ]);

            if (codeIdResponse.ok) {
                const codeIdData = await codeIdResponse.json();
                setSortCodeIdOptions(codeIdData);
            }

            if (accountsResponse.ok) {
                const accountsData = await accountsResponse.json();
                setCreditAccountOptions(accountsData);
                setDebitAccountOptions(accountsData);
                setAccountCardEditDownloadOptions(accountsData);
                const accountCardOptions = [{ name: t('AddAccountCard'), value1: 'AddItem' }, ...accountsData];

                setAccountCardOptions(accountCardOptions);
            }

            if (clientItemsResponse.ok) {
                const clientItemsData = await clientItemsResponse.json();
                clientItemsData.unshift({ makat: 'add-item', name: t('AddItem') });
                setClientItems(clientItemsData);
            }

            setIsFirstUserFetched(true);
        } catch (error) {
            console.error('Error fetching enum data:', error);
            toast.error(t('enumFetchError', { errorText: error.message }));
        } finally {
            setLoading(false);
        }
    };

    const handleCloseAddNewAccountCardDocument = () => {
        setFormData((prev) => ({
            ...prev,
            accountCodeId: '',
            accountCaseId: '',
            accountCodeName: '',
        }));
        setAddNewAccountCardDocument(false);
    };

    // useEffect(() => {
    //     if (client) {
    //         if (client?.signature && client?.invoicesNumbers) {
    //             // setInvoiceModalVisible(true); // If you have an invoice modal
    //         } else {
    //             setSignatureReferenceInvoiceModalVisible(true);
    //         }
    //     }
    // }, [client]);

    const handleChange = (field, value) => {

        setFormData((prevState) => ({
            ...prevState,
            [field]: value,
        }));
    };

    const handleItemChange = (index, field, value) => {
        setFormData((prevState) => {
            const updatedItems = [...prevState.invoiceItems];
            updatedItems[index][field] = value;
            return {
                ...prevState,
                invoiceItems: updatedItems,
            };
        });
    };

    const handleAddItem = () => {
        const newItem = { makat: '', name: '', quantity: 1, price: '', discount: 0, isNew: true };
        setFormData((prevState) => ({
            ...prevState,
            invoiceItems: [...prevState.invoiceItems, newItem],
        }));
        // setAddItemFlags((prevFlags) => [...prevFlags, true]); // Set to true to enter "add new item" mode
    };

    const handleRemoveItem = (index) => {
        setFormData((prevState) => {
            const updatedItems = prevState.invoiceItems.filter((item, idx) => idx !== index);
            return {
                ...prevState,
                invoiceItems: updatedItems,
            };
        });
        setAddItemFlags((prevFlags) => prevFlags.filter((flag, idx) => idx !== index));
    };

    const handleInvoiceItem = (index, selectedOption) => {
        if (selectedOption.value === 'add-item') {
            const updatedFlags = [...addItemFlags];
            updatedFlags[index] = true;
            setAddItemFlags(updatedFlags);
        } else {
            const accountCard = clientItems.find((option) => option.makat === selectedOption.value);
            handleItemChange(index, 'makat', accountCard.makat);
            handleItemChange(index, 'name', accountCard.name);
            // Reset flag in case it was set
            const updatedFlags = [...addItemFlags];
            updatedFlags[index] = false;
            setAddItemFlags(updatedFlags);
        }
    };

    const handleAccountCodeChange = (selectedOption) => {
        if (selectedOption?.value1 === 'AddItem') {
            // Clear formData fields when adding a new item
            setFormData((prev) => ({
                ...prev,
                invoiceAccountCodeId: cardIdOption,
                invoiceClientCaseId: '',
                invoiceClientName: '',
            }));
            setAddNewAccountCardDocument(true);
        } else {
            // Set formData fields based on selected option
            const accountCard = accountCardOptions.find((option) => option.cardId === selectedOption?.value);

            setFormData((prev) => ({
                ...prev,
                invoiceAccountCodeId: selectedOption?.value || '',
                invoiceClientCaseId: selectedOption?.accountCaseId || '',
                invoiceClientName: accountCard ? accountCard.name : '',
                invoiceClientEmail: accountCard ? accountCard.email : '',
                invoiceClientLocation: accountCard ? accountCard.location : '',
                invoiceClientPhoneNumber: accountCard ? accountCard.phoneNumber : '',

            }));
        }
    };

    const formattedAccountCardOptions = accountCardOptions.map((option) => ({
        value: option.cardId,
        label: option.accountCaseId ? `${option.name} - ${option.accountCaseId}` : `${option.name}`,
        name: option.name,
        accountCaseId: option.accountCaseId || '',
        cardId: option.cardId,
        value1: option.value1,
    }));

    useEffect(() => {
        if (accountCardOptions && accountCardOptions.length > 0) {
            const maxCardId = Math.max(...accountCardOptions.slice(1).map((option) => parseInt(option.cardId, 10)));
            setCardIdOption(maxCardId + 1);
        }
    }, [accountCardOptions]);

    const handleCloseAddItem = (index) => {
        const updatedFlags = [...addItemFlags];
        updatedFlags[index] = false;
        setAddItemFlags(updatedFlags);
    };

    const handleAddMakatAndCheck = (index, value) => {
        // Check if the makat already exists in clientItems
        const existingItem = clientItems.find(item => item.makat === value);

        if (existingItem) {
            // Makat exists, alert the user or handle accordingly
            toast.error(t('MakatAlreadyExists'));
        } else {
            // Update the makat value
            handleItemChange(index, 'makat', value);
        }
    };

    const validateInvoiceData = () => {
        let valid = true;

        if (formData.invoiceClientEmail && !validateEmail(formData.invoiceClientEmail)) {
            toast.error(t('InvalidEmailAddress'));
            valid = false;
        }

        if (formData.invoiceClientPhoneNumber && !validateNumber(formData.invoiceClientPhoneNumber)) {
            toast.error(t('InvalidPhoneNumber'));
            valid = false;
        }

        if (formData.invoiceClientCaseId && !validateNumber(formData.invoiceClientCaseId)) {
            toast.error(t('InvalidCaseId'));
            valid = false;
        }

        formData.invoiceItems.forEach((item) => {
            item.quantity = item.quantity?.toString().replace(/\s+/g, '') ?? '';
            item.price = item.price?.toString().replace(/\s+/g, '') ?? '';
            item.discount = item.discount?.toString().replace(/\s+/g, '') ?? '';

            if (item.quantity <= 0) {
                toast.error(t('invalidQuantity'));
                valid = false;
                return;
            }

            if (isNaN(item.price) || !validateAmount(item.price.toString())) {
                valid = false;
                toast.error(t('invalidPrice'));
                return;
            }

            if (isNaN(item.discount) || item.discount < 0 || item.discount > 100) {
                valid = false;
                toast.error(t('invalidDiscount'));
                return;
            }
        });

        return valid;
    };

    const validateEmail = (email) => {
        // Simple email validation
        const re = /\S+@\S+\.\S+/;
        return re.test(email);
    };

    const validateNumber = (number) => {
        const re = /^[0-9\b]+$/;
        return re.test(number);
    };

    const validateAmount = (amount) => {
        const re = /^\d+(\.\d{1,2})?$/;
        return re.test(amount);
    };

    const handleCreateDraftInvoice = async () => {
        setLoading(true);

        // Validate data
        let valid = validateInvoiceData();

        if (!valid) {
            setLoading(false);
            return;
        }

        const invoice = {
            CaseId: client.caseId,
            AccountantId: client.accountantId,
            Name: client.name,
            Description: formData.invoiceDescription,
            Reference: invoiceReference,
            ClientName: formData.invoiceClientName,
            ClientCaseId: formData.invoiceClientCaseId,
            PhoneNumber: client.phoneNumber,
            Email: client.email,
            Website: client.website,
            Location: client.location,
            ClientLocation: formData.invoiceClientLocation,
            ClientEmail: formData.invoiceClientEmail,
            ClientPhoneNumber: formData.invoiceClientPhoneNumber,
            Date: formData.invoiceDate,
            Signature: invoiceSignature,
            AccountCodeId: formData.invoiceAccountCodeId.toString(),
            Items: formData.invoiceItems,
            PriceIncludingVAT: formData.priceIncludingVAT,
        };

        try {


            // Call the API to generate draft invoice
            const pdfBlob = await DocumentService.CreateDraftInvoice(
                InvoiceModalTypes[invoiceModalType].controller,
                InvoiceModalTypes[invoiceModalType].draftEndPoint,
                invoice,
                InvoiceModalTypes[invoiceModalType].type,
                InvoiceModalTypes[invoiceModalType].TransactionDocumentTitle,
                InvoiceModalTypes[invoiceModalType].TransactionDocumentType,
                token
            );

            setDraftPdfBlob(pdfBlob);
            setDraftPdfModalVisible(true);

            toast.success(t('DraftDocumentCreated'));
        } catch (error) {
            console.error(error);
            toast.error(t('DraftDocumentDidNotCreated'));
        }

        setLoading(false);
    };

    const updateLocationState = async (newClientData, screen, type) => {

        const updatedClientData = {
            ...location.state.client,
            ...newClientData
        };

        navigate(-1, {
            replace: true, 
            state: { client: updatedClientData, type }, 
        });
    };
    const handleSaveSignature = async (screen, type) => {
        const Client = await ClientService.GetClient(token, client.caseId);
        updateLocationState(Client, screen, type);

    }

    const saveNewItemsToDatabase = async (invoiceItems) => {
        try {

            // Filter out new items that need to be saved
            const itemsToSave = invoiceItems.filter(item => item.isNew);

            // Map items to the format expected by the API
            const itemsData = itemsToSave.map(item => ({
                makat: item.makat,
                name: item.name,
                caseId: client.caseId
                // Include other necessary fields if needed
            }));

            // Call the API to save new items
            await EnumsService.AddClientItems(itemsData, token);

            // Update clientItems state with the new items
            setClientItems(prevClientItems => [...prevClientItems, ...itemsToSave]);

            // Clear newItems array after saving
            setFormData(prevState => ({
                ...prevState,
                invoiceItems: prevState.invoiceItems.map(item => ({ ...item, isNew: false })),
            }));

            // Optionally, update the addItemFlags if needed
        } catch (error) {
            console.error('Error saving new items:', error);
            toast.error(t('ErrorSavingNewItems'));
        }
    };
    const handleCreateInvoice = async (isAuthCompleted = false) => {
        setLoading(true);
        const VAT_RATE = 0.17;

        // Validate data
        let valid = validateInvoiceData();

        if (!valid) {
            setLoading(false);
            return;
        }
        const totalInvoiceAmountWithoutVAT = formData.invoiceItems.reduce((sum, item) => {
            let price = parseFloat(item.price) || 0;
            price *= item.quantity || 1;
            const discountPercent = parseFloat(item.discount) || 0;
            const discountedPrice = price - (price * discountPercent) / 100;
            const priceWithoutVAT = formData.priceIncludingVAT ? discountedPrice / (1 + VAT_RATE) : discountedPrice;
            return sum + priceWithoutVAT;
        }, 0);
        // const totalInvoiceAmount = computeTotalInvoiceAmount();

        const invoice = {
            CaseId: client.caseId,
            AccountantId: client.accountantId,
            Name: client.name,
            Description: formData.invoiceDescription,
            Reference: invoiceReference,
            ClientName: formData.invoiceClientName,
            ClientCaseId: formData.invoiceClientCaseId,
            PhoneNumber: client.phoneNumber,
            Email: client.email,
            Website: client.website,
            Location: client.location,
            ClientLocation: formData.invoiceClientLocation,
            ClientEmail: formData.invoiceClientEmail,
            ClientPhoneNumber: formData.invoiceClientPhoneNumber,
            Date: formData.invoiceDate,
            reportingMonthDate: formData.reportingMonthDate,
            Signature: invoiceSignature,
            AccountCodeId: formData.invoiceAccountCodeId.toString(),
            Items: formData.invoiceItems,
            PriceIncludingVAT: formData.priceIncludingVAT,
        };

        const isNeedToAskAboutConfirmationNumber =
            invoiceModalType === 0 || invoiceModalType === 1 || invoiceModalType === 4 || invoiceModalType === 5;
        if (totalInvoiceAmountWithoutVAT >= Constants.ISRAEL_INVOICE_AMOUNT_LIMIT && !isAuthCompleted && isNeedToAskAboutConfirmationNumber) {
            try {

                const tokenData = await TaxAuthorityService.requestTokenClient(client.caseId, token);

                if (tokenData?.authUrl) {
                    handleTaxAuthorityAuth(tokenData.authUrl);
                    setLoading(false);
                    return; // Wait for auth to complete
                }
            } catch (error) {
                console.error('Error fetching token:', error);
                toast.error(t('tokenFetchError'));
                setLoading(false);
                return;
            }
        }

        // Proceed to create invoice
        try {
            setDraftPdfBlob(null);
            setDraftPdfModalVisible(false);
            const pdfBlob = await DocumentService.CreateNewInvoice(
                InvoiceModalTypes[invoiceModalType].controller,
                InvoiceModalTypes[invoiceModalType].endPoint,
                invoice,
                InvoiceModalTypes[invoiceModalType].type,
                InvoiceModalTypes[invoiceModalType].TransactionDocumentTitle,
                InvoiceModalTypes[invoiceModalType].TransactionDocumentType,
                token
            );

            if (formData.invoiceItems.some(item => item.isNew)) {
                await saveNewItemsToDatabase(formData.invoiceItems);
            }
            if (addNewAccountCardDocument) {
                await EnumsService.AddAccountCard(
                    client.caseId,
                    formData.invoiceAccountCodeId.toString(),
                    formData.invoiceClientName,
                    formData.invoiceClientCaseId,
                    "לקוחות",
                    token,
                    formData.invoiceClientPhoneNumber,
                    formData.invoiceClientLocation,
                    formData.invoiceClientEmail
                );
            }
            setPdfBlob(pdfBlob);
            setPdfModalVisible(true);

            toast.success(t('InvoiceCreated'));
            handleInvoiceClose();
        } catch (error) {
            console.error(error);
            toast.error(t('InvoiceNotCreated'));
        }

        setLoading(false);
    };

    const handleInvoiceClose = async () => {
        setFormData({
            invoiceClientName: '',
            invoiceClientCaseId: '',
            invoiceAccountCodeId: '',
            invoiceClientEmail: '',
            invoiceClientPhoneNumber: '',
            invoiceClientLocation: '',
            invoiceDescription: '',
            priceIncludingVAT: true,
            invoiceDate: new Date(),
            reportingMonthDate: new Date(),
            invoiceItems: [],
        });
        setAddItemFlags([]);
        setInvoiceSignature('');
        setInvoiceReference('');
        setAddNewAccountCardDocument(false);
        // setNeedReference(false);
        // setNeedSignature(false);
    };
    const handleReset = () => {
        // ... existing reset logic
        setNeedSignature(false);
        setNeedReference(false);
    };

    useEffect(() => {
        if (client) {

            let hasSignature = client.signature;
            let hasDocNumber = false;

            switch (invoiceModalType) {
                case 0: // taxInvoice
                case 5: // invoiceReceipt
                    hasDocNumber = client.invoicesNumbers && client.invoicesNumbers !== 0;
                    break;
                case 1: // creditNote
                    hasDocNumber = client.invoicesNumbers && client.invoicesNumbers !== 0 && client.refundInvoicesNumbers && client.refundInvoicesNumbers !== 0;
                    break;
                case 2: // quotation
                    hasDocNumber = client.quotationInvoicesNumbers && client.quotationInvoicesNumbers !== 0;
                    break;
                case 3: // order
                    hasDocNumber = client.orderInvoicesNumbers && client.orderInvoicesNumbers !== 0;
                    break;
                case 4: // transactionInvoice
                    hasDocNumber = client.transactionInvoicesNumbers && client.transactionInvoicesNumbers !== 0;
                    break;
                case 6: // receipt
                    hasDocNumber = client.receiptNumbers && client.receiptNumbers !== 0;
                    break;
                default:
                    hasDocNumber = false;
                    break;
            }

            if (hasSignature) {
                if (hasDocNumber) {
                    // Has signature and document number > 0, no need for signature or reference
                    setNeedSignature(false);
                    setNeedReference(false);
                    setSignatureReferenceInvoiceModalVisible(false);
                } else {
                    // Has signature but document number is zero or missing, need to collect reference only
                    setNeedSignature(false);
                    setNeedReference(true);
                    setSignatureReferenceInvoiceModalVisible(true);
                }
            } else {
                if (hasDocNumber) {
                    // No signature but document number > 0, need to collect signature only
                    setNeedSignature(true);
                    setNeedReference(false);
                    setSignatureReferenceInvoiceModalVisible(true);
                } else {
                    // No signature and document number is zero or missing, need to collect both signature and reference
                    setNeedSignature(true);
                    setNeedReference(true);
                    setSignatureReferenceInvoiceModalVisible(true);
                }
            }
        }
    }, [client, invoiceModalType]);

    const handleVatRadioChange = (e) => {
        setEditedItem({ ...editedItem, ["vat"]: parseFloat(e.target.value) });
    };

    // const handleTaxAuthorityAuth = (authUrl) => {
    //     const width = 600;
    //     const height = 600;
    //     const left = window.screen.width / 2 - width / 2;
    //     const top = window.screen.height / 2 - height / 2;
    //     const authWindow = window.open(authUrl, '_blank', `width=${width},height=${height},left=${left},top=${top}`);

    //     const receiveMessage = (event) => {
    //         if (event.origin !== window.location.origin) {
    //             return;
    //         }
    //         if (event.data === 'authComplete') {
    //             window.removeEventListener('message', receiveMessage);
    //             if (authWindow && !authWindow.closed) {
    //                 authWindow.close();
    //             }
    //             checkAuthCompletion();
    //         }
    //     };

    //     window.addEventListener('message', receiveMessage, false);
    // };

    const checkAuthCompletion = async (authWindow) => {
        setLoading(true);
        try {
            let attempts = 0;
            const maxAttempts = 25;
            while (attempts < maxAttempts) {
                // Check if the authWindow was closed by the user
                if (authWindow.closed) {
                    setLoading(false);
                    toast.error(t('Authentication window was closed by the user.'));
                    return;
                }

                const result = await TaxAuthorityService.checkAuthCompletionClient(client.caseId);
                if (result.completed) {
                    setLoading(false);
                    setResumeInvoiceCreation(true);
                    // Close the auth window
                    if (authWindow && !authWindow.closed) {
                        authWindow.close();
                    }
                    return;
                } else {
                    attempts += 1;
                    await new Promise((resolve) => setTimeout(resolve, 3000));
                }
            }
            setLoading(false);
            toast.error(t('tokenFetchError'));
            // Close the auth window on timeout
            if (authWindow && !authWindow.closed) {
                authWindow.close();
            }
        } catch (error) {
            console.error('Error checking auth completion:', error);
            setLoading(false);
            toast.error(t('tokenFetchError'));
            // Close the auth window on error
            if (authWindow && !authWindow.closed) {
                authWindow.close();
            }
        }
    };

    useEffect(() => {
        if (resumeInvoiceCreation) {
            handleCreateInvoice(true); // Proceed with invoice creation
        }
    }, [resumeInvoiceCreation]);

    const handleCloseDraftPdfModal = () => {
        setDraftPdfBlob(null);
        setDraftPdfModalVisible(false);
    };

    const handleClosePdfModal = () => {
        setPdfBlob(null);
        setPdfModalVisible(false);
        if (needReference || needSignature) {
            handleSaveSignature(InvoiceModalTypes[invoiceModalType].screen, InvoiceModalTypes[invoiceModalType].type);
        }
        else {
            updateLocationState(client, InvoiceModalTypes[invoiceModalType].screen, InvoiceModalTypes[invoiceModalType].type);
        }
        handleReset();
    };


    const onDateChange = async (selectedDate) => {
        if (selectedDate) {
            const newDate = new Date(Date.UTC(selectedDate.getFullYear(), selectedDate.getMonth(), 10));
            const response1 = await ClientService.CheckIfMonthClosed(client.caseId, newDate, token);
            if (response1.ok) {
                const isClosedReportingMonth = await response1.json();
                if (isClosedReportingMonth) {
                    toast.info(t('MonthReportAlreadyClosedTryAgain'))
                    setDatePickerVisibility(false);
                    return
                }
                // Reset the time portion to avoid time zone offset issues
                selectedDate.setHours(0, 0, 0, 0);

                // Format the date manually or use toLocaleDateString for local date representation
                const year = selectedDate.getFullYear();
                const month = selectedDate.getMonth() + 1; // getMonth() is zero-based
                const day = selectedDate.getDate();

                // Ensure two digits for month and day
                const formattedDate = `${year}-${month?.toString().padStart(2, '0')}-${day?.toString().padStart(2, '0')}`;
                handleChange("invoiceDate", new Date(formattedDate));
                handleChange("reportingMonthDate", new Date(formattedDate));
            }
        }
    };

    const handleGoBack = () => {
        updateLocationState(client, InvoiceModalTypes[invoiceModalType].screen, InvoiceModalTypes[invoiceModalType].type)
    }
    return (
        <>
            <HamburgerMenu client={client} />
            <div className="template-page">
                <div className="template-container">
                    <UpperPanelDetails client={client} />
                    <div className={`main-title ${direction}`}>{t(type)}</div>
                    <div
                        className="backIconBackground"
                        style={{ alignSelf: direction === 'ltr' ? 'flex-start' : 'flex-end' }}
                        onClick={handleGoBack}
                    >
                        <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
                            <path
                                d="M20 21.1667L14.8267 15.9933L20 10.82C20.52 10.3 20.52 9.45999 20 8.93999C19.48 8.41999 18.64 8.41999 18.12 8.93999L12 15.06C11.48 15.58 11.48 16.42 12 16.94L18.12 23.06C18.64 23.58 19.48 23.58 20 23.06C20.5067 22.54 20.52 21.6867 20 21.1667Z"
                                fill="black"
                                fillOpacity="0.9"
                            />
                        </svg>
                    </div>
                    {/* Form Inputs Section */}
                    <div className={`add-client-form ${direction}`}>
                        <div className="form-row">
                            {addNewAccountCardDocument ? (
                                <div className="items_Container">
                                    <button className="clientItemButton" style={{ justifyContent: 'center' }} onClick={handleCloseAddNewAccountCardDocument}>
                                        {t('ReturnToNamesList')}
                                    </button>



                                    <div className="form-row" >
                                        <div className="form-row-item-title">
                                            {t('Card Name')}<span className="required-asterisk">*</span>
                                        </div>
                                        <TextField
                                            fullWidth
                                            placeholder={t('Card Name')}
                                            value={formData.invoiceClientName || ''}
                                            onChange={(e) => handleChange('invoiceClientName', e.target.value)}
                                            variant="standard"
                                            InputProps={{
                                                disableUnderline: true,
                                                className: `custom-box-sizing-border-box ${direction}`,
                                                sx: {
                                                    height: '48px',
                                                    padding: '0 10px',
                                                    borderRadius: '8px',
                                                    background: '#F5F5F6',
                                                    border: '1px solid transparent',
                                                    '&:focus-within': {
                                                        border: '2px solid var(--Foundation-Blue-Normal, #304FFF)',
                                                    },
                                                },
                                            }}
                                            inputProps={{
                                                style: {
                                                    textAlign: direction === 'ltr' ? 'left' : 'right',
                                                },
                                            }}
                                            required
                                        />
                                    </div >

                                    <div className="form-row" style={{ width: "82%" }}>
                                        <div className="form-row-item-title">
                                            {t('Card ID')}<span className="required-asterisk">*</span>
                                        </div>
                                        <TextField
                                            fullWidth
                                            placeholder={t('Card ID')}
                                            value={formData.invoiceAccountCodeId || ''}
                                            type="number"
                                            onBlur={(index,e) => handleAddMakatAndCheck(index, e.target.value)}
                                            onChange={(e) => handleChange('invoiceAccountCodeId', e.target.value)}
                                            variant="standard"
                                            InputProps={{
                                                disableUnderline: true,
                                                className: `custom-box-sizing-border-box ${direction}`,
                                                sx: {
                                                    height: '48px',
                                                    padding: '0 10px',
                                                    borderRadius: '8px',
                                                    background: '#F5F5F6',
                                                    border: '1px solid transparent',
                                                    '&:focus-within': {
                                                        border: '2px solid var(--Foundation-Blue-Normal, #304FFF)',
                                                    },
                                                },
                                            }}
                                            inputProps={{
                                                style: {
                                                    textAlign: direction === 'ltr' ? 'left' : 'right',
                                                },
                                            }}
                                            required
                                        />
                                    </div >


                                </div>
                            ) : (
                                <div className="form-row-item">
                                    <div className="form-row-item-title">
                                        {t('Client Name')}
                                        <span className="required-asterisk">*</span>
                                    </div>

                                    <AutocompleteSelect
                                        options={formattedAccountCardOptions}
                                        value={formattedAccountCardOptions.find(option => option.value === formData.accountCodeId) || null}
                                        onChange={(e, newValue) => {
                                            handleAccountCodeChange(newValue);
                                        }}
                                        placeholder={t('Client Name')}
                                        isMultiple={false}
                                        width='100%'
                                    />
                                </div>
                            )}
                            <div className="form-row-item">
                                <div className="form-row-item-title">
                                    {t('EnterClientCaseId')}
                                    {computeTotalInvoiceAmount() >= Constants.ISRAEL_INVOICE_AMOUNT_LIMIT && (
                                        <span className="required-asterisk">*</span>
                                    )}
                                </div>
                                <TextField

                                    placeholder={t('EnterClientCaseId')}
                                    value={formData.invoiceClientCaseId}
                                    onChange={(e) => handleChange('invoiceClientCaseId', e.target.value)}

                                    variant="standard"
                                    InputProps={{
                                        disableUnderline: true,
                                        className: `custom-box-sizing-border-box ${direction}`,
                                        sx: {
                                            height: '48px',
                                            padding: '0 10px',
                                            borderRadius: '8px',
                                            background: '#F5F5F6',
                                            border: '1px solid transparent',
                                            '&:focus-within': {
                                                border: '2px solid var(--Foundation-Blue-Normal, #304FFF)',
                                            },
                                        },
                                    }}
                                    inputProps={{
                                        style: {
                                            textAlign: direction === 'ltr' ? 'left' : 'right',
                                        },
                                    }}
                                    required
                                />
                            </div>
                        </div>
                        <div className="form-row">
                            <div className="form-row-item">
                                <div className="form-row-item-title">
                                    {t('EnterClientEmail')}
                                </div>
                                <TextField
                                    fullWidth
                                    placeholder={t('EnterClientEmail')}
                                    value={formData.invoiceClientEmail}
                                    onChange={(e) => handleChange('invoiceClientEmail', e.target.value)}
                                    variant="standard"
                                    InputProps={{
                                        className: 'custom-box-sizing-border-box',
                                        disableUnderline: true,
                                        sx: {
                                            height: '48px',
                                            padding: '0 10px',
                                            borderRadius: '8px',
                                            background: '#F5F5F6',
                                            border: '1px solid transparent',
                                            '&:focus-within': {
                                                border: '2px solid var(--Foundation-Blue-Normal, #304FFF)',
                                            },
                                        },
                                    }}
                                    inputProps={{
                                        style: {
                                            textAlign: direction === 'ltr' ? 'left' : 'right',
                                        },
                                    }}
                                    required
                                />
                            </div>

                            <div className="form-row-item">
                                <div className="form-row-item-title">
                                    {t('PhoneNumber')}<span className="required-asterisk">*</span>
                                </div>
                                <TextField
                                    fullWidth
                                    placeholder={t('PhoneNumber')}
                                    value={formData.invoiceClientPhoneNumber}
                                    onChange={(e) => handleChange('invoiceClientPhoneNumber', e.target.value)}

                                    variant="standard"
                                    InputProps={{
                                        disableUnderline: true,
                                        className: `custom-box-sizing-border-box ${direction}`,
                                        sx: {
                                            height: '48px',
                                            padding: '0 10px',
                                            borderRadius: '8px',
                                            background: '#F5F5F6',
                                            border: '1px solid transparent',
                                            '&:focus-within': {
                                                border: '2px solid var(--Foundation-Blue-Normal, #304FFF)',
                                            },
                                        },
                                    }}
                                    inputProps={{
                                        style: {
                                            textAlign: direction === 'ltr' ? 'left' : 'right',
                                        },
                                    }}
                                    required
                                />
                            </div>
                        </div>
                        <div className="form-row">
                            <div className="form-row-item">
                                <div className="form-row-item-title">
                                    {t('EnterClientLocation')}
                                </div>
                                <TextField
                                    fullWidth
                                    placeholder={t('EnterClientLocation')}
                                    value={formData.invoiceClientLocation}
                                    onChange={(e) => handleChange('invoiceClientLocation', e.target.value)}

                                    variant="standard"
                                    InputProps={{
                                        disableUnderline: true,
                                        className: `custom-box-sizing-border-box ${direction}`,
                                        sx: {
                                            height: '48px',
                                            padding: '0 10px',
                                            borderRadius: '8px',
                                            background: '#F5F5F6',
                                            border: '1px solid transparent',
                                            '&:focus-within': {
                                                border: '2px solid var(--Foundation-Blue-Normal, #304FFF)',
                                            },
                                        },
                                    }}
                                    inputProps={{
                                        style: {
                                            textAlign: direction === 'ltr' ? 'left' : 'right',
                                        },
                                    }}
                                    required
                                />
                            </div>

                            <div className="form-row-item">
                                <div className="form-row-item-title">
                                    {t('enterDescription')}
                                </div>
                                <TextField
                                    fullWidth
                                    placeholder={t('enterDescription')}
                                    value={formData.invoiceDescription}
                                    onChange={(e) => handleChange('invoiceDescription', e.target.value)}

                                    variant="standard"
                                    InputProps={{
                                        disableUnderline: true,
                                        className: `custom-box-sizing-border-box ${direction}`,
                                        sx: {
                                            height: '48px',
                                            padding: '0 10px',
                                            borderRadius: '8px',
                                            background: '#F5F5F6',
                                            border: '1px solid transparent',
                                            '&:focus-within': {
                                                border: '2px solid var(--Foundation-Blue-Normal, #304FFF)',
                                            },
                                        },
                                    }}
                                    inputProps={{
                                        style: {
                                            textAlign: direction === 'ltr' ? 'left' : 'right',
                                        },
                                    }}
                                    required
                                />
                            </div>
                        </div>
                        <div className="form-row">
                            <div className="form-row-item">
                                <div className="form-row-item-title">
                                    {t('Document Date')}<span className="required-asterisk">*</span>
                                </div>
                                {/* Use CustomDatePicker here */}
                                <CustomDatePicker
                                    date={formData.documentDate ? new Date(formData.documentDate) : null}
                                    onDateChange={(date) => {
                                        if (date) {
                                            onDateChange(date);
                                        }
                                    }}
                                    // label={t('Select Document Date')}
                                    height="48px"
                                />
                            </div>
                            <div className="form-row-item">

                            </div>

                        </div>
                        <RadioGroup
                            row
                            aria-labelledby="demo-form-control-label-placement"
                            name="position"
                            value={formData.priceIncludingVAT.toString()}
                            onChange={(e) => handleChange('priceIncludingVAT', e.target.value === 'true')}
                        >
                            <FormControlLabel
                                value="true"
                                control={<Radio />}
                                label={t('PriceIncludingVAT')}
                                labelPlacement="top"
                                checked={formData.priceIncludingVAT}
                            />
                            <FormControlLabel
                                value="false"
                                control={<Radio />}
                                label={t('PriceExcludingVAT')}
                                labelPlacement="top"
                                checked={!formData.priceIncludingVAT}
                            />
                        </RadioGroup>
                        <button className='clientItemButton' onClick={handleAddItem}>
                            {t('AddItem')}
                            <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
                                <path d="M15.9993 2.66667C8.63935 2.66667 2.66602 8.64001 2.66602 16C2.66602 23.36 8.63935 29.3333 15.9993 29.3333C23.3594 29.3333 29.3327 23.36 29.3327 16C29.3327 8.64001 23.3594 2.66667 15.9993 2.66667ZM22.666 17.3333H17.3327V22.6667H14.666V17.3333H9.33268V14.6667H14.666V9.33334H17.3327V14.6667H22.666V17.3333Z" fill="#F9AA2A" />
                            </svg>
                        </button>


                        {/* More form rows for invoice items and other details can be added here */}
                        {formData.invoiceItems.map((item, index) => (
                            <>

                                {addItemFlags[index] ? (
                                    < >
                                        <div className="form-row">
                                            <button className='clientItemButton ' onClick={() => handleCloseAddItem(index)}>
                                                {t('ReturnToItemList')}
                                                <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
                                                    <path d="M16 2.66667C8.64 2.66667 2.66667 8.64001 2.66667 16C2.66667 23.36 8.64 29.3333 16 29.3333C23.36 29.3333 29.3333 23.36 29.3333 16C29.3333 8.64001 23.36 2.66667 16 2.66667ZM13.3333 17.3333L9.33333 13.3333L13.3333 9.33334V12H22.6667V14.6667H13.3333V17.3333Z" fill="#F9AA2A" />
                                                </svg>
                                            </button>
                                        </div>
                                        <div className="form-row" style={{ width: "82%" }}>
                                            <div className="form-row-item-title">
                                                {t('Makat')}<span className="required-asterisk">*</span>
                                            </div>
                                            <TextField
                                                fullWidth
                                                placeholder={t('Makat')}
                                                value={item.makat}
                                                type="number"
                                                onChange={(e) => handleItemChange(index, 'makat', e.target.value)}
                                                variant="standard"
                                                InputProps={{
                                                    disableUnderline: true,
                                                    className: `custom-box-sizing-border-box ${direction}`,
                                                    sx: {
                                                        height: '48px',
                                                        padding: '0 10px',
                                                        borderRadius: '8px',
                                                        background: '#F5F5F6',
                                                        border: '1px solid transparent',
                                                        '&:focus-within': {
                                                            border: '2px solid var(--Foundation-Blue-Normal, #304FFF)',
                                                        },
                                                    },
                                                }}
                                                inputProps={{
                                                    style: {
                                                        textAlign: direction === 'ltr' ? 'left' : 'right',
                                                    },
                                                }}
                                                required
                                            />
                                        </div >
                                        <div className="form-row" style={{ width: "82%" }}>
                                            <div className="form-row-item-title">
                                                {t('ItemDescription')}<span className="required-asterisk">*</span>
                                            </div>
                                            <TextField
                                                fullWidth
                                                placeholder={t('ItemDescription')}
                                                value={item.name}
                                                onChange={(e) => handleItemChange(index, 'name', e.target.value)}
                                                variant="standard"
                                                InputProps={{
                                                    disableUnderline: true,
                                                    className: `custom-box-sizing-border-box ${direction}`,
                                                    sx: {
                                                        height: '48px',
                                                        padding: '0 10px',
                                                        borderRadius: '8px',
                                                        background: '#F5F5F6',
                                                        border: '1px solid transparent',
                                                        '&:focus-within': {
                                                            border: '2px solid var(--Foundation-Blue-Normal, #304FFF)',
                                                        },
                                                    },
                                                }}
                                                inputProps={{
                                                    style: {
                                                        textAlign: direction === 'ltr' ? 'left' : 'right',
                                                    },
                                                }}
                                                required
                                            />
                                        </div >


                                    </>
                                ) : (

                                    <AutocompleteSelect
                                        options={clientItems.map(option => ({ value: option.makat, label: option.name }))}
                                        selectedValues={clientItems
                                            .filter(option => option.name === item.name)
                                            .map(option => ({ value: option.makat, label: option.name }))[0]}
                                        onChange={(e, newValue) => {
                                            handleInvoiceItem(index, newValue);
                                        }}
                                        placeholder={t('Item')}
                                        isMultiple={false}
                                        width='82%'
                                    />
                                )}
                                <div className="form-row" style={{ width: "82%" }}>
                                    <div className="form-row-item-title">
                                        {t('Quantity')}<span className="required-asterisk">*</span>
                                    </div>
                                    <TextField
                                        fullWidth
                                        placeholder={t('Quantity')}
                                        value={item.quantity}
                                        type="number"
                                        onChange={(e) => handleItemChange(index, 'quantity', e.target.value)}
                                        variant="standard"
                                        InputProps={{
                                            disableUnderline: true,
                                            className: `custom-box-sizing-border-box ${direction}`,
                                            sx: {
                                                height: '48px',
                                                padding: '0 10px',
                                                borderRadius: '8px',
                                                background: '#F5F5F6',
                                                border: '1px solid transparent',
                                                '&:focus-within': {
                                                    border: '2px solid var(--Foundation-Blue-Normal, #304FFF)',
                                                },
                                            },
                                        }}
                                        inputProps={{
                                            style: {
                                                textAlign: direction === 'ltr' ? 'left' : 'right',
                                            },
                                        }}
                                        required
                                    />
                                </div >
                                <div className="form-row" style={{ width: "82%" }}>
                                    <div className="form-row-item-title">
                                        {t('Price')}<span className="required-asterisk">*</span>
                                    </div>
                                    <TextField
                                        fullWidth
                                        placeholder={t('Price')}
                                        value={item.price}
                                        type="number"
                                        onChange={(e) => handleItemChange(index, 'price', e.target.value)}
                                        variant="standard"
                                        InputProps={{
                                            disableUnderline: true,
                                            className: `custom-box-sizing-border-box ${direction}`,
                                            sx: {
                                                height: '48px',
                                                padding: '0 10px',
                                                borderRadius: '8px',
                                                background: '#F5F5F6',
                                                border: '1px solid transparent',
                                                '&:focus-within': {
                                                    border: '2px solid var(--Foundation-Blue-Normal, #304FFF)',
                                                },
                                            },
                                        }}
                                        inputProps={{
                                            onWheel: (e) => e.target.blur(), // Prevent scroll from changing value
                                            style: {
                                                textAlign: direction === 'ltr' ? 'left' : 'right',
                                            },
                                        }}
                                        required
                                    />
                                </div >
                                <div className="form-row" style={{ width: "82%" }}>
                                    <div className="form-row-item-title">
                                        {t('Discount')}<span className="required-asterisk">*</span>
                                    </div>
                                    <TextField
                                        fullWidth
                                        placeholder={t('Discount')}
                                        value={item.discount}
                                        min={"0"}
                                        max={"100"}
                                        type="number"
                                        onChange={(e) => handleItemChange(index, 'discount', e.target.value)}
                                        variant="standard"
                                        InputProps={{
                                            disableUnderline: true,
                                            className: `custom-box-sizing-border-box ${direction}`,
                                            sx: {
                                                height: '48px',
                                                padding: '0 10px',
                                                borderRadius: '8px',
                                                background: '#F5F5F6',
                                                border: '1px solid transparent',
                                                '&:focus-within': {
                                                    border: '2px solid var(--Foundation-Blue-Normal, #304FFF)',
                                                },
                                            },
                                        }}
                                        inputProps={{
                                            style: {
                                                textAlign: direction === 'ltr' ? 'left' : 'right',
                                            },
                                        }}
                                        required
                                    />
                                </div >
                                <div className="form-row">
                                    <button className='clientItemButton' onClick={() => handleRemoveItem(index)}>
                                        {t('RemoveItem')}
                                        <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
                                            <circle cx="16" cy="16" r="13.3333" fill="#FF0000" />
                                            <path d="M10.666 10.666L21.333 21.333M21.333 10.666L10.666 21.333" stroke="white" stroke-width="2" stroke-linecap="round" />
                                        </svg>
                                    </button>
                                </div>
                            </>
                        ))}
                        <div className="form-row">
                            <button
                                className={`addClient-button ${!allClientItemFieldsFilled ? 'button-disabled' : ''}`}
                                style={{ width: '524px', margin: '20px' }}
                                onClick={allClientItemFieldsFilled ? handleCreateDraftInvoice : null}
                                disabled={!allClientItemFieldsFilled}
                            >
                                <span>{t('CreateDraftInvoice')}</span>
                            </button>


                            <button
                                className={`createInvoice-amount-button `}
                                style={{ width: '524px', margin: '20px' }}
                            >
                                <span>{t('TotalInvoiceAmount')}: {beautifyNumber(computeTotalInvoiceAmount())}</span>
                            </button>
                        </div>
                    </div>

                    {/* Signature Reference Modal */}
                    <SignatureReferenceModal
                        isVisible={signatureReferenceInvoiceModalVisible}
                        onClose={handleSignatureReferenceClose}
                        onSave={handleSignatureReferenceSave}
                        invoiceReference={invoiceReference}
                        setInvoiceReference={setInvoiceReference}
                        invoiceSignature={invoiceSignature}
                        setInvoiceSignature={setInvoiceSignature}
                        direction={direction}
                        needSignature={needSignature}
                        needReference={needReference}
                    />

                    {/* Draft PDF Modal */}
                    <Modal
                        open={draftPdfModalVisible}
                        onClose={handleCloseDraftPdfModal}
                        aria-labelledby="draft-invoice-modal-title"
                        aria-describedby="draft-invoice-modal-description"
                    >
                        <div className="draft-pdf-modal-container">
                            {/* PDF preview */}
                            {draftPdfBlob && (
                                <iframe
                                    src={URL.createObjectURL(draftPdfBlob)}
                                    width="100%"
                                    height="100%"
                                    style={{ border: 'none' }}
                                    aria-label="Draft Invoice PDF"
                                />
                            )}

                            {/* Buttons */}
                            <div className="draft-pdf-button-container">
                                <button
                                    className={`create-invoice-button ${!allClientItemFieldsFilled ? 'button-disabled' : ''}`}
                                    onClick={allClientItemFieldsFilled ? () => handleCreateInvoice() : null}
                                    disabled={!allClientItemFieldsFilled}
                                >
                                    <span>{t(type)}</span>
                                </button>

                                <button className="close-invoice-button" onClick={handleCloseDraftPdfModal}>
                                    {t('close')}
                                </button>
                            </div>
                        </div>
                    </Modal>

                    {/*  PDF Modal */}
                    <Modal
                        open={PdfModalVisible}
                        onClose={handleClosePdfModal}
                        aria-labelledby="draft-invoice-modal-title"
                        aria-describedby="draft-invoice-modal-description"
                    >
                        <div className="draft-pdf-modal-container">
                            {/* PDF preview */}
                            {PdfBlob && (
                                <iframe
                                    src={URL.createObjectURL(PdfBlob)}
                                    width="100%"
                                    height="100%"
                                    style={{ border: 'none' }}
                                    aria-label="Draft Invoice PDF"
                                />
                            )}

                            {/* Buttons */}
                            <div className="draft-pdf-button-container">


                                <button className="close-invoice-button" onClick={handleClosePdfModal}>
                                    {t('close')}
                                </button>
                            </div>
                        </div>
                    </Modal>


                </div>
                <ToastContainer />
            </div>
            {loading &&

                <div className="loader-container">
                    <svg width="0" height="0" style={{ position: 'absolute' }}>
                        <defs>
                            <linearGradient id="gradient" x1="0%" y1="0%" x2="100%" y2="100%">
                                <stop offset="0%" stopColor="#E57C22" />
                                <stop offset="100%" stopColor="#025366" />
                            </linearGradient>
                        </defs>
                    </svg>
                    <CircularProgress
                        size={200}
                        thickness={4}
                        sx={{
                            animation: 'progress 1.4s linear infinite',
                            '@keyframes progress': {
                                '0%': {
                                    transform: 'rotate(0deg)',
                                },
                                '100%': {
                                    transform: 'rotate(360deg)',
                                },
                            },
                            '& .MuiCircularProgress-circle': {
                                stroke: 'url(#gradient)',
                                strokeLinecap: 'round',
                            },
                        }}
                    />
                </div>
            }
        </>
    );
};

export default CreateInvoice;
