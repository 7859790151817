import React, { useState, useEffect, useCallback } from 'react';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import updateLocale from 'dayjs/plugin/updateLocale';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import { debounce } from 'lodash';
// Import Day.js locales
import 'dayjs/locale/en';
import 'dayjs/locale/ar';
import 'dayjs/locale/he';

// Add necessary plugins for formatting and localization
dayjs.extend(customParseFormat);
dayjs.extend(localizedFormat);
dayjs.extend(updateLocale);
dayjs.extend(utc);
dayjs.extend(timezone);

function CustomMonthDatePicker({ date, onDateChange, label, height = '48px', width = '50%' }) {
    const [open, setOpen] = useState(false);
    const [views, setViews] = useState(['month', 'year']); // Start with the month view
    const [inputValue, setInputValue] = useState(date ? dayjs(date).format('MM/YYYY') : '');
    const { i18n } = useTranslation();
    const [direction, setDirection] = useState('ltr'); // Default to 'ltr'

    useEffect(() => {
        // Update Day.js locale based on i18n.language
        dayjs.locale(i18n.language);

        // Set text direction based on language
        if (i18n.language === 'he' || i18n.language === 'ar') {
            setDirection('rtl');
        } else {
            setDirection('ltr');
        }
    }, [i18n.language]);

    const handleOpen = () => {
        setOpen(true);
        setViews(['month', 'year']); // Open with the month view
    };

    const handleClose = () => setOpen(false);

    const handleMonthDateChange = (newValue) => {
        // Set the date to the first day of the selected month
        const startOfMonth = dayjs(newValue).startOf('month');

        // Adjust for the timezone offset
        const offset = startOfMonth.utcOffset();
        const adjustedDate = startOfMonth.add(offset, 'minute').toDate();
        setOpen(false);
        onDateChange(adjustedDate);
    };

    const handleYearDateChange = (newValue) => {
        setOpen(true); // Keep the picker open to choose a month
        setViews(['year', 'month']);
    };

    const handleViewChange = (newView) => {
        // Change view to month or year
        setViews([newView]);
    };
    // Debounced version of onDateChange
    const debouncedOnDateChange = useCallback(
        debounce((date) => {
            onDateChange(date);
        }, 300),
        [] // Dependencies array ensures the debounce function is memoized
    );

    const handleChange = (newValue) => {
        if (newValue && newValue.isValid()) {
            const formattedDate = newValue.toDate();

            // Call the debounced function
            debouncedOnDateChange(formattedDate);

            // Update the input value
            setInputValue(newValue.format('MM/YYYY'));
        } else {
            setInputValue('');
        }
    };
    return (
        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={i18n.language}>
            <DatePicker
                views={views}
                open={open}
                onOpen={handleOpen}
                onClose={handleClose}
                label={label}
                value={date}
                variant="standard"
                onMonthChange={handleMonthDateChange}
                onYearChange={handleYearDateChange}
                onViewChange={handleViewChange}
                format="MM/YYYY"
                inputValue={inputValue}
                onInputChange={(event, newInputValue) => {
                    setInputValue(newInputValue);
                    const cleanValue = newInputValue.replace(/[^\d\/]/g, '');

                    if (dayjs(cleanValue, 'MM/YYYY', true).isValid()) {
                        const israelDate = dayjs(cleanValue, 'MM/YYYY').set('date', 1).toDate();
                        onDateChange(israelDate);
                    }
                }}
                onChange={(newValue) => handleChange(newValue)}
                slotProps={{

                    textField: {
                        // fullWidth: true, // Ensure the TextField takes full width
                        // sx: {
                        //     width: width, // Apply width 100% to the root TextField
                        // },
                        InputProps: {
                            disableUnderline: true,
                            className: `custom-box-sizing-border-box ${direction === 'ltr' ? 'ltr-input' : 'rtl-input'}`,
                            sx: {
                                height: height,
                                padding: '0 10px',
                                borderRadius: 'var(--Spacing-md, 8px)',
                                background: 'var(--bg-input, #F5F5F6)',
                                border: '1px solid transparent',
                            },
                        },
                        inputProps: {
                            style: {
                                textAlign: direction === 'ltr' ? 'left' : 'right',
                            },
                        },
                    },
                }}
            />
        </LocalizationProvider>
    );
}

export default CustomMonthDatePicker;
