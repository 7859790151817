import React, { useRef, useState, useEffect } from 'react';
import { Modal, Box, Button } from '@mui/material';
import html2canvas from 'html2canvas';
import html2pdf from 'html2pdf.js';
import jsPDF from 'jspdf';
import { beautifyNumber } from '../../../Utils/FormatNumber';
import hebrewFontBase64 from './NotoSansHebrew-Regular.base64.txt'; // Adjust the path as necessary
import IconButton from '@mui/material/IconButton';
import DownloadIcon from '@mui/icons-material/Download';
import TocIcon from '@mui/icons-material/Toc';
import * as XLSX from 'xlsx';
import { t } from 'i18next';
import PrintIcon from '@mui/icons-material/Print';
import PythonService from '../../../Services/pythonScripts.service';
const modalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '70%',
    height: '90%',
    bgcolor: 'background.paper',
    boxShadow: 24,
    borderRadius: '10px',
    overflowY: 'auto', // Add scroll functionality
};

const AccountCardReportHTML = ({ open, handleClose, data, onSortConfigChange, token, initialData, finalBalance = 0 }) => {
    const reportContentRef = useRef(null);
    const [text, setText] = useState('');
    // const handleDownloadPdf = async () => {
    //     const input = reportContentRef.current;
    //     const pdf = new jsPDF({
    //         orientation: 'portrait',
    //         unit: 'pt',
    //         format: 'a4',
    //     });

    //     const canvas = await html2canvas(input, {
    //         scale: 2,
    //         useCORS: true,
    //         allowTaint: true,
    //     });

    //     const imgData = canvas.toDataURL('image/png');
    //     const imgWidth = 595.28; // A4 width in pt
    //     const imgHeight = (canvas.height * imgWidth) / canvas.width;

    //     let heightLeft = imgHeight;
    //     let position = 0;

    //     pdf.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);

    //     heightLeft -= pdf.internal.pageSize.height;

    //     while (heightLeft > 0) {
    //         position = heightLeft - imgHeight;
    //         pdf.addPage();
    //         pdf.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
    //         heightLeft -= pdf.internal.pageSize.height;
    //     }

    //     pdf.save('AccountCardReport.pdf');
    // };
    useEffect(() => {
        fetchTextFile();
    }, []);

    const fetchTextFile = async () => {
        try {
            const response = await fetch(hebrewFontBase64);
            const textContent = await response.text();
            setText(textContent);
        } catch (error) {
            console.error('Error fetching the text file:', error);
        }
    };

    function fix(str) {
        return str.split('').reverse().join('');
    }


    const handleDownloadPdf = async () => {


        try {
            const response = await PythonService.convertHtmlToPdf(reportHtmlContent, token);

            if (response.ok) {
                const blob = await response.blob();
                const url = window.URL.createObjectURL(new Blob([blob]));
                const link = document.createElement('a');
                link.href = url;
                const fileName = `${data.clientName}-${data.clientCase}_דוח כרטסת.pdf`;
                link.setAttribute('download', fileName);
                document.body.appendChild(link);
                link.click();
                link.remove();
            } else {
                console.error('Failed to convert HTML to PDF:', response.status);
            }
        } catch (error) {
            console.error('Error converting HTML to PDF:', error);
        }
    };

    const handleDownloadExcel = () => {
        if (!data || !data.transactions) return;

        // Define the header translations manually
        const headers = {
            countraAccount: t('חשבון נגדי'),
            debitAccount: t('חובה'),
            creditAccount: t('זכות'),
            balance: t('יתרה'),
            uploadDate: t('תאריך אסמכתא'),
            documentDate: t('תאריך ערך'),
            reference1: t('אסמכתא 1'),
            reference2: t('אסמכתא 2'),
            command: t('פקודה חודש/שנה'),
            description: t('פרטים'),
            debitAccountName: t('שם חשבון חובה'),
            creditAccountName: t('שם חשבון זכות'),
            debitAccountId: t('מזהה חשבון חובה'),
            creditAccountId: t('מזהה חשבון זכות')
        };

        // Transform the data, excluding 'id' and 'type', and translating the headers
        const transformedData = data.transactions.map(transaction => {
            const transformedTransaction = {};
            for (const key in transaction) {
                if (headers[key]) {
                    transformedTransaction[headers[key]] = transaction[key];
                }
            }
            return transformedTransaction;
        });

        // Convert the transformed data to a worksheet
        const ws = XLSX.utils.json_to_sheet(transformedData);

        // Create a new workbook and append the worksheet
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, 'Transactions');

        // Write the workbook and download it
        XLSX.writeFile(wb, `${data.clientName}_AccountCardReport.xlsx`);
    };


    const generateReportHtml = () => {
        if (!data) return '';

        const totalDebitAmount = data.transactions?.reduce((acc, curr) => acc + curr.debitAccount, 0).toFixed(2);
        const totalCreditAmount = data.transactions?.reduce((acc, curr) => acc + curr.creditAccount, 0).toFixed(2);




        const startYear = new Date(data.startDate).getFullYear();
        const endYear = new Date(data.EndDate).getFullYear();
        const yearDisplay = startYear === endYear ? startYear : `${startYear} - ${endYear}`;

        const transactionsPerPage = 14;
        const totalTransactions = data.transactions?.length;

        let totalPages = Math.ceil((totalTransactions - 1) / transactionsPerPage);
        if (totalTransactions === 1) {
            totalPages = 1;
        }


        let fullContent = '';

        // Include the initialData row once at the top
        const initialDataRow = `
            <tr>
                <td></td>
                <td>${beautifyNumber(initialData?.debitAccountAmount || 0)}</td>
                <td>${beautifyNumber(initialData?.creditAccountAmount || 0)}</td>
                <td>${beautifyNumber(initialData?.balance || 0)}</td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td>${t(initialData?.description)}</td>
            </tr>
        `;

        for (let page = 0; page < totalPages; page++) {
            let start = page * transactionsPerPage;
            let end = start + transactionsPerPage > totalTransactions - 1 ? totalTransactions - 1 : start + transactionsPerPage;
            if (totalTransactions === 1) {
                end = 1;
            }


            const isLastPage = page === totalPages - 1;

            const transactionsRows = data.transactions.slice(start, end + 1).map((transaction, index) => `
                <tr ${index === data.transactions.length - 1 ? 'class="tr-final-row"' : ''}>
                    <td class="${index === data.transactions.length - 1 ? 'td-last-sum2' : ''}">${transaction.countraAccount}</td>
                    <td class="${index === data.transactions.length - 1 ? 'td-last-sum2' : ''}">${beautifyNumber(transaction.debitAccount)}</td>
                    <td class="${index === data.transactions.length - 1 ? 'td-last-sum2' : ''}">${beautifyNumber(transaction.creditAccount)}</td>
                    <td class="${index === data.transactions.length - 1 ? 'td-last-sum2' : ''}">${beautifyNumber(transaction.balance)}</td>
                    <td class="${index === data.transactions.length - 1 ? 'td-last-sum2' : ''}">${new Date(transaction.documentDate).toLocaleDateString('he-IL')}</td>
                    <td class="${index === data.transactions.length - 1 ? 'td-last-sum2' : ''}">${new Date(transaction.uploadDate).toLocaleDateString('he-IL')}</td>
                    <td class="${index === data.transactions.length - 1 ? 'td-last-sum2' : ''}">${transaction.reference1 || ''}</td>
                    <td class="${index === data.transactions.length - 1 ? 'td-last-sum2' : ''}">${transaction.reference2 || ''}</td>
                    <td class="${index === data.transactions.length - 1 ? 'td-last-sum2' : ''}">${transaction.command}</td>
                    <td class="${index === data.transactions.length - 1 ? 'td-last-sum2' : ''}">${transaction.description}</td>
                </tr>
                ${((index + 1) % 14 === 0) ? '<tr><td colspan="10"></td></tr>' : ''}
                ${((index + 1) % 14 === 0) ? '<tr><td colspan="10"></td></tr>' : ''}
                ${((index + 1) % 14 === 0) ? '<tr><td colspan="10"></td></tr>' : ''}
                ${((index + 1) % 14 === 0) ? '<tr><td colspan="10"></td></tr>' : ''}
                ${((index + 1) % 14 === 0) ? '<tr><td colspan="10"></td></tr>' : ''}
                ${((index + 1) % 14 === 0) ? '<tr><td colspan="10"></td></tr>' : ''}





            `).join('');

            fullContent += `
                <div>
                    <div class="upper-name-div">
                        <p class="first" style="font-size: 20px; margin-left:20px; text-align:right">${data.clientName}</p>
                        <p class="first" style="font-size: 20px; margin-left:20px; text-align:right">ח.פ. / מ.ע: ${data.clientCase}</p>
                    </div>
                    <div class="middle-details">
                        <div style="margin-left:90px; margin-top: 10px; padding: 0;">
                            <p class="rt1" style="font-size: 20px; margin: 0;">דוח כרטסת הנהלת חשבונות</p>
                            <div class="dates_container">
                                <p class="det-small" style="font-size: 20px; text-decoration: underline; font-weight: 900;">מערך ${new Date(data.startDate).toLocaleDateString('he-IL')} עד מערך ${new Date(data.EndDate).toLocaleDateString('he-IL')}</p>
                                <p class="det-small" style="font-size: 20px; text-decoration: underline; font-weight: 900; margin-right:20px">מתאריך ${new Date(data.startDate).toLocaleDateString('he-IL')} עד תאריך ${new Date(data.EndDate).toLocaleDateString('he-IL')}</p>
                            </div>
                        </div>
                    </div>
                    <div class="sum-details">
                        <p class="sums-of-transactions">${data.AccountingGroupClassifaction || '-'}</p>
                    </div>
                    <div class="titles-details">
                        <div style="margin-left: 40px;">
                            <div class="sums-of-transactions" style="margin-bottom: 10px; text-decoration: underline;">${data.palCodeAccountGroup || '-'}</div>
                            <div class="sums-of-transactions" style="text-decoration: underline;">${data.palCodeCategory || '-'}</div>
                        </div>
                        <div style="margin-right: 40px; text-align: right;">
                            <div class="sums-of-transactions" style="margin-bottom: 10px; text-decoration: underline;">${data.palCode || '-'}</div>
                            <div class="sums-of-transactions" style=" margin-bottom: 10px; text-decoration: underline;">${data.accountCard}</div>
                            <div class="sums-of-transactions" style="font-size: 18px; text-decoration: underline;">${yearDisplay}</div>
                        </div>
                    </div>
                    <table class="table" style="margin-right: 40px; margin-top: 20px;" dir="rtl">
                        <thead>
                             <tr class="tr">
                                <th id="header-account" class="td-header" data-header="countraAccount" onclick="window.parent.dispatchEvent(new CustomEvent('headerSortUpdate', { detail: JSON.stringify({ key: 'countraAccount', type: 'string' }) }))">
                                    חשבון נגדי
                                </th>
                                <th id="header-debit" class="td-header" data-header="debitAccount" onclick="window.parent.dispatchEvent(new CustomEvent('headerSortUpdate', { detail: JSON.stringify({ key: 'debitAccount', type: 'number' }) }))">
                                    חובה
                                </th>
                                <th id="header-credit" class="td-header" data-header="creditAccount" onclick="window.parent.dispatchEvent(new CustomEvent('headerSortUpdate', { detail: JSON.stringify({ key: 'creditAccount', type: 'number' }) }))">
                                    זכות
                                </th>
                                <th id="header-balance" class="td-header" data-header="balance" onclick="window.parent.dispatchEvent(new CustomEvent('headerSortUpdate', { detail: JSON.stringify({ key: 'balance', type: 'number' }) }))">
                                    יתרה
                                </th>
                                <th id="header-doc-date" class="td-header" data-header="uploadDate" onclick="window.parent.dispatchEvent(new CustomEvent('headerSortUpdate', { detail: JSON.stringify({ key: 'uploadDate', type: 'date' }) }))">
                                    תאריך אסמכתא
                                </th>
                                <th id="header-val-date" class="td-header" data-header="documentDate" onclick="window.parent.dispatchEvent(new CustomEvent('headerSortUpdate', { detail: JSON.stringify({ key: 'documentDate', type: 'date' }) }))">
                                    תאריך ערך
                                </th>
                                <th id="header-ref1" class="td-header" data-header="reference1" onclick="window.parent.dispatchEvent(new CustomEvent('headerSortUpdate', { detail: JSON.stringify({ key: 'reference1', type: 'number' }) }))">
                                    אסמכתא 1
                                </th>
                                <th id="header-ref2" class="td-header" data-header="reference2" onclick="window.parent.dispatchEvent(new CustomEvent('headerSortUpdate', { detail: JSON.stringify({ key: 'reference2', type: 'number' }) }))">
                                    אסמכתא 2
                                </th>
                                <th id="header-command" class="td-header" data-header="command" onclick="window.parent.dispatchEvent(new CustomEvent('headerSortUpdate', { detail: JSON.stringify({ key: 'command', type: 'string' }) }))">
                                    (פקודה) חודש/שנה
                                </th>
                                <th id="header-description" class="td-header" data-header="description" onclick="window.parent.dispatchEvent(new CustomEvent('headerSortUpdate', { detail: JSON.stringify({ key: 'description', type: 'string' }) }))">
                                    פרטים
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            ${page === 0 ? initialDataRow : ''}
                            ${transactionsRows}
                        </tbody>
                    </table>
                    ${isLastPage ? `
                    <table class="table" style="margin-right: 40px; margin-top: 20px;" dir="rtl">
                        <tbody>
                            <tr>
                                <td></td>
                               <td class="td-last-sum">
                                    סה''כ חובה: ${beautifyNumber(totalDebitAmount)}
                                </td>
                                <td class="td-last-sum">
                                    סה''כ זכות: ${beautifyNumber(totalCreditAmount)}
                                </td>
                                <td class="td-last-sum">
                                    סה''כ יתרה:  ${beautifyNumber(finalBalance)}
                                </td>
                                <td class="overflowed" colspan="6">סה''כ שם כרטיס עם מספר עסק אם יש</td>
                            </tr>
                        </tbody>
                    </table>
                   ` : ''}
                </div>`;
        }

        return `
            <!DOCTYPE html>
            <html lang="en">
            <head>
                <meta charset="UTF-8">
                <meta name="viewport" content="width=device-width, initial-scale=1.0">
                <title>AccountCard Report</title>
                <style>
                    @media print {
                        .page-break {
                            page-break-before: always;
                        }
    
                        .table thead {
                            display: table-header-group; /* Ensures the table header is repeated on each page */
                        }
                    }
                    .a4 {
                        position: relative;
                        width: 100%;
                        height: 100%;
                        margin-left: auto;
                        margin-right: auto;
                        box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
                        background: white;
                        page-break-after: always;
                        justify-content: center;
                        box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
                        margin-top:20px
                    }
                   
                    h1 {
                        color: navy;
                        text-align: center;
                    }
                    p {
                        color: #333;
                        padding: 20px;
                        text-align: center;
                    }
                    .container {
                        position: relative;
                        display: flex;
                        justify-content: flex-end;
                        align-items: center;
                        padding: 0px;
                        margin-inline-end: 50px;
                        border-bottom: 1px solid #000000;
                    }
                    .item {
                        color: black;
                        font-size: 12px;
                        font-family: 'Inter', sans-serif;
                        font-weight: 700;
                        word-wrap: break-word;
                        margin-left: 50px;
                    }
                    .upper-name-div {
                        display: -webkit-box;            /* Old syntax for older WebKit browsers */
                        display: -moz-box;               /* Old syntax for Firefox */
                        display: -ms-flexbox;            /* Old syntax for IE10 */
                        display: -webkit-flex;           /* Newer WebKit syntax */
                        display: flex;                   /* Standard syntax */

                        -webkit-box-orient: horizontal;  /* Ensures horizontal layout in older WebKit */
                        -moz-box-orient: horizontal;     /* Ensures horizontal layout in older Firefox */
                        -webkit-box-direction: reverse;  /* Reverses direction for older WebKit */
                        -moz-box-direction: reverse;     /* Reverses direction for older Firefox */
                        -webkit-flex-direction: row-reverse; /* Flexbox reverse direction for WebKit */
                        -ms-flex-direction: row-reverse; /* Flexbox reverse direction for IE10 */
                        flex-direction: row-reverse;     /* Standard syntax */

                        padding: 10px;
                        margin-right: 50px;

                       direction: rtl;
                       text-align: right;
                        justify-content: flex-end;
                    }
                     .dates_container {
                        display: -webkit-box;              /* Old syntax for older WebKit browsers */
                        display: -moz-box;                 /* Old syntax for Firefox */
                        display: -ms-flexbox;              /* Old syntax for IE10 */
                        display: -webkit-flex;             /* Newer WebKit syntax */
                        display: flex;                     /* Standard syntax */

                        -webkit-box-orient: horizontal;    /* Ensures horizontal layout in older WebKit */
                        -moz-box-orient: horizontal;       /* Ensures horizontal layout in older Firefox */
                        -webkit-box-direction: reverse;    /* Reverses direction for older WebKit */
                        -moz-box-direction: reverse;       /* Reverses direction for older Firefox */
                        -webkit-flex-direction: row-reverse; /* Flexbox reverse direction for WebKit */
                        -ms-flex-direction: row-reverse;   /* Flexbox reverse direction for IE10 */
                        flex-direction: row-reverse;       /* Standard syntax */
                        gap:20px
                    }
                    .upper-name-font {
                        font-weight: 800;
                    }
                    .line {
                        width: 95%;
                        height: 1px;
                        background-color: #000000;
                        margin-left: auto;
                        margin-right: auto;
                    }
                    .middle-details {
                        display: flex;
                        height: 80px;
                        justify-content: flex-start;
                        align-items: center;
                        padding-right: 30px;
                        padding-left: 30px;
                        justify-content: center;
                    }
                    .middle-under-details {
                        display: flex;
                        height: 80px;
                        align-items: center;
                        padding-right: 30px;
                    }
                    .rt1 {
                        font-size: 32px;
                        font-style: normal;
                        font-weight: 800;
                        line-height: normal;
                        direction: rtl;
                        text-decoration: underline;
                    }
                    .rt2 {
                        margin: 0;
                        padding: 0;
                        padding-bottom: 10px;
                        font-size: 23px;
                        font-style: normal;
                        font-weight: 800;
                        line-height: normal;
                        text-decoration: underline;
                    }
                    .num-total {
                        margin: 0;
                        padding: 0;
                        padding-bottom: 10px;
                        font-size: 23px;
                        font-style: normal;
                        font-weight: 800;
                        line-height: normal;
                        position: relative;
                        display: inline-block;
                    }
                    .num-total-no-upper {
                        margin: 0;
                        padding: 0;
                        padding-bottom: 10px;
                        font-size: 23px;
                        font-style: normal;
                        font-weight: 800;
                        line-height: normal;
                        position: relative;
                        display: inline-block;
                    }
                    .num-total::before {
                        content: '';
                        position: absolute;
                        left: 0;
                        right: 0;
                        height: 2px;
                        background: black;
                        top: -1px;
                    }
                    .num-total::after {
                        content: '';
                        position: absolute;
                        left: 0;
                        right: 0;
                        height: 2px;
                        background: repeating-linear-gradient(to right,
                                rgb(0, 0, 0),
                                rgb(0, 0, 0) 2px,
                                transparent 2px,
                                transparent 4px
                            );
                        bottom: 10px;
                    }
                    .num-total-no-upper::after {
                        content: '';
                        position: absolute;
                        left: 0;
                        right: 0;
                        height: 2px;
                        background: repeating-linear-gradient(to right,
                                rgb(0, 0, 0),
                                rgb(0, 0, 0) 2px,
                                transparent 2px,
                                transparent 4px
                            );
                        bottom: 10px;
                    }
                    .normal-num {
                        margin: 0;
                        padding: 0;
                        padding-bottom: 10px;
                        font-size: 23px;
                        font-style: normal;
                        line-height: normal;
                        position: relative;
                        direction: ltr;
                        display: inline-block;
                    }
                    .underlined {
                        margin: 0;
                        padding: 0;
                        padding-bottom: 10px;
                        font-size: 23px;
                        font-style: normal;
                        line-height: normal;
                        position: relative;
                        direction: ltr;
                        display: inline-block;
                    }
                    .underlined::after {
                        content: '';
                        position: absolute;
                        left: 0;
                        right: 0;
                        height: 1px;
                        background: rgb(0, 0, 0);
                        bottom: 10px;
                    }
                    .first {
                        margin: 0;
                        padding: 0;
                        padding-bottom: 10px;
                        font-size: 23px;
                        font-style: normal;
                        font-weight: 800;
                        line-height: normal;
                    }
                    .det {
                        margin: 0;
                        padding: 2px;
                        font-size: 23px;
                        font-style: normal;
                    }
                    .det-small {
                        margin: 0;
                        padding: 2px;
                        font-size: 18px;
                        font-style: normal;
                    }
                    .num {
                        margin: 0;
                        padding: 2px;
                        font-size: 23px;
                        font-style: normal;
                    }
                    .details-div {
                        width: 200px;
                        margin-right: 90px;
                        display: flex;
                        flex-direction: column;
                        align-items: end;
                    }
                    .details-line {
                        width: 100%;
                        height: 0px;
                        border: 1px solid black;
                        margin-left: auto;
                        margin-right: auto;
                    }
                    .period-div {
                        display: flex;
                        justify-content: flex-end;
                        flex-direction: row;
                        align-items: start;
                        width: 95%;
                        margin-left: auto;
                        margin-right: auto;
                        padding: 10px;
                    }
                    .overlay-line {
                        position: absolute;
                        top: 19.5%;
                        left: 0;
                        width: 95%;
                        height: 1px;
                        background: rgb(0, 0, 0);
                        z-index: 10;
                        transform: translateX(2.7%);
                    }
                    .column_div {
                        width: 200px;
                        display: flex;
                        flex-direction: column;
                        align-items: flex-end;
                    }
                    .table {
                        width: 100%;
                    }
                    .tr-underline {
                        direction: rtl;
                        border-bottom: 1px;
                        border-color: #000000;
                    }
                    td {
                        font-size: 14px;
                    }
                    .td-last {
                        border-bottom: 1px;
                        border-color: #000000;
                    }
                    .td-last:hover {
                        background-color: #000000;
                    }
                    .td-last-sum {
                            position: relative;
                            text-align: center; /* Ensure the text is centered */
                            font-weight: 1000;  /* Adjust font weight as needed */
                            padding-bottom: 8px; /* Add extra padding to make room for two lines */
                        }

                        .td-last-sum::before, 
                        .td-last-sum::after {
                            content: "";
                            position: absolute;
                            left: 0;
                            right: 0;
                            border-bottom: 1px solid black;
                        }

                        .td-last-sum::before {
                            bottom: -2px; /* First line closer to the text */
                        }

                        .td-last-sum::after {
                            bottom: -8px; /* Second line below the first one */
                    }
                    .td-header {
                        position: relative;
                        border-bottom: 1px solid black;
                    }
                    .td-last-sum2 {
                        position: relative;
                    }
                    .td-last-sum2::after {
                        content: "";
                        position: absolute;
                        left: 0;
                        right: 0;
                        bottom: -3px;
                        border-bottom: 1px solid black;
                    }
                    .tr {
                        border-bottom: 1px solid #b2b2b2;
                        font-size: 16px;
                    }
                    .tr-final-row {
                        border-bottom: 1px solid #000000;
                        font-size: 16px;
                    }
                    .tr:hover {
                        background-color: #ddd;
                    }
                    .th, td {
                        border: 0px solid black;
                        padding: 8px;
                        text-align: center;
                    }
                    .overflowed {
                        text-align: right;
                        padding-right: 20px !important;
                    }
                    .td {
                        display: flex;
                        flex-direction: column;
                        align-items: flex-end;
                        border: 1px solid black;
                        padding: 8px;
                        text-align: center;
                    }
                    .number-of-transactions {
                        font-size: 18px;
                        font-weight: 1000;
                        text-decoration: underline;
                    }
                    .sums-of-transactions {
                        font-size: 25px;
                        font-weight: 1000;
                    }
                    .number-total {
                        font-size: 16px;
                        font-weight: 1000;
                        text-align: center;
                        border: none; /* Remove any border */
                        box-shadow: none; /* Ensure no box-shadow is applied */
                        background-color: transparent; /* Ensure no background color */
                        padding: 0;
                        margin: 0;
                    }
                    .underline {
                        width: 100%;
                        border: 1px solid black;
                    }
                    .sum-details {
                        display: flex;
                        flex-direction: row-reverse;
                        align-content: center;
                        justify-content: center;
                    }
                    .titles-details {
                        display: -webkit-box;                /* Old syntax for older WebKit browsers */
                        display: -moz-box;                   /* Old syntax for Firefox */
                        display: -ms-flexbox;                /* Old syntax for IE10 */
                        display: -webkit-flex;               /* Newer WebKit syntax */
                        display: flex;                       /* Standard syntax */

                        -webkit-box-align: center;           /* Align content for older WebKit browsers */
                        -moz-box-align: center;              /* Align content for older Firefox */
                        -ms-flex-align: center;              /* Align content for IE10 */
                        align-content: center;               /* Standard syntax */

                        -webkit-box-pack: justify;           /* Justify content for older WebKit browsers */
                        -moz-box-pack: justify;              /* Justify content for older Firefox */
                        -ms-flex-pack: justify;              /* Justify content for IE10 */
                        -webkit-justify-content: space-between; /* Newer WebKit syntax for justifying content */
                        justify-content: space-between;      /* Standard syntax */
                    }

                    footer {
                        position: absolute;
                        left: 0;
                        bottom: 0;
                        width: 100%;
                        background-color: #f8f8f8;
                        text-align: center;
                        padding: 10px 0;
                        box-shadow: 0 -1px 10px 0 rgba(0, 0, 0, 0.1);

                    }
                    .footer-details {
                        font-family: 'Inter', sans-serif;
                        font-size: 16px;
                        font-style: normal;
                        font-weight: 300;
                        line-height: normal;
                        text-align: center;
                    }
                    .footer-Xtram {
                        margin-right: 10px;
                        margin-left: 10px;
                        font-weight: bold;
                        font-family: 'Inter', sans-serif;
                        font-size: 16px;
                        font-style: normal;
                        line-height: normal;
                        text-align: center;
                    }
                    .footer {
                        background-color: #c5c7c9;
                        text-align: left;
                        font-size: 18px;
                        color: #333;
                        display: flex;
                        height: 50px;
                        align-items:center
                    }
                </style>
            </head>
            <body style="height: 100vh;">
                ${fullContent}
            </body>
            </html>
        `;
    };

    const [sortConfig, setSortConfig] = useState({ key: '', direction: '', type: '' });

    const handleSortChange = (key, type) => {
        let direction = 'ascending';
        if (sortConfig.key === key && sortConfig.direction === 'ascending') {
            direction = 'descending';
        }
        setSortConfig({ key, direction, type });
    };



    const reportHtmlContent = generateReportHtml();

    useEffect(() => {
        const handleHeaderSortUpdate = (event) => {
            const { key, type } = JSON.parse(event.detail);

            const newSortConfig = { key, type };

            // Update the local sort configuration
            setSortConfig(newSortConfig);

            // Optionally, notify the parent component
            if (onSortConfigChange) {
                onSortConfigChange(newSortConfig);
            }
        };

        // Attach event listener for the custom event
        window.addEventListener('headerSortUpdate', handleHeaderSortUpdate);

        // Cleanup the event listener on component unmount
        return () => {
            window.removeEventListener('headerSortUpdate', handleHeaderSortUpdate);
        };
    }, [onSortConfigChange]); // Re-run effect when onSortConfigChange changes
    // This ensures the effect runs when the modal opens

    const handlePrint = () => {
        if (reportContentRef.current) {
            const printContent = reportContentRef.current.innerHTML;
            const printWindow = window.open('', '_blank');

            if (printWindow) {
                printWindow.document.write(`
                    <html>
                    <head>
                        <title>Print Report</title>
                        <style>
                            @media print {
                                @page { 
                                    margin: 0; 
                                }
                                body {
                                    margin: 20mm;
                                }
                                /* Additional styles for print */
                            }
                            body {
                                font-family: Arial, sans-serif;
                            }
                        </style>
                    </head>
                    <body>
                        ${printContent}
                    </body>
                    </html>
                `);

                printWindow.document.close();
                printWindow.focus();

                // Adding a slight delay before printing
                setTimeout(() => {
                    printWindow.print();
                    printWindow.close();
                }, 500); // 500ms delay, adjust if necessary
            } else {
                console.error("Failed to open print window. Pop-up might be blocked.");
            }
        }
    };



    return (
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="account-card-report-modal-title"
            aria-describedby="account-card-report-modal-description"
        >
            <>
                <Box sx={modalStyle}>
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%' }}>
                        <div
                            className="pdf-view-upper"
                            style={{
                                width: '50%',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                marginBottom: '10px',
                                borderRadius: '0 0 10px 10px'
                            }}
                        >
                            <div style={{ margin: '20px', textAlign: 'center' }}>
                                <Button
                                    variant="contained"
                                    onClick={handleDownloadPdf}
                                    startIcon={<DownloadIcon sx={{ ml: 1 }} />}
                                    sx={{ mt: 1, backgroundColor: '#025366', color: '#fff', '&:hover': { backgroundColor: '#1565c0' } }}
                                >
                                    {t('DownloadPDFFile')}
                                </Button>
                            </div>

                            <div style={{ margin: '20px', textAlign: 'center' }}>
                                <Button
                                    variant="contained"
                                    onClick={handleDownloadExcel}
                                    startIcon={<TocIcon sx={{ ml: 1 }} />}
                                    sx={{ mt: 1, backgroundColor: '#025366', color: '#fff', '&:hover': { backgroundColor: '#1565c0' } }}
                                >
                                    {t('DownloadExcelFile')}
                                </Button>
                            </div>

                            <div style={{ margin: '20px', textAlign: 'center' }}>
                                <Button
                                    variant="contained"
                                    onClick={handlePrint}
                                    startIcon={<PrintIcon sx={{ ml: 1 }} />}
                                    sx={{ mt: 1, backgroundColor: '#025366', color: '#fff', '&:hover': { backgroundColor: '#1565c0' } }}
                                >
                                    {t('PrintReport')}
                                </Button>
                                {/* <ReactToPrint
                                    trigger={() => (
                                        <Button
                                            variant="contained"
                                            startIcon={<PrintIcon sx={{ ml: 1 }} />}
                                            sx={{ mt: 1, backgroundColor: '#025366', color: '#fff', '&:hover': { backgroundColor: '#1565c0' } }}
                                        >
                                            {t('PrintReport')}
                                        </Button>
                                    )}
                                    content={() => reportContentRef.current}
                                /> */}
                            </div>
                        </div>
                    </div>
                    <div ref={reportContentRef} dangerouslySetInnerHTML={{ __html: reportHtmlContent }} />
                </Box>

            </>
        </Modal>
    );
};

export default AccountCardReportHTML;
