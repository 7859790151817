// ImportDialog.js
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    Typography,
} from '@mui/material';
import { useDropzone } from 'react-dropzone';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import './BankMatching.css'; // Custom animations

const ImportDialog = ({ open, onClose, onImport }) => {
    const [userFile, setUserFile] = useState(null);

    const onDrop = (acceptedFiles) => {
        // Set the first file in the array as the uploaded file
        setUserFile(acceptedFiles[0]);
    };

    const handleClearFile = () => {
        setUserFile(null); // Clear the selected file
    };

    const handleClose = () => {
        setUserFile(null); // Reset the file on dialog close
        onClose(); // Close the dialog
    };

    const handleImport = () => {
        if (userFile) {
            onImport(userFile); // Call the import handler if a file is selected
            handleClose(); // Optionally close the dialog after import
        }
    };

    const { t } = useTranslation();
    const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

    return (
        <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
            <DialogTitle style={{ textAlign: 'right' }}>{t("ImportFile")}</DialogTitle>
            <DialogContent>
                <div
                    {...getRootProps()}
                    style={{
                        border: '2px dashed #ccc',
                        borderRadius: '5px',
                        padding: '40px',
                        textAlign: 'center',
                        backgroundColor: isDragActive ? '#e0f7fa' : '#fafafa',
                        transition: 'background-color 0.3s ease',
                        cursor: 'pointer',
                    }}
                >
                    <input {...getInputProps()} />
                    {userFile ? (
                        <div>
                            {/* Display file name and file icon if a file is uploaded */}
                            <Typography variant="h6" style={{ color: "#7d7d7d", marginBottom: '10px' }}>
                                {userFile.name} {/* Display the name of the uploaded file */}
                            </Typography>
                            <InsertDriveFileIcon style={{ fontSize: '50px', color: '#7d7d7d' }} />
                        </div>
                    ) : isDragActive ? (
                        <div>
                            <Typography variant="h6" style={{ color: "#7d7d7d" }}>{t("DropFilesHere")}</Typography>
                            <CloudUploadIcon className="animated-upload-icon" />
                        </div>
                    ) : (
                        <div>
                            <Typography variant="h6" style={{ color: "#7d7d7d" }}>
                                {t("DragDropFilesOrClick")}
                            </Typography>
                            <CloudUploadIcon className="animated-upload-icon" />
                        </div>
                    )}
                </div>

                {/* Add Clear button when file is uploaded */}
                {userFile && (
                    <div style={{ display: 'flex', justifyContent: 'center', marginTop: '10px' }}>
                        <Button
                            variant="outlined"
                            onClick={handleClearFile}
                            color="secondary"
                        >
                            {t("Clear")}
                        </Button>
                    </div>
                )}
            </DialogContent>
            <DialogActions sx={{ justifyContent: 'center' }}>
                <Button onClick={handleClose}>{t("Cancel")}</Button>
                <Button
                    onClick={handleImport}
                    variant="contained"
                    color="primary"
                    disabled={!userFile} // Disable if no file is uploaded
                >
                    {t("Import")}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default ImportDialog;
