import React, { useState, useMemo, useEffect, useRef } from 'react';
import {
    TableContainer,
    Paper,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    TablePagination,
    IconButton,
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import { useTranslation } from 'react-i18next';
import { beautifyNumber } from '../../../Utils/FormatNumber';
import editIconImage from "../../../assests/images/Icons/editIcon.svg";


const AccountCardDashboardTable = ({
    firstRowDetails,
    filteredAndSortedTransactions,
    handleEdit,
    handleSortChange,
    formatDateNew,
}) => {
    const [currentPage, setCurrentPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(100);
    const tableContainerRef = useRef(null);
    const { i18n, t } = useTranslation();
    const direction = i18n.dir();
    // Reset page when data changes
    useEffect(() => {
        setCurrentPage(0);
    }, [filteredAndSortedTransactions]);

    // Pagination logic
    const paginatedTransactions = useMemo(() => {
        const start = currentPage * rowsPerPage;
        const end = start + rowsPerPage;
        return filteredAndSortedTransactions.slice(start, end);
    }, [filteredAndSortedTransactions, currentPage, rowsPerPage]);

    const handleChangePage = (event, newPage) => {
        setCurrentPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setCurrentPage(0);
    };

    const headerStyle = {
        backgroundColor: 'var(--foundation-blue-light-active, #BFC8FF)',
        color: 'var(--Foundation-Blue-Normal, #304FFF)',
        textAlign: 'center',
        fontFamily: 'Montserrat',
        fontSize: '14px',
        fontWeight: 'bold',
        height: '56px',
        padding: '0',
    };

    return (
        <div className={`${direction}`} style={{ width: '100%', height: '55vh' }}>
            <TableContainer
                component={Paper}
                sx={{ height: '50vh', overflow: 'auto' }}
                ref={tableContainerRef}
            >
                <Table
                    aria-label="Account Card Dashboard Table"
                    stickyHeader
                    className="sticky-header"
                    style={{ zIndex: '1' }}
                >
                    <TableHead ref={tableContainerRef}>
                        <TableRow>
                            <TableCell
                                align="center"
                                className="table-headers"
                                sx={{ width: '10px', ...headerStyle }}
                            />
                            <TableCell
                                align="center"
                                className="table-headers-accountcard"
                                sx={headerStyle}
                                onClick={() => handleSortChange('countraAccount', 'string')}
                            >
                                {t('OppositeAccount')}
                            </TableCell>
                            <TableCell
                                align="center"
                                className="table-headers-accountcard"
                                sx={headerStyle}
                                onClick={() => handleSortChange('debitAccount', 'number')}
                            >
                                {t('Debit')}
                            </TableCell>
                            <TableCell
                                align="center"
                                className="table-headers-accountcard"
                                sx={headerStyle}
                                onClick={() => handleSortChange('creditAccount', 'number')}
                            >
                                {t('Credit')}
                            </TableCell>
                            <TableCell
                                align="center"
                                className="table-headers-accountcard"
                                sx={headerStyle}
                                onClick={() => handleSortChange('balance', 'number')}
                            >
                                {t('Balance')}
                            </TableCell>
                            <TableCell
                                align="center"
                                className="table-headers-accountcard"
                                sx={headerStyle}
                                onClick={() => handleSortChange('uploadDate', 'date')}
                            >
                                {t('ReferenceDate')}
                            </TableCell>
                            <TableCell
                                align="center"
                                className="table-headers-accountcard"
                                sx={headerStyle}
                                onClick={() => handleSortChange('documentDate', 'date')}
                            >
                                {t('ValueDate')}
                            </TableCell>
                            <TableCell
                                align="center"
                                className="table-headers-accountcard"
                                sx={headerStyle}
                                onClick={() => handleSortChange('reference1', 'string')}
                            >
                                {t('Reference1')}
                            </TableCell>
                            <TableCell
                                align="center"
                                className="table-headers-accountcard"
                                sx={headerStyle}
                                onClick={() => handleSortChange('reference2', 'string')}
                            >
                                {t('Reference2')}
                            </TableCell>
                            <TableCell
                                align="center"
                                className="table-headers-accountcard"
                                sx={headerStyle}
                                onClick={() => handleSortChange('description', 'string')}
                            >
                                {t('Details')}
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {/* First Row Details */}
                        {firstRowDetails && (
                            <TableRow>
                                <TableCell align="center" sx={{ width: '30px' }}></TableCell>
                                <TableCell align="center" />
                                <TableCell align="center">
                                    {firstRowDetails.creditAccountAmount !== undefined
                                        ? beautifyNumber(firstRowDetails.creditAccountAmount)
                                        : '-'}
                                </TableCell>
                                <TableCell align="center">
                                    {firstRowDetails.debitAccountAmount !== undefined
                                        ? beautifyNumber(firstRowDetails.debitAccountAmount)
                                        : '-'}
                                </TableCell>
                                <TableCell align="center" />
                                <TableCell align="center" />
                                <TableCell align="center" />
                                <TableCell align="center" />
                                <TableCell align="center" />
                                <TableCell align="center">
                                    {t(firstRowDetails.description) ?? '-'}
                                </TableCell>
                            </TableRow>
                        )}


                        {/* Transactions */}
                        {paginatedTransactions.map((transaction, index) => (
                            <TableRow key={index}>
                                <TableCell align="center" sx={{ width: '30px' }}>
                                    <IconButton
                                        className="row-buttons"
                                        onClick={() => handleEdit(transaction, index)}
                                    >
                                        <img src={editIconImage} alt="Edit" />
                                    </IconButton>
                                </TableCell>
                                <TableCell align="center">
                                    {transaction.countraAccount || '-'}
                                </TableCell>
                                <TableCell align="center">
                                    {transaction.debitAccount !== undefined
                                        ? beautifyNumber(transaction.debitAccount)
                                        : '0.00'}
                                </TableCell>
                                <TableCell align="center">
                                    {transaction.creditAccount !== undefined
                                        ? beautifyNumber(transaction.creditAccount)
                                        : '0.00'}
                                </TableCell>
                                <TableCell align="center">
                                    {transaction.balance !== undefined
                                        ? beautifyNumber(transaction.balance)
                                        : '0.00'}
                                </TableCell>
                                <TableCell align="center">
                                    {transaction.uploadDate
                                        ? formatDateNew(transaction.uploadDate)
                                        : '-'}
                                </TableCell>
                                <TableCell align="center">
                                    {transaction.documentDate
                                        ? formatDateNew(transaction.documentDate)
                                        : '-'}
                                </TableCell>
                                <TableCell align="center">
                                    {transaction.reference1 || '-'}
                                </TableCell>
                                <TableCell align="center">
                                    {transaction.reference2 || '-'}
                                </TableCell>
                                <TableCell align="center">
                                    {transaction.description || '-'}
                                </TableCell>
                            </TableRow>
                        ))}

                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                rowsPerPageOptions={[5, 10, 25, 50, 100]}
                component="div"
                count={filteredAndSortedTransactions.length}
                rowsPerPage={rowsPerPage}
                page={currentPage}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                labelRowsPerPage={t('Rows per page')}
                labelDisplayedRows={({ from, to, count }) =>
                    `${from}-${to} ${t('of')} ${count !== -1 ? count : `${t('more than')} ${to}`
                    }`
                }
            />
        </div>
    );
};

export default AccountCardDashboardTable;
