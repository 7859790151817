// OtherDocumentCreateModal.js

import React, { useState, useEffect } from 'react';
import {
    Modal,
    TextField,
    Autocomplete,
    Popper,
} from '@mui/material';
import CustomDatePicker from '../../CustomDatePicker/CustomDatePicker';

const OtherDocumentCreateModal = ({
    modalVisible,
    closeCreateModal,
    t,
    direction,
    selectOtherDocumentsOptions,
    handleSave,
    initialFormData,
    errorMessage,
    okMessage,
}) => {
    const [formData, setFormData] = useState(initialFormData || {});
    const [documents, setDocuments] = useState([]);

    useEffect(() => {
        if (initialFormData) {
            setFormData(initialFormData);
        } else {
            setFormData({
                title: '',
                documentType: '',
                uploadDate: new Date().toISOString(),
                uploadNumber: '',
            });
        }
    }, [initialFormData]);

    const handleChange = (field, value) => {
        setFormData((prev) => ({ ...prev, [field]: value }));
    };

    const inputPropsStyle = {
        disableUnderline: true,
        className: `custom-box-sizing-border-box ${direction === 'ltr' ? 'ltr-input' : 'rtl-input'}`,
        sx: {
            height: '48px',
            padding: '0 10px',
            borderRadius: '8px',
            background: '#F5F5F6',
            border: '1px solid transparent',
            '&:focus-within': {
                border: '2px solid var(--Foundation-Blue-Normal, #304FFF)',
            },
            '& *': {
                boxSizing: 'border-box !important',
            },
        },
    };

    const inputStyle = {
        style: { textAlign: direction === 'ltr' ? 'left' : 'right' },
    };

    const allFieldsFilled = formData.title && formData.documentType && documents.length > 0;

    const handleDocumentUpload = (event) => {
        const files = Array.from(event.target.files);
        setDocuments(files);
    };

    const handleModalSave = () => {
        if (allFieldsFilled) {
            // Prepare data to save
            const newData = {
                ...formData,
                documents,
            };
            handleSave(newData,documents);
            closeCreateModal();
        } 
    };

    return (
        <Modal open={modalVisible} onClose={closeCreateModal}>
            <div className="create-modal-overlay">
                <div className="modal-background">
                    <div className={`newDocument-modal-content ${direction}`}>
                        <div className={`modal-content-title ${direction}`}>
                            <h2>{t('newDocument')}</h2>
                        </div>

                        <div className={`newDocument-container-items ${direction}`}>
                            <div className="newDocument-form-row-item">
                                <div className="edit-form-row-item-title">
                                    {t('Title')}<span className="required-asterisk">*</span>
                                </div>
                                <TextField
                                    fullWidth
                                    value={formData.title || ''}
                                    onChange={(e) => handleChange('title', e.target.value)}
                                    variant="standard"
                                    InputProps={inputPropsStyle}
                                    inputProps={inputStyle}
                                    placeholder={t('Title')}
                                />
                            </div>

                            <div className="newDocument-form-row-item">
                                <div className="edit-form-row-item-title">
                                    {t('Document Type')}<span className="required-asterisk">*</span>
                                </div>
                                <Autocomplete
                                    options={selectOtherDocumentsOptions}
                                    getOptionLabel={(option) => option.name}
                                    value={
                                        selectOtherDocumentsOptions.find(
                                            (option) => option.name === formData.documentType
                                        ) || null
                                    }
                                    onChange={(e, newValue) =>
                                        handleChange('documentType', newValue ? newValue.name : '')
                                    }
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            variant="standard"
                                            InputProps={{ ...params.InputProps, ...inputPropsStyle }}
                                            inputProps={{ ...params.inputProps, ...inputStyle }}
                                            placeholder={t('Document Type')}
                                        />
                                    )}
                                    PopperComponent={(props) => (
                                        <Popper
                                            {...props}
                                            style={{
                                                ...props.style,
                                                direction: direction,
                                            }}
                                        />
                                    )}
                                />
                            </div>

                            {/* <div className="newDocument-form-row-item">
                                <div className="edit-form-row-item-title">
                                    {t('Upload Date')}<span className="required-asterisk">*</span>
                                </div>
                                <CustomDatePicker
                                    date={formData.uploadDate ? new Date(formData.uploadDate) : null}
                                    onDateChange={(date) => {
                                        if (date) {
                                            handleChange('uploadDate', date.toISOString());
                                        }
                                    }}
                                    height="48px"
                                />
                            </div> */}

                            {/* <div className="newDocument-form-row-item">
                                <div className="edit-form-row-item-title">
                                    {t('UploadNumber')}
                                </div>
                                <TextField
                                    fullWidth
                                    value={formData.uploadNumber || ''}
                                    onChange={(e) => handleChange('uploadNumber', e.target.value)}
                                    variant="standard"
                                    InputProps={inputPropsStyle}
                                    inputProps={inputStyle}
                                    placeholder={t('UploadNumber')}
                                />
                            </div> */}
                        </div>

                        <div className="newDocument-autoContainer">
                            <div className={`newDocument-file-upload-container input-field_Container_required-asterisk`}>
                                <input
                                    type="file"
                                    id="file"
                                    className="input-file"
                                    multiple
                                    onChange={handleDocumentUpload}
                                />
                                <label htmlFor="file" className="newDocument-upload-label">
                                    <svg xmlns="http://www.w3.org/2000/svg" className="upload-icon" viewBox="0 0 58 58" fill="none">
                                        <path fillRule="evenodd" clipRule="evenodd" d="M29 38.0625C30.001 38.0625 30.8125 37.251 30.8125 36.25V9.73298L34.874 14.4712C35.5253 15.2313 36.6696 15.3193 37.4296 14.6678C38.1896 14.0164 38.2776 12.8721 37.6261 12.1121L30.3761 3.65378C30.0319 3.25204 29.529 3.02084 29 3.02084C28.471 3.02084 27.9681 3.25204 27.624 3.65378L20.3739 12.1121C19.7224 12.8721 19.8104 14.0164 20.5705 14.6678C21.3305 15.3193 22.4747 15.2313 23.1262 14.4712L27.1875 9.73298V36.25C27.1875 37.251 27.999 38.0625 29 38.0625Z" fill="#F9AA2A" />
                                        <path d="M38.6673 21.75C36.9703 21.75 36.1218 21.75 35.5124 22.1572C35.2485 22.3336 35.0218 22.5602 34.8456 22.8241C34.4381 23.4336 34.4381 24.2822 34.4381 25.9792V36.25C34.4381 39.2529 32.0038 41.6875 29.0006 41.6875C25.9977 41.6875 23.5632 39.2529 23.5632 36.25V25.9792C23.5632 24.2822 23.5632 23.4335 23.1559 22.824C22.9796 22.5601 22.7531 22.3336 22.4893 22.1573C21.8797 21.75 21.0311 21.75 19.334 21.75C12.4986 21.75 9.08094 21.75 6.95746 23.8735C4.83398 25.9971 4.83398 29.4142 4.83398 36.2495V38.6662C4.83398 45.5015 4.83398 48.9191 6.95746 51.0427C9.08094 53.1662 12.4986 53.1662 19.334 53.1662H38.6673C45.5026 53.1662 48.9203 53.1662 51.0438 51.0427C53.1673 48.9191 53.1673 45.5015 53.1673 38.6662V36.2495C53.1673 29.4142 53.1673 25.9971 51.0438 23.8735C48.9203 21.75 45.5026 21.75 38.6673 21.75Z" fill="#F9AA2A" />
                                    </svg>
                                    <span className="upload-text">
                                        {documents.length > 0 ? documents.map(doc => doc.name).join(', ') : t('uploadDocument')}
                                    </span>
                                </label>
                            </div>
                        </div>

                        <div className="newDocument-button-container">
                            <div
                                className={`newDocument-create-buttons ${!allFieldsFilled ? 'disabled' : ''}`}
                                onClick={allFieldsFilled ? handleModalSave : null}
                            >
                                {t('save')}
                            </div>
                            <div className="newDocument-cancel-buttons" onClick={closeCreateModal}>
                                {t('close')}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Modal>
    );
};

export default OtherDocumentCreateModal;
