import React, { createContext, useContext, useEffect, useState } from 'react';
import UserService from '../Services/user.service';
import * as signalR from "@microsoft/signalr";

// Create a context
const SignalRContext = createContext();

// Context provider component
export const SignalRProvider = ({ children }) => {
    const [connection, setConnection] = useState();
    const [documentUpdate, setDocumentUpdate] = useState({});
    const [user, setUser] = useState();
    const [token, setToken] = useState();

    useEffect(() => {
        const initializeSignalRConnection = async () => {
          try {
            const user = await UserService.getCurrentUser();
            const token = await UserService.getCurrentToken();
            
            if (!user || !token) {
              console.log('User or token is not available');
              return;
            }
            
            setUser(user);
            setToken(token);
      
            const buildConnection = new signalR.HubConnectionBuilder()
            .withUrl(`https://accounting-manager.com:5163/documentHub?accountantId=${user.accountantId}`)
            .configureLogging(signalR.LogLevel.Debug)
            .withAutomaticReconnect() // This configures the automatic reconnect functionality
            .build();
      
            setConnection(buildConnection);
      
            await buildConnection.start();
            console.log('SignalR Connection established successfully.');
      
            buildConnection.on("ReceiveDocumentUpdate", (caseId, documentsIds, documentsCount, totalCounts) => {
              console.log("Received document update");
              setDocumentUpdate(prevState => ({
                ...prevState,
                [caseId]: {
                  documentsIds,
                  documentsCount,
                  totalCounts
                }
              }));
            });

            // Handle the reconnection event
            buildConnection.onreconnected(connectionId => {
              console.log(`SignalR reconnected. Connection ID: ${connectionId}`);
            });

            // Handle the reconnecting event
            buildConnection.onreconnecting(error => {
              console.log(`SignalR reconnecting due to error: ${error}`);
            });

          } catch (err) {
            console.error('Error while starting connection or fetching user/token:', err);
          }
        };

        initializeSignalRConnection();
      
        return () => {
          if (connection) {
            connection.stop();
            console.log('SignalR Connection stopped');
          }
        };
      }, []);

    return (
        <SignalRContext.Provider value={{ connection, documentUpdate }}>
            {children}
        </SignalRContext.Provider>
    );
};

export const useSignalR = () => useContext(SignalRContext);
