import React, { useState, useEffect } from 'react';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import updateLocale from 'dayjs/plugin/updateLocale';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import { debounce } from 'lodash';

// Import Day.js locales
import 'dayjs/locale/en';
import 'dayjs/locale/ar';
import 'dayjs/locale/he';

// Add necessary plugins for formatting and localization
dayjs.extend(customParseFormat);
dayjs.extend(localizedFormat);
dayjs.extend(updateLocale);

function CustomDatePicker({ date, onDateChange, label, height = '48px' }) {
    const [inputValue, setInputValue] = useState(date ? dayjs(date).format('DD/MM/YYYY') : '');
    const { i18n } = useTranslation();
    const [direction, setDirection] = useState('ltr'); // Default to 'ltr'

    // Debounce the onDateChange call to add a delay when typing
    const debouncedDateChange = debounce((newDate) => {
        if (dayjs(newDate, 'DD/MM/YYYY', true).isValid()) {
            onDateChange(newDate ? dayjs(newDate, 'DD/MM/YYYY').toDate() : null);
        }
    }, 300);

    useEffect(() => {
        // Update Day.js locale based on i18n.language
        dayjs.locale(i18n.language);

        // Set text direction based on language
        if (i18n.language === 'he' || i18n.language === 'ar') {
            setDirection('rtl');
        } else {
            setDirection('ltr');
        }
    }, [i18n.language]);

    return (
        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={i18n.language}>
            <DatePicker
                label={label}
                value={date ? dayjs(date) : null}
                onChange={(newValue) => {
                    if (newValue && newValue.isValid()) {
                        onDateChange(newValue.toDate());
                        setInputValue(newValue.format('DD/MM/YYYY'));
                    } else {
                        setInputValue('');
                    }
                }}
                format="DD/MM/YYYY"
                inputValue={inputValue}
                onInputChange={(event, newInputValue) => {
                    setInputValue(newInputValue);
                    const cleanValue = newInputValue.replace(/[^\d\/]/g, '');
                    debouncedDateChange(cleanValue); // Use debounce for validation and setting the date
                }}
                slotProps={{
                    textField: {
                        fullWidth: true, // Ensure the TextField takes full width
                        sx: {
                            width: '100%', // Apply width 100% to the root TextField
                        },
                        InputProps: {
                            disableUnderline: true,
                            className: `custom-box-sizing-border-box ${direction === 'ltr' ? 'ltr-input' : 'rtl-input'}`,
                            sx: {
                                height: height,
                                padding: '0 10px',
                                borderRadius: 'var(--Spacing-md, 8px)',
                                background: 'var(--bg-input, #F5F5F6)',
                                border: '1px solid transparent',
                                '&:focus-within': {
                                    border: '2px solid var(--Foundation-Blue-Normal, #304FFF)',
                                },
                            },
                        },
                        inputProps: {
                            style: {
                                textAlign: direction === 'ltr' ? 'left' : 'right',
                            },
                        },
                    },
                }}
            />
        </LocalizationProvider>
    );
}

export default CustomDatePicker;
