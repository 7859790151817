// SignatureReferenceModal.jsx

import React, { useRef } from 'react';
import SignaturePad from 'react-signature-canvas';
import cancelIcon from '../../../assests/images/cancel.png'; // Adjust the import path as needed
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import './SignatureReferenceModal.css'; // Optional: Create a CSS file for styling
import invoiceReferenceAnimation from '../../../assests/images/Animations/InvoiceReference.json';
import Lottie from 'lottie-react';
import TextField from '@mui/material/TextField';

const SignatureReferenceModal = ({
    isVisible,
    onClose,
    onSave,
    invoiceReference,
    setInvoiceReference,
    invoiceSignature,
    setInvoiceSignature,
    direction,
    needSignature,
    needReference,
}) => {
    const { t } = useTranslation();
    const sigPad = useRef();

    const handleSignatureChange = () => {
        if (sigPad.current) {
            const signatureDataUrl = sigPad.current.getTrimmedCanvas().toDataURL('image/png');
            setInvoiceSignature(signatureDataUrl);
        }
    };

    const clearSignature = () => {
        if (sigPad.current) {
            sigPad.current.clear();
            setInvoiceSignature('');
        }
    };

    const handleBackgroundClick = (e) => {
        e.stopPropagation(); // Prevent modal from closing when clicking inside the modal content
    };

    // Determine if the save button should be disabled
    const isSaveDisabled = () => {
        if (needSignature && needReference) {
            return !invoiceSignature || !invoiceReference;
        } else if (needSignature) {
            return !invoiceSignature;
        } else if (needReference) {
            return !invoiceReference;
        }
        return false; // If neither is needed, the save button can be enabled
    };

    return (
        <>
            {isVisible && (
                <div className="create-modal-overlay" onClick={onClose}>
                    <div className="modal-background" onClick={handleBackgroundClick}>
                        <div className={`signatureReference-content ${direction}`} >
                            <Lottie
                                animationData={invoiceReferenceAnimation}
                                loop={true}
                                style={{ height: 188, width: 188, margin: '0 auto' }}
                            />
                            <h2>{t('NewInvoiceSignature')}</h2>

                            {/* Conditionally render the reference input */}
                            {needReference && (
                                <div className="form-row-item" style={{ width: '100%', margin: '20px 0' }}>
                                    <div className="form-row-item-title">
                                        {t('Reference')}<span className="required-asterisk">*</span>
                                    </div>
                                    <TextField
                                        fullWidth
                                        placeholder={t('LastInvoiceNumberOrStartWith1')}
                                        value={invoiceReference}
                                        onChange={(e) => setInvoiceReference(e.target.value)}
                                        variant="standard"
                                        InputProps={{
                                            disableUnderline: true,
                                            className: `custom-box-sizing-border-box ${direction}`,

                                            sx: {
                                                height: '48px',
                                                padding: '0 10px',
                                                borderRadius: '8px',
                                                background: '#F5F5F6',
                                                border: '1px solid transparent',
                                                '&:focus-within': {
                                                    border: '2px solid var(--Foundation-Blue-Normal, #304FFF)',
                                                },
                                            },
                                        }}
                                        inputProps={{
                                            style: {
                                                textAlign: direction === 'ltr' ? 'left' : 'right',
                                            },
                                        }}
                                        required
                                    />
                                </div>
                            )}

                            {/* Conditionally render the signature pad */}
                            {needSignature && (
                                <>
                                    <SignaturePad
                                        ref={sigPad}
                                        canvasProps={{ className: 'signatureCanvasContainer' }}
                                        onEnd={handleSignatureChange}
                                    />
                                    <button className="clientItemButton clearSignature" onClick={clearSignature}>
                                        {t('ClearSignature')}
                                        <img src={cancelIcon} alt="Clear" className="clientItem-icon clientItemListBack" />
                                    </button>
                                </>
                            )}

                            <div className="download-button-row">
                                <button
                                    className={`signatureReference-save-button ${isSaveDisabled() ? 'disabled' : ''}`}
                                    onClick={onSave}
                                    disabled={isSaveDisabled()}
                                >
                                    {t('save')}
                                </button>
                                <button
                                    className="signatureReference-cancel-button"
                                    onClick={onClose}
                                >
                                    {t('close')}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

SignatureReferenceModal.propTypes = {
    isVisible: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    onSave: PropTypes.func.isRequired,
    invoiceReference: PropTypes.string.isRequired,
    setInvoiceReference: PropTypes.func.isRequired,
    invoiceSignature: PropTypes.string.isRequired,
    setInvoiceSignature: PropTypes.func.isRequired,
    direction: PropTypes.string.isRequired,
    needSignature: PropTypes.bool.isRequired,
    needReference: PropTypes.bool.isRequired,
};

export default SignatureReferenceModal;
