import config from '../config';
import selectedEnvironment from '../env';
const API_URL = `${config[selectedEnvironment].API_URL}/Accountants`;
const API_URL_SubAccountant = `${config[selectedEnvironment].API_URL}/SubAccountants`;
// Function to update user information
const UpdateUser = async (accountantId, token) => {
    try {
        
        const response = await fetch(`${API_URL}/${accountantId}`, {
            method: 'GET',
            headers: {
                "Authorization": 'Bearer ' + token,
                "Content-Type": "application/json",
            },
        });

        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }

        const data = await response.json();
        
        localStorage.setItem('user', JSON.stringify(data));
        return data;
    } catch (error) {
    }
};

const addClient = async (clientData, token) => {
    const response = await fetch(`${API_URL}/CreateClient`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            "Authorization": 'Bearer ' + token,
        },
        body: JSON.stringify(clientData),
    });

    if (!response.ok) {
        const errorBody = await response.text(); // Get the response text
        throw new Error(`Failed to add client: ${errorBody}`);
    }

    // Check if the response is JSON
    const contentType = response.headers.get("content-type");
    if (contentType && contentType.indexOf("application/json") !== -1) {
        return response.json();
    } else {
        return response.text(); // If not JSON, just return the text response
    }
}


// Function to get the current logged-in user
const getCurrentUser = () => {
    const user = localStorage.getItem('user');
    return user != null ? JSON.parse(user) : null;
};

// Function to get the current token
const getCurrentToken = () => {
    const token = localStorage.getItem('token');
    return token != null ? JSON.parse(token) : null;
};

// Function to get the current token
const getCurrentUserType = () => {
    const userType = localStorage.getItem('userType');
    return userType != null ? JSON.parse(userType) : null;
};

const EditUser = async (token, user) => {
    try {
        const response = await fetch(`${API_URL}/Update`, {
            method: 'PUT',
            headers: {
                "Authorization": 'Bearer ' + token,
                "Content-Type": "application/json",
            },
            body: JSON.stringify({ ...user })
        });

        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }

        return response;
    } catch (error) {
    }
};

const EditSubAccountant = async (token, user) => {
    try {
        const response = await fetch(`${API_URL_SubAccountant}/Update`, {
            method: 'PUT',
            headers: {
                "Authorization": 'Bearer ' + token,
                "Content-Type": "application/json",
            },
            body: JSON.stringify({ ...user })
        });

        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }

        return response;
    } catch (error) {
    }
};

const addSubAccountant = async (subAccountantData, token) => {
    const response = await fetch(`${API_URL_SubAccountant}/Create`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            "Authorization": 'Bearer ' + token,
        },
        body: JSON.stringify(subAccountantData),
    });

    if (!response.ok) {
        const errorBody = await response.text(); // Get the response text
        throw new Error(`Failed to add client: ${errorBody}`);
    }

    // Check if the response is JSON
    const contentType = response.headers.get("content-type");
    if (contentType && contentType.indexOf("application/json") !== -1) {
        return response.json();
    } else {
        return response.text(); // If not JSON, just return the text response
    }
}

// Function to update user information
const GetSubAccountant = async (subAccountantId, token) => {
    try {
        const response = await fetch(`${API_URL_SubAccountant}/${subAccountantId}`, {
            method: 'GET',
            headers: {
                "Authorization": 'Bearer ' + token,
                "Content-Type": "application/json",
            },
        });

        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }

        const data = await response.json();
        localStorage.setItem('user', JSON.stringify(data));
        return data;
    } catch (error) {
    }
};


const UserService = {
    getCurrentUser,
    getCurrentToken,
    UpdateUser,
    addClient,
    EditUser,
    addSubAccountant,
    getCurrentUserType,
    EditSubAccountant,
    GetSubAccountant,
    
};

export default UserService;
