import React, { useState, useEffect, useRef, useMemo } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import HamburgerMenu from '../HamburgerMenu/HamburgerMenu';
import Select from 'react-select';
import ReactDatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { registerLocale, setDefaultLocale } from "react-datepicker";
import enGB from 'date-fns/locale/en-GB';
import { useTranslation } from 'react-i18next';
import DocumentService from '../../Services/documents.service';
import UserService from '../../Services/user.service';
import './Check.css';
import backIcon from '../../assests/images/left-arrow.png';
import calendarIcon from '../../assests/images/calendar.png';
import backInputIcon from '../../assests/images/back.png';
import EnumsService from '../../Services/enums.service';
import CustomDeleteConfirmationModal from '../CustomDeleteConfirmationModal/CustomDeleteConfirmationModal'
import { HashLoader } from "react-spinners";
import { ToastContainer, toast } from "react-toastify";
import DownloadIcon from "../../assests/images/downloadIcon.png";
import { useSignalR } from '../../Contexts/signalR';
import ClientDocumentCountService from '../../Services/ClientDocumentCount.service';
import Header from '../Header/Header';
import editIcon from "../../assests/images/edit.png";
import saveIcon from "../../assests/images/save.png";
import cancelIcon from "../../assests/images/cancel.png";
import deleteIcon from "../../assests/images/deleteicon.png";
import FormControlLabel from '@mui/material/FormControlLabel';
import RadioGroup from '@mui/material/RadioGroup';
import Radio from '@mui/material/Radio';
import Iframe from 'react-iframe';
import ClientService from '../../Services/client.service';
import loadingAnimation from "../../assests/images/Animation.gif";
import PuffLoader from "react-spinners/PuffLoader";
import CircularProgress, {
    circularProgressClasses,
} from '@mui/material/CircularProgress';
registerLocale('en-GB', {
    ...enGB,
    localize: {
        ...enGB.localize,
        month: n => n + 1, // Display months as numbers starting from 1
    },
    formatLong: {
        ...enGB.formatLong,
        date: () => 'MM/yyyy', // Date format when the picker is not showing
    },
});

setDefaultLocale('en-GB'); // Set the default locale to use it

function Checks() {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const [date, setDate] = useState(new Date());
    const [modalVisible, setModalVisible] = useState(false);
    const [checkDate, setCheckDate] = useState(new Date())
    const [checkAmount, setCheckAmount] = useState('');
    const [checkWithholdingTax, setCheckWithholdingTax] = useState('');
    const [checkTypeOption, setCheckTypeOption] = useState('');
    const [documentReference, setDocumentReference] = useState('');
    const [accountCodeName, setAccountCodeName] = useState('');
    const [accountCodeId, setAccountCodeId] = useState('');
    const [description, setDescription] = useState('');
    const [documents, setDocuments] = useState([]);
    const [errorMessage, setErrorMessage] = useState('');
    const [okMessage, setOkMessage] = useState('');
    const [user, setUser] = useState(null);
    const [token, setToken] = useState('');
    const [data, setData] = useState([]);
    const [client, setClient] = useState(null);
    const [type, setType] = useState('');
    const [searchQuery, setSearchQuery] = useState('');
    const modalCreateDocumentRef = useRef(); // Reference to the modal content
    const [checkTypeOptions, setCheckTypeOptions] = useState([]);
    const [accountCardOptions, setAccountCardOptions] = useState([]);
    const [editAccountCardOptions, setEditAccountCardOptions] = useState([]);
    const location = useLocation();
    const [editingRow, setEditingRow] = useState(null);
    const [editedItem, setEditedItem] = useState({});
    const [editedAccountCard, setEditedAccountCard] = useState(false);
    const [originalAccountCardId, setOriginalAccountCardId] = useState('');
    const [sortDate, setSortDate] = useState(() => {
        const storedDateString = localStorage.getItem('Check_Date');
        return storedDateString ? new Date(storedDateString) : new Date();
    });
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [itemIdToDelete, setItemIdToDelete] = useState(null);
    const [loading, setLoading] = useState(false);
    const [selectedLanguage, setSelectedLanguage] = useState(localStorage.getItem('language') || 'he');
    const [startDate, setStartDate] = useState(() => {
        const storedDateString = localStorage.getItem('Check_StartDate');
        return storedDateString ? new Date(storedDateString) : new Date();
    });; // Start date for range
    const [endDate, setEndDate] = useState(() => {
        const storedDateString = localStorage.getItem('Check_EndDate');
        return storedDateString ? new Date(storedDateString) : startDate;
    });;; // End date for range
    const [isRange, setIsRange] = useState(() => {
        const storedIsRange = localStorage.getItem('Check_IsRange');
        return storedIsRange === "true";
    });;// Flag to toggle between single date and range


    const [showDownloadModal, setShowDownloadModal] = useState(false);
    const [downloadStartDate, setDownloadStartDate] = useState(null); // Start date for range
    const [downloadEndDate, setDownloadEndDate] = useState(null);
    const [downloadAmount, setDownloadAmount] = useState();
    const [downloadAccountCodeId, setDownloadAccountCodeId] = useState([]);
    const [downloadCheckType, setDownloadCheckType] = useState('');
    const { documentUpdate } = useSignalR();
    const [stautsCheck, setStautsCheck] = useState(false);
    const [isClosedReportingMonth, setIsClosedReportingMonth] = useState(false);
    const [actionType, setActionType] = useState('delete');
    const [addNewAccountCardDocument, setAddNewAccountCardDocument] = useState(false);
    const [sortConfig, setSortConfig] = useState({ key: 'uploadNumber', direction: 'descending', type: 'number' });
    const [isFirstUserFetched, setIsFirstUserFetched] = useState(false);
    const [editedAddAccountCard, setEditedAddAccountCard] = useState(false);
    const [companyId, setCompanyId] = useState('');

    const CloseReportingMonth = async (status) => {
        setLoading(true);
        try {
            const response = await ClientService.UpdateReportingMonth(client.caseId, sortDate, status, token);
            if (response.ok) {
                toast.success({
                    text1: status ? t('MonthReportClosedSuccess') : t('MonthReportOpenedSuccess'),
                });
                setIsClosedReportingMonth(status)
            } else {
                const errorText = await response.text();
                console.error(t('MonthReportNotClosed'), errorText);
                toast.error({
                    text1: status ? `${t('MonthReportNotClosed')}: ${errorText}` : `${t('MonthReportNotOpened')}: ${errorText}`,
                });
            }
        } catch (error) {
            console.error(t('MonthReportNotClosed'), error);
            toast.error({
                text1: status ? `${t('MonthReportNotClosed')}: ${error}` : `${t('MonthReportNotOpened')}: ${error}`,
            });
        } finally {
            setLoading(false); // Stop loading regardless of the outcome
            setIsModalVisible(false)
        }
    };

    const handleDeleteClick = (itemId) => {
        setItemIdToDelete(itemId);
        setIsModalVisible(true);
    };

    const handleDoneClick = async (itemId) => {
        setLoading(true);
        try {
            const response = await ClientDocumentCountService.removeDocumentByTypeAndId(client.caseId, client.accountantId, type, itemId, token)
            if (response.ok) {
                await fetchDocumentsNotification(client, token)
                toast.success(t('DocumentProcessed'));
            } else {
                const errorText = await response.text();
                toast.error(t('DocumentNotProcessed', { errorText }));
            }
        } catch (error) {
            toast.error(t('DocumentNotProcessed', { errorText: error.message }));
        }
        setLoading(false);
    };

    const handleNotDoneClick = async (itemId) => {
        setLoading(true);
        try {
            const response = await ClientDocumentCountService.addDocumentByTypeAndId(client.caseId, client.accountantId, type, itemId, token)
            if (response.ok) {
                await fetchDocumentsNotification(client, token)
                toast.success(t('DocumentProcessed'));
            } else {
                const errorText = await response.text();
                toast.error(t('DocumentNotProcessed', { errorText }));
            }
        } catch (error) {
            toast.error(t('DocumentNotProcessed', { errorText: error.message }));
        }
        setLoading(false);
    };

    const fetchDocumentsNotification = async (client, token) => {
        const documentCounts = await ClientDocumentCountService.getClientDocumentCountByCaseId(client.caseId, token);
        // Enrich clients with their total document counts
        const enrichedClient = {
            ...client,
            totalCounts: documentCounts.totalCounts || 0, // Default to 0 if no matching count is found
            documentCounts: documentCounts.documentCounts || {},
            documentIds: documentCounts.documentIds || {},
        };
        setClient(enrichedClient);
    }

    const handleConfirm = async () => {
        if (actionType === 'delete') {
            await handleConfirmDelete();
        } else if (actionType === 'closeMonth') {
            await CloseReportingMonth(true);
        } else if (actionType === 'openMonth') {
            await CloseReportingMonth(false);
        }
    };

    const getConfirmMessage = () => {
        switch (actionType) {
            case 'closeMonth':
                return t('AreYouSureCloseMonth');
            case 'openMonth':
                return t('AreYouSureOpenMonth');
            default:
                return t('confirmDeleteMessage');

        }
    };

    const getTitleMessage = () => {
        switch (actionType) {

            case 'closeMonth':
                return t('ConfirmCloseMonth');
            case 'openMonth':
                return t('ConfirmOpenMonth');
            default:
                return t('confirmDeleteTitle');
        }
    };

    const handleConfirmDelete = async () => {
        setLoading(true);
        // Perform delete operation
        try {
            // Call the DeleteDocument function from DocumentService with the item's id
            const response = await DocumentService.DeleteDocument(type, itemIdToDelete, token);
            if (response.ok) {
                // Handle successful deletion, e.g., by updating your UI
                fetchDocuments(new Date(sortDate));

                // Display a success message based on the selected language
                const successMessage = t('documentDeletedSuccessfully'); // Use t() to access translations
                toast.success(successMessage);
            } else {
                // Handle any errors or display an error message
                const errorResponse = await response.json();
                console.error('Error deleting document:', errorResponse);

                // Display an error message based on the selected language
                const errorMessage = t('errorDeletingDocument'); // Use t() to access translations
                toast.error(errorMessage);
            }
        } catch (error) {
            console.error('Error deleting document:', error);
            // Display an error message based on the selected language
            const errorMessage = t('errorDeletingDocument'); // Use t() to access translations
            toast.error(errorMessage);
        }
        setIsModalVisible(false);
        setLoading(false);
    };

    const handleEdit = (item) => {
        setEditingRow(item.id);
        setEditedItem({ ...item }); // create a copy of the item for editing
        let statusChecked = client.documentIds.checks.includes(item.id);
        setStautsCheck(!statusChecked);
    };

    const handleChange = (e, field) => {
        setEditedItem({ ...editedItem, [field]: e.target.value });
    };
    const handleDateChange = (e, field) => {
        setEditedItem({ ...editedItem, [field]: e });
    };

    const handleChangeNew = (selectedOption, field) => {
        if (selectedOption.value1 === "AddItem") {
            setAccountCodeName("");
            setAccountCodeId("");
            setCompanyId("");
            setAddNewAccountCardDocument(true);
        }
        else if (field == "accountCodeName") {
            setAccountCodeName(selectedOption.value);
            setCompanyId(selectedOption.accountCaseId);
            const accountCodeId = accountCardOptions.find(option => option.name === selectedOption.value);
            setAccountCodeId(accountCodeId.cardId);
        }
    };

    const handleUpdate = async (itemId) => {
        setLoading(true);

        try {
            delete editedItem.documentUrl;
            const response = await DocumentService.UpdateDocument(type, token, editedItem);
            if (editedItem.status == 'done') {
                await handleDoneClick(editedItem.id)
            }
            else if (editedItem.status == 'notDone') {
                await handleNotDoneClick(editedItem.id)
            }

            if (editedAddAccountCard) {

                await handleAccountCardSave(editedItem.accountCodeId, editedItem.accountCodeName, editedItem.accountCaseId);
                await fetchEnums(user, token, client)
            }

            if (response.ok) {
                fetchDocuments(new Date(sortDate));

                // Display a success message based on the selected language
                const successMessage = t('documentUpdatedSuccess'); // Use t() to access translations
                toast.success(successMessage);
            } else {
                const errorText = await response.text();
                // Handle error

                // Display an error message based on the selected language
                const errorMessage = t('errorUpdatingDocument'); // Use t() to access translations
                toast.error(errorMessage);
            }
        } catch (error) {
            // Handle error

            // Display an error message based on the selected language
            const errorMessage = t('errorUpdatingDocument'); // Use t() to access translations
            toast.error(errorMessage);
        }
        setEditingRow(null);
        setEditedItem({});
        setLoading(false);
    };

    const setReportingMonthDate = async (selectedDate, field) => {
        if (!selectedDate) {
            return;
        }
        const newDate = new Date(Date.UTC(selectedDate.getFullYear(), selectedDate.getMonth(), 10));
        const response1 = await ClientService.CheckIfMonthClosed(client.caseId, newDate, token);
        if (response1.ok) {
            const isClosedReportingMonth = await response1.json();
            if (isClosedReportingMonth) {
                toast.info(t('MonthReportAlreadyClosedTryAgain'));
                return
            }
            else {
                setEditedItem({ ...editedItem, [field]: newDate });
            }
        }
    }

    const handleCancel = () => {
        setEditingRow(null);
        setEditedItem({});
    };

    const handleDocumentUpload = (event) => {
        setDocuments(Array.from(event.target.files));
    };

    const formatDate = dateString => {
        const date = new Date(dateString);
        const day = ('0' + date.getDate()).slice(-2);
        const month = ('0' + (date.getMonth() + 1)).slice(-2);
        const year = date.getFullYear();
        return `${day}-${month}-${year}`;
    };


    const closeCreateModal = (e) => {
        // Check if the clicked element is the same as the event handler's element
        if (modalCreateDocumentRef.current && !modalCreateDocumentRef.current.contains(e.target)) {
            handleClose();
        }
    }

    const handleClose = async () => {
        setDocumentReference("");
        setAccountCodeName("");
        setAccountCodeId("");
        setCheckTypeOption("");
        setCheckAmount("");
        setCheckWithholdingTax("");
        setDocumentReference("");
        setDescription("");
        setDocuments([]);
        setAddNewAccountCardDocument(false);
        setModalVisible(false);

    }
    // const allFieldsFilled = documentReference && checkAmmount && checkDate && accountCodeName && accountCodeId && photo && checkTypeOption && description
    // const allFieldsFilled = checkAmount && checkDate && documents && documents.length > 0;
    const allFieldsFilled = documents && documents.length > 0;

    const handleAccountCardSave = async (AccountCodeId, AccountCodeName, AccountCaseId) => {
        const response2 = await EnumsService.AddAccountCard(client.caseId, AccountCodeId, AccountCodeName, AccountCaseId, token);
        if (!response2.ok) {
            throw new Error(`HTTP error! status: ${response2.status}`);
        }
    };

    const handleSave = async () => {
        if (!allFieldsFilled) {
            setErrorMessage(t('fieldsNotFilledError'));
            return; // Stop execution if fields are not filled
        }
        setLoading(true);
        const formData = new FormData();
        formData.append('CaseId', client.caseId);
        formData.append('AccountantId', user.accountantId);
        formData.append('Reference', documentReference)
        formData.append('Amount', checkAmount);
        formData.append('CheckDate', new Date(checkDate).toISOString());
        formData.append('ReportingMonthDate', new Date(sortDate).toISOString());
        formData.append('AccountCodeName', accountCodeName);
        formData.append('AccountCodeId', accountCodeId);
        formData.append('AccountCaseId', companyId);
        documents.forEach(document => {
            formData.append("documents", document, document.name);
        });
        formData.append('checkType', checkTypeOption)
        formData.append('DocumentType', type);
        formData.append('DocumentFileType', type);
        formData.append('Description', description)
        formData.append('WithholdingTax', checkWithholdingTax)

        try {
            const response = await DocumentService.UploadDocument(type, formData, token);
            if (response.ok) {
                if (addNewAccountCardDocument) {
                    await handleAccountCardSave(accountCodeId, accountCodeName, companyId);
                    await fetchEnums(user, token, client);
                }
                toast.success(t('documentUploadedSuccess'));
            } else {
                const errorText = await response.text();
                toast.error(t('documentUploadError') + `: ${errorText}`);
            }
        } catch (error) {
            toast.error(t('documentUploadError'));
        }
        // setDocumentCost("");
        handleClose()
        fetchDocuments(new Date(sortDate));
        setLoading(false);
    };

    const handleOCRSave = async () => {
        if (!allFieldsFilled) {
            setErrorMessage(t('errorFieldsNotFilled'));
            return; // Stop execution if fields are not filled
        }
        setLoading(true);

        const formData = new FormData();
        formData.append('CaseId', client.caseId);
        formData.append('AccountantId', user.accountantId);
        formData.append('ReportingMonthDate', new Date(sortDate).toISOString());
        formData.append('DocumentFileType', type);
        documents.forEach(document => {
            formData.append("documents", document, document.name);
        });


        try {
            const response = await DocumentService.UploadOCRDocument(type, formData, token);
            if (response.ok) {
                toast.success(t('documentUploadedSuccess'));
            } else {
                const errorText = await response.text();
                toast.error(t('documentUploadError', { errorText }));
            }
        } catch (error) {
            toast.error(t('documentUploadError', { errorText: 'Error uploading document' }));
        }
        setDocuments([])
        setModalVisible(false);
        await fetchDocuments(new Date(sortDate));
        await fetchDocumentsNotification(client, token)
        await fetchEnums(user, token, client);
        setLoading(false);
    };
    const fetchEnums = async (user, token, client) => {
        setLoading(true);
        try {
            // Fetching accountCardOptions
            const accountCardResponse = await EnumsService.GetAccountCardsByCaseId(client.caseId, token);
            const accountCardData = await accountCardResponse.json();
            setEditAccountCardOptions(accountCardData);
            const accountCardOptions = [{ name: t('AddAccountCard'), value1: 'AddItem' }, ...accountCardData];
            setAccountCardOptions(accountCardOptions);

            // Ferching checks data
            const checksTypeResponse = await EnumsService.GetChecksTypesByAccountantId(user.accountantId, token);
            const checkTypeOptions = await checksTypeResponse.json();
            setCheckTypeOptions(checkTypeOptions);

            // Show success toast notification
            setIsFirstUserFetched(true);
            toast.success(t('fetchingEnumsSuccess'));
        } catch (error) {
            console.error("Error fetching enum data:", error);

            // Show error toast notification with translation
            toast.error(t('fetchingEnumsError'));

            // Handle the error appropriately
        }
        setLoading(false);
    };

    useEffect(() => {
        const fetchUserData = async () => {
            setLoading(true);
            try {
                const currentUser = await UserService.getCurrentUser();
                const currentToken = await UserService.getCurrentToken();
                if (currentUser && currentToken) {
                    setUser(currentUser);
                    setToken(currentToken);

                    // Get client data from location.state
                    if (location.state && location.state.client && location.state.type) {
                        await fetchDocumentsNotification(location.state.client, currentToken);
                        setType(location.state.type)
                    }
                    await fetchEnums(currentUser, currentToken, location.state.client);
                    // Fetch financial data for the current date on initial load only
                }
                else {
                    navigate("/login");
                }


            } catch (error) {
                // Handle error - maybe navigate to login or show a message
            }
            setLoading(false);
        };


        fetchUserData();

    }, []);

    useEffect(() => {
        // To handle multiple updates efficiently, consider iterating over documentUpdate keys
        Object.keys(documentUpdate).forEach(updateCaseId => {
            const update = documentUpdate[updateCaseId];
            // Update clients with new totalCounts for the matching caseId

            if (client?.caseId === updateCaseId) { // Check if the client's caseId matches the update's caseId
                const updatedClients = {
                    ...client,
                    totalCounts: update.totalCounts, // Update totalCounts from documentUpdate
                    documentIds: update.documentsIds,
                    documentsCount: update.documentsCount
                };
                setClient(updatedClients); // Update the state with the modified lzist
            }

        });
    }, [documentUpdate]);

    const fetchDocuments = async (selectedDate) => {
        setLoading(true);
        const newDate = new Date(Date.UTC(selectedDate.getFullYear(), selectedDate.getMonth(), 10));

        try {
            const response = await DocumentService.GetDocumentbyDate(type, client.caseId, newDate, token)
            const response1 = await ClientService.CheckIfMonthClosed(client.caseId, newDate, token);
            if (response.ok && response1.ok) {
                const data = await response.json(); // Parse the JSON in the response
                const isClosedReportingMonth = await response1.json();
                setIsClosedReportingMonth(isClosedReportingMonth);
                setData(data);
                toast.success(t('fetchDocumentsSuccess'));
            } else {
                const errorText = await response.text();
                toast.error(t('fetchDocumentsFailure', { errorText }));
            }
        } catch (error) {
            toast.error(t('errorFetchingDocuments'));
        }
        setLoading(false);
    };


    useEffect(() => {
        if (isFirstUserFetched) {
            if (!isRange)
                fetchDocuments(sortDate);
            else
                fetchDataOnDateRangeChange(startDate, endDate)
        }
    }, [isFirstUserFetched]);

    const CustomInput = React.forwardRef(({ value, onClick }, ref) => (
        <button className={`document-custom-input ${isRange ? "document-custom-input-range" : ""}`} onClick={onClick} ref={ref}>
            {value}
        </button>
    ));
    const CustomInput1 = React.forwardRef(({ value, onClick }, ref) => (
        <button className="document-custom-input1" onClick={onClick} ref={ref}>
            {value}
        </button>
    ));

    const CustomDownloadInput = React.forwardRef(({ value, placeholderText, onClick }, ref) => {
        return (
            <button className="download-document-custom-input" onClick={onClick} ref={ref}>
                {value || placeholderText}
            </button>
        );
    });

    const openPdfInNewTab = (pdfUrl) => {
        window.open(pdfUrl, '_blank');
    };

    // const filteredAndSortedData = useMemo(() => {
    // const filteredData = data.filter((item) => {
    //     const query = searchQuery.toLowerCase();
    //     const amountString = item?.amount?.toString(); // Safely access item.amount
    //     return (
    //         item?.accountCodeName?.toLowerCase()?.includes(query) || // Safely access item.title
    //         amountString?.includes(query) ||
    //         formatDate(item?.checkDate)?.includes(query) ||
    //         item?.reference?.includes(query) ||
    //         item?.description?.includes(query) ||
    //         item?.uploadNumber?.toString()?.includes(query)
    //     );
    // });

    //     switch (currentSort) {
    //         case 'title':
    //             return filteredData.sort((a, b) =>
    //                 sortByTitleAsc ? a.checkType?.localeCompare(b?.checkType) : b.checkType?.localeCompare(a.checkType)
    //             );
    //         case 'date':
    //             return filteredData.sort((a, b) => {
    //                 const dateA = new Date(a?.checkDate);
    //                 const dateB = new Date(b?.checkDate);
    //                 return sortByDateAsc ? dateA - dateB : dateB - dateA;
    //             });
    //         case 'amount':
    //             return filteredData.sort((a, b) => {
    //                 const amountA = parseFloat(a?.amount);
    //                 const amountB = parseFloat(b?.amount);
    //                 return sortByAmountAsc ? amountA - amountB : amountB - amountA;
    //             });
    //         case 'newest': {
    //             const highlightedIds = client.documentIds.checks;
    //             return filteredData.sort((a, b) => {
    //                 const aIsHighlighted = highlightedIds.includes(a.id);
    //                 const bIsHighlighted = highlightedIds.includes(b.id);

    //                 if (aIsHighlighted && !bIsHighlighted) {
    //                     return sortByNewest ? -1 : 1;
    //                 } else if (!aIsHighlighted && bIsHighlighted) {
    //                     return sortByNewest ? 1 : -1;
    //                 } else {
    //                     // Both are highlighted or neither are, sort by document date
    //                     const dateA = new Date(a.documentDate);
    //                     const dateB = new Date(b.documentDate);
    //                     return sortByNewest ? dateB - dateA : dateA - dateB;
    //                 }
    //             });
    //         }
    //         default:
    //             return filteredData; // No sorting or default sorting logic
    //     }
    // }, [data, searchQuery, sortByTitleAsc, sortByDateAsc, sortByAmountAsc, sortByNewest, currentSort]);

    const handleSortChange = (key, type) => {
        let direction = 'ascending';
        if (sortConfig.key === key && sortConfig.direction === 'ascending') {
            direction = 'descending';
        }
        setSortConfig({ key, direction, type });
    };

    const filteredAndSortedData = useMemo(() => {
        if (data.length < 0) {
            return;
        }
        const sortedData = data.filter((item) => {
            const query = searchQuery.toLowerCase();
            const amountString = item?.amount?.toString(); // Safely access item.amount
            return (
                item?.accountCodeName?.toLowerCase()?.includes(query) || // Safely access item.title
                amountString?.includes(query) ||
                formatDate(item?.checkDate)?.includes(query) ||
                item?.reference?.includes(query) ||
                item?.description?.includes(query) ||
                item?.uploadNumber?.toString()?.includes(query)
            );
        });
        if (sortConfig.key) {
            sortedData.sort((a, b) => {
                if (sortConfig.type === 'newest') {
                    const highlightedIds = client.documentIds.checks;
                    const aIsHighlighted = highlightedIds.includes(a.id);
                    const bIsHighlighted = highlightedIds.includes(b.id);

                    if (sortConfig.direction === 'ascending') {
                        // Ascending: Non-highlighted (older) items first
                        if (!aIsHighlighted && bIsHighlighted) {
                            return -1; // 'a' should come before 'b' (a is older, hence "lower")
                        } else if (aIsHighlighted && !bIsHighlighted) {
                            return 1;  // 'b' should come before 'a' (b is older, hence "lower")
                        }
                    } else {
                        // Descending: Highlighted (newer) items first
                        if (aIsHighlighted && !bIsHighlighted) {
                            return -1; // 'a' should come before 'b' (a is newer, hence "higher")
                        } else if (!aIsHighlighted && bIsHighlighted) {
                            return 1;  // 'b' should come before 'a' (b is newer, hence "higher")
                        }
                    }
                } else {
                    const aValue = a[sortConfig.key] || ""; // Default to empty string if null or undefined
                    const bValue = b[sortConfig.key] || ""; // Default to empty string if null or undefined
                    if (sortConfig.type === 'number') {
                        return sortConfig.direction === 'ascending' ? aValue - bValue : bValue - aValue;
                    } else if (sortConfig.type === 'date') {
                        return sortConfig.direction === 'ascending' ? new Date(aValue) - new Date(bValue) : new Date(bValue) - new Date(aValue);
                    } else { // default to string comparison
                        return sortConfig.direction === 'ascending' ? aValue?.localeCompare(bValue) : bValue?.localeCompare(aValue);
                    }
                }
            });
        }

        return sortedData;
    }, [data, searchQuery, sortConfig]);

    const handleGoBack = () => {
        // Navigate back to the previous page using React Router
        if (client) {
            navigate('/client-details', { state: { client } });
        }
    };

    const handleAccountCardChange = (selectedOption, field) => {
        if (field === 'accountCodeId') {
            const accountCodeName = accountCardOptions.find(option => option.cardId === selectedOption.value)?.name;
            if (selectedOption.value < 0) {
                setEditedAccountCard(true)
                setOriginalAccountCardId(editedItem.accountCodeId)
            }
            setEditedItem({ ...editedItem, accountCodeId: selectedOption.value, accountCodeName });
        } else if (field === 'accountCodeName') {
            if (selectedOption.value1 === 'AddItem') {
                setEditedItem({ ...editedItem, accountCodeId: '', accountCodeName: '', accountCaseId: '' });
                setEditedAddAccountCard(true);
                return;
            }
            const accountCodeId = accountCardOptions.find(option => option.name === selectedOption.value)?.cardId;
            setEditedItem({ ...editedItem, accountCodeId, accountCodeName: selectedOption.value, accountCaseId: selectedOption.accountCaseId });
        }
    };

    const handleAccountCardIdChange = (e) => {
        const accountCardIdValue = e.target.value;
        setEditedAccountCard(true);
        setOriginalAccountCardId(editedItem.accountCodeId); // Store the original accountCodeId

        // Store the new accountCodeId (whether positive or negative)
        setEditedItem({ ...editedItem, accountCodeId: accountCardIdValue });
    };

    const handleBackAccountCardIdChange = () => {

        setEditedAccountCard(false);
        if (originalAccountCardId) {
            const accountCodeName = accountCardOptions.find(option => option.accountCodeId === originalAccountCardId)?.accountCodeName;
            setEditedItem({ ...editedItem, accountCodeId: originalAccountCardId, accountCodeName: accountCodeName });

        }
        setOriginalAccountCardId(null); // Store the original accountCodeId
    };

    const handleSetSortDate = (date) => {
        setSortDate(new Date(date));
        localStorage.setItem('Check_Date', date);
        fetchDocuments(new Date(date));
    }
    const handleStartDateChange = async (date) => {
        const newDate = new Date(Date.UTC(date.getFullYear(), date.getMonth(), 10));
        localStorage.setItem('Check_StartDate', date)
        setStartDate(newDate);
        if (isRange) {
            await fetchDataOnDateRangeChange(new Date(newDate), new Date(endDate));
        }
    };

    const handleEndDateChange = async (date) => {
        const endDate = new Date(Date.UTC(date.getFullYear(), date.getMonth(), 10));
        localStorage.setItem('Check_EndDate', date)
        setEndDate(endDate);
        if (isRange) {
            await fetchDataOnDateRangeChange(new Date(startDate), new Date(endDate));
        }
    };

    const handleDownloadStartDateChange = (date) => {
        const newDate = new Date(Date.UTC(date.getFullYear(), date.getMonth(), 10));
        setDownloadStartDate(newDate);
    };

    const handleDownloadEndDateChange = async (date) => {
        const endDate = new Date(Date.UTC(date.getFullYear(), date.getMonth(), 10));
        setDownloadEndDate(endDate);
    };


    async function fetchDataOnDateRangeChange(startDate, endDate) {

        setLoading(true)
        const formattedStartDate = startDate.toISOString();
        const formattedEndDate = endDate.toISOString();

        try {
            const response = await DocumentService.GetDocumentbyDateRange(type, client.caseId, formattedStartDate, formattedEndDate, token)
            if (response.ok) {
                const data = await response.json(); // Parse the JSON in the response
                setData(data);
                toast.success(t('documentFetchSuccess'));
            } else {
                const errorText = await response.text();
                toast.error(t('documentFetchError', { errorText }));
            }
        } catch (error) {
            toast.error(t('documentFetchError', { errorText: error.message }));
        }

        setLoading(false)
    }

    const handleDownloadDocuments = async () => {
        setLoading(true);
        try {
            const dto = {
                CaseId: client.caseId,
                Date: downloadStartDate,
                EndDate: downloadEndDate,
                AccountCodeIds: downloadAccountCodeId,
                Amount: downloadAmount,
                CheckType: downloadCheckType
            };

            const blob = await DocumentService.DownloadCustomDocuments(type, token, dto); // Assuming you have token state or prop for authorization
            const url = window.URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'documents.zip'); // or any other extension
            document.body.appendChild(link);
            link.click();
            link.parentNode.removeChild(link);
            window.URL.revokeObjectURL(url);
            toast.success(t('documentDownloadSuccess'));
        } catch (error) {
            console.error('Error downloading documents:', error);
            toast.error(t('documentDownloadError', { errorText: error.message }));
        }
        setDownloadStartDate(null);
        setDownloadEndDate(null);
        setDownloadAmount();
        setDownloadAccountCodeId([]);
        setDownloadCheckType('');
        setShowDownloadModal(false)
        setLoading(false);
    };

    const closeDownloadModal = () => {
        setShowDownloadModal(false);
    };

    const handleRangeChange = async () => {
        !isRange ? await fetchDataOnDateRangeChange(new Date(startDate), new Date(endDate)) : await fetchDocuments(new Date(sortDate));
        setIsRange(!isRange);
        localStorage.setItem('Check_IsRange', !isRange);
    }

    const CustomInput2 = React.forwardRef(({ value, onClick }, ref) => (
        <button className="edit-custom-input" onClick={onClick} ref={ref}>
            {value}
        </button>
    ));

    const handleRadioChange = (e) => {
        if (e.target.value == 'done') {
            setStautsCheck(true);
        }
        else if (e.target.value == 'notDone') {
            setStautsCheck(false);
        }
        setEditedItem({ ...editedItem, ["status"]: e.target.value });
    };
    const handleCloseAddNewAccountCardDocument = () => {
        setAccountCodeId("");
        setAccountCodeName("");
        setAddNewAccountCardDocument(false);
    };

    const handleEditAddAccountChange = (selectedOption, field) => {

        if (field === 'AccountCodeId') {
            setEditedItem({ ...editedItem, accountCodeId: selectedOption });
        }
        if (field === 'AccountCaseId') {
            setEditedItem({ ...editedItem, accountCaseId: selectedOption });
        }
        if (field === 'AccountCodeName') {
            setEditedItem({ ...editedItem, accountCodeName: selectedOption });
        }
    };

    const handleCloseEditAddNewAccountCardDocument = () => {
        setEditedItem({ ...editedItem, accountCodeName: '', accountCodeId: '', accountCaseId: '' })
        setEditedAddAccountCard(false);
    };

    return (
        <>
            <div className="template-page">
                <div className="template-container">
                    <div className="back-icon" onClick={handleGoBack}>
                        <img src={backIcon} alt="Back" className="arrow" />
                    </div>
                    <h1 className="document-title" >{t(type)}</h1>
                    <div className="search-bar__container">
                        <div className="search-bar-container">
                            <input
                                type="text"
                                placeholder={t('searchCheck')}
                                className="document-search-bar"
                                value={searchQuery}
                                onChange={(e) => setSearchQuery(e.target.value)}
                            />
                        </div>
                        {!isRange && !isClosedReportingMonth && <div className="create-selector" onClick={() => setModalVisible(true)}>
                            {t('create')}
                        </div>}
                        {modalVisible && (
                            <div className='create-modal-overlay' onClick={closeCreateModal}>
                                <div className="modal-background" >
                                    <div className={`modal-content ${selectedLanguage === 'he' || selectedLanguage === 'ar' ? 'rtl-text' : ''}`} ref={modalCreateDocumentRef}>
                                        <h2>{t('newCheck')}</h2>
                                        {/* {addNewAccountCardDocument ? (
                                            <div className='items_Container'>
                                                <button className='clientItemButton ' onClick={handleCloseAddNewAccountCardDocument}>
                                                    <img src={backIcon} alt="Income" className="clientItem-icon clientItemListBack" />
                                                    {t('ReturnToNamesList')}
                                                </button>
                                                <div className="input-field_Container ">
                                                    <input
                                                        className="input-field input-field_Container_required-asterisk"
                                                        type="number"
                                                        placeholder={t('CompanyId')}
                                                        value={companyId}
                                                        onChange={(e) => setCompanyId(e.target.value)}
                                                    />
                                                </div>
                                                <div className="input-field_Container ">
                                                    <input
                                                        className="input-field input-field_Container_required-asterisk"
                                                        type="text"
                                                        placeholder={t('Card Name')}
                                                        value={accountCodeName}
                                                        onChange={(e) => setAccountCodeName(e.target.value)}
                                                    />
                                                </div>
                                                <div className="input-field_Container ">
                                                    <input
                                                        className="input-field input-field_Container_required-asterisk"
                                                        type="number"
                                                        placeholder={t('Card ID')}
                                                        value={accountCodeId}
                                                        onChange={(e) => setAccountCodeId(e.target.value)}
                                                    />
                                                </div>

                                            </div>
                                        ) : (
                                            <Select
                                                className="input-field-dropdown"
                                                placeholder={t("CheckTitle")} // Added placeholder prop
                                                options={accountCardOptions.map(option => ({ value: option.name, label: option.name, value1: option.value1, label: option.accountCaseId?  `${option.name} - ${option?.accountCaseId} `: `${option.name} `, value1: option.value1,accountCaseId:option.accountCaseId || ''}))}
                                                value={accountCardOptions
                                                    .filter(option => option.name === accountCodeName)
                                                    .map(option => ({ value: option.name, label: option.name, value1: option.value1, accountCaseId: option.accountCaseId || '' }))[0]}
                                                onChange={(selectedOption) => handleChangeNew(selectedOption, 'accountCodeName')}
                                                styles={{
                                                    control: (baseStyles, state) => ({
                                                        ...baseStyles,
                                                        borderColor: state.isFocused ? 'grey' : 'red',
                                                    }),
                                                }}
                                            />
                                        )}

                                        <Select
                                            className="input-field-dropdown"
                                            placeholder={t('checktype')} // Added placeholder prop
                                            options={checkTypeOptions.map(option => ({ value: option.name, label: option.name }))}
                                            value={checkTypeOptions
                                                .filter(option => option.name === checkTypeOption)
                                                .map(option => ({ value: option.name, label: option.name }))[0]} // Ensure it's in the correct format
                                            onChange={(selectedOption) => setCheckTypeOption(selectedOption.value)}
                                        />

                                        <input
                                            type="text"
                                            placeholder={t('enterReference')}
                                            className="input-field"
                                            value={documentReference}
                                            onChange={(e) => setDocumentReference(e.target.value)}
                                        />
                                        <input
                                            type="text"
                                            placeholder={t('enterDescription')}
                                            className="input-field"
                                            value={description}
                                            onChange={(e) => setDescription(e.target.value)}
                                        />

                                        <input
                                            type="number"
                                            placeholder={t('enterCheckAmmount')}
                                            className="input-field input-field_Container_required-asterisk"
                                            value={checkAmount}
                                            onChange={(e) => setCheckAmount(e.target.value)}
                                        />

                                        <input
                                            type="number"
                                            placeholder={t('enterCheckWithholdingTax')}
                                            className="input-field"
                                            value={checkWithholdingTax}
                                            onChange={(e) => setCheckWithholdingTax(e.target.value)}
                                        /> */}
                                        <div className="file-upload-container input-field_Container_required-asterisk">
                                            <input
                                                type="file"
                                                id="file"
                                                className="input-file"
                                                multiple
                                                onChange={handleDocumentUpload}
                                            />
                                            <label htmlFor="file" className={`upload-label ${selectedLanguage === 'he' || selectedLanguage === 'ar' ? 'rtl-text' : ''} `}>
                                                {/* The label will show "Please upload photo of document" by default or the file names when selected */}
                                                {documents.length > 0 ? documents.map(doc => doc.name).join(', ') : t('uploadDocument')}
                                            </label>
                                        </div>

                                        {/* <div className="date-section">
                                            <div className="date-input-container">
                                                <ReactDatePicker
                                                    selected={checkDate}
                                                    onChange={(date) => setCheckDate(date)}
                                                    dateFormat="dd-MM-yyyy"
                                                    className="date-input"
                                                    customInput={<CustomInput />}
                                                />
                                                <img src={calendarIcon} alt="Calendar" className="calendar-icon" />

                                            </div>
                                        </div> */}

                                        {errorMessage && <p className="error-message-create-document">{errorMessage}</p>}
                                        {okMessage && <p className="ok-message-create-document">{okMessage}</p>}
                                        <div className="button-row">
                                            <button className="create-buttons" onClick={handleOCRSave} disabled={!allFieldsFilled}>
                                                {t('save')}
                                            </button>
                                            <button className="create-buttons" onClick={handleClose}>
                                                {t('close')}
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                    {!isRange && <div className="MonthReportButton" onClick={() => {
                        setActionType(isClosedReportingMonth ? 'openMonth' : 'closeMonth');
                        setIsModalVisible(true);
                    }}>
                        {isClosedReportingMonth ? t("OpenMonthReport") : t("CloseMonthReport")}
                    </div>
                    }
                    <div className='document__container'>
                        <div className="filter-container">
                            <button onClick={() => setShowDownloadModal(true)} className="document-download">
                                <span className="document-download-icon" onClick={() => setShowDownloadModal(true)}>
                                    <img src={DownloadIcon} alt="Download" className='document-style-icon-download' />
                                </span>
                                <span>{t("DownloadDocuments")}</span>
                            </button>
                            {/* <button
                                className="filter-button-document"
                                onClick={() => {
                                    setSortByTitleAsc(!sortByTitleAsc);
                                    setCurrentSort('title'); // Set current sort to 'title'
                                }}
                            >
                                {t('sortTitleCheck')} {sortByTitleAsc ? '▲' : '▼'}
                            </button>
                            <button
                                className="filter-button-document"
                                onClick={() => {
                                    setSortByAmountAsc(!sortByAmountAsc);
                                    setCurrentSort('amount'); // Set current sort to 'amount'
                                }}
                            >
                                {t('sortAmount')} {sortByAmountAsc ? '▲' : '▼'}
                            </button>
                            <button
                                className="filter-button-document"
                                onClick={() => {
                                    setSortByDateAsc(!sortByDateAsc);
                                    setCurrentSort('date'); // Set current sort to 'date'
                                }}
                            >
                                {t('sortDate')} {sortByDateAsc ? '▲' : '▼'}
                            </button> */}
                            {/* <div className="document-date-picker">
                            <ReactDatePicker
                                selected={date}
                                onChange={(date) => handleSetSortDate(date)}
                                showMonthYearPicker
                                dateFormat="MM-yyyy"
                                className="date-input"
                                customInput={<CustomInput />}
                            />
                        </div> */}
                            <button
                                className="filter-button-document"
                                onClick={() => {
                                    handleSortChange('id', 'newest'); // 'custom' indicates a special sorting logic
                                }}
                            >
                                {t('SortByprocessed')} {sortConfig.type === 'newest' ? (sortConfig.direction === 'ascending' ? '▲' : '▼') : ''}
                            </button>
                            <button onClick={handleRangeChange} className="document-date-range-toggle">
                                {isRange ? t("SwitchToSingleDate") : t("SwitchToDateRange")}
                            </button>

                            {/* Conditional Rendering for Date Picker based on isRange */}
                            {isRange ? (
                                <>
                                    {/* To Date Picker */}
                                    <div className="document-date-picker">
                                        <label htmlFor="end-date" className="document-date-picker-label">{t("To")}</label>
                                        <ReactDatePicker
                                            id="end-date"
                                            selected={endDate}
                                            onChange={handleEndDateChange}
                                            showMonthYearPicker
                                            dateFormat="MM-yyyy"
                                            className="date-input"
                                            customInput={<CustomInput />}
                                        />
                                    </div>
                                    {/* From Date Picker */}
                                    <div className="document-date-picker">
                                        <label htmlFor="start-date" className="document-date-picker-label">{t("From")}</label>
                                        <ReactDatePicker
                                            id="start-date"
                                            selected={startDate}
                                            onChange={handleStartDateChange}
                                            showMonthYearPicker
                                            dateFormat="MM-yyyy"
                                            className="date-input"
                                            customInput={<CustomInput />}
                                        />
                                    </div>

                                </>
                            ) : (
                                <div className="document-date-picker">
                                    <ReactDatePicker
                                        selected={sortDate}
                                        onChange={(date) => handleSetSortDate(date)}
                                        showMonthYearPicker
                                        dateFormat="MM-yyyy"
                                        className="date-input"
                                        customInput={<CustomInput />}
                                    />
                                </div>
                            )}
                        </div>
                    </div>
                    {showDownloadModal && (
                        <div className="document-download-modal-overlay" onClick={closeDownloadModal}>
                            <div className="document-download-modal" onClick={(e) => e.stopPropagation()}>
                                <h4 className="download-report-title">{t('DownloadDocuments')}</h4>
                                <div className="document-download-modal-content">
                                    <div className="input-group">
                                        <input
                                            type="number"
                                            className='download-document-input-field'
                                            value={downloadAmount || ''}
                                            onChange={(e) => setDownloadAmount(e.target.value)}
                                            placeholder={t('enterDocumentCost')}
                                        />
                                    </div>
                                    <Select
                                        classNamePrefix="download-my-select"
                                        placeholder={t('checktype')} // Added placeholder prop
                                        options={checkTypeOptions.map(option => ({ value: option.name, label: option.name }))}
                                        value={checkTypeOptions
                                            .filter(option => option.name === checkTypeOption)
                                            .map(option => ({ value: option.name, label: option.name }))[0]} // Ensure it's in the correct format
                                        onChange={(selectedOption) => setDownloadCheckType(selectedOption.value)}
                                    />
                                    <Select
                                        classNamePrefix="download-my-select"
                                        options={accountCardOptions.map(option => ({ value: option.cardId, label: option.name }))}
                                        value={accountCardOptions
                                            .filter(option => option.name === editedItem.accountCodeName)
                                            .map(option => ({ value: option.cardId, label: option.name }))[0]}
                                        onChange={(selectedOptions) => {
                                            const selectedValues = selectedOptions.map(option => option.value); // Extract the values
                                            setDownloadAccountCodeId(selectedValues); // Assuming setDownloadAccountCodeId expects an array of values
                                        }}
                                        isMulti
                                        placeholder={t('CheckTitle')}
                                    />

                                    <div className="download-document-date-picker">
                                        <label htmlFor="end-date" className="download-document-date-picker-label">{t("To")}</label>
                                        <ReactDatePicker
                                            id="end-date"
                                            selected={downloadEndDate}
                                            onChange={handleDownloadEndDateChange}
                                            showMonthYearPicker
                                            dateFormat="MM-yyyy"
                                            className="date-input"
                                            customInput={<CustomDownloadInput placeholderText={t('SelectEndDate')} />}
                                        />
                                    </div>
                                    {/* From Date Picker */}
                                    <div className="download-document-date-picker">
                                        <label htmlFor="start-date" className="download-document-date-picker-label">{t("From")}</label>
                                        <ReactDatePicker
                                            id="start-date"
                                            selected={downloadStartDate}
                                            onChange={handleDownloadStartDateChange}
                                            showMonthYearPicker
                                            dateFormat="MM-yyyy"
                                            className="date-input"
                                            customInput={<CustomDownloadInput placeholderText={t('SelectStartDate')} />}

                                        />
                                    </div>
                                </div>
                                <div className="download-button-row">
                                    <button className="download-create-buttons" onClick={handleDownloadDocuments} >
                                        {t('Download')}
                                    </button>
                                    <button className="download-create-buttons" onClick={() => setShowDownloadModal(false)}>
                                        {t('close')}
                                    </button>
                                </div>
                            </div>
                        </div>
                    )}
                    <div className='table-wrapper'>
                        <table className="custom-table">
                            <thead>
                                {/* Table headers */}
                                <tr>
                                    <th>
                                        {t('Document')}
                                    </th>

                                    <th className='headerTH' onClick={() => handleSortChange('accountCaseId', 'string')}>
                                        {t('CheckTitleId')}
                                        {sortConfig.key === 'accountCaseId' && (sortConfig.direction === 'ascending' ? ' ▲' : ' ▼')}
                                    </th>
                                    <th className='headerTH' onClick={() => handleSortChange('accountCodeName', 'string')}>
                                        {t('CheckTitle')}
                                        {sortConfig.key === 'accountCodeName' && (sortConfig.direction === 'ascending' ? ' ▲' : ' ▼')}
                                    </th>
                                    <th className='headerTH' onClick={() => handleSortChange('reference', 'string')}>
                                        {t('Reference')}
                                        {sortConfig.key === 'reference' && (sortConfig.direction === 'ascending' ? ' ▲' : ' ▼')}
                                    </th>
                                    <th className='headerTH' onClick={() => handleSortChange('withholdingTax', 'number')}>
                                        {t('WithholdingTax')}
                                        {sortConfig.key === 'withholdingTax' && (sortConfig.direction === 'ascending' ? ' ▲' : ' ▼')}
                                    </th>
                                    <th className='headerTH' onClick={() => handleSortChange('amount', 'number')}>
                                        {t('Amount')}
                                        {sortConfig.key === 'amount' && (sortConfig.direction === 'ascending' ? ' ▲' : ' ▼')}
                                    </th>
                                    <th className='headerTH' onClick={() => handleSortChange('uploadDate', 'date')}>
                                        {t('Upload Date')}
                                        {sortConfig.key === 'uploadDate' && (sortConfig.direction === 'ascending' ? ' ▲' : ' ▼')}
                                    </th>
                                    <th className='headerTH' onClick={() => handleSortChange('checkDate', 'date')}>
                                        {t('Check Date')}
                                        {sortConfig.key === 'checkDate' && (sortConfig.direction === 'ascending' ? ' ▲' : ' ▼')}
                                    </th>
                                    <th className='headerTH' onClick={() => handleSortChange('checkType', 'string')}>
                                        {t('CheckType')}
                                        {sortConfig.key === 'checkType' && (sortConfig.direction === 'ascending' ? ' ▲' : ' ▼')}
                                    </th>
                                    <th className='headerTH' onClick={() => handleSortChange('description', 'string')}>
                                        {t('Description')}
                                        {sortConfig.key === 'description' && (sortConfig.direction === 'ascending' ? ' ▲' : ' ▼')}
                                    </th>
                                    {!editingRow && (
                                        <th className='headerTH' onClick={() => handleSortChange('uploadNumber', 'number')}>
                                            {t('UploadNumber')}
                                            {sortConfig.key === 'uploadNumber' && (sortConfig.direction === 'ascending' ? ' ▲' : ' ▼')}
                                        </th>
                                    )}
                                </tr>

                            </thead>
                            <tbody>
                                {filteredAndSortedData.map((item) => (
                                    <tr className={`table-items__container ${client.documentIds.checks.includes(item.id) ? 'highlighted-row' : ''}`} key={item.id}>
                                        <>
                                            <td className='pdf-td' onClick={() => openPdfInNewTab(item.documentUrl)}>
                                                <div className="iframe-overlay"></div>
                                                <iframe
                                                    src={`${item.documentUrl}#page=1`}
                                                    className='table-pdf'
                                                    title={item.title}
                                                ></iframe>
                                            </td>
                                            <td><div className='td-container'>{item?.accountCaseId}</div></td>
                                            <td><div className='td-container'>{item?.accountCodeName}</div></td>
                                            <td><div className='td-container'>{item?.reference}</div></td>
                                            <td><div className='td-container'>{item?.withholdingTax?.toLocaleString()}</div></td>
                                            <td><div className='td-container totalPrice'>{item?.amount?.toLocaleString()}</div></td>
                                            <td><div className='td-container'>{formatDate(item?.uploadDate)}</div></td>
                                            <td><div className='td-container'>{formatDate(item?.checkDate)}</div></td>
                                            <td><div className='td-container'>{item?.checkType}</div></td>
                                            <td><div className='td-container'>{item?.description}</div></td>
                                            <td><div className='td-container'>{item?.uploadNumber}</div></td>
                                        </>
                                        {/* Edit and Delete Buttons */}
                                        <td>
                                            {editingRow === item.id ? (
                                                <div className='row-buttons-container'>
                                                    <button className='row-buttons' onClick={() => handleUpdate(item.id)}> <img src={saveIcon} alt="Income" className="doc-details-icon" /></button>
                                                    <button className='row-buttons' onClick={handleCancel}> <img src={cancelIcon} alt="Income" className="doc-details-icon" /></button>
                                                </div>
                                            ) : (
                                                <div className='row-buttons-container'>
                                                    <button className='row-buttons' onClick={() => handleEdit(item)}> <img src={editIcon} alt="Income" className="doc-details-icon" /></button>
                                                    {
                                                        (isRange ? !item?.isClosedReportingMonth : !isClosedReportingMonth) && (
                                                            <button className='row-buttons' onClick={() => handleDeleteClick(item.id)}>
                                                                <img src={deleteIcon} alt="Delete" className="doc-details-icon" />
                                                            </button>
                                                        )
                                                    }
                                                </div>
                                            )}
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                    {/* Sidebar */}
                    <aside>
                        {/* Sidebar links would go here */}
                    </aside>
                </div>
                <HamburgerMenu client={client} />
                <CustomDeleteConfirmationModal
                    isVisible={isModalVisible}
                    onClose={() => setIsModalVisible(false)}
                    onDelete={handleConfirm}
                    confirmMessage={getConfirmMessage()}
                    titleMessage={getTitleMessage()}
                />
                <ToastContainer />
                {loading && (
                    <div className="loader-container">
                        <PuffLoader
                            size={100}        // Larger size for better visibility
                            loading={loading}
                        />
                    </div>
                )}
                {editedItem && Object.keys(editedItem).length > 0 && (
                    <div className="edit-modal"> {/* This should be styled appropriately */}
                        <div className="edit-modal-content">
                            <div className="edit-modal-content-details">
                                <div className="edit-modal-content-left-details" >
                                    <div className='edit-document-title'>{editedItem.uploadNumber} {t('Document')}</div>
                                    <div className='edit-pdf-td' >
                                        {/* <div className="iframe-overlay"></div> */}
                                        {/* <iframe
                                            src={`${editedItem.documentUrl}#page=1`}
                                            className='edit-table-pdf'
                                            title={editedItem.title}
                                        ></iframe> */}
                                        <Iframe
                                            url={`${editedItem.documentUrl}#page=1`}
                                            width="100%"
                                            height="95%"
                                            id={editedItem.id}
                                            className="edit-table-pdf"
                                            display="initial"
                                            position="relative"
                                            title={editedItem.title}
                                        />
                                    </div>
                                </div>
                                <div className="edit-modal-content-right-details" >
                                    <div className='edit-modal-content-up-container'>
                                        <div className='edit-modal-content-left-container'>


                                            <div className='edit-document-input-container'>


                                            </div>

                                            <div className='edit-document-input-container'>
                                                {
                                                    !editedAddAccountCard ?
                                                        <>
                                                            <Select
                                                                classNamePrefix="edit-my-select"
                                                                options={accountCardOptions.map(option => ({ value: option.name, label: option.name, value1: option.value1, label: option.accountCaseId ? `${option.name} - ${option?.accountCaseId} ` : `${option.name} `, value1: option.value1, accountCaseId: option.accountCaseId || '' }))}
                                                                value={accountCardOptions
                                                                    .filter(option => option.name === editedItem.accountCodeName)
                                                                    .map(option => ({ value: option.name, label: option.name, label: option?.accountCaseId ? `${option.name} - ${option?.accountCaseId} ` : `${option.name} `, value1: option.value1, accountCaseId: option.accountCaseId || '' }))[0]}
                                                                onChange={(selectedOption) => handleAccountCardChange(selectedOption, 'accountCodeName')}
                                                                isDisabled={!isRange ? isClosedReportingMonth : editedItem.isClosedReportingMonth}
                                                                placeholder={t('SelectCheckTitle')}
                                                            />
                                                            <div className='edit-document-input-name'>:{t('CheckTitle')}</div>                                                        </>
                                                        :
                                                        <div className='items_Container'>
                                                            <button className='clientItemButton ' onClick={handleCloseEditAddNewAccountCardDocument}>
                                                                {t('ReturnToNamesList')}
                                                                <img src={backIcon} alt="Income" className="clientItem-icon clientItemListBack" />
                                                            </button>
                                                            <div className="input-field_Container ">
                                                                <input
                                                                    className="input-field input-field_Container_required-asterisk"
                                                                    type="number"
                                                                    placeholder={t('CompanyId')}
                                                                    value={editedItem.accountCaseId}
                                                                    onChange={(e) => handleEditAddAccountChange(e.target.value, 'AccountCaseId')}
                                                                />
                                                                <div className='edit-document-input-name'>:{t('CompanyId')}</div>
                                                            </div>
                                                            <div className="input-field_Container ">
                                                                <input
                                                                    className="input-field input-field_Container_required-asterisk"
                                                                    type="text"
                                                                    placeholder={t('Card Name')}
                                                                    value={editedItem.accountCodeName}
                                                                    onChange={(e) => handleEditAddAccountChange(e.target.value, 'AccountCodeName')}
                                                                />
                                                                <div className='edit-document-input-name'>:{t('Card Name')}</div>
                                                            </div>
                                                            <div className="input-field_Container ">
                                                                <input
                                                                    className="input-field input-field_Container_required-asterisk"
                                                                    type="number"
                                                                    placeholder={t('Card ID')}
                                                                    value={editedItem.accountCodeId}
                                                                    onChange={(e) => handleEditAddAccountChange(e.target.value, 'AccountCodeId')}
                                                                />
                                                                <div className='edit-document-input-name'>:{t('Card ID')}</div>
                                                            </div>

                                                        </div>
                                                }
                                            </div>

                                            <div className='edit-document-input-container'>
                                                <input
                                                    type="number"
                                                    className='edit-input-field'
                                                    value={editedItem.withholdingTax || ''}
                                                    onChange={(e) => handleChange(e, 'withholdingTax')}
                                                    disabled={!isRange ? isClosedReportingMonth : editedItem.isClosedReportingMonth}
                                                />
                                                <div className='edit-document-input-name'>:{t('WithholdingTax')}</div>

                                            </div>
                                            <div className='edit-document-input-container'>
                                                <Select
                                                    classNamePrefix="edit-my-select"
                                                    options={checkTypeOptions.map(option => ({ value: option.name, label: option.name }))}
                                                    value={checkTypeOptions
                                                        .filter(option => option.name === editedItem.checkType)
                                                        .map(option => ({ value: option.name, label: option.name }))[0]} // Ensure it's in the correct format
                                                    onChange={(selectedOption) => handleChange({ target: { value: selectedOption.value } }, 'checkType')}
                                                    isDisabled={!isRange ? isClosedReportingMonth : editedItem.isClosedReportingMonth}
                                                    placeholder={t('Select CheckType')} />
                                                <div className='edit-document-input-name'>:{t('CheckType')}</div>

                                            </div>
                                            <div className='edit-document-input-container'>
                                                <div className="edit-date-input-container">
                                                    <ReactDatePicker
                                                        selected={new Date(editedItem.uploadDate)}
                                                        onChange={(e) => handleDateChange(e, 'uploadDate')}
                                                        dateFormat="dd-MM-yyyy"
                                                        className="date-input"
                                                        customInput={<CustomInput2 />}
                                                        disabled={!isRange ? isClosedReportingMonth : editedItem.isClosedReportingMonth}
                                                    />
                                                    {/* <img src={calendarIcon} alt="Calendar" className="calendar-icon" /> */}

                                                </div>
                                                <div className='edit-document-input-name'>:{t('Upload Date')}</div>
                                            </div>
                                            <div className='edit-document-input-container'>
                                                <div className="edit-date-input-container">
                                                    <ReactDatePicker
                                                        selected={new Date(editedItem.checkDate)}
                                                        onChange={(e) => handleDateChange(e, 'checkDate')}
                                                        dateFormat="dd-MM-yyyy"
                                                        className="date-input"
                                                        customInput={<CustomInput2 />}
                                                        disabled={!isRange ? isClosedReportingMonth : editedItem.isClosedReportingMonth}
                                                    />
                                                    {/* <img src={calendarIcon} alt="Calendar" className="calendar-icon" /> */}

                                                </div>
                                                <div className='edit-document-input-name'>:{t('Check Date')}</div>
                                            </div>

                                        </div>
                                        <div className='edit-modal-content-right-container'>
                                            {/* <div className='edit-document-input-container'>
                                                {
                                                    editedAccountCard ? (
                                                        <div className="accountCodeId-section">
                                                            <input
                                                                className='input-field'
                                                                type="text"
                                                                value={editedItem.accountCodeId || ''}
                                                                onChange={(e) => handleAccountCardIdChange(e)}
                                                            />
                                                            <img src={backInputIcon} alt="Back" className="calendar-icon" onClick={() => handleBackAccountCardIdChange()} />
                                                        </div>
                                                    ) : (
                                                        <Select
                                                            classNamePrefix="edit-my-select"
                                                            options={editAccountCardOptions.map(option => ({ value: option.cardId, label: option.cardId }))}
                                                            value={editAccountCardOptions
                                                                .filter(option => option.cardId === editedItem.accountCodeId)
                                                                .map(option => ({ value: option.cardId, label: option.cardId }))[0]}
                                                            onChange={(selectedOption) => handleAccountCardChange(selectedOption, 'accountCodeId')}
                                                            isDisabled={!isRange ? isClosedReportingMonth : editedItem.isClosedReportingMonth}
                                                            placeholder={t('SelectCheckTitleId')}
                                                        />
                                                    )
                                                }
                                                <div className='edit-document-input-name'>:{t('CheckTitleId')}</div>
                                            </div> */}
                                            <div className='edit-document-input-container'>
                                                <input
                                                    type="number"
                                                    className='edit-input-field'
                                                    value={editedItem.amount || ''}
                                                    onChange={(e) => handleChange(e, 'amount')}
                                                    disabled={!isRange ? isClosedReportingMonth : editedItem.isClosedReportingMonth}
                                                />
                                                <div className='edit-document-input-name'>:{t('Amount')}</div>

                                            </div>
                                            <div className='edit-document-input-container'>
                                                <input
                                                    type="text"
                                                    className='edit-input-field'
                                                    value={editedItem.reference || ''}
                                                    onChange={(e) => handleChange(e, 'reference')}
                                                    disabled={!isRange ? isClosedReportingMonth : editedItem.isClosedReportingMonth}
                                                />
                                                <div className='edit-document-input-name'>:{t('Reference')}</div>

                                            </div>
                                            <div className='edit-document-input-container'>
                                                <div className='edit-checkBox-field'>
                                                    <RadioGroup
                                                        row
                                                        aria-labelledby="demo-form-control-label-placement"
                                                        name="position"
                                                        defaultValue="top"
                                                        onChange={handleRadioChange}
                                                    >
                                                        <FormControlLabel
                                                            value="notDone"
                                                            control={<Radio />}
                                                            label={t('unprocessed')}
                                                            labelPlacement="top"
                                                            disabled={!isRange ? isClosedReportingMonth : editedItem.isClosedReportingMonth}
                                                            checked={!stautsCheck}
                                                        />
                                                        <FormControlLabel
                                                            value="done"
                                                            control={<Radio />}
                                                            label={t('processed')}
                                                            labelPlacement="top"
                                                            disabled={!isRange ? isClosedReportingMonth : editedItem.isClosedReportingMonth}
                                                            checked={stautsCheck}
                                                        />
                                                    </RadioGroup>
                                                </div>

                                                <div className='edit-document-input-name'>:{t('status')}</div>

                                            </div>
                                            <div className='edit-document-input-container'>
                                                <div className="edit-date-input-container">
                                                    <ReactDatePicker
                                                        selected={new Date(editedItem.reportingMonthDate)}
                                                        onChange={(e) => setReportingMonthDate(e, 'reportingMonthDate')}
                                                        dateFormat="MM-yyyy"
                                                        className="date-input"
                                                        showMonthYearPicker
                                                        customInput={<CustomInput2 />}
                                                        disabled={!isRange ? isClosedReportingMonth : editedItem.isClosedReportingMonth}
                                                    />
                                                    {/* <img src={calendarIcon} alt="Calendar" className="calendar-icon" /> */}

                                                </div>
                                                <div className='edit-document-input-name'>:{t('MonthReportDate')}</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='edit-modal-content-down-container'>

                                        <textarea
                                            // type="text"
                                            className='edit-description-input-field'
                                            value={editedItem.description || ''}
                                            onChange={(e) => handleChange(e, 'description')}
                                            disabled={!isRange ? isClosedReportingMonth : editedItem.isClosedReportingMonth}
                                        />
                                        <div className='edit-document-input-description'>:{t('Description')}</div>
                                    </div>
                                </div>
                            </div>
                            <div className='edit-buttons-container'>
                                {
                                    (isRange ? !editedItem?.isClosedReportingMonth : !isClosedReportingMonth) && (
                                        <button className='edit-button-save' onClick={handleUpdate}>
                                            {t('save')}
                                        </button>
                                    )
                                }
                                <button className='edit-button-cancel' onClick={handleCancel}>{t('close')}</button>
                            </div>

                        </div>
                    </div>
                )}

            </div>
        </>

    );
}


export default Checks;
