import React, { useState, useContext } from 'react';
import './BiMonthlyPeriodModal.css'; // Import the CSS file

const BiMonthlyPicker = ({ visible, onClose, date, onDateChange, t }) => {
    const biMonthlyPeriods = [
        { label: '1-2', value: 0 },
        { label: '3-4', value: 2 },
        { label: '5-6', value: 4 },
        { label: '7-8', value: 6 },
        { label: '9-10', value: 8 },
        { label: '11-12', value: 10 },
    ];


    const translateLabel = (label) => {
        const translationsMap = {
            '1-2': '1-2',
            '3-4': '3-4',
            '5-6': '5-6',
            '7-8': '7-8',
            '9-10': '9-10',
            '11-12': '11-12',
        };
        return translationsMap[label] || label;
    };

    const [selectedYear, setSelectedYear] = useState(date.getFullYear());

    const handlePeriodChange = (period) => {
        const startMonth = period.value;
        const endMonth = period.value + 1;
        const startDate = new Date(Date.UTC(selectedYear, startMonth, 1));
        const endDate = new Date(Date.UTC(selectedYear, endMonth + 1, 0, 23, 59, 59, 999));
        onDateChange(startDate, endDate);
        onClose();
    };

    const handleYearChange = (direction) => {
        setSelectedYear((prevYear) => prevYear + direction);
    };

    if (!visible) return null;

    return (
        <div className="modalBackdrop" onClick={onClose}>
            <div className="centeredView" onClick={(e) => e.stopPropagation()}>
                <div className="modalView">
                    <h2 className="modalTitle">{t('SelectBiMonthlyDate')}</h2>
                    <div className="yearSelector">
                        <button className='add-subtract-button' onClick={() => handleYearChange(-1)}><p>-</p></button>
                        <span className="yearText">{selectedYear}</span>
                        <button className='add-subtract-button' onClick={() => handleYearChange(1)}><p>+</p></button>
                    </div>
                    {biMonthlyPeriods.map((period) => (
                        <button
                            key={period.value}
                            className="biMonthlyPickerItem"
                            onClick={() => handlePeriodChange(period)}
                        >
                            {translateLabel(period.label)}
                        </button>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default BiMonthlyPicker;
