import React, { useState, useEffect, useMemo } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TablePagination,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { beautifyNumber } from '../../../Utils/FormatNumber';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';

const TransactionsTable = ({
  transactions,
  formatDate,
  handleEdit,
  handleSortChange,
  sortConfig,
}) => {
  const [currentPage, setCurrentPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(100);
  const { i18n, t } = useTranslation();
  const direction = i18n.dir();

  // Reset page when data changes
  useEffect(() => {
    setCurrentPage(0);
  }, [transactions]);

  // Pagination logic
  const paginatedTransactions = useMemo(() => {
    const start = currentPage * rowsPerPage;
    const end = start + rowsPerPage;
    return transactions.slice(start, end);
  }, [transactions, currentPage, rowsPerPage]);

  const handleChangePage = (event, newPage) => {
    setCurrentPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setCurrentPage(0);
  };

  const headerStyle = {
    backgroundColor: 'var(--foundation-blue-light-active, #BFC8FF)',
    color: 'var(--Foundation-Blue-Normal, #304FFF)',
    textAlign: 'center',
    fontFamily: 'Montserrat',
    fontSize: '14px',
    fontWeight: 'bold',
    height: '56px',
    padding: '0',
    cursor: 'pointer',
  };

  return (
    <div className={`${direction}`} style={{ width: '100%', height: '55vh' }}>
      <TableContainer component={Paper} sx={{ height: '50vh', overflow: 'auto' }}>
        <Table aria-label="Transactions Table" stickyHeader>
          <TableHead>
            <TableRow>
              {[
                { key: 'transactionDate', label: t('date'), type: 'date' },
                { key: 'description', label: t('Description'), type: 'string' },
                { key: 'reference1', label: t('Reference'), type: 'string' },
                { key: 'debitAccount', label: t('Debit'), type: 'number' },
                { key: 'creditAccount', label: t('Credit'), type: 'number' },
                { key: 'balance', label: t('Balance'), type: 'number' },
              ].map((header) => (
                <TableCell
                  key={header.key}
                  align="center"
                  className="table-headers"
                  sx={headerStyle} // Applied updated headerStyle here
                  onClick={() => handleSortChange(header.key, header.type)}
                >
                  <div style={{display : 'flex',justifyContent: 'center',alignItems: 'center',gap:'5px'}}>

                    {header.label}

                    {sortConfig.key === header.key && (
                      sortConfig.direction === 'ascending' ? (
                        <ArrowUpwardIcon fontSize="small" />
                      ) : (
                        <ArrowDownwardIcon fontSize="small" />
                      )
                    )}
                  </div>

                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {paginatedTransactions.map((transaction, index) => (
              <TableRow key={transaction.id || index} onClick={() => handleEdit(transaction)}>
                <TableCell align="center">
                  {transaction.transactionDate
                    ? formatDate(transaction.transactionDate)
                    : '-'}
                </TableCell>
                <TableCell align="center">{transaction.description || '-'}</TableCell>
                <TableCell align="center">{transaction.reference1 || '-'}</TableCell>
                <TableCell align="center">
                  {beautifyNumber(transaction.debitAccount)}
                </TableCell>
                <TableCell align="center">
                  {beautifyNumber(transaction.creditAccount)}
                </TableCell>
                <TableCell align="center">{beautifyNumber(transaction.balance)}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      {/* Pagination Controls */}
      <TablePagination
        rowsPerPageOptions={[5, 10, 25, 50, 100]}
        component="div"
        count={transactions.length}
        rowsPerPage={rowsPerPage}
        page={currentPage}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        labelRowsPerPage={t('Rows per page')}
        labelDisplayedRows={({ from, to, count }) =>
          `${from}-${to} ${t('of')} ${count !== -1 ? count : `${t('more than')} ${to}`}`
        }
      />
    </div>
  );
};

export default TransactionsTable;
