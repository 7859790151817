import React, { useState, useMemo, useEffect, useRef } from 'react';
import {
    TableContainer,
    Paper,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    TablePagination,
    IconButton,
} from '@mui/material';
import { KeyboardArrowUp, KeyboardArrowDown } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import { formatDate_MM_YYYY, formatDateRangeFromString } from '../../../Utils/FormatDate';
import { beautifyNumber } from '../../../Utils/FormatNumber';
const TrialBalanceTable = ({
    palDates,
    isMonthly,
    palCodesData,
    footerTotals,
    handleSortChange,
    handleSelect,
    handleRightClickedRow,
    selectedSearchType,
    searchQuery,
}) => {
    const [currentPage, setCurrentPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(100);
    const [openRows, setOpenRows] = useState({});
    const tableContainerRef = useRef(null);
    const { t, i18n } = useTranslation();
    const direction = i18n.dir();
    // Reset page when data changes
    useEffect(() => {
        setCurrentPage(0);
    }, [palCodesData]);
    // Pagination logic
    const paginatedData = useMemo(() => {
        const start = currentPage * rowsPerPage;
        const end = start + rowsPerPage;
        return palCodesData?.slice(start, end);
    }, [palCodesData, currentPage, rowsPerPage]);
    // Handle row expansion
    const handleToggleRow = (index) => {
        setOpenRows((prevOpenRows) => ({
            ...prevOpenRows,
            [index]: !prevOpenRows[index],
        }));
    };

    // Memoized rows
    const memoizedRows = useMemo(() => {
        return paginatedData?.map((palCode, index) => (
            <Row
                key={palCode.palCodeId}
                palCode={palCode}
                dateHeaders={palDates}
                open={!!openRows[index]}
                handleToggleRow={() => handleToggleRow(index)}
                onRightClick={handleRightClickedRow}
                handleSelect={handleSelect}
                selectedSearchType={selectedSearchType}
                searchQuery={searchQuery}
                t={t}
            />
        ));
    }, [
        paginatedData,
        openRows,
        palDates,
        handleRightClickedRow,
        handleSelect,
        selectedSearchType,
        searchQuery,
        t,
    ]);



    const handleChangePage = (event, newPage) => {
        setCurrentPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setCurrentPage(0);
    };

    const headerStyle = {
        backgroundColor: 'var(--foundation-blue-light-active, #BFC8FF)',
        color: 'var(--Foundation-Blue-Normal, #304FFF)',
        textAlign: 'center',
        fontFamily: 'Montserrat',
        fontSize: '14px',
        fontWeight: 'bold',
        height: '56px',
        padding: '0',
    };

    return (
        <div className={`${direction}`} style={{ width: '100%', height: '65vh' }}>
            <TableContainer
                component={Paper}
                sx={{ height: '60vh', overflow: 'auto' }}
                ref={tableContainerRef}
            >
                <Table
                    aria-label="Trial Balance Table"
                    stickyHeader
                    className="sticky-header"
                    style={{ zIndex: 1 }}
                >
                    <TableHead>
                        <TableRow>
                            <TableCell
                                align="center"
                                className="table-headers"
                                sx={headerStyle}
                                onClick={() =>
                                    handleSortChange('description', 'descending')
                                }
                            >
                                {t('Description')}
                            </TableCell>


                            {[...palDates].map((date) => (
                                <TableCell
                                    key={date}
                                    align="center"
                                    className="table-headers"
                                    sx={headerStyle}
                                    onClick={() =>
                                        handleSortChange('dateValues', 'number')
                                    }
                                >
                                    {date === 'InitialBalance'
                                        ? t('InitialBalance')
                                        : isMonthly
                                            ? formatDate_MM_YYYY(date)
                                            : formatDateRangeFromString(date)}
                                </TableCell>
                            ))}
                            <TableCell
                                align="left"
                                className="table-headers"
                                sx={headerStyle}
                                onClick={() =>
                                    handleSortChange('total', 'descending')
                                }
                            >
                                {t('Total')}
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {memoizedRows}
                        {/* Footer Row */}
                        <TableRow
                            sx={{
                                backgroundColor: '#e8e8e8',
                                position: 'sticky',
                                bottom: 0,
                                zIndex: 1,
                            }}
                        >
                            <TableCell
                                align="left"
                                style={{ fontWeight: 'bold', color: 'black' }}
                            >
                                {t('Total')}
                            </TableCell>


                            {[...palDates].map((date) => (
                                <TableCell
                                    key={date}
                                    align="left"
                                    style={{ fontWeight: 'bold', color: 'black' }}
                                >
                                    {beautifyNumber(
                                        footerTotals.dateTotals[date] || 0
                                    )}
                                </TableCell>
                            ))}
                            <TableCell
                                align="center"
                                style={{ fontWeight: 'bold', color: 'black' }}
                            >
                                {beautifyNumber(footerTotals.grandTotal)}
                            </TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                rowsPerPageOptions={[5, 10, 25, 50, 100]}
                component="div"
                count={palCodesData?.length}
                rowsPerPage={rowsPerPage}
                page={currentPage}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                labelRowsPerPage={t('Rows per page')}
                labelDisplayedRows={({ from, to, count }) =>
                    `${from}-${to} ${t('of')} ${count !== -1 ? count : `${t('more than')} ${to}`
                    }`
                }
            />
        </div>
    );
};

export default TrialBalanceTable;

// Row Component
const Row = ({
    palCode,
    dateHeaders,
    open,
    handleToggleRow,
    onRightClick,
    handleSelect,
    selectedSearchType,
    searchQuery,
    t,
}) => {
    // High-level total sum for the palCode (sum of all accountCards)
    const palCodeTotal = palCode.accountCards.reduce((sum, accountCard) => {
        return (
            sum +
            accountCard.dateAmounts.reduce(
                (acc, dateAmount) => acc + (dateAmount ? dateAmount.amount : 0),
                0
            )
        );
    }, 0);

    return (
        <>
            <TableRow
                sx={{
                    transition: 'background-color 0.2s ease-in-out',
                    '&:hover': {
                        backgroundColor: 'rgba(0, 0, 0, 0.04)',
                        cursor: 'pointer',
                    },
                }}
            >
                {/* Last cell for the sum of the row */}
                <TableCell
                    sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        justifyContent: 'left',
                        fontSize: '0.8rem',
                        fontWeight: 'bold',
                    }}
                >
                    <IconButton
                        aria-label="expand row"
                        size="small"
                        onClick={handleToggleRow}
                    >
                        {open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
                    </IconButton>
                    <span>
                        {palCode.palCodeId === 'NonPalCode'
                            ? t(palCode.palCodeId)
                            : palCode.palCodeId}
                    </span>
                </TableCell>

                {[...dateHeaders].map((date) => (
                    <TableCell key={date} align="center">
                        {beautifyNumber(
                            palCode.accountCards.reduce((sum, accountCard) => {
                                const dateAmount = accountCard.dateAmounts.find(
                                    (da) => da.date === date
                                );
                                return sum + (dateAmount ? dateAmount.amount : 0);
                            }, 0)
                        )}
                    </TableCell>
                ))}
                {/* High-level sum (total sum of the palCode row) */}
                <TableCell align="left" style={{ fontWeight: 'bold' }}>
                    {beautifyNumber(palCodeTotal)}
                </TableCell>
            </TableRow>

            {(open ||
                (selectedSearchType === 'AccountCard' && searchQuery)) &&
                palCode.accountCards.map((accountCard, index) => {
                    // Low-level total sum for each accountCard
                    const accountCardTotal = accountCard.dateAmounts.reduce(
                        (sum, dateAmount) =>
                            sum + (dateAmount ? dateAmount.amount : 0),
                        0
                    );

                    return (
                        <TableRow
                            onClick={() => handleSelect(accountCard, palCode)}
                            onContextMenu={(e) => {
                                e.preventDefault();
                                onRightClick(accountCard, palCode);
                            }}
                            className="open-rows rowHover"
                            sx={{
                                boxShadow:
                                    'inset 0px 2px 200px 0px rgba(250, 235, 215, 0.2)',
                                transition:
                                    'transform 0.2s ease, box-shadow 0.1s ease',
                                '&:hover': {
                                    transform: 'translateY(-3px)',
                                    cursor: 'pointer',
                                },
                            }}
                            key={index}
                        >
                            <TableCell component="th" scope="row">
                                {accountCard.name}
                            </TableCell>

                            {[...dateHeaders].map((date) => (
                                <TableCell key={date} align="left">
                                    {beautifyNumber(
                                        accountCard.dateAmounts.find(
                                            (da) => da.date === date
                                        )?.amount || 0
                                    )}
                                </TableCell>
                            ))}
                            {/* Low-level sum (total sum of the accountCard row) */}
                            <TableCell align="left" style={{ fontWeight: 'bold' }}>
                                {beautifyNumber(accountCardTotal)}
                            </TableCell>
                        </TableRow>
                    );
                })}
        </>
    );
};
